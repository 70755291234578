import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventTracking from 'lib/event-tracking'
import trackPosthog from 'lib/posthog/tracker'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import validationResponseHelper from 'lib/validation-response-handler'
import eventOrder from 'services/Order/event'
import IOrderModel from 'services/Order/models/OrderModel/interface'
import store from 'stores/index'

const OrderModel: IOrderModel = {
  isLoading: false,
  isLoadingPatchOrder: false,
  isError: false,
  dataOrder: null,
  grandTotal: {
    totalPrice: '0',
    grandTotal: '0',
    wallet: '0',
    walletTotal: 0,
    discountPrice: '0',
    discountShippingPrice: '0',
    shippingPrice: '0',
    feeAmount: '0',
    feePercentage: '0',
    insuranceFee: '0',
  },
  shippingTracks: null,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsLoadingPatchOrder: action((state, payload) => {
    state.isLoadingPatchOrder = payload
  }),
  setDataOrder: action((state, payload) => {
    state.isLoading = false
    state.isError = false
    state.dataOrder = payload
  }),
  setShippingTracks: action((state, payload) => {
    state.shippingTracks = payload
  }),
  calculateGrandTotal: action((state, payload) => {
    const totalPrice = parseFloat(payload.totalPrice || '0')
    const shippingPrice = parseFloat(payload.shippingPrice || '0')
    const discountPrice = parseFloat(payload.discountPrice || '0')
    const discountShippingPrice = parseFloat(
      payload.discountShippingPrice || '0',
    )
    const wallet = parseFloat(payload.wallet || '0')
    const insuranceFee = parseFloat(payload.insuranceFee || '0')
    const feePercentage = parseFloat(payload.feePercentage || '0')
    /**
     * function to calculate all price by user
     * @returns float
     */
    const calculateTotalPrice = () => {
      return totalPrice + shippingPrice + insuranceFee
    }
    /**
     * function to calculate all discount to user
     * @returns float
     */
    const calculateDiscountPrice = () => {
      return discountPrice + discountShippingPrice
    }
    /**
     * function to calculate total wallet has been used from user account
     * @returns float
     */
    const sumWallet = () => {
      const subTotalPrice = calculateTotalPrice()
      const totalDiscountPrice = calculateDiscountPrice()

      const grandTotalPrice = subTotalPrice - totalDiscountPrice
      if (wallet > grandTotalPrice) {
        return grandTotalPrice
      }

      const remainingToPay = grandTotalPrice - wallet
      if (
        grandTotalPrice > 11000 &&
        remainingToPay < 11000 &&
        remainingToPay !== 0
      ) {
        return wallet - (11000 - remainingToPay)
      }

      return wallet
    }

    const subTotalPrice = calculateTotalPrice()
    const totalDiscountPrice = calculateDiscountPrice()
    const usedWalletAmount = sumWallet()

    const feeAmount = Math.floor(
      (subTotalPrice - totalDiscountPrice) * (feePercentage / 100),
    )

    state.grandTotal = {
      totalPrice: payload.totalPrice || '0',
      grandTotal: `${
        subTotalPrice -
        (usedWalletAmount + totalDiscountPrice) +
        feeAmount
      }`,
      wallet: payload.wallet || '0',
      walletTotal: usedWalletAmount,
      discountPrice: payload.discountPrice || '0',
      discountShippingPrice: payload.discountShippingPrice || '0',
      shippingPrice: payload.shippingPrice || '0',
      feeAmount: `${feeAmount}`,
      feePercentage: payload.feePercentage || '0',
      insuranceFee: payload.insuranceFee || '0',
    }
  }),
  postCreateOrder: thunk(
    async (actions, payload, { injections, getState }) => {
      actions.setIsLoading(true)
      const useWalletHeader = getState()?.dataOrder?.use_wallet
        ? {
            'X-Ota': store.getState().otp?.ota,
          }
        : {}
      const useRalaliPlusHeader =
        getState()?.dataOrder?.payment_method_id ===
        store.getState()?.paymentMethod?.ralaliPlusPaymentMethod?.id
          ? {
              'X-Ota': store.getState()?.otp?.ota,
            }
          : {}
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/${payload.apiVersion}/orders`,
          method: 'POST',
          data: getState().dataOrder,
          headers: {
            'Device-Name': 'desktop',
            'X-Lang': 'id',
            ...useWalletHeader,
            ...useRalaliPlusHeader,
          },
        })
        if (response?.status === 200) {
          let targetUrl = ''
          if (response?.data?.data?.payment) {
            const paymentData = response.data.data.payment
            if (paymentData.url) targetUrl = paymentData.url
            else if (paymentData.completion_url) {
              targetUrl = paymentData.completion_url
            }

            const {
              cart_id: cartId,
              shipments,
              promo_code: coupon,
              facture_required: factureRequired,
            } = getState().dataOrder

            const {
              data: paymentMethodData,
            } = store.getState().paymentMethod

            const posthog = eventTracking(trackPosthog())
            const paymentGroup = paymentMethodData?.find((group) =>
              group.payment_methods.some(
                (method) => method.id === paymentData.method_id,
              ),
            )?.name

            posthog.track('Submitted', {
              Name: 'Create Order SKU',
              'Cart ID': cartId,
              'Wallet Usage': paymentData.wallet_usage,
              Coupon: coupon,
              Facture: factureRequired,
              'Payment Method': paymentData.method_name,
              'Payment Group': paymentGroup,
              'Order Serial': response.data.data.order_serial,
              'Grand Total': getState().grandTotal.grandTotal,
              'Shipping Price': getState().grandTotal.shippingPrice,
              Shipments: shipments.reduce((prev, curr) => {
                return {
                  ...prev,
                  [curr.seller_id]: {
                    expedition: curr.expedition,
                    service: curr.service,
                  },
                }
              }, {}),
            })
          }
          return {
            orderSerial: response.data.data.order_serial,
            payment: response.data.data.payment?.method_name,
            targetUrl,
          }
        }

        sendLogHitEndpoint(
          eventOrder.postCreateOrder.event,
          dependencyContextMapper(
            eventOrder.postCreateOrder.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (error) {
        actions.error()
        const message = validationResponseHelper(
          error,
          'top-center',
          true,
          {
            toastClientSideError: false,
          },
        )
        Toast(message, {
          toastId: 'error--post-create-order',
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          autoClose: 5000,
          pauseOnHover: true,
        })

        sendLogHitEndpoint(
          eventOrder.postCreateOrder.event,
          dependencyContextMapper(
            eventOrder.postCreateOrder.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
      }
      return null
    },
  ),
  patchOrderStatus: thunk(
    async (actions, payload, { injections }) => {
      actions.setIsLoadingPatchOrder(true)
      try {
        const { apiClient } = injections
        const { orderId, orderStatus, params } = payload
        const response = await apiClient({
          url: `/marketplace/v1/orders/${orderId}`,
          method: 'PATCH',
          data: {
            status: orderStatus,
            update_order_by: {
              identifier: 'seller_id',
              params,
            },
          },
        })
        return response
      } catch (error) {
        const message = validationResponseHelper(
          error,
          'top-center',
          true,
          {
            toastClientSideError: false,
          },
        )
        Toast(message, {
          toastId: 'error--patch-order-status',
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          autoClose: 5000,
          pauseOnHover: true,
        })
        return null
      } finally {
        actions.setIsLoadingPatchOrder(false)
      }
    },
  ),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
  resetError: action((state) => {
    state.isError = false
  }),
  getShippingTracks: thunk(
    async (actions, { orderSerial, vendorId }, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.resetError()
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/orders/${orderSerial}/shipping-tracks`,
          method: 'GET',
          params: {
            vendor_id: vendorId,
          },
        })

        sendLogHitEndpoint(
          eventOrder.getShippingTracks.event,
          dependencyContextMapper(
            eventOrder.getShippingTracks.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )

        actions.setShippingTracks(
          objectToCamelCase(response.data.data),
        )
      } catch (error) {
        actions.error()
        sendLogHitEndpoint(
          eventOrder.getShippingTracks.event,
          dependencyContextMapper(
            eventOrder.getShippingTracks.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default OrderModel
