import Image from 'common/components/Image'
import Loader from 'common/components/Loader'
import theme from 'common/theme'
import cdn from 'lib/cdn'
import { useState } from 'react'
import styled from 'styled-components'

const WrapperMessageBirdChatIcon = styled.section`
  position: fixed;
  width: 64px;
  height: 64px;
  bottom: 36px;
  right: 36px;
  background-color: ${theme.colors.white};
  box-shadow: 0 0 24px 0 rgb(104 121 146 / 20%);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: ${theme.zIndex.messageBird};
  ${({ isLoading }) => isLoading && `pointer-events: none;`}
  > * {
    display: flex;
  }
`

function MessageBirdChatWidget(): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hideComponent, setHideComponent] = useState<boolean>(false)

  const loadScript = () => {
    setIsLoading(true)

    const script = document.createElement('script')
    script.innerHTML = `var MessageBirdChatWidgetSettings={widgetId: '${process.env.NEXT_PUBLIC_MESSAGEBIRD_ID}',initializeOnLoad:true,};!function(){"use strict";if(Boolean(document.getElementById("live-chat-widget-script")))console.error("MessageBirdChatWidget: Snippet loaded twice on page");else{var e,t;window.MessageBirdChatWidget={},window.MessageBirdChatWidget.queue=[];for(var i=["init","setConfig","toggleChat","identify","hide","on","shutdown"],n=function(){var e=i[d];window.MessageBirdChatWidget[e]=function(){for(var t=arguments.length,i=new Array(t),n=0;n<t;n++)i[n]=arguments[n];window.MessageBirdChatWidget.queue.push([[e,i]])}},d=0;d<i.length;d++)n();var a=(null===(e=window)||void 0===e||null===(t=e.MessageBirdChatWidgetSettings)||void 0===t?void 0:t.widgetId)||"",o=function(){var e,t=document.createElement("script");t.type="text/javascript",t.src="https://livechat.messagebird.com/bootstrap.js?widgetId=".concat(a),t.async=!0,t.id="live-chat-widget-script";var i=document.getElementsByTagName("script")[0];null==i||null===(e=i.parentNode)||void 0===e||e.insertBefore(t,i)};"complete"===document.readyState?o():window.attachEvent?window.attachEvent("onload",o):window.addEventListener("load",o,!1)}}();`
    document.body.appendChild(script)
    window.MessageBirdChatWidget.on('ready', () => {
      setHideComponent(true)
      window.MessageBirdChatWidget.toggleChat(true)
    })
  }

  if (!process.env.NEXT_PUBLIC_MESSAGEBIRD_ID || hideComponent) {
    return null
  }

  return (
    <WrapperMessageBirdChatIcon
      isLoading={isLoading}
      onClick={() => loadScript()}
    >
      {isLoading ? (
        <Loader radius="50px" />
      ) : (
        <Image
          src={cdn({ path: '/assets/img/messagebird-icon.png' })}
          width="48px"
          height="48px"
        />
      )}
    </WrapperMessageBirdChatIcon>
  )
}

export default MessageBirdChatWidget
