import { DEFAULT_LANG, LANG_COOKIE_NAME } from 'common/constants'
import { action, thunk } from 'easy-peasy'
import { getCookie } from 'lib/cookie'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import camelToSnakeKeys from 'lib/transform/camel-to-snake-case'
import { composeUrlParameter } from 'lib/transform/query-string'
import { productListEvent } from 'services/Product/stores/events'
import IProductsModel from 'services/Product/stores/models/ProductsModel/interface'

const ProductsModel: IProductsModel = {
  isLoading: false,
  isLoadingBrands: false,
  isLoadingUpload: false,
  isLoadingSave: false,
  isLoadingUpdate: false,
  products: [],
  categories: [],
  uom: [],
  brands: [],
  isError: false,
  errorStatus: null,
  productsMeta: {
    current_page: 1,
    limit: 10,
    total_page: 1,
    total_count: 0,
  },

  setProducts: action((state, payload) => {
    state.products = payload
    state.isLoading = false
    state.isError = false
  }),

  setCategories: action((state, payload) => {
    state.categories = payload
    state.isLoading = false
    state.isError = false
  }),

  setBrands: action((state, payload) => {
    state.brands = payload
    state.isLoadingBrands = false
    state.isError = false
  }),

  setUOM: action((state, payload) => {
    state.uom = payload
    state.isLoading = false
    state.isError = false
  }),

  setError: action((state) => {
    state.isLoading = false
    state.isError = true
  }),

  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),

  setIsLoadingBrands: action((state, payload) => {
    state.isLoadingBrands = payload
  }),

  setIsLoadingUpload: action((state, payload) => {
    state.isLoadingUpload = payload
  }),

  setIsLoadingSave: action((state, payload) => {
    state.isLoadingSave = payload
  }),

  setIsLoadingUpdate: action((state, payload) => {
    state.isLoadingUpdate = payload
  }),

  setIsError: action((state, payload) => {
    state.isError = payload
  }),

  setErrorStatus: action((state, payload) => {
    state.errorStatus = payload
  }),

  setProductsMeta: action((state, payload) => {
    state.productsMeta = payload
  }),

  getProducts: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      actions.setIsLoading(true)
      actions.setIsError(false)
      const response = await apiClient({
        url: `/product/v1/products${composeUrlParameter(
          camelToSnakeKeys(payload),
        )}`,
        method: 'GET',
      })

      if (response?.data?.data?.items?.length) {
        actions.setProducts(response.data.data.items)
        actions.setProductsMeta(response.data.meta)
      }
      sendLogHitEndpoint(
        productListEvent.getProducts.event,
        dependencyContextMapper(
          productListEvent.getProducts.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (err) {
      sendLogHitEndpoint(
        productListEvent.getProducts.event,
        dependencyContextMapper(
          productListEvent.getProducts.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.setErrorStatus(err?.response?.status)
      if ([400, 422].includes(err?.response?.status)) {
        actions.setProducts([])
        actions.setProductsMeta({
          current_page: 1,
          limit: 10,
          total_page: 1,
          total_count: 0,
        })
      } else {
        actions.setError()
      }
    } finally {
      actions.setIsLoading(false)
    }
  }),

  getProductDetail: thunk(async (actions, id, { injections }) => {
    try {
      const { apiClient } = injections
      actions.setIsLoading(true)
      actions.setIsError(false)
      const response = await apiClient({
        url: `/product/v1/products/${id}`,
        method: 'GET',
      })
      return response.data.data
    } catch (err) {
      actions.setError()
      throw err
    } finally {
      actions.setIsLoading(false)
    }
  }),

  getCategories: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      actions.setIsLoading(true)
      const response = await apiClient({
        url: '/marketplace/v1/categories?page=1&limit=20',
        method: 'GET',
        params: payload,
      })

      if (response?.data?.data?.items?.length) {
        actions.setCategories(response.data.data.items)
      }

      actions.setIsLoading(false)
      sendLogHitEndpoint(
        productListEvent.getCategories.event,
        dependencyContextMapper(
          productListEvent.getCategories.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      return response?.data?.data?.items
    } catch (err) {
      sendLogHitEndpoint(
        productListEvent.getCategories.event,
        dependencyContextMapper(
          productListEvent.getCategories.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.setError()
      actions.setIsLoading(false)
      throw err
    }
  }),

  getBrands: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      actions.setIsLoadingBrands(true)
      const response = await apiClient({
        url: '/search/v1/brand-suggestions',
        method: 'GET',
        params: { keyword: payload, page: 1, limit: 5 },
      })

      if (response?.data?.data?.length) {
        actions.setBrands(response.data.data)
      }

      actions.setIsLoadingBrands(false)
      sendLogHitEndpoint(
        productListEvent.getBrands.event,
        dependencyContextMapper(
          productListEvent.getBrands.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (err) {
      actions.setError()
      actions.setIsLoadingBrands(false)
      sendLogHitEndpoint(
        productListEvent.getBrands.event,
        dependencyContextMapper(
          productListEvent.getBrands.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    }
  }),

  getUOM: thunk(async (actions, _payload, { injections }) => {
    try {
      const { apiClient } = injections
      actions.setIsLoading(true)
      const response = await apiClient({
        url: 'product/v1/price-uoms',
        method: 'GET',
      })

      if (response?.data?.data?.items?.length) {
        actions.setUOM(response.data.data.items)
      }

      actions.setIsLoading(false)
      sendLogHitEndpoint(
        productListEvent.getUOM.event,
        dependencyContextMapper(
          productListEvent.getUOM.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      return response?.data?.data?.items
    } catch (err) {
      actions.setError()
      actions.setIsLoading(false)
      sendLogHitEndpoint(
        productListEvent.getUOM.event,
        dependencyContextMapper(
          productListEvent.getUOM.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      throw err
    }
  }),

  postProductFiles: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoadingUpload(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/product/v1/files`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: payload,
        })
        actions.setIsLoadingUpload(false)
        sendLogHitEndpoint(
          productListEvent.postProductFiles.event,
          dependencyContextMapper(
            productListEvent.postProductFiles.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return response?.data?.data?.url
      } catch (err) {
        actions.setIsLoadingUpload(false)
        sendLogHitEndpoint(
          productListEvent.postProductFiles.event,
          dependencyContextMapper(
            productListEvent.postProductFiles.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        throw err
      }
    },
  ),

  postProducts: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      actions.setIsLoadingSave(true)
      actions.setIsError(false)
      const response = await apiClient({
        url: `/product/v1/products`,
        method: 'POST',
        data: camelToSnakeKeys(payload),
        headers: {
          'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
        },
      })
      sendLogHitEndpoint(
        productListEvent.postProducts.event,
        dependencyContextMapper(
          productListEvent.postProducts.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      return response.data
    } catch (err) {
      actions.setError()
      sendLogHitEndpoint(
        productListEvent.postProducts.event,
        dependencyContextMapper(
          productListEvent.postProducts.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      throw err
    } finally {
      actions.setIsLoadingSave(false)
    }
  }),

  deleteProduct: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      actions.setIsLoading(true)
      actions.setIsError(false)
      const response = await apiClient({
        url: `/product/v1/products/${payload}`,
        method: 'DELETE',
      })

      sendLogHitEndpoint(
        productListEvent.deleteProduct.event,
        dependencyContextMapper(
          productListEvent.deleteProduct.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (err) {
      sendLogHitEndpoint(
        productListEvent.deleteProduct.event,
        dependencyContextMapper(
          productListEvent.deleteProduct.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.setError()
    } finally {
      actions.setIsLoading(false)
    }
  }),

  updateProduct: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      actions.setIsLoadingUpdate(true)
      actions.setIsError(false)
      const response = await apiClient({
        url: `/product/v1/products/${payload.productId}`,
        method: 'PATCH',
        data: camelToSnakeKeys(payload?.details),
      })
      sendLogHitEndpoint(
        productListEvent.updateProduct.event,
        dependencyContextMapper(
          productListEvent.updateProduct.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      return response.data
    } catch (err) {
      sendLogHitEndpoint(
        productListEvent.updateProduct.event,
        dependencyContextMapper(
          productListEvent.updateProduct.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.setError()
      throw err
    } finally {
      actions.setIsLoadingUpdate(false)
    }
  }),
}

export default ProductsModel
