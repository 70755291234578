// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import DateFormatter from 'lib/date-formatter'

const CountdownFormat = (endDate: string, format = {}) => {
  const timerFormat = {
    day: false,
    hours: true,
    minutes: true,
    seconds: true,
    ...format,
  }
  const handleCallback = (days, hours, minutes, seconds) => {
    const callbackFormat = [
      timerFormat.day ? `${days}:` : '',
      timerFormat.hours ? `${hours}:` : '',
      timerFormat.minutes ? `${minutes}:` : '',
      timerFormat.seconds ? seconds : '',
    ].join('')
    return callbackFormat
  }
  const distance = +DateFormatter(endDate) - +new Date()
  if (distance > 0) {
    const days = Math.floor(distance / (1000 * 60 * 60 * 24))
    const hours = timerFormat.day
      ? Math.floor((distance / (1000 * 60 * 60)) % 24)
      : Math.floor(distance / (1000 * 60 * 60))
    const minutes = timerFormat.hours
      ? Math.floor((distance / 1000 / 60) % 60)
      : Math.floor(distance / 1000 / 60)
    const seconds = timerFormat.minutes
      ? Math.floor((distance / 1000) % 60)
      : Math.floor(distance / 1000)

    return handleCallback(
      days < 10 ? `0${days}` : days,
      hours < 10 ? `0${hours}` : hours,
      minutes < 10 ? `0${minutes}` : minutes,
      seconds < 10 ? `0${seconds}` : seconds,
    )
  }
  return handleCallback('00', '00', '00', '00')
}

export default CountdownFormat
