export default {
  getMyVoucher: {
    event: {
      name: 'Voucher',
      state: 'GetMyVoucher',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetMyVoucher',
      service: 'rll-gw-api',
    },
  },
  getActiveUserVoucher: {
    event: {
      name: 'Voucher',
      state: 'getActiveUserVoucher',
    },
    dependency: {
      name: 'API Gateway',
      event: 'getActiveUserVoucher',
      service: 'rll-gw-api',
    },
  },
}
