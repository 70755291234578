import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import validationResponseHelper from 'lib/validation-response-handler'
import { eventAddress } from 'services/User/event'
import IAddressModel from 'services/User/models/AddressModel/interface'

const AddressModel: IAddressModel = {
  billingAddress: null,
  setBillingAddress: action((state, payload) => {
    state.isLoading = false
    state.billingAddress = payload
  }),
  shippingAddressList: null,
  setShippingAddress: action((state, payload) => {
    state.isLoading = false
    state.shippingAddressList = payload
  }),
  isLoading: true,
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  findOneAddress: thunk(async (actions, type, { injections }) => {
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/marketplace/v1/customer/addresses',
        method: 'GET',
        params: {
          type,
          page: 1,
          limit: 1,
        },
      })
      sendLogHitEndpoint(
        eventAddress.findOneAddress.event,
        dependencyContextMapper(
          eventAddress.findOneAddress.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      if (response?.status === 200) return response.data?.data?.items
      return null // outside of the API Contract
    } catch (err) {
      sendLogHitEndpoint(
        eventAddress.findOneAddress.event,
        dependencyContextMapper(
          eventAddress.findOneAddress.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      if (err?.response?.status === 422) return []
      const message = validationResponseHelper(
        err,
        'top-center',
        true,
        {
          toastClientSideError: false,
        },
      )
      Toast(message, {
        type: 'error',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        pauseOnHover: true,
        toastId: 'error-find-one-address',
      })
      return null
    }
  }),
  getBillingAddress: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: '/marketplace/v1/customer/addresses',
          method: 'GET',
          params: {
            type: 'billing',
            limit: 50,
          },
        })
        sendLogHitEndpoint(
          eventAddress.getBillingAddress.event,
          dependencyContextMapper(
            eventAddress.getBillingAddress.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        if (response?.status !== 200) {
          actions.setBillingAddress(null)
          return null
        }
        actions.setBillingAddress(response?.data?.data)
        return response?.data?.data?.items
      } catch (error) {
        sendLogHitEndpoint(
          eventAddress.getBillingAddress.event,
          dependencyContextMapper(
            eventAddress.getBillingAddress.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
        actions.setBillingAddress(null)

        if (error?.response?.status !== 422) {
          const message = validationResponseHelper(
            error,
            'top-center',
            true,
            {
              toastClientSideError: false,
            },
          )

          Toast(message, {
            toastId: message,
            type: 'error',
            theme: 'colored',
            closeButton: true,
            hideProgressBar: false,
            position: 'top-center',
            pauseOnHover: true,
          })
        }

        return null
      }
    },
  ),
  getShippingAddress: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: '/marketplace/v1/customer/addresses',
          method: 'GET',
          params: {
            type: 'shipping',
            limit: 50, // TODO: get from config
          },
        })
        sendLogHitEndpoint(
          eventAddress.getShippingAddress.event,
          dependencyContextMapper(
            eventAddress.getShippingAddress.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        if (response?.status !== 200) {
          actions.setShippingAddress(null)
          return null
        }
        actions.setShippingAddress(response?.data?.data)
        return response?.data?.data?.items
      } catch (error) {
        sendLogHitEndpoint(
          eventAddress.getShippingAddress.event,
          dependencyContextMapper(
            eventAddress.getShippingAddress.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
        actions.setShippingAddress(null)

        return null
      }
    },
  ),
}

export default AddressModel
