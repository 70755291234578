import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import eventRalaliPlus from 'services/RalaliPlus/events'
import IRalaliPlusModel from 'services/RalaliPlus/models/RalaliPlusModel/interface'

const RalaliPlusModel: IRalaliPlusModel = {
  alreadyGetRalaliPlus: false,
  isLoading: true,
  isError: false,
  isFoundRalaliPlus: false,
  balanceData: null,
  setAlreadyGetRalaliPlus: action((state, payload) => {
    state.alreadyGetRalaliPlus = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsFoundRalaliPlus: action((state, payload) => {
    state.isFoundRalaliPlus = payload
  }),
  setBalanceData: action((state, payload) => {
    state.balanceData = payload
  }),
  getRalaliPlusBalance: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.reset()
        actions.setIsLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/wallet/v1/balances?currency_code=${payload}`,
          method: 'GET',
        })
        actions.setBalanceData(
          objectToCamelCase({
            balance: response?.data?.data?.total_balance,
            ...response?.data?.data,
          }),
        )
        actions.setIsFoundRalaliPlus(true)
        actions.setIsLoading(false)
        actions.setAlreadyGetRalaliPlus(true)
        sendLogHitEndpoint(
          {
            ...eventRalaliPlus.getRalaliPlusBalance.event,
          },
          dependencyContextMapper(
            eventRalaliPlus.getRalaliPlusBalance.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (err) {
        if (err?.response?.status !== 422) {
          actions.error()
        }
        actions.setIsFoundRalaliPlus(false)
        actions.setIsLoading(false)
        actions.setAlreadyGetRalaliPlus(true)
        sendLogHitEndpoint(
          {
            ...eventRalaliPlus.getRalaliPlusBalance.event,
          },
          dependencyContextMapper(
            eventRalaliPlus.getRalaliPlusBalance.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
      }
    },
  ),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
  reset: action((state) => {
    state.isLoading = false
    state.isError = false
    state.isFoundRalaliPlus = false
    state.alreadyGetRalaliPlus = false
  }),
}

export default RalaliPlusModel
