export function decodeQueryString(
  url: string = window.location.href,
): Object {
  const urlParams = url.split('?')[1]
  let query = {}
  if (urlParams) {
    const params = decodeURIComponent(urlParams)

    params.split('&').forEach((param) => {
      const variable = param.split('=')
      query = { [variable[0]]: variable[1], ...query }
    })
  }
  return query
}

export function composeUrlParameter(query: Object): string {
  return Object.keys(query).reduce((str, keys, i) => {
    const delimiter = i === 0 ? '?' : '&'
    const key = encodeURIComponent(keys)
    const val = query[keys]
    if (Array.isArray(val)) {
      const valParams = val.reduce((p, c, idx) => {
        const delim = idx === 0 ? '' : '&'
        const params = `${key}=${encodeURIComponent(c)}`
        return [p, params].join(delim)
      }, '')
      return [str, delimiter, valParams].join('')
    }
    return [
      str,
      delimiter,
      key,
      '=',
      encodeURIComponent(val ?? ''),
    ].join('')
  }, '')
}
