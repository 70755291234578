import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventWallet from 'services/Wallet/events'
import IWalletModel from 'services/Wallet/models/WalletModel/interface'

const WalletModel: IWalletModel = {
  isLoading: true,
  isLoadingActivation: false,
  isLoadingLedgers: true,
  dataLedgers: [],
  metaLedgers: null,
  isError: false,
  data: {
    status: '',
    balance: 0,
    message: '',
  },
  successActivateWallet: false,
  rllPin: '',
  transactionKey: '',
  transactionToken: '',
  pinStatus: null,
  addBalance: action((state, payload) => {
    state.isLoading = false
    state.data.status = payload.status
    state.data.balance = payload.data?.balance || 0
    state.data.message = payload.message
  }),
  setIsLoadingActivation: action((state, payload) => {
    state.isLoadingActivation = payload
  }),
  setRllPin: action((state, payload) => {
    state.rllPin = payload
  }),
  setTransactionKey: action((state, payload) => {
    state.transactionKey = payload
  }),
  setTransactionToken: action((state, payload) => {
    state.transactionToken = payload
  }),
  setPinStatus: action((state, payload) => {
    state.pinStatus = payload
  }),
  setSuccessActivateWallet: action((state, payload) => {
    state.successActivateWallet = payload
  }),
  getPinStatus: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/wallet/v2/auth/pin',
        method: 'GET',
      })

      sendLogHitEndpoint(
        eventWallet.getPinStatus.event,
        dependencyContextMapper(
          eventWallet.getPinStatus.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      actions.setPinStatus(response.data)
    } catch (err) {
      sendLogHitEndpoint(
        eventWallet.getPinStatus.event,
        dependencyContextMapper(
          eventWallet.getPinStatus.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.error()
    }
  }),
  getWalletTransactionKey: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/wallet/v2/auth/encryptkey/${payload}`,
          method: 'GET',
        })
        sendLogHitEndpoint(
          {
            ...eventWallet?.getWalletTransactionKey?.event,
          },
          dependencyContextMapper(
            eventWallet?.getWalletTransactionKey?.dependency,
            requestMapper(response?.config),
            responseMapper(response),
          ),
        )
        actions.setTransactionKey(response.data.key)
        return response
      } catch (err) {
        sendLogHitEndpoint(
          {
            ...eventWallet?.getWalletTransactionKey?.event,
          },
          dependencyContextMapper(
            eventWallet?.getWalletTransactionKey?.dependency,
            requestMapper(err?.config),
            errorMapper(err),
          ),
        )
        actions.error()
        throw err
      }
    },
  ),
  postCreateXRllPin: thunk(
    async (actions, payload, { injections, getState }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: '/wallet/v2/auth/helper/encrypt/aes256',
          method: 'POST',
          data: {
            plaintext: payload,
            key: `${getState().transactionKey}`,
          },
        })
        sendLogHitEndpoint(
          {
            ...eventWallet?.postCreateXRllPin?.event,
          },
          dependencyContextMapper(
            eventWallet?.postCreateXRllPin?.dependency,
            requestMapper(response?.config),
            responseMapper(response),
          ),
        )
        actions.setRllPin(response.data.result)
        return response
      } catch (err) {
        sendLogHitEndpoint(
          {
            ...eventWallet?.postCreateXRllPin?.event,
          },
          dependencyContextMapper(
            eventWallet?.postCreateXRllPin?.dependency,
            requestMapper(err?.config),
            errorMapper(err),
          ),
        )
        actions.error()
        throw err
      }
    },
  ),
  getExchangePin: thunk(
    async (actions, payload, { injections, getState }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: '/wallet/v2/auth/token/exchange_pin',
          method: 'GET',
          headers: {
            'X-Rll-Pin': `${getState().rllPin}`,
          },
        })
        sendLogHitEndpoint(
          {
            ...eventWallet?.getExchangePin?.event,
          },
          dependencyContextMapper(
            eventWallet?.getExchangePin?.dependency,
            requestMapper(response?.config),
            responseMapper(response),
          ),
        )
        actions.setTransactionToken(response.data.transaction_token)
        return response
      } catch (err) {
        sendLogHitEndpoint(
          {
            ...eventWallet?.getExchangePin?.event,
          },
          dependencyContextMapper(
            eventWallet?.getExchangePin?.dependency,
            requestMapper(err?.config),
            errorMapper(err),
          ),
        )
        actions.error()
        throw err
      }
    },
  ),
  postCreatePin: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/wallet/v2/auth/pin',
        method: 'POST',
        data: payload,
      })
      actions.setPinStatus({
        status: 200,
        message: 'User PIN has been set up',
      })
      sendLogHitEndpoint(
        {
          ...eventWallet?.postCreatePin?.event,
        },
        dependencyContextMapper(
          eventWallet?.postCreatePin?.dependency,
          requestMapper(response?.config),
          responseMapper(response),
        ),
      )
      return response
    } catch (err) {
      sendLogHitEndpoint(
        {
          ...eventWallet?.postCreatePin?.event,
        },
        dependencyContextMapper(
          eventWallet?.postCreatePin?.dependency,
          requestMapper(err?.config),
          errorMapper(err),
        ),
      )
      throw err
    }
  }),
  getSecurityQuestion: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: '/wallet/v2/auth/pin/recovery',
          method: 'GET',
        })
        sendLogHitEndpoint(
          eventWallet.getSecurityQuestion.event,
          dependencyContextMapper(
            eventWallet.getSecurityQuestion.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return response
      } catch (err) {
        sendLogHitEndpoint(
          eventWallet.getSecurityQuestion.event,
          dependencyContextMapper(
            eventWallet.getSecurityQuestion.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.error()
        throw err
      }
    },
  ),
  postForgotPin: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/wallet/v2/auth/pin/forgot',
        method: 'POST',
        data: payload,
      })
      sendLogHitEndpoint(
        {
          ...eventWallet?.postForgotPin?.event,
        },
        dependencyContextMapper(
          eventWallet?.postForgotPin?.dependency,
          requestMapper(response?.config),
          responseMapper(response),
        ),
      )
      return response
    } catch (err) {
      sendLogHitEndpoint(
        {
          ...eventWallet?.postForgotPin?.event,
        },
        dependencyContextMapper(
          eventWallet?.postForgotPin?.dependency,
          requestMapper(err?.config),
          errorMapper(err),
        ),
      )
      throw err
    }
  }),
  postActivateWallet: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoadingActivation(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: '/core/api/v2/wallet/activation',
          method: 'PUT',
          requireLogin: true,
        })

        if (response.status === 200) {
          actions.setSuccessActivateWallet(true)
        }
        actions.setIsLoadingActivation(false)

        sendLogHitEndpoint(
          eventWallet.postActivateWallet.event,
          dependencyContextMapper(
            eventWallet.postActivateWallet.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (err) {
        sendLogHitEndpoint(
          eventWallet.postActivateWallet.event,
          dependencyContextMapper(
            eventWallet.postActivateWallet.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )

        actions.error()

        Toast(err?.response?.data?.message, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'error-post-activate-wallet',
        })
      }
    },
  ),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
  alreadyGetWallet: false,
  firstGetLedgers: true,
  setAlreadyGetWallet: action((state) => {
    state.alreadyGetWallet = true
  }),
  getWalletBalance: thunk(
    async (actions, { currencyCode }, { injections }) => {
      try {
        actions.setAlreadyGetWallet()
        const { apiClient } = injections
        const response = await apiClient({
          url: '/wallet/v1/balances',
          method: 'GET',
          params: {
            currency_code: currencyCode,
          },
        })
        sendLogHitEndpoint(
          eventWallet.getWalletBalance.event,
          dependencyContextMapper(
            eventWallet.getWalletBalance.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )

        const payload = {
          ...response.data,
          status: response.data?.data?.status,
          data: {
            balance: response.data?.data?.total_balance,
          },
        }
        actions.addBalance(payload)
      } catch (err) {
        sendLogHitEndpoint(
          eventWallet.getWalletBalance.event,
          dependencyContextMapper(
            eventWallet.getWalletBalance.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setData({
          ...err?.response?.data,
          balance: 0,
          status: 'failed',
        })
        actions.error()
      }
    },
  ),
  getWalletLedgers: thunk(
    async (actions, { clearCurrentData, params }, { injections }) => {
      try {
        actions.beginFetchLedgers()
        if (params.page === 1 || clearCurrentData)
          actions.resetWalletLedgers()
        const { apiClient } = injections
        const response = await apiClient({
          url: '/wallet/v1/ledgers',
          method: 'GET',
          params,
        })
        if (response.status === 200) {
          actions.setDataLedgers(response.data?.data?.items || [])
          actions.setMetaLedgers(response.data.meta)
        }
      } catch (err) {
        actions.error()
      }
    },
  ),
  setData: action((state, payload) => {
    state.data = payload
  }),
  beginFetchLedgers: action((state) => {
    state.isLoadingLedgers = true
    state.isError = false
  }),
  setDataLedgers: action((state, payload) => {
    const incomingItems = payload || []
    state.dataLedgers = payload?.length
      ? [...state.dataLedgers, ...incomingItems]
      : [...incomingItems]
    state.isLoadingLedgers = false
    state.firstGetLedgers = false
  }),
  setMetaLedgers: action((state, payload) => {
    state.metaLedgers = payload
  }),
  resetWalletLedgers: action((state) => {
    state.dataLedgers = []
    state.isLoadingLedgers = true
    state.firstGetLedgers = true
  }),
  setWalletMaintenance: action((state) => {
    state.isLoading = false
    state.data = {
      balance: 0,
      status: 'failed',
      message: '',
    }
  }),
}

export default WalletModel
