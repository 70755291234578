import { WEB_DESKTOP_RALALI_URL } from 'common/constants'
import auth from 'config/auth'
import { action, thunk } from 'easy-peasy'
import getIPAddress from 'lib/client-ip-address'
import { getCookie, setCookie } from 'lib/cookie'
import validationResponseHelper from 'lib/validation-response-handler'
import ISearchHistoryModel from 'services/Product/stores/models/SearchHistoryModel/interface'
import { v4 as uuidv4 } from 'uuid'

const SearchHistoryModel: ISearchHistoryModel = {
  isLoadingHistory: false,
  isError: false,
  alreadyGetHistory: false,
  alreadyPushHistory: false,
  itemHistory: [],
  setItemHistory: action((state, payload) => {
    state.isLoadingHistory = false
    state.itemHistory = payload
  }),
  setLoading: action((state, payload) => {
    state.isLoadingHistory = payload
  }),
  setAlreadyGetHistory: action((state, payload) => {
    state.alreadyGetHistory = payload
  }),
  setAlreadyPushHistory: action((state, payload) => {
    state.alreadyPushHistory = payload
  }),
  getItemHistory: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setAlreadyGetHistory(true)
      actions.setLoading(true)
      const { apiClient } = injections
      let response = null
      if (
        getCookie(auth.accessTokenName) ||
        getCookie(auth.refreshTokenName)
      ) {
        response = await apiClient({
          url: '/search/v3/histories',
          method: 'GET',
        })
      } else {
        const userId = getCookie('uuid') || uuidv4()
        const expired = new Date()
        expired.setDate(expired.getDate() + 3)
        setCookie('uuid', userId, '/', expired)
        response = await apiClient({
          url: `/search/v3/histories`,
          method: 'GET',
          headers: {
            'X-Guest-Id': userId,
          },
        })
      }
      if (response.status === 200 && response.data.data.length) {
        actions.setItemHistory(response.data.data)
      }
      actions.setLoading(false)
    } catch (err) {
      actions.setLoading(false)
      actions.error()
    }
  }),
  pushItemHistory: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setAlreadyPushHistory(true)
      const { apiClient } = injections
      const timestamp = new Date().getTime()
      const url = '/search/v1/history'
      const method = 'POST'
      if (
        getCookie(auth.accessTokenName) ||
        getCookie(auth.refreshTokenName)
      ) {
        await apiClient({
          url,
          method,
          data: {
            user_id: payload.user_id,
            keyword: payload.keyword,
            timestamp,
          },
        })
      } else {
        const ipAddress = await getIPAddress()

        await apiClient({
          url,
          method,
          data: {
            user_id: getCookie('uuid'),
            keyword: payload.keyword,
            source: ipAddress,
            timestamp,
          },
        })
      }
      window.location.href = `${WEB_DESKTOP_RALALI_URL}/search/${payload.keyword}`
    } catch (err) {
      actions.error()
      window.location.href = `${WEB_DESKTOP_RALALI_URL}/search/${payload.keyword}`
    }
  }),
  /**
   * @description: delete search history with id or delete all history
   * @payload {string} history id
   */
  deleteItemHistory: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        let response = null
        const url = `/search/v3/histories${
          payload ? `/${payload}` : ''
        }`
        const method = 'DELETE'
        if (
          getCookie(auth.accessTokenName) ||
          getCookie(auth.refreshTokenName)
        ) {
          response = await apiClient({
            url,
            method,
          })
        } else {
          const userId = getCookie('uuid')
          response = await apiClient({
            url,
            method,
            headers: {
              'X-Guest-ID': userId,
            },
          })
        }
        if (response.status === 200)
          actions.successDeleteItemHistory(payload)
        actions.setAlreadyGetHistory(false)
        actions.setLoading(false)
      } catch (err) {
        actions.error()
        validationResponseHelper(err, 'bottom-center', false)
      }
    },
  ),
  /**
   * @description: remapping item history after success deleted
   * @payload {string} history id
   */
  successDeleteItemHistory: action((state, payload) => {
    const filterItems = state.itemHistory.filter(
      (item) => item.id !== payload,
    )
    if (payload) {
      state.itemHistory = filterItems
    } else {
      state.itemHistory = []
    }
  }),
  error: action((state) => {
    state.isLoadingHistory = false
    state.isError = true
  }),
}

export default SearchHistoryModel
