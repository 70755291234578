import customTheme from 'common/theme/custom-theme'
import konektoTheme from 'services/LocalStorefront/theme'

export default {
  backgroundImage: { ...customTheme.backgroundImage },
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
  colors: {
    ...konektoTheme,
    ...customTheme.colors,
    black: '#333333',
    black75: 'rgba(51, 51, 51, 0.75)',
    black50: 'rgba(51, 51, 51, 0.5)',
    black20: 'rgba(51, 51, 51, 0.2)',
    blue: '#2196F3',
    blueLighter: '#e3f3ff',
    gray: '#b6b6b6',
    grayDark: '#747474',
    grayLight: '#e6e6e6',
    grayLightAlt: '#eeeeee',
    grayLighter: '#f6f6f6',
    green: '#22cd33',
    greenLight: '#afeeb5',
    orange: '#ff7e00',
    orangeLight: '#fff1eb',
    orangeYellow: '#fbaf05',
    orangeYellowLight: '#fff5ec',
    orange05: 'rgba(245, 130, 32, 0.05)',
    orange1: 'rgba(245, 130, 32, 0.1)',
    red: '#e50000',
    redLighter: '#ffddd8',
    redOrange: '#ff7e00',
    white: '#ffffff',
    white30: 'rgba(255, 255, 255, 0.3)',
    whiteBG: '#f8f8f8',
    whiteTwo: '#f2f2f2',
    primary: '#ff7e00',
    headerBG: '#fff',
  },
  sectionGap: {
    desktop: '60px',
    tablet: '40px',
    mobile: '32px',
  },
  typography: {
    bh1: {
      fontSize: '32px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rh1: {
      fontSize: '32px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    bh2: {
      fontSize: '24px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rh2: {
      fontSize: '24px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    bh3: {
      fontSize: '22px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rh3: {
      fontSize: '22px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    bh4: {
      fontSize: '20px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rh4: {
      fontSize: '20px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    bh5: {
      fontSize: '18px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rh5: {
      fontSize: '18px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    bh6: {
      fontSize: '16px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rh6: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
    },
    bsubtitle: {
      fontSize: '14px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rsubtitle: {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    bparagraph: {
      fontSize: '13px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rparagraph: {
      fontSize: '13px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: '0px',
    },
    blabel: {
      fontSize: '12px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rlabel: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '18px',
      letterSpacing: '0px',
    },
    bcaption: {
      fontSize: '11px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rcaption: {
      fontSize: '11px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    blead: {
      fontSize: '10px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rlead: {
      fontSize: '10px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    bsmall: {
      fontSize: '9px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rsmall: {
      fontSize: '9px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    btiny: {
      fontSize: '8px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
    rtiny: {
      fontSize: '8px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0px',
    },
  },
  zIndex: {
    messageBird: 9,
    snackbar: 12,
    konektoHeader: 11,
    modal: 10,
    header: 7,
    dropdown: 5,
    autoComplete: 5,
    popover: 8,
  },
  radius: {
    base: '4px',
    lg: '8px',
    xl: '12px',
    xxl: '16px',
  },
  dropdown: {
    fontSize: '13px',
    width: '160px',
    height: '40px',
    padding: '11px 15px 12px',
    margin: '',
  },
  popover: {
    boxShadow: '0 0 20px 0 rgba(134, 134, 134, 0.14)',
    padding: '15px',
  },
  layout: {
    containerWidth: '1180px',
  },
  alert: {
    padding: '10px 15px;',
    fontSize: '14px',
    default: {
      background: '#333333',
    },
    error: {
      background: '#e50000',
    },
    info: {
      background: '#fdb913',
    },
    success: {
      background: '#22cd33',
    },
  },
  snackbar: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
  },
  gridBreakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
  switcher: {
    unchecked: {
      circle: '#787878',
      background: '#c4c4c4',
    },
    primary: {
      circle: '#FF5917',
      background: '#FFCEBB',
    },
  },
}
