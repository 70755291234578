import { action, thunk } from 'easy-peasy'
import IPromotionModel from 'services/Notification/models/PromotionModel/interface'

const PromotionModel: IPromotionModel = {
  isLoading: true,
  isError: false,
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  data: {},
  setData: action((state, payload) => {
    state.isLoading = false
    state.data = payload
  }),
  getPromotion: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        url: '/core/api/v2/campaign/all-campaign',
        method: 'GET',
      })
      actions.setData(
        Object.keys(response.data.result.all).length
          ? response.data.result.all
          : {},
      )
    } catch (err) {
      actions.error(err?.data?.message)
    }
  }),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
}

export default PromotionModel
