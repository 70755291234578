export const eventLogin = {
  loginPassword: {
    event: { name: 'Login', state: 'SubmitPassword' },
    dependency: {
      name: 'API Gateway',
      event: 'LoginPassword',
      service: 'rll-gw-api',
    },
  },
  getVerificationChannel: {
    event: { name: 'Login', state: 'GetVerificationChannel' },
    dependency: {
      name: 'API Gateway',
      event: 'AvailableVerificationChannel',
      service: 'rll-gw-api',
    },
  },
  sendOTP: {
    event: { name: 'Login', state: 'SendOTP' },
    dependency: {
      name: 'API Gateway',
      event: 'RequestOTPLogin',
      service: 'rll-gw-api',
    },
  },
  resendOTP: {
    event: { name: 'Login', state: 'ResendOTP' },
    dependency: {
      name: 'API Gateway',
      event: 'RequestOTPLogin',
      service: 'rll-gw-api',
    },
  },
  loginOTP: {
    event: { name: 'Login', state: 'SubmitOTP' },
    dependency: {
      name: 'API Gateway',
      event: 'LoginOTP',
      service: 'rll-gw-api',
    },
  },
  loginGoogle: {
    event: { name: 'Login', state: 'SubmitGoogle' },
    dependency: {
      name: 'API Gateway',
      event: 'LoginGoogle',
      service: 'rll-gw-api',
    },
  },
  loginFacebook: {
    event: { name: 'Login', state: 'SubmitFacebook' },
    dependency: {
      name: 'API Gateway',
      event: 'LoginFacebook',
      service: 'rll-gw-api',
    },
  },
}

export const eventRegister = {
  sendOTP: {
    event: { name: 'Register', state: 'SendOTP' },
    dependency: {
      name: 'API Gateway',
      event: 'RequestOTPRegister',
      service: 'rll-gw-api',
    },
  },
  resendOTP: {
    event: { name: 'Register', state: 'ResendOTP' },
    dependency: {
      name: 'API Gateway',
      event: 'RequestOTPRegister',
      service: 'rll-gw-api',
    },
  },
  verifyRegister: {
    event: { name: 'Register', state: 'SubmitOTP' },
    dependency: {
      name: 'API Gateway',
      event: 'VerifyRegister',
      service: 'rll-gw-api',
    },
  },
}

export const eventOtp = {
  sendOTP: {
    event: { name: 'OTP', state: 'SendOTP' },
    dependency: {
      name: 'API Gateway',
      event: 'RequestOTP',
      service: 'rll-gw-api',
    },
  },
  resendOTP: {
    event: { name: 'OTP', state: 'ResendOTP' },
    dependency: {
      name: 'API Gateway',
      event: 'RequestOTP',
      service: 'rll-gw-api',
    },
  },
  verifyOTP: {
    event: { name: 'OTP', state: 'SubmitOTP' },
    dependency: {
      name: 'API Gateway',
      event: 'VerifyOTP',
      service: 'rll-gw-api',
    },
  },
  getVerificationChannel: {
    event: { name: 'GetVerificationChannel', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'GetVerificationChannel',
      service: 'rll-gw-api',
    },
  },
  postRequestOtpForOta: {
    event: { name: 'PostRequestOtpForOta', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'PostRequestOtpForOta',
      service: 'rll-gw-api',
    },
  },
  postRequestOta: {
    event: { name: 'PostRequestOta', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'PostRequestOta',
      service: 'rll-gw-api',
    },
  },
  postNewIdentity: {
    event: { name: 'OTP', state: 'NewIdentity' },
    dependency: {
      name: 'API Gateway',
      event: 'RequestOTP',
      service: 'rll-gw-api',
    },
  },
}

export const eventResetPassword = {
  postResetPassword: {
    event: { name: 'PostResetPassword', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'PostResetPassword',
      service: 'rll-gw-api',
    },
  },
  postChangePassword: {
    event: { name: 'PostChangePassword', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'PostChangePassword',
      service: 'rll-gw-api',
    },
  },
}

export const eventLogout = {
  logout: {
    event: { name: 'Logout', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'Logout',
      service: 'rll-gw-api',
    },
  },
}

export const eventPin = {
  setPin: {
    event: {
      name: 'SetPin',
      state: 'APICall',
    },
    dependency: {
      name: 'Access Gateway',
      event: 'SetPin',
      service: 'rll-gw-access',
    },
  },
  changePin: {
    event: {
      name: 'ChangePin',
      state: 'APICall',
    },
    dependency: {
      name: 'Access Gateway',
      event: 'ChangePin',
      service: 'rll-gw-access',
    },
  },
}
