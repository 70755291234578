import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import orderEvent from 'services/Order/event'
import IOrderListV2Model from 'services/Order/models/OrderListV2Model/interface'

const OrderListV2Model: IOrderListV2Model = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  data: null,
  meta: null,
  setData: action((state, payload) => {
    state.data = payload
  }),
  setMeta: action((state, payload) => {
    state.meta = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setErrorMessage: action((state, payload) => {
    state.errorMessage = payload
  }),
  resetError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
  error: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  getOrderList: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setIsLoading(true)
      actions.resetError()

      const { apiClient } = injections
      const response = await apiClient({
        url: `/marketplace/v2/orders`,
        method: 'GET',
        params: {
          user_type: payload.userType,
          status_order: payload.statusOrder,
          sort: payload.sort,
          keyword: payload.keyword,
          start_date: payload.startDate,
          end_date: payload.endDate,
          page: payload.page,
          limit: payload.limit,
        },
      })

      sendLogHitEndpoint(
        orderEvent.getOrderList.event,
        dependencyContextMapper(
          orderEvent.getOrderList.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )

      if (response.status === 200) {
        const mappedResponse = objectToCamelCase(response.data)
        const data = mappedResponse?.data
        const meta = mappedResponse?.meta

        actions.setData(data)
        actions.setMeta(meta)

        return {
          data,
          meta,
        }
      }

      return undefined
    } catch (err) {
      sendLogHitEndpoint(
        orderEvent.getOrderList.event,
        dependencyContextMapper(
          orderEvent.getOrderList.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      if (err?.response?.status === 422) {
        actions.setData(null)
        actions.setMeta(null)
      } else {
        actions.error(err?.response?.data?.message)
      }

      return undefined
    } finally {
      actions.setIsLoading(false)
    }
  }),
}

export default OrderListV2Model
