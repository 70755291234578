import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventSearch from 'services/Product/stores/events'
import ISearchSuggestionModel from 'services/Product/stores/models/SearchSuggestionModel/interface'

const SearchSuggestionModel: ISearchSuggestionModel = {
  isLoading: false,
  isError: false,
  items: [],
  vendors: [],
  suggestions: [],
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setItems: action((state, payload) => {
    state.items = payload
  }),
  setVendors: action((state, payload) => {
    state.vendors = payload
  }),
  setSuggestions: action((state, payload) => {
    state.suggestions = payload
  }),
  getItems: thunk(
    async (actions, payload: string, { injections }) => {
      try {
        actions.setLoading(true)
        actions.setItems([])
        actions.setVendors([])
        const { apiClient } = injections
        const response = await apiClient({
          url: 'search/v3/suggestions',
          method: 'GET',
          params: {
            keyword: payload,
          },
        })
        const { data } = response
        if (data?.data?.items?.length) {
          actions.setItems(data.data.items)
        }
        if (data?.data?.sellers?.length) {
          actions.setVendors(data.data.sellers)
        }
        sendLogHitEndpoint(
          {
            ...eventSearch.getSearchSuggestion.event,
          },
          dependencyContextMapper(
            eventSearch.getSearchSuggestion.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        actions.setLoading(false)
      } catch (err) {
        sendLogHitEndpoint(
          {
            ...eventSearch.getSearchSuggestion.event,
          },
          dependencyContextMapper(
            eventSearch.getSearchSuggestion.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.error()
      }
    },
  ),
  getSuggestion: thunk(
    async (actions, payload: string, { injections }) => {
      const { apiClient } = injections
      try {
        actions.setLoading(true)
        actions.setItems([])
        actions.setVendors([])
        const response = await apiClient({
          url: '/search/v4/suggestions',
          method: 'GET',
          params: {
            keyword: payload,
          },
        })

        actions.setSuggestions(response.data.data.sections)
        actions.setLoading(false)
      } catch (err) {
        actions.error()
      }
    },
  ),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
}

export default SearchSuggestionModel
