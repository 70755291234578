import { DEFAULT_LANG, LANG_COOKIE_NAME } from 'common/constants'
import { action, thunk } from 'easy-peasy'
import { getCookie } from 'lib/cookie'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventQuestionnaire from 'services/Questionnaire/event'
import IQuestionnaireModel from 'services/Questionnaire/models/QuestionnaireModel/interface'

export const checkTypeChecklist = (type): boolean => {
  const checklist = [
    'checklist_card',
    'checklist_dropdown',
    'checklist_box',
  ]

  return checklist.includes(type)
}

const QuestionnaireModel: IQuestionnaireModel = {
  errorList: [],
  isLoading: false,
  isLoadingSubmitQuestion: false,
  data: [],
  dataHeader: null,
  questionnaireId: '',
  answerList: [],
  selectedPage: 0,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsLoadingSubmitQuestion: action((state, payload) => {
    state.isLoadingSubmitQuestion = payload
  }),
  setQuestionnaireId: action((state, payload) => {
    state.questionnaireId = payload
  }),
  setHeader: action((state, payload) => {
    state.dataHeader = payload
  }),
  setAnswer: action((state, payload) => {
    state.answerList = payload
  }),
  setQuestionnaire: action((state, payload) => {
    state.data = payload
  }),
  setNextPage: action((state) => {
    const { answerList, data, selectedPage } = state

    // Handle Answer
    const getNewAnswer = []

    data?.forEach((question) =>
      question.questions?.forEach((q) => {
        if (
          question?.group_id === data?.[selectedPage + 1]?.group_id &&
          answerList.filter((answer) => answer.question_id === q.id)
            .length < 1
        ) {
          getNewAnswer.push({
            question_id: q.id,
            answer_type: q.answer_type,
            answer: checkTypeChecklist(q.answer_type) ? [] : '',
            idRefference: question?.idRefference,
          })
        }
      }),
    )
    const newAnswer = [...answerList, ...getNewAnswer]
    state.selectedPage += 1
    state.answerList = newAnswer
  }),
  setPreviousPage: action((state) => {
    if (state.selectedPage > 0) {
      state.selectedPage -= 1
    }
  }),
  setDocumentPath: action((state, payload) => {
    const toReplace = state.answerList.find(
      (obj) => obj?.question_id === payload?.questionId,
    )
    if (toReplace) {
      toReplace.answer = payload.path
    }
  }),
  getHeaderQuestionnaire: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/profiling/v1/questionnaires/${payload}`,
          method: 'GET',
        })
        if (response?.data?.data) {
          actions.setHeader(response.data.data)
        }
        sendLogHitEndpoint(
          {
            ...eventQuestionnaire.getQuestionnaire.event,
          },
          dependencyContextMapper(
            eventQuestionnaire.getQuestionnaire.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (error) {
        actions.setHeader(null)
        sendLogHitEndpoint(
          {
            ...eventQuestionnaire.getQuestionnaire.event,
          },
          dependencyContextMapper(
            eventQuestionnaire.getQuestionnaire.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
      }
    },
  ),
  getFirstQuestionnaire: thunk(
    // eslint-disable-next-line consistent-return
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/profiling/v1/questionnaires/${payload}/questions`,
          method: 'GET',
          headers: {
            'cache-control': 'no-cache',
            'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
          },
        })

        const items = response?.data?.data?.items || []
        if (items) {
          actions.setQuestionnaire(items)
          const newAnswer = []

          items?.forEach((groups) => {
            groups?.questions?.forEach((question) => {
              newAnswer.push({
                question_id: question.id,
                answer_type: question.answer_type,
                answer: checkTypeChecklist(question.answer_type)
                  ? []
                  : '',
              })
            })
          })
          actions.setQuestionnaireId(payload)
          actions.setAnswer(newAnswer)
        }
        sendLogHitEndpoint(
          {
            ...eventQuestionnaire.getQuestionnaireList.event,
          },
          dependencyContextMapper(
            eventQuestionnaire.getQuestionnaireList.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return items
      } catch (error) {
        actions.setQuestionnaire([])
        sendLogHitEndpoint(
          {
            ...eventQuestionnaire.getQuestionnaireList.event,
          },
          dependencyContextMapper(
            eventQuestionnaire.getQuestionnaireList.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  getMoreQuestionnaire: thunk(
    async (actions, payload, { injections, getState }) => {
      const getParams = payload?.url?.split('questionnaires/')?.[1]
      const { selectedPage, data } = getState()
      const indexQuestion = data?.[
        selectedPage
      ]?.questions?.findIndex((q) => q.id === payload.idReff)

      const oldQuestionnaire = getState().data
      const { answerList } = getState()
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/profiling/v1/questionnaires/${getParams}`,
          method: 'GET',
        })
        if (response?.data?.data?.items && indexQuestion >= 0) {
          // checking that idReff has used
          const indexIdReff = oldQuestionnaire.findIndex(
            (quest) => quest.id === payload.idReff,
          )
          let reff = payload.idReff
          if (indexIdReff >= 0) {
            reff += oldQuestionnaire?.[indexIdReff]?.idRefference
              ? `|| ${oldQuestionnaire?.[indexIdReff]?.idRefference}`
              : ''
          }
          const additionalData = response?.data?.data?.items?.map(
            (additional) => {
              if (
                additional?.group_id !==
                data?.[selectedPage]?.group_id
              ) {
                return {
                  idRefference: reff,
                  ...additional,
                }
              }
              return {
                ...additional,
                questions: additional?.questions?.map((quest) => ({
                  IdRefference: reff,
                  quest,
                })),
              }
            },
          )
          // Handle New Questionnaire
          const newQuestionnaire = [...oldQuestionnaire]
          newQuestionnaire.splice(
            indexQuestion + 1,
            0,
            ...additionalData,
          )
          actions.setQuestionnaire(newQuestionnaire)

          // Handle Answer
          const getNewAnswer = []
          response?.data?.data?.items?.forEach((question) =>
            question.questions?.forEach((q) => {
              if (
                question?.group_id === data?.[selectedPage]?.group_id
              ) {
                getNewAnswer.push({
                  question_id: q.id,
                  answer_type: q.answer_type,
                  answer: '',
                  idRefference: reff,
                })
              }
            }),
          )
          const newAnswer = [...answerList, ...getNewAnswer]
          actions.setAnswer(newAnswer)
        }
        sendLogHitEndpoint(
          {
            ...eventQuestionnaire.getMoreQuestionnaireList.event,
          },
          dependencyContextMapper(
            eventQuestionnaire.getMoreQuestionnaireList.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (error) {
        actions.setQuestionnaire(oldQuestionnaire)
        actions.setAnswer(answerList)
        sendLogHitEndpoint(
          {
            ...eventQuestionnaire.getMoreQuestionnaireList.event,
          },
          dependencyContextMapper(
            eventQuestionnaire.getMoreQuestionnaireList.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
      }
    },
  ),
  submitQuestionnaire: thunk(
    async (actions, payload, { injections, getState }) => {
      actions.setIsLoadingSubmitQuestion(true)
      const newErrorList = []
      const { answerList } = getState()
      const dataToSend = answerList?.map((data) => {
        if (!data.answer) {
          newErrorList.push(data?.question_id)
        }
        return {
          question_id: data?.question_id,
          answer_type: data?.answer_type,
          answer: data?.answer,
        }
      })
      if (newErrorList?.length > 0) {
        actions.setError(newErrorList)
        return false
      }
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/profiling/v1/questionnaires/${payload}/submission`,
          method: 'POST',
          data: { answers: dataToSend },
        })
        sendLogHitEndpoint(
          {
            ...eventQuestionnaire.submitQuestionnaire.event,
          },
          dependencyContextMapper(
            eventQuestionnaire.submitQuestionnaire.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        actions.setIsLoadingSubmitQuestion(false)
        return true
      } catch (error) {
        sendLogHitEndpoint(
          {
            ...eventQuestionnaire.submitQuestionnaire.event,
          },
          dependencyContextMapper(
            eventQuestionnaire.submitQuestionnaire.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
        actions.setIsLoadingSubmitQuestion(false)
        return false
      }
    },
  ),
  putQuestionnaireSubmission: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/profiling/v1/questionnaires/${payload.questionnaireId}/submission`,
          method: 'PUT',
          data: {
            answers: payload.answers,
          },
        })
        sendLogHitEndpoint(
          {
            ...eventQuestionnaire.putQuestionnaire.event,
          },
          dependencyContextMapper(
            eventQuestionnaire.putQuestionnaire.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return true
      } catch (error) {
        sendLogHitEndpoint(
          {
            ...eventQuestionnaire.putQuestionnaire.event,
          },
          dependencyContextMapper(
            eventQuestionnaire.putQuestionnaire.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
        return false
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  removeQuestion: action((state, payload) => {
    const newQuestion = state.data
      .filter((question) => !question.idRefference?.includes(payload))
      .map((fq) => ({
        ...fq,
        questions: fq?.questions.filter(
          (question) => !question.idRefference?.includes(payload),
        ),
      }))
    state.data = newQuestion
    const newAnswer = state.answerList.filter(
      (answer) => !answer.idRefference?.includes(payload),
    )
    state.answerList = newAnswer
  }),
  uploadFile: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setIsLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        url: `/profiling/v1/questionnaires/${payload.questionnaireId}/questions/${payload.questionId}/upload`,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: payload.file,
      })
      if (
        response.status === 200 &&
        response?.data?.data?.file_path
      ) {
        actions.setDocumentPath({
          path: response?.data?.data?.file_path,
          questionId: payload.questionId,
        })
      }
      sendLogHitEndpoint(
        {
          ...eventQuestionnaire.uploadFile.event,
        },
        dependencyContextMapper(
          eventQuestionnaire.uploadFile.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      actions.setIsLoading(false)
      return response
    } catch (error) {
      sendLogHitEndpoint(
        {
          ...eventQuestionnaire.uploadFile.event,
        },
        dependencyContextMapper(
          eventQuestionnaire.uploadFile.dependency,
          requestMapper(error.config),
          errorMapper(error),
        ),
      )
      actions.setIsLoading(false)
      throw error
    }
  }),
  resetQuestionnaire: action((state) => {
    state.errorList = []
    state.data = []
    state.dataHeader = null
    state.answerList = []
    state.selectedPage = 0
  }),
  setError: action((state, payload) => {
    state.errorList = payload
  }),
}

export default QuestionnaireModel
