import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventDigital from 'services/DigitalGoods/events'
import { IRedeemGiftCardModel } from 'services/DigitalGoods/models/RedeemGiftCardModel/interface'
import store from 'stores/index'

const RedeemGiftCardModel: IRedeemGiftCardModel = {
  isLoading: false,
  isError: false,
  postRedeemGiftCard: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setIsError(false)

        const { apiClient } = injections
        const res = await apiClient({
          url: '/digital/v1/claim-gift-card',
          method: 'POST',
          data: payload,
          headers: {
            'X-Ota': store.getState().otp?.ota,
            'X-Source-Name': 'website',
            'X-Source-Detail': store.getState().profile.profileData
              ?.email,
          },
        })

        if (res.status === 200) {
          sendLogHitEndpoint(
            eventDigital.postRedeemGiftCard.event,
            dependencyContextMapper(
              eventDigital.postRedeemGiftCard.dependency,
              requestMapper(res.config),
              responseMapper(res),
            ),
          )
          Toast('Berhasil Melakukan Penukaran Ralali Gift Card', {
            type: 'success',
            theme: 'colored',
            closeButton: true,
            hideProgressBar: false,
            position: 'top-center',
            autoClose: 5000,
            pauseOnHover: true,
            toastId: 'redeem-gift-card',
          })
          actions.setIsLoading(false)
          return res
        }

        actions.setIsError(true)
        Toast(res?.data?.message, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'redeem-gift-card',
        })
        actions.setIsLoading(false)
        sendLogHitEndpoint(
          eventDigital.postRedeemGiftCard.event,
          dependencyContextMapper(
            eventDigital.postRedeemGiftCard.dependency,
            requestMapper(res.config),
            errorMapper(res),
          ),
        )
        throw res
      } catch (err) {
        actions.setIsError(true)
        Toast(err?.response?.data?.message, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'redeem-gift-card',
        })
        actions.setIsLoading(false)
        sendLogHitEndpoint(
          eventDigital.postRedeemGiftCard.event,
          dependencyContextMapper(
            eventDigital.postRedeemGiftCard.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        throw err
      }
    },
  ),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
}

export default RedeemGiftCardModel
