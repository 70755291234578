import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import ArrayofObjectToCamelCase from 'lib/transform/array-to-camel-case'
import sellerEvent from 'services/Seller/events'
import ICategoryReportModel from 'services/Seller/models/ReportModel/CategoryReportModel/interface'

const CategoryReportModel: ICategoryReportModel = {
  isLoading: true,
  isError: false,
  categoryList: [],
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setCategoriesList: action((state, payload) => {
    state.categoryList = payload
  }),

  getTopCategories: thunk(
    async (actions, payload, { injections }) => {
      actions.setIsLoading(true)
      actions.setIsError(false)
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/stores/${payload.storeId}/top-categories?start_date=${payload.startDate}&end_date=${payload.endDate}`,
          method: 'GET',
        })
        actions.setCategoriesList(
          ArrayofObjectToCamelCase(response?.data?.data?.items),
        )
        sendLogHitEndpoint(
          sellerEvent.getTopCategories.event,
          dependencyContextMapper(
            sellerEvent.getTopCategories.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (error) {
        actions.setIsError(true)
        sendLogHitEndpoint(
          sellerEvent.getTopCategories.event,
          dependencyContextMapper(
            sellerEvent.getTopCategories.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
        throw error
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default CategoryReportModel
