import {
  GoogleOAuthProvider,
  TokenResponse,
  useGoogleLogin,
} from '@react-oauth/google'
import GoogleIcon from 'assets/svg/login/google.svg'
import Loader from 'common/components/Loader'
import Typography from 'common/components/Typography'
import { SSO_CODE } from 'common/constants'
import theme from 'common/theme'
import { useStoreActions, useStoreState } from 'stores/hooks'
import styled from 'styled-components'

const GoogleButton = styled.div`
  width: 230px;
  height: 50px;
  border-radius: 6px;
  border: 1px solid ${theme.colors.grayLightAlt};
  background-color: ${theme.colors.grayLighter};
  cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1199.98px) {
    width: 100%;
  }
`

function CustomGoogleLoginButton({
  onSuccess,
}: {
  onSuccess: (response: TokenResponse) => void
}): JSX.Element {
  const login = useGoogleLogin({
    onSuccess,
  })

  return (
    <GoogleButton
      onClick={login}
      data-testid="custom-google-login-button-test"
    >
      <GoogleIcon />
      <Typography fontWeight="bold" marginLeft="15px" fontSize="16px">
        Google
      </Typography>
    </GoogleButton>
  )
}

function GoogleLogin(): JSX.Element {
  const clientId = process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID
  const googleLoginState = useStoreState((state) => state.googleLogin)
  const googleLoginAction = useStoreActions(
    (actions) => actions.googleLogin,
  )

  if (!clientId) return null
  return (
    <GoogleOAuthProvider clientId={clientId}>
      {googleLoginState.isLoading ? (
        <GoogleButton isLoading>
          <Loader radius="50px" />
        </GoogleButton>
      ) : (
        <CustomGoogleLoginButton
          onSuccess={(tokenResponse) => {
            googleLoginAction.postGoogleLogin({
              sso_code: SSO_CODE,
              grant_type: 'login_social',
              provider_name: 'google',
              provider_code: tokenResponse.access_token,
            })
          }}
        />
      )}
    </GoogleOAuthProvider>
  )
}

export default GoogleLogin
