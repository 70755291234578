import common from 'common/locales/id'
import auth from 'services/Auth/locales/id'
import businessSolution from 'services/BusinessSolution/locales/id'
import home from 'services/Home/locales/id'

export default {
  common,
  auth,
  businessSolution,
  home,
}
