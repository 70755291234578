export const eventAddressForm = {
  getDistrictAddress: {
    event: { name: 'AddressForm', state: 'getDistrictAddress' },
    dependency: {
      name: 'API Gateway',
      event: 'getDistrictAddress',
      service: 'rll-gw-api',
    },
  },
  getPostalCode: {
    event: { name: 'AddressForm', state: 'getPostalCode' },
    dependency: {
      name: 'API Gateway',
      event: 'getPostalCode',
      service: 'rll-gw-api',
    },
  },
  submitShippingAddress: {
    event: { name: 'AddressForm', state: 'submitShippingAddress' },
    dependency: {
      name: 'API Gateway',
      event: 'submitShippingAddress',
      service: 'rll-gw-api',
    },
  },
  submitBillingAddress: {
    event: { name: 'AddAddressForm', state: 'submitBillingAddress' },
    dependency: {
      name: 'API Gateway',
      event: 'submitBillingAddress',
      service: 'rll-gw-api',
    },
  },
  putAddress: {
    event: { name: 'Address', state: 'putAddress' },
    dependency: {
      name: 'API Gateway',
      event: 'putAddress',
      service: 'rll-gw-api',
    },
  },
  deleteAddress: {
    event: { name: 'Address', state: 'deleteAddress' },
    dependency: {
      name: 'API Gateway',
      event: 'deleteAddress',
      service: 'rll-gw-api',
    },
  },
}
export const eventAddress = {
  findOneAddress: {
    event: { name: 'Address', state: 'FindOneAddress' },
    dependency: {
      name: 'API Gateway',
      event: 'FindOneAddress',
      service: 'rll-gw-api',
    },
  },
  getBillingAddress: {
    event: { name: 'Address', state: 'getBillingAddress' },
    dependency: {
      name: 'API Gateway',
      event: 'GetBillingAddress',
      service: 'rll-gw-api',
    },
  },
  getShippingAddress: {
    event: { name: 'Address', state: 'getShippingAddress' },
    dependency: {
      name: 'API Gateway',
      event: 'getShippingAddress',
      service: 'rll-gw-api',
    },
  },
}
