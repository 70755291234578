import common from 'common/locales/en'
import auth from 'services/Auth/locales/en'
import businessSolution from 'services/BusinessSolution/locales/en'
import home from 'services/Home/locales/en'

export default {
  common,
  auth,
  businessSolution,
  home,
}
