import 'lazysizes/plugins/attrchange/ls.attrchange'

import lazySizes from 'lazysizes'

Object.assign(lazySizes.cfg, {
  expand: 10,
  expFactor: 1.5,
})

export default {
  lazyClass: 'lazyload',
}
