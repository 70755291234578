import { APP_DOMAIN } from 'common/constants'
import { NextPageContext } from 'next'
import nookies from 'nookies'

/**
 * Returns cookie value
 *
 * @param context
 * @param cookieName
 */
const getCookie = (
  cookieName: string,
  context?: NextPageContext,
): string => {
  if (context?.req && context?.req.headers) {
    const value = nookies.get(context)[cookieName]
    return context.res && value
  }
  const value = nookies.get({})[cookieName]
  return value
}

/**
 * Returns true after cookie is set
 *
 * @param context
 * @param name
 * @param value
 * @param path
 * @param expiry
 * @param domain
 */
const setCookie = (
  name: string,
  value: string,
  path = '/',
  expiry?: Date,
  context?: NextPageContext,
  domain = APP_DOMAIN === 'localhost' ? APP_DOMAIN : `.${APP_DOMAIN}`,
): boolean => {
  if (context?.req && context?.req.headers) {
    nookies.set(context, name, value, {
      path,
      expires: expiry,
      domain,
    })
    return true
  }
  nookies.set({}, name, value, {
    path,
    expires: expiry,
    domain,
  })
  return true
}

/**
 * Return true after cookie is destroyed
 *
 * @param context
 * @param name
 * @param path
 * @param domain
 */
const destroyCookie = (
  name: string,
  path = '/',
  context?: NextPageContext,
  domain = `.${APP_DOMAIN}`,
): boolean => {
  if (context?.req && context?.req.headers) {
    nookies.destroy(context, name, { path, domain })
    return true
  }
  nookies.destroy({}, name, { path, domain })
  return true
}

export { getCookie, setCookie, destroyCookie }
