export function camelToSnake(str: string): string {
  return str?.replace(
    /[A-Z]/g,
    (letter) => `_${letter.toLowerCase()}`,
  )
}

function camelToSnakeKeys(obj: {
  [key: string]: any
}): { [key: string]: any } {
  return Object.fromEntries(
    Object.entries(obj)?.map(([key, value]) => {
      if (Array.isArray(value)) {
        const newValue = value.map((v) => {
          if (v instanceof Object) return camelToSnakeKeys(v)

          return v
        })
        return [camelToSnake(key), newValue]
      }

      return [
        camelToSnake(key),
        value instanceof Object && !Array.isArray(value)
          ? camelToSnakeKeys(value)
          : value,
      ]
    }),
  )
}

export default camelToSnakeKeys
