/**
 * @desc Google Analytics Universal Event
 * @param {String} eventCategory
 * @param {String} eventAction
 * @param {String} eventLabel
 * @param {Number} eventValue
 * @param {String} event
 * @return {Boolean} return true means that no tags fired and false means that a tag fired
 */
function GAUniversalEvent(
  eventCategory: string,
  eventAction: string,
  eventLabel = '',
  eventValue = 0,
  event = 'GoogleAnalyticsUniversalEvent',
): boolean {
  const data = {
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    event,
  }
  return window.dataLayer.push(data)
}

/**
 * @see https://developers.google.com/tag-manager/enhanced-ecommerce for param ecommerce
 * @desc Google Analytics - Ecommerce Event
 * @param {String} ecommerceEventCategory
 * @param {String} ecommerceEventAction
 * @param {*} ecommerce
 * @param {String} event
 * @return {Boolean} return true means that no tags fired and false means that a tag fired
 */
function GAEcommerceEvent(
  ecommerceEventCategory: string,
  ecommerceEventAction: string,
  ecommerce: Object,
  event = 'GoogleAnalyticsEcommerceEvent',
): boolean {
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  return window.dataLayer.push({
    ecommerceEventCategory,
    ecommerceEventAction,
    ecommerce,
    event,
  })
}

/**
 * @see https://developers.google.com/tag-manager/enhanced-ecommerce for param ecommerce
 * @desc Google Analytics - Ecommerce Page View
 * @param {*} ecommerce
 * @param {String} event
 * @return {Boolean} return true means that no tags fired and false means that a tag fired
 */
function GAEcommercePageView(
  ecommerce: Object,
  event = 'GoogleAnalyticsEcommercePageView',
): boolean {
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  return window.dataLayer.push({
    ecommerce,
    event,
  })
}

export { GAUniversalEvent, GAEcommerceEvent, GAEcommercePageView }
