import { RUDDERSTACK_ID, RUDDERSTACK_URL } from 'common/constants'
import rudderstackTracker from 'lib/rudderstack/tracker'
import store from 'stores/index'

declare global {
  interface Window {
    rudderstack: any
  }
}

export default async function rudderstack(): Promise<any> {
  window.rudderstack = await import('rudder-sdk-js')

  window.rudderstack.load(RUDDERSTACK_ID, RUDDERSTACK_URL, {
    logLevel: 'DEBUG',
  })
  window.rudderstack.ready(() => {
    const tracker = rudderstackTracker()
    const { profileData } = store.getState().profile
    if (
      profileData.sso_id &&
      profileData.sso_id !== tracker.getUserId()
    ) {
      tracker.identify(profileData.sso_id, {
        id: profileData.sso_id,
        email: profileData.email,
        phone: profileData.handphone,
      })
    }
  })
}
