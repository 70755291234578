import { action, thunk } from 'easy-peasy'
import IPartnersModel from 'services/Home/stores/models/PartnersModel/interface'

const PartnersModel: IPartnersModel = {
  isLoading: true,
  isError: false,
  data: [],
  setPartners: action((state, payload) => {
    state.isLoading = false
    state.data = payload
  }),
  getPartners: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/core/api/v2/home',
        method: 'GET',
      })
      if (response?.data?.result?.anchors?.rows?.length) {
        actions.setPartners(response.data.result.anchors.rows)
      }
    } catch (err) {
      actions.error()
    }
  }),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
}

export default PartnersModel
