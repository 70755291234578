interface cdnProps {
  path: string
}

export const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL

function cdn({ path }: cdnProps): string {
  return `${CDN_URL}${path}`
}

export function pathToCdn(input: string): string {
  const fullUrlRegex = /^(https?:\/\/|ftp:\/\/|www\.)\S+/i
  if (fullUrlRegex.test(input)) {
    return input
  }
  return cdn({ path: input.startsWith('/') ? input : `/${input}` })
}

export default cdn
