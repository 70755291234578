import { WEB_DESKTOP_RALALI_URL } from 'common/constants'

const config = {
  api_host: `${WEB_DESKTOP_RALALI_URL}/flag`,
  autocapture: false,
  rageclick: true,
  persistence: process.env.NEXT_PUBLIC_POSTHOG_PERSISTENCE,
  capture_pageview:
    process.env.NEXT_PUBLIC_POSTHOG_PAGEVIEW === 'true',
  capture_pageleave:
    process.env.NEXT_PUBLIC_POSTHOG_PAGELEAVE === 'true',
}

const FEATURE_FLAGS = [
  'biz--ralali-pin--enabled-set-and-change-pin--global',
  'biz--wallet--enable-maintenance--global',
  'biz--ralali-plus--enabled--global',
  'biz--questionnaire--user-profiling--website',
  'biz--shipping-method-enabled--website',
  'rel--user-panel--new-logistic-service--website',
  'rel--see-all-button--website',
  'rel--profile-business-solutions--enabled--website',
  'rel--dashboard-product-views--enabled--website',
  'rel--sku-checkout-via-store-service--global',
  'rel--user-panel--bank-account-v2--website',
  'rel--ralali-plus--website',
  'rel--user-panel--new-product-list--website',
  'rel--ralali-plus--currency-code-partner--website',
  'rel--new-payment-method-card--website',
  'rel--ralali-plus-edit--website',
  'rel--ralali-plus-reapply--website',
  'rel--store-operations--website',
  'rel--marketplace-chat-v2--global',
  'rel--user-panel-reports-phase-1--website',
  'rel--user-panel-reports-phase-2--website',
  'rel--marketplace-product-sni--global',
  'biz--marketplace-enable-cart--global',
  'biz--marketplace-enable-local-store--global',
  'biz--marketplace-enable-personal-courier-validation--global',
]

export default config
export { config, FEATURE_FLAGS }
