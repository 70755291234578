import GoogleLoginModel from 'services/Auth/stores/models/GoogleLoginModel'
import LoginModel from 'services/Auth/stores/models/LoginModel'
import LogoutModel from 'services/Auth/stores/models/LogoutModel'
import OtpModel from 'services/Auth/stores/models/OtpModel'
import PinModel from 'services/Auth/stores/models/PinModel'
import RegisterModel from 'services/Auth/stores/models/RegisterModel'
import ResetPasswordModel from 'services/Auth/stores/models/ResetPasswordModel'
import BankAccountModel from 'services/Bank/models/BankAccountModel/'
import BankListModel from 'services/Bank/models/BankListModel/'
import BusinessSolutionModel from 'services/BusinessSolution/models/BusinessSolutionModel'
import RalaliPavilionModel from 'services/BusinessSolution/models/RalaliPavilion'
import CartModel from 'services/Cart/models/CartModel'
import CategoryModel from 'services/Category/models/CategoryModel'
import ChatModel from 'services/Chat/models/ChatModel'
import DigitalOrderModel from 'services/DigitalGoods/models/DigitalOrderModel'
import RedeemGiftCardModel from 'services/DigitalGoods/models/RedeemGiftCardModel'
import ArticleModel from 'services/Home/stores/models/ArticleModel'
import BusinessModel from 'services/Home/stores/models/BusinessModel'
import DailyDealsModel from 'services/Home/stores/models/DailyDealsModel'
import PartnersModel from 'services/Home/stores/models/PartnersModel'
import ProductHomepageModel from 'services/Home/stores/models/ProductHomepage'
import RalaliConnectModel from 'services/LocalStorefront/models/RalaliConnectModel'
import LogisticModel from 'services/Logistic/models/LogisticModel'
import MembershipModel from 'services/Membership/models/MembershipModel'
import OrderNotificationModel from 'services/Notification/models/OrderNotificationModel'
import PromotionModel from 'services/Notification/models/PromotionModel'
import OrderListModel from 'services/Order/models/OrderListModel'
import OrderListV2Model from 'services/Order/models/OrderListV2Model'
import OrderModel from 'services/Order/models/OrderModel'
import PaymentMethodModel from 'services/Payment/models/PaymentMethodModel'
import ThankYouModel from 'services/Payment/models/ThankYouModel'
import PermissionModel from 'services/Permission/models/PermissionModel'
import ProductsModel from 'services/Product/stores/models/ProductsModel'
import SearchHistoryModel from 'services/Product/stores/models/SearchHistoryModel'
import SearchPopularModel from 'services/Product/stores/models/SearchPopularModel'
import SearchSuggestionModel from 'services/Product/stores/models/SearchSuggestionModel'
import TotalProductsModel from 'services/Product/stores/models/TotalProductsModel'
import ProfileModel from 'services/Profile/stores/models/ProfileModel'
import ProfilingModel from 'services/Profile/stores/models/ProfilingModel'
import QuestionnaireModel from 'services/Questionnaire/models/QuestionnaireModel'
import RalaliPlusApplicantHistoriesModel from 'services/RalaliPlus/models/RalaliPlusApplicantHistoriesModel'
import RalaliPlusModel from 'services/RalaliPlus/models/RalaliPlusModel'
import RequestModel from 'services/Rfq/models/RequestModel'
import PopularSearchModel from 'services/Search/models/PopularSearchModel'
import DownloadOrderModel from 'services/Seller/models/DownloadOrderModel'
import StoreSellerModel from 'services/Seller/models/InformationStoreModel'
import OperationalStoreModel from 'services/Seller/models/OperationalStoreModel'
import OrderDetailModel from 'services/Seller/models/OrderDetailModel'
import OrderPickupModel from 'services/Seller/models/OrderPickupModel'
import BuyerReportModel from 'services/Seller/models/ReportModel/BuyerReportModel'
import BuyerSummaryModel from 'services/Seller/models/ReportModel/BuyerSummaryModel'
import CategoryReportModel from 'services/Seller/models/ReportModel/CategoryReportModel'
import OrderReportModel from 'services/Seller/models/ReportModel/OrderReportModel'
import ProductReportModel from 'services/Seller/models/ReportModel/ProductReportModel'
import ProductSummaryModel from 'services/Seller/models/ReportModel/ProductSummaryModel'
import SellerSummaryModel from 'services/Seller/models/ReportModel/SellerSummaryModel'
import TopLocationModel from 'services/Seller/models/ReportModel/TopLocationModel'
import ShippingModel from 'services/Seller/models/ShippingModel'
import StoreBrandModel from 'services/Seller/models/StoreBrandsModel'
import StoreCategoriesModel from 'services/Seller/models/StoreCategoriesModel'
import StoreLocationModel from 'services/Seller/models/StoreLocationModel'
import StoreSlugModel from 'services/Seller/models/StoreSlugModel'
import StoreStatisticsModel from 'services/Seller/models/StoreStatisticsModel'
import StoreSummariesModel from 'services/Seller/models/StoreSummariesModel'
import UserStoreModel from 'services/Seller/models/UserStoreModel'
import AddressModel from 'services/User/models/AddressModel'
import AddressFormModel from 'services/User/stores/models/AddressFormModel'
import ActionStateModel from 'services/UserAction/models/ActionStateModel'
import MyVoucherModel from 'services/Voucher/models/MyVoucherModel'
import PointsModel from 'services/Wallet/models/PointsModel'
import WalletModel from 'services/Wallet/models/WalletModel'
import IStoreModel from 'stores/models/interface'

// Please order this alphabetically when adding new model for better readability
const StoreModel: IStoreModel = {
  actionState: ActionStateModel,
  address: AddressModel,
  AddressForm: AddressFormModel,
  article: ArticleModel,
  bankAccount: BankAccountModel,
  bankList: BankListModel,
  business: BusinessModel,
  businessSolution: BusinessSolutionModel,
  cart: CartModel,
  category: CategoryModel,
  dailyDeals: DailyDealsModel,
  downloadOrder: DownloadOrderModel,
  digitalOrder: DigitalOrderModel,
  forgotPassword: ResetPasswordModel,
  googleLogin: GoogleLoginModel,
  logistic: LogisticModel,
  login: LoginModel,
  logOut: LogoutModel,
  membership: MembershipModel,
  myVoucher: MyVoucherModel,
  otp: OtpModel,
  orderDetail: OrderDetailModel,
  orderList: OrderListModel,
  orderListV2: OrderListV2Model,
  order: OrderModel,
  orderNotification: OrderNotificationModel,
  orderPickup: OrderPickupModel,
  partners: PartnersModel,
  paymentMethod: PaymentMethodModel,
  permission: PermissionModel,
  pin: PinModel,
  points: PointsModel,
  popularSearch: PopularSearchModel,
  products: ProductsModel,
  productHomepage: ProductHomepageModel,
  profile: ProfileModel,
  profiling: ProfilingModel,
  promotion: PromotionModel,
  questionnaire: QuestionnaireModel,
  ralaliConnect: RalaliConnectModel,
  ralaliPavilion: RalaliPavilionModel,
  ralaliPlus: RalaliPlusModel,
  register: RegisterModel,
  request: RequestModel,
  searchHistory: SearchHistoryModel,
  searchPopular: SearchPopularModel,
  searchSuggestion: SearchSuggestionModel,
  storeLocation: StoreLocationModel,
  storeOperation: OperationalStoreModel,
  storeSeller: StoreSellerModel,
  storeShipping: ShippingModel,
  storeSlug: StoreSlugModel,
  storeStatistics: StoreStatisticsModel,
  storeSummaries: StoreSummariesModel,
  totalProducts: TotalProductsModel,
  thankYou: ThankYouModel,
  userStore: UserStoreModel,
  wallet: WalletModel,
  ralaliPlusApplicant: RalaliPlusApplicantHistoriesModel,
  storeBrands: StoreBrandModel,
  storeCategories: StoreCategoriesModel,
  userChat: ChatModel,
  redeemGiftCard: RedeemGiftCardModel,
  orderReport: OrderReportModel,
  sellerSummary: SellerSummaryModel,
  productSummary: ProductSummaryModel,
  buyerSummary: BuyerSummaryModel,
  productReport: ProductReportModel,
  buyerReport: BuyerReportModel,
  topLocation: TopLocationModel,
  categoryReport: CategoryReportModel,
}

export default StoreModel
