import theme from 'common/theme'
import styled, { keyframes } from 'styled-components'

interface ILoaderProps {
  variant?: string
  primaryColor?: string
  secondaryColor?: string
  radius?: string
  strokeWidth?: string
}

interface IRenderLoader {
  primaryColor: string
  secondaryColor: string
  variant: string
  radius?: string
  strokeWidth?: string
}
const rippleAnimation = keyframes`
  0% {
    top: 1.75rem;
    left: 1.75rem;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 3.625rem;
    height: 3.625rem;
    opacity: 0;
  }
`

const circleAnimation = (
  primaryColor: string,
  secondaryColor: string,
) => keyframes`
	0% {
    stroke-dashoffset: calc((2 * 3.14 * (100px - 15px)) - 30px);
		stroke: ${primaryColor};
  }

	50% {
    stroke: ${secondaryColor};
		stroke-dashoffset: 0;
  }

	100% {
    stroke-dashoffset: calc((2 * 3.14 * (100px - 15px)) - 30px);
		stroke: ${primaryColor};
  }
`

const rotateAnimation = keyframes`
	0% {
    transform: rotate(-450deg);
  }
		
	100% {
    transform: rotate(90deg);
  }
`

const StyledCircleLoader = styled.svg`
  width: calc(${(props) => props.radius} / 2);
  height: calc(${(props) => props.radius} / 2);
  circle {
    fill: none;
    stroke: ${(props) => props.primaryColor};
    stroke-width: ${(props) => props.strokeWidth};
    stroke-dasharray: calc(
      (
        2 * 3.14 *
          (
            ${(props) => props.radius} -
              ${(props) => props.strokeWidth}
          )
      )
    );
    stroke-linecap: round;
    r: calc(
      ${(props) => props.radius} - ${(props) => props.strokeWidth}
    );
    cx: ${(props) => props.radius};
    cy: ${(props) => props.radius};
    animation: circle 2s infinite;
    transition: all 2s ease-in-out;
    animation: ${(props) =>
        circleAnimation(props.primaryColor, props.secondaryColor)}
      2s infinite;
    transform: scale(0.25);
  }
  animation: ${rotateAnimation} 2.5s linear infinite;
`

const StyledLoaderRound = styled.div`
  position: absolute;
  border: 4px solid ${(props) => props.primaryColor};
  opacity: 1;
  border-radius: 50%;
  animation: 1s ${rippleAnimation} cubic-bezier(0, 0.2, 0.8, 1)
    infinite;
  :nth-child(2) {
    animation-delay: -0.5s;
    border: 4px solid ${(props) => props.secondaryColor};
  }
`

const StyledLoader = styled.div`
  display: inline-block;
  position: relative;
`

/**
 * @param {String} props.variant
 * @param {String} props.primaryColor
 * @param {String} props.secondaryColor
 * @param {String} props.radius
 * @param {String} props.strokeWidth
 * @return {React.Node}
 */
const RenderLoader = ({
  variant,
  primaryColor,
  secondaryColor,
  radius,
  strokeWidth,
}: IRenderLoader): JSX.Element => {
  switch (variant) {
    case 'waves':
      return (
        <>
          <StyledLoaderRound primaryColor={primaryColor} />
          <StyledLoaderRound secondaryColor={secondaryColor} />
        </>
      )
    case 'circle':
    default:
      return (
        <>
          <StyledCircleLoader
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            radius={radius}
            strokeWidth={strokeWidth}
          >
            <circle />
          </StyledCircleLoader>
        </>
      )
  }
}

/**
 * @param {String} props.variant
 * @param {String} props.primaryColor
 * @param {String} props.secondaryColor
 * @return {React.Node}
 */
const Loader = ({
  variant,
  primaryColor,
  secondaryColor,
  radius,
  strokeWidth,
}: ILoaderProps): JSX.Element => {
  return (
    <StyledLoader data-testid="loader-component">
      <RenderLoader
        variant={String(variant)}
        primaryColor={String(primaryColor)}
        secondaryColor={String(secondaryColor)}
        radius={radius}
        strokeWidth={strokeWidth}
      />
    </StyledLoader>
  )
}

Loader.defaultProps = {
  primaryColor: `${theme.colors.orange}`,
  secondaryColor: `${theme.colors.orangeYellow}`,
  variant: 'circle',
  radius: '100px',
  strokeWidth: '15px',
}

RenderLoader.defaultProps = {
  radius: '100px',
  strokeWidth: '15px',
}

export default Loader
