import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import { productListEvent } from 'services/Product/stores/events'
import ITotalProductsModel from 'services/Product/stores/models/TotalProductsModel/interface'

const TotalProductsModel: ITotalProductsModel = {
  isLoading: false,
  totalProducts: {
    total_product_active: 0,
    total_product_inactive: 0,
    total_product_all: 0,
  },
  isError: false,

  setTotalProducts: action((state, payload) => {
    state.totalProducts = payload
    state.isLoading = false
    state.isError = false
  }),

  setError: action((state) => {
    state.isLoading = false
    state.isError = true
  }),

  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),

  getTotalProducts: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        actions.setIsLoading(true)

        const response = await apiClient({
          url: '/product/v1/total-products',
          method: 'GET',
        })

        if (response?.data?.data) {
          actions.setTotalProducts(response.data.data)
          sendLogHitEndpoint(
            productListEvent.getTotalProducts.event,
            dependencyContextMapper(
              productListEvent.getTotalProducts.dependency,
              requestMapper(response.config),
              responseMapper(response),
            ),
          )
        } else {
          actions.setError()
        }
      } catch (err) {
        sendLogHitEndpoint(
          productListEvent.getTotalProducts.event,
          dependencyContextMapper(
            productListEvent.getTotalProducts.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setError()
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default TotalProductsModel
