import MessageBirdChatWidget from 'lib/chat-widgets/providers/MessageBirdChatWidget'
import WhatsappSDK from 'lib/chat-widgets/providers/WhatsappSDK'

function ChatWidgets({
  widget = 'whatsapp-sdk',
}: {
  widget: string
}): JSX.Element {
  const widgetMapper = {
    'whatsapp-sdk': <WhatsappSDK />,
    'message-bird': <MessageBirdChatWidget />,
  }

  return widgetMapper[widget]
}

export default ChatWidgets
