const randomizeNumber = (min?: number, max?: number): number => {
  if (min !== undefined && max !== undefined) {
    const byteArray = new Uint32Array(1)

    window.crypto.getRandomValues(byteArray)

    const randomNumber = byteArray[0] / (0xffffffff + 1)

    const minVal = Math.ceil(min)
    const maxVal = Math.floor(max)
    return Math.floor(randomNumber * (maxVal - minVal + 1)) + minVal
  }

  const byteArray = new Uint32Array(1)
  return window.crypto.getRandomValues(byteArray)[0]
}

export default randomizeNumber
