import { IEventProvider } from 'lib/event-tracking/interface'

declare global {
  interface Window {
    rudderstack: any
  }
}

export default function rudderstack(): IEventProvider {
  return {
    identify(userId, properties, callback) {
      window.rudderstack.identify(userId, properties, () =>
        callback(),
      )
    },
    track(eventType, eventProperties, callback): void {
      window.rudderstack.track(eventType, eventProperties, () =>
        callback(),
      )
    },
    getUserId(): string | undefined {
      return window.rudderstack.getUserId()
    },
  }
}
