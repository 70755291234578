import 'react-toastify/dist/ReactToastify.css'
import 'react-quill/dist/quill.snow.css'

import theme from 'common/theme'
import { StoreProvider } from 'easy-peasy'
import ChatWidgets from 'lib/chat-widgets'
import { initLogger } from 'lib/datadog/log'
import ErrorBoundary from 'lib/error-boundary'
import FeatureFlagsProvider from 'lib/feature-flags'
import SendbirdWrapper from 'lib/providers/sendbird/SendbirdWrapper'
import initRudderstack from 'lib/rudderstack'
import i18n from 'locales/index'
import { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import store from 'stores/index'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

initLogger()

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Noto Sans', sans-serif;
  }
  a {
    text-decoration: none;
  }
  .grecaptcha-badge { display: none; }
`

// remove disabled eslint after using flow
// eslint-disable-next-line react/prop-types
function App({ Component, pageProps, err }) {
  useEffect(() => {
    if (
      'serviceWorker' in navigator &&
      process.env.NEXT_PUBLIC_CLEVERTAP_ACCOUNT_ID !== '-' &&
      process.env.NEXT_PUBLIC_CLEVERTAP_ENABLE_SW === 'true'
    ) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/sw.js').then(
          function (registration) {
            console.log(
              'Service Worker registration successful with scope: ',
              registration.scope,
            )
          },
          function (swError) {
            console.log(
              'Service Worker registration failed: ',
              swError,
            )
          },
        )
      })
    }
    initRudderstack()
  }, [])
  return (
    <>
      <GlobalStyle />
      <StoreProvider store={store}>
        <ThemeProvider theme={theme}>
          <FeatureFlagsProvider>
            <SendbirdWrapper>
              <I18nextProvider i18n={i18n}>
                <ErrorBoundary>
                  <Component {...pageProps} err={err} />
                </ErrorBoundary>
              </I18nextProvider>
            </SendbirdWrapper>
          </FeatureFlagsProvider>
        </ThemeProvider>
      </StoreProvider>
      <ChatWidgets widget="whatsapp-sdk" />
    </>
  )
}

export default App
