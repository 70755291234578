import Modal from 'common/components/Modal'
import { GAUniversalEvent } from 'lib/google-analytics'
import { useState } from 'react'
import LoginForm from 'services/Auth/components/LoginForm'
import OtpForm from 'services/Auth/components/OtpForm'
import OtpChannel from 'services/Auth/components/VerificationChannel'
import { useStoreActions, useStoreState } from 'stores/hooks'
import styled from 'styled-components'

const LoginTrigger = styled.div``

interface ILogin {
  children: any
}

export const parsePhoneFormat = (
  phoneNumber: string,
  parse = '62',
): string => {
  if (phoneNumber.charAt(0) === '0') {
    return phoneNumber.replace('0', parse)
  }
  if (phoneNumber.charAt(0) === '6' && parse.charAt(0) !== '6') {
    return phoneNumber.replace('62', parse)
  }
  return phoneNumber
}

export const convertChannelNameCase = (channel: string): string => {
  if (channel === 'sms') return 'SMS'
  if (channel === 'whatsapp') return 'WhatsApp'
  if (channel === 'pin') return 'PIN'
  return 'Email'
}

function Login({ children }: ILogin): JSX.Element {
  const [showLogin, setShowLogin] = useState<boolean>(false)

  const { step, isLoading } = useStoreState((state) => state.login)

  const {
    setStep,
    postVerifyOtp,
    postSendOtp,
    postResendOtp,
  } = useStoreActions((actions) => actions.login)

  const handleCloseLogin = () => {
    if (step === 'otp-form') return
    setShowLogin(false)
    setStep('login-form')
  }

  const handleOpenLogin = () => {
    GAUniversalEvent(
      'Login and Register on Home page',
      'Click button login and register in home page',
      'Click_button_login_register_home_Page',
    )
    setShowLogin(true)
  }

  return (
    <>
      <LoginTrigger
        data-testid="login-button"
        onClick={handleOpenLogin}
      >
        {children}
      </LoginTrigger>
      <Modal
        isOpen={showLogin}
        onOverlayClick={handleCloseLogin}
        width="560px"
        height="auto"
        padding="0"
        borderRadius="10px"
      >
        <>
          {step === 'login-form' && (
            <LoginForm handleCloseLogin={handleCloseLogin} />
          )}
          {step === 'otp-channel' && (
            <OtpChannel
              handleClose={handleCloseLogin}
              back={() => setStep('login-form')}
              postSendOtp={postSendOtp}
              isLoadingChannel={isLoading}
            />
          )}
          {step === 'otp-form' && (
            <OtpForm
              back={() => setStep('otp-channel')}
              resendOtp={postResendOtp}
              onSubmit={postVerifyOtp}
            />
          )}
        </>
      </Modal>
    </>
  )
}

export default Login
