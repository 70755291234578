import Flex from 'common/components/Flex'
import Typography from 'common/components/Typography'
import theme from 'common/theme'
import GoogleLogin from 'services/Auth/components/SocialLogin/GoogleLogin'
import styled from 'styled-components'

const ButtonHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  > * + * {
    margin-left: 20px;
  }
`

const StyledHr = styled.hr`
  width: 60px;
  border-top: 0.5px solid ${theme.colors.grayLight};
  border-bottom: none;
  border-left: none;
  border-right: none;
  opacity: 70%;
`

function SocialLogin(): JSX.Element {
  if (process.env.NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID) {
    return (
      <>
        <ButtonHeader>
          <GoogleLogin />
        </ButtonHeader>
        <Flex
          alignItems="center"
          justifyContent="center"
          margin="30px 0 0"
        >
          <Flex>
            <StyledHr />
            <Typography
              color="black50"
              marginLeft="13px"
              marginRight="13px"
            >
              Atau
            </Typography>
            <StyledHr />
          </Flex>
        </Flex>
      </>
    )
  }
  return null
}

export default SocialLogin
