import Flex from 'common/components/Flex'
import Loader from 'common/components/Loader'
import styled from 'styled-components'

const StyledFlex = styled(Flex)`
  height: 100vh;
`

function FirstTimeLoader(): JSX.Element {
  return (
    <StyledFlex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Loader />
    </StyledFlex>
  )
}

export default FirstTimeLoader
