import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import camelToSnakeKeys from 'lib/transform/camel-to-snake-case'
import sellerEvent from 'services/Seller/events'
import IOperationalStoreModel from 'services/Seller/models/OperationalStoreModel/interface'

const OperationalStoreModel: IOperationalStoreModel = {
  isError: false,
  isLoading: true,
  data: null,
  isChanges: false,
  setIsChanges: action((state, payload) => {
    state.isChanges = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  error: action((state) => {
    state.isError = true
  }),
  reset: action((state) => {
    state.isLoading = false
    state.isError = false
    state.data = null
  }),
  getOperationalStore: thunk(
    async (actions, storeId, { injections }) => {
      try {
        actions.reset()
        actions.setIsLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/stores/${storeId}/operational`,
          method: 'GET',
        })
        sendLogHitEndpoint(
          sellerEvent.getOperationalStore.event,
          dependencyContextMapper(
            sellerEvent.getOperationalStore.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        if (response.status === 200) {
          actions.setData({
            isOpen: response.data?.data.is_open,
            description: response.data?.data.description,
            startDate: response.data?.data.start_date,
            endDate: response.data?.data.end_date,
          })
        }
      } catch (err) {
        sendLogHitEndpoint(
          sellerEvent.getOperationalStore.event,
          dependencyContextMapper(
            sellerEvent.getOperationalStore.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.error(err?.response?.data?.message)
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  patchOperationalStore: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        const { apiClient } = injections
        const { storeId, params } = payload
        const response = await apiClient({
          url: `/marketplace/v1/stores/${storeId}/operational`,
          method: 'PATCH',
          data: camelToSnakeKeys(params),
        })
        sendLogHitEndpoint(
          sellerEvent.PatchOperationalStore.event,
          dependencyContextMapper(
            sellerEvent.PatchOperationalStore.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        actions.setIsChanges(false)
        return response
      } catch (err) {
        sendLogHitEndpoint(
          sellerEvent.PatchOperationalStore.event,
          dependencyContextMapper(
            sellerEvent.PatchOperationalStore.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        throw err
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default OperationalStoreModel
