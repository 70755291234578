const idProfiling = {
  profilingAccountType:
    process.env.NEXT_PUBLIC_PROFILING_ACCOUNT_TYPE,
  profilingAccountTypeQuestionId:
    process.env.NEXT_PUBLIC_PROFILING_ACCOUNT_TYPE_QUESTION_ID,
  profilingAccountTypeAnswerBusinessId:
    process.env.NEXT_PUBLIC_PROFILING_ACCOUNT_TYPE_ANSWER_BUSINESS_ID,
  profilingAccountTypeAnswerIndividualId:
    process.env
      .NEXT_PUBLIC_PROFILING_ACCOUNT_TYPE_ANSWER_INDIVIDUAL_ID,
  profilingAccountTypeQuestionnaireDocumentId:
    process.env
      .NEXT_PUBLIC_PROFILING_ACCOUNT_TYPE_QUESTIONNAIRE_DOCUMENT_ID,
}

export default idProfiling
