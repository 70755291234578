import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventVoucher from 'services/Voucher/events'
import IMyVoucherModel from 'services/Voucher/models/MyVoucherModel/interface'

const MyVoucherModel: IMyVoucherModel = {
  isLoading: true,
  isError: false,
  isErrorGetActiveUserVoucher: false,
  isErrorGetMyVoucher: false,
  data: [],
  activeUserVoucher: [],
  selectedVoucher: {},
  addMyVoucher: action((state, payload) => {
    state.isLoading = false
    state.data = payload
  }),
  addActiveUserVoucher: action((state, payload) => {
    state.activeUserVoucher = payload
  }),
  getMyVoucher: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setAlreadyGetMyVoucher()
      const { apiClient } = injections
      const response = await apiClient({
        url: '/voucher/v4/customer/my-voucher',
        method: 'POST',
        params: payload,
      })
      sendLogHitEndpoint(
        {
          ...eventVoucher?.getMyVoucher?.event,
        },
        dependencyContextMapper(
          eventVoucher?.getMyVoucher?.dependency,
          requestMapper(response?.config),
          responseMapper(response),
        ),
      )
      actions.addMyVoucher(
        response.data?.result?.length ? response.data.result : [],
      )
      actions.setIsErrorGetMyVoucher(false)
    } catch (err) {
      sendLogHitEndpoint(
        {
          ...eventVoucher?.getMyVoucher?.event,
        },
        dependencyContextMapper(
          eventVoucher?.getMyVoucher?.dependency,
          requestMapper(err?.config),
          errorMapper(err),
        ),
      )
      actions.setIsErrorGetMyVoucher(true)
      actions.error()
    }
  }),
  getActiveUserVoucher: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/voucher/v2/voucher/active-user-vouchers/${payload}`,
          method: 'POST',
          data: {
            source_page: 'checkout',
            limit: '100',
            page: '1',
          },
        })
        sendLogHitEndpoint(
          {
            ...eventVoucher?.getActiveUserVoucher?.event,
          },
          dependencyContextMapper(
            eventVoucher?.getActiveUserVoucher?.dependency,
            requestMapper(response?.config),
            responseMapper(response),
          ),
        )
        actions.addActiveUserVoucher(
          response.data?.result?.active_vouchers_result?.length
            ? response?.data?.result?.active_vouchers_result
            : [],
        )
        actions.setIsErrorGetActiveUserVoucher(false)
      } catch (err) {
        sendLogHitEndpoint(
          {
            ...eventVoucher?.getActiveUserVoucher?.event,
          },
          dependencyContextMapper(
            eventVoucher?.getActiveUserVoucher?.dependency,
            requestMapper(err?.config),
            errorMapper(err),
          ),
        )

        actions.setIsErrorGetActiveUserVoucher(
          err?.response?.status !== 404,
        )
        actions.error()
      }
    },
  ),
  postCheckPromoCode: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: '/voucher/v2/order/check-promo-code',
          method: 'POST',
          data: payload,
        })
        sendLogHitEndpoint(
          {
            ...eventVoucher?.getActiveUserVoucher?.event,
          },
          dependencyContextMapper(
            eventVoucher?.getActiveUserVoucher?.dependency,
            requestMapper(response?.config),
            responseMapper(response),
          ),
        )
        return response?.data
      } catch (error) {
        sendLogHitEndpoint(
          {
            ...eventVoucher?.getActiveUserVoucher?.event,
          },
          dependencyContextMapper(
            eventVoucher?.getActiveUserVoucher?.dependency,
            requestMapper(error?.config),
            errorMapper(error),
          ),
        )
        actions.error()
        throw error
      }
    },
  ),
  setSelectedVoucher: action((state, payload) => {
    state.selectedVoucher = payload
  }),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
  setIsErrorGetActiveUserVoucher: action((state, payload) => {
    state.isErrorGetActiveUserVoucher = payload
  }),
  setIsErrorGetMyVoucher: action((state, payload) => {
    state.isErrorGetMyVoucher = payload
  }),
  alreadyGetMyVoucher: false, // state used to keep from calling the my-voucher API
  setAlreadyGetMyVoucher: action((state) => {
    state.alreadyGetMyVoucher = true
  }),
}

export default MyVoucherModel
