import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventPayment from 'services/Payment/event'
import IThankYouModel from 'services/Payment/models/ThankYouModel/interface'

const ThankYouModel: IThankYouModel = {
  isLoading: true,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  isError: false,
  data: {},
  setData: action((state, payload) => {
    state.data = payload
  }),
  dataPushToPay: {},
  setDataPushToPay: action((state, payload) => {
    state.dataPushToPay = payload
  }),
  getOrderDetail: thunk(async (actions, payload, { injections }) => {
    actions.setDataPushToPay({})
    actions.setIsLoading(true)
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: `/marketplace/v1/orders/${payload.order_serial}`,
        method: 'GET',
      })
      if (response.status === 200 && response?.data?.data) {
        actions.setData(response.data.data)
      } else {
        actions.setData({})
      }
      actions.setIsLoading(false)
      sendLogHitEndpoint(
        eventPayment.getOrderDetail.event,
        dependencyContextMapper(
          eventPayment.getOrderDetail.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (err) {
      actions.error(true)

      sendLogHitEndpoint(
        eventPayment.getOrderDetail.event,
        dependencyContextMapper(
          eventPayment.getOrderDetail.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    }
  }),
  pushToPay: thunk(async (actions, payload, { injections }) => {
    actions.setDataPushToPay({})
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: `/payment/v1/push-to-pay`,
        method: 'POST',
        data: {
          order_id: Number(payload.order_id),
        },
        headers: {
          'Device-Name': 'desktop',
          'X-Lang': 'id',
        },
      })
      if (response.status === 200 && response?.data) {
        actions.setDataPushToPay(response.data)
      }

      sendLogHitEndpoint(
        eventPayment.pushToPay.event,
        dependencyContextMapper(
          eventPayment.pushToPay.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (err) {
      if (err.response.status === 422 && err.response?.data) {
        actions.setDataPushToPay(err.response.data)
      }

      sendLogHitEndpoint(
        eventPayment.pushToPay.event,
        dependencyContextMapper(
          eventPayment.pushToPay.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    } finally {
      actions.setIsLoading(false)
    }
  }),
  error: action((state, payload) => {
    state.isError = payload
  }),
}

export default ThankYouModel
