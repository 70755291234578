import { action, thunk } from 'easy-peasy'
import IArticleModel from 'services/Home/stores/models/ArticleModel/interface'

const ArticleModel: IArticleModel = {
  isLoading: false,
  isError: false,
  data: [],
  setData: action((state, payload) => {
    state.data = payload
  }),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  getArticles: thunk(async (actions, _, { injections }) => {
    try {
      actions.setLoading(true)

      const { apiClient } = injections
      const response = await apiClient({
        apiHost: 'https://news.ralali.com',
        url: `/wp-json/wp/v2/posts`,
        method: 'GET',
      })

      actions.setData(response.data)
      actions.setLoading(false)
    } catch (err) {
      actions.error()
    }
  }),
}

export default ArticleModel
