import theme from 'common/theme'
import styled, { keyframes } from 'styled-components'

const PulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

const WaveAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  60% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
`

const SkeletonPulse = styled.span`
  display: block;
  flex-shrink: 0;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  margin-top: ${(props) => props.marginTop};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  animation: ${PulseAnimation} 1.5s ease-in-out 0.5s infinite;
`

const SkeletonWave = styled.span`
  display: block;
  flex-shrink: 0;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  margin-top: ${(props) => props.marginTop};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};
  margin-left: ${(props) => props.marginLeft};
  overflow: hidden;
  position: relative;
  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    content: '';
    animation: ${WaveAnimation} 1.6s linear 0.5s infinite;
    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.04),
      transparent
    );
  }
`

interface ISkeleton {
  animation?: string
  width?: string
  height: string
  backgroundColor?: string
  borderRadius?: string
  marginTop?: string
  marginRight?: string
  marginBottom?: string
  marginLeft?: string
}

function Skeleton({
  animation = 'pulse',
  width = '100%',
  height,
  backgroundColor = theme.colors.grayLight,
  borderRadius = '8px',
  marginTop = '0',
  marginRight = '0',
  marginBottom = '0',
  marginLeft = '0',
  ...otherProps
}: ISkeleton): JSX.Element {
  if (animation === 'wave') {
    return (
      <SkeletonWave
        data-testid="skeleton-wave-test"
        width={width}
        height={height}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        marginTop={marginTop}
        marginRight={marginRight}
        marginBottom={marginBottom}
        marginLeft={marginLeft}
        {...otherProps}
      />
    )
  }
  return (
    <SkeletonPulse
      data-testid="skeleton-pulse-test"
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      borderRadius={borderRadius}
      marginTop={marginTop}
      marginRight={marginRight}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      {...otherProps}
    />
  )
}

export default Skeleton
