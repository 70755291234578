import { IEventProvider } from 'lib/event-tracking/interface'
import { posthog } from 'posthog-js'

export default function trackPosthog(): IEventProvider {
  return {
    identify(userId, properties): void {
      posthog.identify(userId, properties)
    },
    track(eventType, eventProperties): void {
      const clickEventProperties = {
        $event_type: 'click',
      }

      eventProperties = {
        ...(eventType === 'Clicked' ? clickEventProperties : {}),
        ...eventProperties,
      }

      posthog.capture(eventType, eventProperties)
    },
    getUserId(): string {
      return posthog.get_distinct_id()
    },
  }
}
