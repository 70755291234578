import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import sellerEvent from 'services/Seller/events'
import IBuyerReportModel from 'services/Seller/models/ReportModel/BuyerReportModel/interface'

const BuyerReportModel: IBuyerReportModel = {
  isLoading: false,
  isError: false,
  topBuyerList: null,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setTopBuyerList: action((state, payload) => {
    state.topBuyerList = payload
  }),
  getTopBuyer: thunk(async (actions, payload, { injections }) => {
    actions.setIsLoading(true)
    actions.setIsError(false)
    try {
      const { apiClient } = injections
      const res = await apiClient({
        url: `/seller/v2/sales-report/top-buyer?start_date=${payload.startDate}&end_date=${payload.endDate}&page=${payload.page}&limit=5`,
        method: 'GET',
      })
      actions.setTopBuyerList({
        ...objectToCamelCase(res?.data),
        page: Number(res?.data?.page),
      })
      sendLogHitEndpoint(
        sellerEvent.getTopBuyer.event,
        dependencyContextMapper(
          sellerEvent.getTopBuyer.dependency,
          requestMapper(res.config),
          responseMapper(res),
        ),
      )
    } catch (err) {
      actions.setIsError(true)
      sendLogHitEndpoint(
        sellerEvent.getTopBuyer.event,
        dependencyContextMapper(
          sellerEvent.getTopBuyer.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    } finally {
      actions.setIsLoading(false)
    }
  }),
  getExportBuyerReport: thunk(
    async (actions, payload, { injections }) => {
      actions.setIsLoading(true)
      actions.setIsError(false)
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/seller/v2/sales-report/export-buyer-report?start_date=${payload.startDate}&end_date=${payload.endDate}`,
          method: 'GET',
        })
        sendLogHitEndpoint(
          sellerEvent.getExportBuyerReport.event,
          dependencyContextMapper(
            sellerEvent.getExportBuyerReport.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return response?.data?.download_url
      } catch (err) {
        actions.setIsError(true)
        sendLogHitEndpoint(
          sellerEvent.getExportBuyerReport.event,
          dependencyContextMapper(
            sellerEvent.getExportBuyerReport.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        throw err
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default BuyerReportModel
