import { action, thunk } from 'easy-peasy'
import IPointsModel from 'services/Wallet/models/PointsModel/interface'

const PointsModel: IPointsModel = {
  isLoading: true,
  isError: false,
  data: 0,
  addPoints: action((state, payload) => {
    state.isLoading = false
    state.data = payload
  }),
  getPoints: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setAlreadyGetPoints()
      const { apiClient } = injections
      const response = await apiClient({
        url: '/loyalty/v1/points',
        method: 'GET',
        params: payload,
      })
      actions.addPoints(
        response.data?.point ? response.data.point : 0,
      )
    } catch (err) {
      actions.error()
    }
  }),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
  alreadyGetPoints: false, // state used to keep from calling the points API
  setAlreadyGetPoints: action((state) => {
    state.alreadyGetPoints = true
  }),
}

export default PointsModel
