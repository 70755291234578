// eslint-disable-next-line import/prefer-default-export
export const eventBank = {
  getBankList: {
    event: { name: 'GetBankList', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'GetBankList',
      service: 'rll-gw-api',
    },
  },
  postBankAccount: {
    event: { name: 'PostBankAccount', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'PostBankAccount',
      service: 'rll-gw-api',
    },
  },
  postPrimaryBankAccount: {
    event: { name: 'PostPrimaryBankAccount', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'PostPrimaryBankAccount',
      service: 'rll-gw-api',
    },
  },
  getUserBankAccounts: {
    event: { name: 'getUserBankAccounts', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'getUserBankAccounts',
      service: 'rll-gw-api',
    },
  },
  deleteUserBankAccount: {
    event: { name: 'deleteUserBankAccount', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'deleteUserBankAccount',
      service: 'rll-gw-api',
    },
  },
  updateUserBankAccount: {
    event: { name: 'updateUserBankAccount', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'updateUserBankAccount',
      service: 'rll-gw-api',
    },
  },
  uploadFileSavingBooks: {
    event: { name: 'uploadFileSavingBooks', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'uploadFileSavingBooks',
      service: 'rll-gw-api',
    },
  },
  createUserBankAccount: {
    event: { name: 'createUserBankAccount', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'createUserBankAccount',
      service: 'rll-gw-api',
    },
  },
}
