export default {
  LANGUAGE: 'Bahasa',
  WHY_RALALI: 'Kenapa Ralali',
  BUSINESS_SOLUTIONS: 'Solusi Bisnis',
  MEMBERSHIP: 'Membership',
  CONTACT_US: 'Hubungi Kami',
  VISIT_MARKETPLACE: 'Kunjungi Marketplace',
  SEND: 'Kirim',
  FULL_NAME: 'Nama Lengkap',
  EMAIL: 'Email',
  PHONE_NUMBER: 'No. Ponsel',
  COMPANY_NAME: 'Nama Perusahaan',
  NEEDS: 'Kebutuhan',
  YOUR_MESSAGE: 'Pesan Anda',
  YOUR_FULL_NAME: 'Nama Lengkap Anda',
  YOUR_EMAIL: 'Email Anda',
  YOUR_PHONE_NUMBER: 'No. Ponsel Anda',
  YOUR_COMPANY_NAME: 'Nama Perusahaan Anda',
  CHOOSE_YOUR_NEEDS: 'Pilih Kebutuhan Anda',
  WRITE_YOUR_MESSAGE: 'Tulis Pesan Anda',
  FIELD_IS_REQUIRED: '{{field}} Wajib Diisi',
  INVALID_FIELD_FORMAT: 'Format {{field}} tidak valid',
  RALALI: 'Ralali',
  RALALI_TENDER: 'Ralali Tender',
  RALALI_PLUS: 'Ralali Plus',
  RALALI_DIRECT: 'Ralali Direct',
  RALALI_PAVILION: 'Ralali Pavilion',
  RALALI_AGENT: 'Ralali Agent',
  RALALI_FOOD_VENTURE: 'Ralali Food Venture',
  RALALI_FOOD: 'Ralali Food',
  FIND_US: 'Temukan Kami',
  ABOUT_US: 'Tentang Kami',
  RALALI_COM: 'Ralali.com',
  SERVICES: 'Layanan',
  CAREERS: 'Karir',
  NEWS: 'Berita',
  EVENT: 'Event',
  ALL_BRANDS: 'Semua Merek',
  BUYER: 'Pembeli',
  HOW_TO_SHOP: 'Cara Berbelanja',
  VIEW_PROMOS: 'Lihat Promo',
  PAYMENT_METHODS: 'Metode Pembayaran',
  POPULAR_SEARCH: 'Pencarian Populer',
  LATEST_PRODUCTS: 'Produk Terkini',
  TAGS: 'Tag',
  HOW_TO_SELL: 'Cara Berjualan',
  SELLING_AT_RALALI: 'Jual di Ralali.com',
  SELLER_CENTER: 'Pusat Penjual',
  HELP: 'Bantuan',
  HELLO_RALALI: 'Hello Ralali',
  FAQ: 'FAQ',
  SECURITY_N_PRIVACY: 'Keamanan & Privasi',
  FINANCIAL_PARTNERS: 'Mitra Keuangan',
  SHIPPING_METHODS: 'Metode Pengiriman',
  SELLER: 'Penjual',
  ENDS_ON: 'Berakhir',
  VALUE: 'Nilai',
  FOOTER_DESCRIPTION:
    'Ralali adalah platform B2B online terbesar yang memberikan kemudahan dalam proses transaksi jual-beli melalui teknologi dan fitur yang membantu penjual dan pembeli menjalankan bisnis dengan lebih mudah, aman, dan transparan.',
  TITLE_EXTRA_MENU: 'Lihat Semua Solusi',
  DESCRIPTION_EXTRA_MENU:
    'Solusi Bisnis Ralali memberikan berbagai kemudahan bagi bisnis Anda untuk berkembang dan meraih keuntungan maksimal.',
  LOGIN_REGISTER: 'Masuk / Daftar',
  NEXT: 'Lanjut',
  ERROR_OCCURS: 'Terjadi Kesalahan',
  MARKETPLACE: 'Marketplace',
  EVENT_AND_NEWS: 'Event & Berita',
  ALL_CATEGORIES: 'Semua Kategori',
  OTHERS: 'Lain-Lain',
  SEE_MORE_SOLUTIONS: 'Lihat Solusi Lainnya',
}
