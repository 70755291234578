import Toast from 'common/components/Toast'
import { action, computed, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import camelToSnakeKeys from 'lib/transform/camel-to-snake-case'
import transformObject from 'lib/transform/object-to-camel-case'
import sellerEvent from 'services/Seller/events'
import IStoreModel from 'services/Seller/models/InformationStoreModel/interface'
import refetchProgress from 'services/Seller/widgets/CompletionBanner/utils'

const UserStoreModel: IStoreModel = {
  isBegin: true,
  isLoading: false,
  isLoadingUpload: false,
  isLoadingUpdate: false,
  isUpdateImage: false,
  isError: false,
  isGetStoreListCalled: false,
  isSellerAccount: computed((state) => {
    const { idStore, isGetStoreListCalled } = state

    return !!(idStore && isGetStoreListCalled)
  }),
  errorMessage: '',
  idStore: '',
  data: null,
  initialState: null,
  storeListData: null,
  firstStore: computed((state) => state.storeListData?.items[0]),
  onUpload: null,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsLoadingUpload: action((state, payload) => {
    state.isLoadingUpload = payload
  }),
  setIsLoadingUpdate: action((state, payload) => {
    state.isLoadingUpdate = payload
  }),
  setUpdateImage: action((state, payload) => {
    state.isUpdateImage = payload
  }),
  setInitialState: action((state, payload) => {
    state.initialState = payload
  }),
  setErrorMessage: action((state, payload) => {
    state.errorMessage = payload
  }),
  setIsGetStoreListCalled: action((state, payload) => {
    state.isGetStoreListCalled = payload
  }),
  setIdStore: action((state, payload) => {
    state.idStore = payload
  }),
  setOnUpload: action((state, payload) => {
    state.onUpload = payload
  }),
  setIsBegin: action((state, payload) => {
    state.isBegin = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  setStoreListData: action((state, payload) => {
    state.storeListData = payload
  }),
  error: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  getStoreList: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setIsLoading(true)
      actions.setErrorMessage('')
      const { apiClient } = injections
      const response = await apiClient({
        url: `/store/v1/stores`,
        method: 'GET',
      })
      actions.setIdStore(response.data.data?.items[0]?.id)
      actions.setIsGetStoreListCalled(true)

      sendLogHitEndpoint(
        sellerEvent.getStoreList.event,
        dependencyContextMapper(
          sellerEvent.getStoreList.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )

      actions.setStoreListData(transformObject(response.data.data))

      return response.data.data
    } catch (err) {
      if (err?.response?.status === 422) {
        actions.setIsGetStoreListCalled(true)
      }

      sendLogHitEndpoint(
        sellerEvent.getStoreList.event,
        dependencyContextMapper(
          sellerEvent.getStoreList.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.error(err?.response?.data?.message)
      return undefined
    } finally {
      actions.setIsLoading(false)
    }
  }),
  getStore: thunk(
    async (actions, storeId, { injections, getState }) => {
      try {
        const { isBegin } = getState()
        actions.setIsLoading(true)
        actions.setErrorMessage('')
        const { apiClient } = injections
        const response = await apiClient({
          url: `/store/v1/stores/${storeId}`,
          method: 'GET',
        })
        const transformData = transformObject(response.data.data)
        if (isBegin) {
          actions.setInitialState(transformData)
        }
        actions.setData(transformData)
        actions.setUpdateImage(false)
        actions.setIsBegin(false)
        sendLogHitEndpoint(
          sellerEvent.getStore.event,
          dependencyContextMapper(
            sellerEvent.getStore.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return response.data.data
      } catch (err) {
        sendLogHitEndpoint(
          sellerEvent.getStore.event,
          dependencyContextMapper(
            sellerEvent.getStore.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.error(err?.response?.data?.message)
        return undefined
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  updateStore: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setIsLoadingUpdate(true)
      actions.setErrorMessage('')
      const { apiClient } = injections
      const data = camelToSnakeKeys(payload)
      delete data.id
      const response = await apiClient({
        url: `/store/v1/stores/${payload.id}`,
        method: 'PUT',
        data,
      })
      actions.setIsBegin(true)
      actions.getStore(payload.id)
      sendLogHitEndpoint(
        sellerEvent.updateStore.event,
        dependencyContextMapper(
          sellerEvent.updateStore.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      Toast('Penyimpanan Data Berhasil', {
        toastId: 'success-update-store',
        type: 'success',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        pauseOnHover: true,
      })

      if (response.status === 200) {
        refetchProgress('store-information')
      }

      return response.data
    } catch (err) {
      sendLogHitEndpoint(
        sellerEvent.updateStore.event,
        dependencyContextMapper(
          sellerEvent.updateStore.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.error(err?.response?.data?.message)
      Toast(err?.response?.data?.message, {
        toastId: 'error-update-store',
        type: 'error',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        pauseOnHover: true,
      })
      return undefined
    } finally {
      actions.setIsLoadingUpdate(false)
    }
  }),
  uploadFile: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setOnUpload(payload.type)
      actions.setIsLoadingUpload(true)
      actions.setErrorMessage('')
      const { apiClient } = injections
      const response = await apiClient({
        url: '/store/v1/files',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        requireLogin: true,
        data: payload.data,
      })
      if (response.status === 200) {
        return response.data.data
      }
      actions.setUpdateImage(true)
      sendLogHitEndpoint(
        sellerEvent.uploadFile.event,
        dependencyContextMapper(
          sellerEvent.uploadFile.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      return response
    } catch (err) {
      if (err?.response?.data?.errors?.file[0]) {
        Toast(err?.response?.data?.errors?.file[0], {
          toastId: 'error-upload-file',
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
        })
      }
      actions.error(err?.response?.data?.message)
      return undefined
    } finally {
      actions.setIsLoadingUpload(false)
      actions.setOnUpload(null)
    }
  }),
  postSubmissionVerification: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/profiling/v1/questionnaires/${payload}/submission/verification`,
          method: 'POST',
          requireLogin: true,
        })
        refetchProgress('additional-documents')
        actions.setIsLoading(false)
        return response?.data?.status
      } catch (error) {
        actions.error(error)
        actions.setIsLoading(false)
        throw error
      }
    },
  ),
}

export default UserStoreModel
