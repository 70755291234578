import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'

function createRootElement(id: string, rootElement: Element) {
  let rootContainer = rootElement
  if (!rootElement) {
    rootContainer = document.createElement('div')
  }
  rootContainer.setAttribute('id', id)
  return rootContainer
}

function addRootElement(rootElem: Element) {
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild.nextElementSibling,
  )
}

function usePortal(
  id: string,
  rootElement: HTMLElement,
): HTMLElement {
  const rootElemRef = useRef(null)

  useEffect(
    function setupElement() {
      const existingParent = document.querySelector(`#${id}`)
      const parentElem =
        existingParent || createRootElement(id, rootElement)

      if (!existingParent) {
        addRootElement(parentElem)
      }

      parentElem.appendChild(rootElemRef.current)

      return function removeElement() {
        rootElemRef.current.remove()
        if (!parentElem.childElementCount) {
          parentElem.remove()
        }
      }
    },
    [id],
  )

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement('div')
    }
    return rootElemRef.current
  }

  return getRootElem()
}

function Portal({
  id,
  container = null,
  children,
}: {
  id: string
  container?: HTMLElement
  children: any
}): JSX.Element {
  const target = usePortal(id, container)
  return createPortal(children, target)
}

export default Portal
