import Toast from 'common/components/Toast'
import { WEB_DESKTOP_RALALI_URL } from 'common/constants'
import apiConfig from 'config/api'
import { action, persist, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import validationResponseHelper from 'lib/validation-response-handler'
import { eventPin } from 'services/Auth/event'
import IPinModel from 'services/Auth/stores/models/PinModel/interface'
import store from 'stores/index'

const PinModel: IPinModel = {
  isLoading: false,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  postSetPin: thunk(async (actions, payload, { injections }) => {
    actions.setIsLoading(true)
    try {
      const { apiClient } = injections
      const response = await apiClient({
        apiHost: apiConfig.baseAccessUrl,
        url: '/sso/v1/pin/set',
        method: 'POST',
        data: payload.data,
        headers: payload.headers,
      })
      sendLogHitEndpoint(
        eventPin.setPin.event,
        dependencyContextMapper(
          eventPin.setPin.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      store.getActions().profile.setUserProfile(persist(null))
      Toast('Aktivasi Berhasil! PIN sudah terbuat', {
        toastId: 'success-post-set-pin',
        type: 'success',
      })
      window.location.assign(
        `${WEB_DESKTOP_RALALI_URL}/customer/profile`,
      )
    } catch (err) {
      actions.setIsLoading(false)
      sendLogHitEndpoint(
        eventPin.setPin.event,
        dependencyContextMapper(
          eventPin.setPin.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      const message = validationResponseHelper(
        err,
        'top-center',
        true,
        {
          toastClientSideError: false,
        },
      )
      Toast(message, {
        type: 'error',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        pauseOnHover: true,
        toastId: 'error-post-set-pin',
      })
    }
  }),
  postChangePin: thunk(async (actions, payload, { injections }) => {
    actions.setIsLoading(true)
    try {
      const { apiClient } = injections
      const response = await apiClient({
        apiHost: apiConfig.baseAccessUrl,
        url: '/sso/v1/pin/change',
        method: 'POST',
        data: payload.data,
        headers: payload.headers,
      })
      Toast('PIN Anda Berhasil Diubah!', {
        toastId: 'success-post-change-pin',
        type: 'success',
      })
      sendLogHitEndpoint(
        eventPin.changePin.event,
        dependencyContextMapper(
          eventPin.changePin.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      window.location.assign(
        `${WEB_DESKTOP_RALALI_URL}/customer/profile`,
      )
    } catch (err) {
      actions.setIsLoading(false)
      const message = validationResponseHelper(
        err,
        'top-center',
        true,
        {
          toastClientSideError: false,
        },
      )
      Toast(message, {
        type: 'error',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        pauseOnHover: true,
        toastId: 'error-post-change-pin',
      })
      sendLogHitEndpoint(
        eventPin.changePin.event,
        dependencyContextMapper(
          eventPin.changePin.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    }
  }),
}

export default PinModel
