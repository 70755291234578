export default {
  getQuestionnaire: {
    event: {
      name: 'Questionnaire',
      state: 'GetQuestionnaire',
    },
    dependency: {
      name: 'API Gateway',
      event: 'Questionnaire',
      service: 'rll-gw-api',
    },
  },
  getQuestionnaireList: {
    event: {
      name: 'Questionnaire',
      state: 'GetQuestionnaireList',
    },
    dependency: {
      name: 'API Gateway',
      event: 'Questionnaire',
      service: 'rll-gw-api',
    },
  },
  getMoreQuestionnaireList: {
    event: {
      name: 'Questionnaire',
      state: 'GetMoreQuestionnaireList',
    },
    dependency: {
      name: 'API Gateway',
      event: 'Questionnaire',
      service: 'rll-gw-api',
    },
  },
  submitQuestionnaire: {
    event: {
      name: 'Questionnaire',
      state: 'SubmitQuestionnaire',
    },
    dependency: {
      name: 'API Gateway',
      event: 'Questionnaire',
      service: 'rll-gw-api',
    },
  },
  putQuestionnaire: {
    event: {
      name: 'Questionnaire',
      state: 'PutQuestionnaire',
    },
    dependency: {
      name: 'API Gateway',
      event: 'Questionnaire',
      service: 'rll-gw-api',
    },
  },
  uploadFile: {
    event: {
      name: 'Questionnaire',
      state: 'UploadFile',
    },
    dependency: {
      name: 'API Gateway',
      event: 'Questionnaire',
      service: 'rll-gw-api',
    },
  },
}
