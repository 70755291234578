import { useEffect } from 'react'

function getQueryParam(param: string): string {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}

const loadScript = () => {
  const chatWidgetScript = document.createElement('script')
  chatWidgetScript.src =
    'https://cdn.ralali.id/sdk/widget-chat/chat-widget.js'

  // Set data attributes dynamically
  chatWidgetScript.setAttribute(
    'data-icon-url',
    'https://cdn.prod.website-files.com/64b4ed8031e2d786aa52548c/64d20e147c3ee07f7f1da7b9_logo-ralali-nobg.svg',
  )
  chatWidgetScript.setAttribute('data-name', 'Hello Ralali')
  chatWidgetScript.setAttribute(
    'data-message',
    'Silakan hubungi kami apabila Anda memerlukan bantuan.',
  )

  // Dynamically generate the data-phone-number value
  const phoneNumber = '62818500465'

  // Get UTM parameters from the URL
  const utmMedium = getQueryParam('utm_medium')
  const utmCampaign = getQueryParam('utm_campaign')

  // Include UTM data in the message if available
  const chatMessage = `${phoneNumber}?text=${encodeURIComponent(
    `Halo, saya mendapatkan informasi tentang Ralali dari ${
      utmMedium ? `${utmMedium} - ` : 'Website Ralali'
    }${
      utmCampaign || ''
    }. Bisa informasikan detail lebih lanjut mengenai program Ralali?`,
  )}`

  chatWidgetScript.setAttribute('data-phone-number', chatMessage)
  chatWidgetScript.setAttribute(
    'data-chat-text',
    'Chat WhatsApp Kami',
  )

  // Append the script to <head> to ensure it runs early
  document.body.appendChild(chatWidgetScript)

  chatWidgetScript.onload = () => {
    // The script has been loaded, you can now use the WhatsApp SDK
    console.log('WhatsApp SDK loaded successfully')
    window.document.dispatchEvent(
      new Event('DOMContentLoaded', {
        bubbles: true,
        cancelable: true,
      }),
    )
  }
}

const loadCss = () => {
  const chatWidgetCss = document.createElement('link')
  chatWidgetCss.rel = 'stylesheet'
  chatWidgetCss.href =
    'https://cdn.ralali.id/sdk/widget-chat/chat-widget.css'
  document.head.appendChild(chatWidgetCss)
}

function WhatsappSDK(): JSX.Element {
  useEffect(() => {
    loadScript()
    loadCss()
  }, [])

  return null
}

export default WhatsappSDK
