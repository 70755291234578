import FirstTimeLoader from 'common/components/FirstTimeLoader'
import dynamic from 'next/dynamic'
import React from 'react'

// TODO Fix Next/Dynamic testing
/* istanbul ignore next */
const ErrorBoundaryContainer = dynamic(
  () => import('common/components/ErrorBoundary'),
  // eslint-disable-next-line react/display-name
  { loading: () => <FirstTimeLoader /> },
)

type Props = {
  children: Node | Element | JSX.Element
}

type State = {
  error: Error
  info: Object
}
/**
 * @return {React.Node}
 */
export default class ErrorBoundary extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null, info: null }
  }

  componentDidCatch(error: Error, info: Object): void {
    this.setState({ error, info })
  }

  render(): React.ReactNode {
    const { error, info } = this.state
    const { children } = this.props
    if (error) {
      return <ErrorBoundaryContainer info={info} error={error} />
    }
    return children
  }
}
