import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventChat from 'services/Chat/events'
import IChatModel from 'services/Chat/models/ChatModel/interface'

const ChatModel: IChatModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  channelUrl: '',
  unreadMessages: 0,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = payload.isError
    state.errorMessage = payload.errMessage
  }),
  setChannelUrl: action((state, payload) => {
    state.channelUrl = payload
  }),
  setUnreadMessages: action((state, payload) => {
    state.unreadMessages = payload
  }),
  postChatGroupChannel: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setError({ isError: false, errMessage: '' })
        const { apiClient } = injections
        const response = await apiClient({
          url: '/marketplace/v1/chats/group-channels',
          method: 'POST',
          data: payload,
        })
        actions.setChannelUrl(response?.data?.data.chat_channel_url)
        sendLogHitEndpoint(
          eventChat.createGroupChannel.event,
          dependencyContextMapper(
            eventChat.createGroupChannel.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (err) {
        actions.setChannelUrl('')
        actions.setError({
          isError: true,
          errMessage: err?.response?.data?.message,
        })
        sendLogHitEndpoint(
          eventChat.createGroupChannel.event,
          dependencyContextMapper(
            eventChat.createGroupChannel.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  getTotalUnreadMessages: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setError({ isError: false, errMessage: '' })
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/chats/total-unread-messages?type=${payload}`,
          method: 'GET',
        })
        actions.setUnreadMessages(response?.data?.data?.total_count)
        sendLogHitEndpoint(
          eventChat.getTotalUnreadMessages.event,
          dependencyContextMapper(
            eventChat.getTotalUnreadMessages.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (err) {
        actions.setUnreadMessages(null)
        actions.setError({
          isError: true,
          errMessage: err?.response?.data?.message,
        })
        sendLogHitEndpoint(
          eventChat.getTotalUnreadMessages.event,
          dependencyContextMapper(
            eventChat.getTotalUnreadMessages.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default ChatModel
