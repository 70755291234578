import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventSearch from 'services/Search/event'
import IPopularSearchModel from 'services/Search/models/PopularSearchModel/interface'

const PopularSearchModel: IPopularSearchModel = {
  isLoading: true,
  isError: false,
  data: [],
  meta: {},
  resetState: action((state) => {
    state.isLoading = true
    state.isError = false
    state.data = []
    state.meta = {}
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  setMeta: action((state, meta) => {
    state.meta = meta
  }),
  getPopularSearch: thunk(
    async (actions, payload, { injections }) => {
      actions.resetState()
      try {
        const { apiClient } = injections
        const res = await apiClient({
          url: '/search/v3/popular-search',
          method: 'GET',
          params: payload,
        })
        if (res.status === 200 && res.data?.data?.length) {
          actions.setData(res.data.data)
          actions.setMeta(res.data.meta)
        }
        sendLogHitEndpoint(
          eventSearch.getPopularSearch.event,
          dependencyContextMapper(
            eventSearch.getPopularSearch.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )
      } catch (e) {
        if (e?.response?.status === 422) actions.setData([])
        else actions.setIsError(true)
        sendLogHitEndpoint(
          eventSearch.getPopularSearch.event,
          dependencyContextMapper(
            eventSearch.getPopularSearch.dependency,
            requestMapper(e.config),
            errorMapper(e),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default PopularSearchModel
