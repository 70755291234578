import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import sellerEvent from 'services/Seller/events'
import IBuyerSummaryModel from 'services/Seller/models/ReportModel/BuyerSummaryModel/interface'

const SellerSummaryModel: IBuyerSummaryModel = {
  isLoading: true,
  isError: false,
  data: null,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  getBuyerReport: thunk(async (actions, payload, { injections }) => {
    actions.setIsLoading(true)
    actions.setIsError(false)
    try {
      const { apiClient } = injections
      const res = await apiClient({
        url: `/marketplace/v1/stores/${payload.id}/buyer-report?start_date=${payload.startDate}&end_date=${payload.endDate}&interval_group=${payload.interval}`,
        method: 'GET',
      })
      actions.setData(objectToCamelCase(res?.data?.data))
      sendLogHitEndpoint(
        sellerEvent.getBuyerReport.event,
        dependencyContextMapper(
          sellerEvent.getBuyerReport.dependency,
          requestMapper(res.config),
          responseMapper(res),
        ),
      )
    } catch (err) {
      actions.setIsError(true)
      sendLogHitEndpoint(
        sellerEvent.getBuyerReport.event,
        dependencyContextMapper(
          sellerEvent.getBuyerReport.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    } finally {
      actions.setIsLoading(false)
    }
  }),
}

export default SellerSummaryModel
