export default {
  getStorefronts: {
    event: {
      name: 'TokoPos',
      state: 'GetStorefronts',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetStorefronts',
      service: 'rll-gw-api',
    },
  },
  getProvinces: {
    event: {
      name: 'TokoPos',
      state: 'GetProvinces',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetProvinces',
      service: 'rll-gw-api',
    },
  },
  getCities: {
    event: {
      name: 'TokoPos',
      state: 'GetCities',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetCities',
      service: 'rll-gw-api',
    },
  },
  getBusinessTypes: {
    event: {
      name: 'TokoPos',
      state: 'GetBusinessTypes',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetBusinessTypes',
      service: 'rll-gw-api',
    },
  },
  getSubBusinessTypes: {
    event: {
      name: 'TokoPos',
      state: 'GetSubBusinessTypes',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetSubBusinessTypes',
      service: 'rll-gw-api',
    },
  },
}
