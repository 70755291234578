import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import ArrayofObjectToCamelCase from 'lib/transform/array-to-camel-case'
import sellerEvent from 'services/Seller/events'
import IProductReportModel from 'services/Seller/models/ReportModel/ProductReportModel/interface'

const ProductReportModel: IProductReportModel = {
  isLoading: true,
  isError: false,
  productList: [],
  metaProductList: {
    total: 0,
    page: 1,
    limit: 5,
  },
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setProductList: action((state, payload) => {
    state.productList = payload
  }),
  setMetaProductList: action((state, payload) => {
    state.metaProductList = payload
  }),
  getTopProduct: thunk(async (actions, payload, { injections }) => {
    actions.setIsLoading(true)
    actions.setIsError(false)
    try {
      const { apiClient } = injections
      const res = await apiClient({
        url: `/seller/v2/sales-report/top-product?limit=5&page=${payload.page}&start_date=${payload.startDate}&end_date=${payload.endDate}`,
        method: 'GET',
      })
      const { page, limit, total } = res.data
      actions.setMetaProductList({ page, limit, total })
      actions.setProductList(
        ArrayofObjectToCamelCase(res?.data?.result),
      )
      sendLogHitEndpoint(
        sellerEvent.getTopProduct.event,
        dependencyContextMapper(
          sellerEvent.getTopProduct.dependency,
          requestMapper(res.config),
          responseMapper(res),
        ),
      )
    } catch (err) {
      actions.setIsError(true)
      sendLogHitEndpoint(
        sellerEvent.getTopProduct.event,
        dependencyContextMapper(
          sellerEvent.getTopProduct.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    } finally {
      actions.setIsLoading(false)
    }
  }),
  getExportProductReport: thunk(
    async (actions, payload, { injections }) => {
      actions.setIsLoading(true)
      actions.setIsError(false)
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/seller/v2/sales-report/export-product-report?start_date=${payload.startDate}&end_date=${payload.endDate}`,
          method: 'GET',
        })
        sendLogHitEndpoint(
          sellerEvent.getExportProductReport.event,
          dependencyContextMapper(
            sellerEvent.getExportProductReport.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return response?.data?.download_url
      } catch (err) {
        actions.setIsError(true)
        sendLogHitEndpoint(
          sellerEvent.getExportProductReport.event,
          dependencyContextMapper(
            sellerEvent.getExportProductReport.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        throw err
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default ProductReportModel
