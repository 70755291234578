const searchSuggestionEvent = {
  getSearchSuggestion: {
    event: {
      name: 'Search 2.0',
      state: 'GetSearchSuggestion',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetSearchSuggestion',
      service: 'rll-gw-api',
    },
  },
}

const productListEvent = {
  getProducts: {
    event: {
      name: 'Product',
      state: 'getProducts',
    },
    dependency: {
      name: 'API Gateway',
      event: 'getProducts',
      service: 'rll-gw-api',
    },
  },
  getCategories: {
    event: {
      name: 'Product',
      state: 'getCategories',
    },
    dependency: {
      name: 'API Gateway',
      event: 'getCategories',
      service: 'rll-gw-api',
    },
  },
  getUOM: {
    event: {
      name: 'Product',
      state: 'getUOM',
    },
    dependency: {
      name: 'API Gateway',
      event: 'getUOM',
      service: 'rll-gw-api',
    },
  },
  getBrands: {
    event: {
      name: 'Product',
      state: 'getBrands',
    },
    dependency: {
      name: 'API Gateway',
      event: 'getBrands',
      service: 'rll-gw-api',
    },
  },
  postProducts: {
    event: {
      name: 'Product',
      state: 'postProducts',
    },
    dependency: {
      name: 'API Gateway',
      event: 'postProducts',
      service: 'rll-gw-api',
    },
  },
  postProductFiles: {
    event: {
      name: 'Product',
      state: 'postProductFiles',
    },
    dependency: {
      name: 'API Gateway',
      event: 'postProductFiles',
      service: 'rll-gw-api',
    },
  },
  getTotalProducts: {
    event: {
      name: 'Product',
      state: 'getTotalProducts',
    },
    dependency: {
      name: 'API Gateway',
      event: 'getTotalProducts',
      service: 'rll-gw-api',
    },
  },
  deleteProduct: {
    event: {
      name: 'Product',
      state: 'deleteProduct',
    },
    dependency: {
      name: 'API Gateway',
      event: 'deleteProduct',
      service: 'rll-gw-api',
    },
  },
  updateProduct: {
    event: {
      name: 'Product',
      state: 'updateProduct',
    },
    dependency: {
      name: 'API Gateway',
      event: 'updateProduct',
      service: 'rll-gw-api',
    },
  },
}

export { searchSuggestionEvent, productListEvent }
export default searchSuggestionEvent
