import Arrow from 'assets/svg/arrow/arrow_forward.svg'
import CancelIcon from 'assets/svg/cancel-login/cancel.svg'
import Alert from 'common/components/Alert'
import Button from 'common/components/Button'
import Flex from 'common/components/Flex'
import Typography from 'common/components/Typography'
import theme from 'common/theme'
import countdownFormatter from 'lib/countdown-formatter'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import OtpEmailIcon from 'services/Auth/assets/svg/otp-illustration/mail-illustration.svg'
import OtpIcon from 'services/Auth/assets/svg/otp-illustration/otp-illustration.svg'
import OtpWhatsappIcon from 'services/Auth/assets/svg/whatsapp/whatsapp.svg'
import { convertChannelNameCase } from 'services/Auth/widgets/Login'
import PinIcon from 'services/Wallet/assets/svg/pin/pin.svg'
import { useStoreActions, useStoreState } from 'stores/hooks'
import styled from 'styled-components'

const OtpFormContainer = styled(Flex)`
  padding: 50px;
  ${(props) =>
    props.responsive &&
    `
    @media (max-width: 1199.98px) {
      padding: 24px;
    }
  `}
`

const HeaderSection = styled.div``

const BodySection = styled.div`
  margin-top: 10px;
`
const FooterSection = styled.div`
  margin-top: 30px;
  text-align: center;

  > button {
    font-size: 16px;
  }
`
const Icon = styled.span`
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
`

const IconTopRight = styled.span`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`

const ArrowIcon = styled(Arrow)`
  path {
    fill: ${theme.colors.black};
    opacity: 0.5;
  }
  transform: scale(2) rotate(180deg);
  transform-origin: 25% 30%;
`
const OtpInput = styled.input`
  width: 30px;
  height: 50px;
  border: none;
  background-color: transparent;
  border-bottom: 4px solid ${({ borderColor }) => borderColor};
  font-size: ${theme.typography.bh4.fontSize};
  font-weight: ${theme.typography.bh4.fontWeight};
  color: ${theme.colors.black};
  caret-color: ${theme.colors.orange};
  text-align: center;
  outline: 0;
  -webkit-appearance: none;
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button,
  ::-webkit-calendar-picker-indicator,
  ::-webkit-clear-button {
    display: none;
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
  margin-left: 5px;
  margin-right: 5px;
`
const Link = styled.a`
  text-decoration: none;
  color: ${({ color }) => color};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  font-size: 13px;
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
`

interface IChannelIcon {
  channel: string
}
interface IOtpForm {
  resendOtp?: Function
  back?: Function
  onSubmit: Function
  type?: 'OTP' | 'OTA'
  handleTimeOut?: Function
  closeable?: boolean
  onClose?: Function
  resendText?: string
  responsive?: boolean
  isShowUseAnotherMethod?: boolean
  onClickUseAnotherMethod?: () => void
  isMaskedValue?: boolean
}

const ChannelIcon = ({ channel }: IChannelIcon): JSX.Element => {
  if (channel === 'SMS') return <OtpIcon />
  if (channel === 'WhatsApp') return <OtpWhatsappIcon width="80px" />
  if (channel === 'PIN') return <PinIcon />
  return <OtpEmailIcon width="140px" />
}

function OtpForm({
  resendOtp,
  back,
  onSubmit,
  type = 'OTP',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleTimeOut = () => {},
  closeable = false,
  onClose,
  resendText,
  responsive = false,
  isShowUseAnotherMethod = false,
  onClickUseAnotherMethod,
  isMaskedValue = false,
}: IOtpForm): JSX.Element {
  const { t } = useTranslation('auth')
  // Need to refactor for ref usage
  const inputRef1 = useRef<null | HTMLInputElement>(null)
  const inputRef2 = useRef<null | HTMLInputElement>(null)
  const inputRef3 = useRef<null | HTMLInputElement>(null)
  const inputRef4 = useRef<null | HTMLInputElement>(null)
  const inputRef5 = useRef<null | HTMLInputElement>(null)
  const inputRef6 = useRef<null | HTMLInputElement>(null)
  const [activeInput, setActiveInput] = useState<number>(1)
  const [otpValue, setOtpValue] = useState<Array<number | ''>>([])
  const [isValidOtp, setIsValidOtp] = useState<boolean>(false)
  const [countdownValue, setCountdownValue] = useState<string>('')

  const {
    channels,
    selectedChannel,
    otpTimer,
    isLoadingOtp,
    errorMessageOtp,
  } = useStoreState((state) => state.otp)

  const title = useMemo(() => {
    if (selectedChannel === 'pin') return t('ENTER_PIN')
    return t('ENTER_CODE')
  }, [selectedChannel])

  const subtitle = useMemo(() => {
    if (selectedChannel === 'pin') {
      return t('ENTER_6_PIN')
    }
    return (
      <Trans>
        {t('SUCCESS_SEND_VIA', {
          channel: convertChannelNameCase(selectedChannel),
          selectedChannel: channels[selectedChannel],
        })}
      </Trans>
    )
  }, [selectedChannel, channels])

  const { getUserProfile } = useStoreActions(
    (actions) => actions.profile,
  )

  const { setIsLoadingOtp } = useStoreActions(
    (actions) => actions.otp,
  )

  const submitOtp = async (otp) => {
    if (isValidOtp && !isLoadingOtp) {
      const otpResult = await onSubmit(otp)
      if (otpResult) {
        getUserProfile()
      }
    }
  }

  const fieldValue = (index) => {
    if (!!otpValue[index] && isMaskedValue) return '*'
    return otpValue[index]
  }

  const handleOnKeyDown = (e) => {
    e.preventDefault()
    const currentValue = [...otpValue]
    switch (e.key) {
      case 'Backspace':
      case 'Delete': {
        e.preventDefault()
        currentValue[activeInput - 1] = ''
        setOtpValue([...currentValue])
        setIsValidOtp(false)
        if (activeInput !== 0 && !otpValue[activeInput - 1]) {
          setActiveInput(activeInput - 1)
          break
        }
        break
      }
      case 'ArrowLeft': {
        e.preventDefault()
        if (activeInput !== 1) setActiveInput(activeInput - 1)
        break
      }
      case 'ArrowRight': {
        e.preventDefault()
        if (activeInput < 6) setActiveInput(activeInput + 1)
        break
      }
      default: {
        if (activeInput <= 6 && e.key[0].match(/[0-9]/)) {
          currentValue[activeInput - 1] = e.key
          setOtpValue([...currentValue])
          if (activeInput < 6) {
            setActiveInput(activeInput + 1)
          }
        }
        break
      }
    }
  }

  const handleChangeInput = (e, keys) => {
    e.preventDefault()
    const currentValue = [...otpValue]
    const inputValue = e.target.value
    if (keys <= 6 && inputValue.match(/[0-9]/)) {
      currentValue[activeInput - 1] = inputValue
      setOtpValue([...currentValue])
      if (keys !== 6) setActiveInput(activeInput + 1)
    }
    if (keys <= 6 && inputValue === '' && currentValue[keys - 1]) {
      currentValue[activeInput - 1] = ''
      setOtpValue([...currentValue])
    }
  }

  const handleSubmitForm = (e) => {
    e.preventDefault()
    const otp = otpValue.join('')
    submitOtp(otp)
  }

  const clearOtp = () => {
    setOtpValue([])
    setIsValidOtp(false)
    inputRef1.current.value = ''
    inputRef2.current.value = ''
    inputRef3.current.value = ''
    inputRef4.current.value = ''
    inputRef5.current.value = ''
    inputRef6.current.value = ''
    setActiveInput(1)
    inputRef1.current.focus()
  }

  const handleResendOtp = (e) => {
    e.preventDefault()
    if (countdownValue === '00:00' && !isLoadingOtp) {
      clearOtp()
      resendOtp()
    }
  }

  useEffect(() => {
    setIsLoadingOtp(false)
    const timer = setInterval(() => {
      setCountdownValue(
        countdownFormatter(otpTimer, { hours: false }),
      )
    }, 1000)

    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otpTimer])

  useEffect(() => {
    if (!isLoadingOtp && countdownValue === '00:00') {
      handleTimeOut()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownValue])

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (activeInput) {
      case 1:
        inputRef1.current.focus()
        break
      case 2:
        inputRef2.current.focus()
        break
      case 3:
        inputRef3.current.focus()
        break
      case 4:
        inputRef4.current.focus()
        break
      case 5:
        inputRef5.current.focus()
        break
      case 6:
        inputRef6.current.focus()
        break
    }
  }, [activeInput])

  useEffect(() => {
    if (errorMessageOtp !== '') clearOtp()
  }, [errorMessageOtp])

  useEffect(() => {
    if (otpValue.join('').length === 6) {
      setIsValidOtp(true)
    }
  }, [otpValue])

  useEffect(() => {
    if (isValidOtp) {
      submitOtp(otpValue.join(''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidOtp])

  return (
    <OtpFormContainer
      flexDirection="column"
      flexGrow="1"
      responsive={responsive}
      data-testid="otp-form-test"
    >
      <HeaderSection>
        <Flex alignItems="center" justifyContent="space-between">
          <>
            {back && (
              <Icon data-testid="otp-form-back" onClick={back}>
                <ArrowIcon fill={theme.colors.black} />
              </Icon>
            )}

            {closeable && (
              <IconTopRight
                data-testid="otp-form-close"
                onClick={onClose}
              >
                <CancelIcon />
              </IconTopRight>
            )}
          </>
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          padding="10px"
        >
          <ChannelIcon
            channel={convertChannelNameCase(selectedChannel)}
          />
        </Flex>
        <Typography type="bh2" textAlign="center" marginTop="20px">
          {title}
        </Typography>
        <Typography textAlign="center" marginTop="10px">
          {subtitle}
        </Typography>
      </HeaderSection>
      {errorMessageOtp && (
        <Alert
          data-testid="otp-form-error-message"
          type="error"
          margin="10px 0 0"
          minWidth={responsive && 'auto'}
        >
          {errorMessageOtp}
        </Alert>
      )}
      <form onSubmit={handleSubmitForm}>
        <BodySection>
          <Flex justifyContent="center">
            <>
              <OtpInput
                data-testid="otp-input-1"
                borderColor={
                  otpValue[0]
                    ? theme.colors.orange
                    : theme.colors.gray
                }
                onChange={(e) => handleChangeInput(e, 1)}
                onKeyDown={handleOnKeyDown}
                onClick={() => setActiveInput(1)}
                ref={inputRef1}
                maxLength={1}
                value={fieldValue(0)}
                required
                type="text"
              />
              <OtpInput
                data-testid="otp-input-2"
                borderColor={
                  otpValue[1]
                    ? theme.colors.orange
                    : theme.colors.gray
                }
                onChange={(e) => handleChangeInput(e, 2)}
                onKeyDown={handleOnKeyDown}
                onClick={() => setActiveInput(2)}
                ref={inputRef2}
                maxLength={1}
                value={fieldValue(1)}
                type="text"
                required
              />
              <OtpInput
                data-testid="otp-input-3"
                borderColor={
                  otpValue[2]
                    ? theme.colors.orange
                    : theme.colors.gray
                }
                onChange={(e) => handleChangeInput(e, 3)}
                onKeyDown={handleOnKeyDown}
                onClick={() => setActiveInput(3)}
                ref={inputRef3}
                maxLength={1}
                value={fieldValue(2)}
                type="text"
                required
              />
              <OtpInput
                data-testid="otp-input-4"
                borderColor={
                  otpValue[3]
                    ? theme.colors.orange
                    : theme.colors.gray
                }
                onChange={(e) => handleChangeInput(e, 4)}
                onKeyDown={handleOnKeyDown}
                onClick={() => setActiveInput(4)}
                ref={inputRef4}
                maxLength={1}
                value={fieldValue(3)}
                type="text"
                required
              />
              <OtpInput
                data-testid="otp-input-5"
                borderColor={
                  otpValue[4]
                    ? theme.colors.orange
                    : theme.colors.gray
                }
                onChange={(e) => handleChangeInput(e, 5)}
                onKeyDown={handleOnKeyDown}
                onClick={() => setActiveInput(5)}
                ref={inputRef5}
                maxLength={1}
                value={fieldValue(4)}
                type="text"
                required
              />
              <OtpInput
                data-testid="otp-input-6"
                borderColor={
                  otpValue[5]
                    ? theme.colors.orange
                    : theme.colors.gray
                }
                onChange={(e) => handleChangeInput(e, 6)}
                onKeyDown={handleOnKeyDown}
                onClick={() => setActiveInput(6)}
                ref={inputRef6}
                maxLength={1}
                value={fieldValue(5)}
                type="text"
                required
              />
            </>
          </Flex>
        </BodySection>
        <FooterSection>
          {type === 'OTP' && (
            <>
              <Typography
                type="rparagraph"
                textAlign="center"
                marginBottom="20px"
              >
                {countdownValue && (
                  <Trans>
                    {t('PLEASE_WAIT', {
                      countdown: countdownValue,
                    })}
                  </Trans>
                )}
              </Typography>
              <Link
                data-testid="otp-form-resend-otp"
                role="button"
                onClick={handleResendOtp}
                clickable={
                  countdownValue === '00:00' && !isLoadingOtp
                }
                color={
                  countdownValue === '00:00' && !isLoadingOtp
                    ? theme.colors.orange
                    : theme.colors.black50
                }
              >
                {resendText ?? t('RESEND')}
              </Link>
            </>
          )}
          {type === 'OTA' && (
            <>
              <Typography
                type="rparagraph"
                textAlign="center"
                marginBottom="20px"
              >
                {countdownValue &&
                  (selectedChannel === 'pin' ? (
                    <Trans>
                      {t('DESC_ENTER_PIN', {
                        countdown: countdownValue,
                      })}
                    </Trans>
                  ) : (
                    <Trans>
                      {t('DESC_ENTER_CODE', {
                        countdown: countdownValue,
                      })}
                    </Trans>
                  ))}
              </Typography>
              {isShowUseAnotherMethod && (
                <Link
                  data-testid="use-another-verification-method"
                  role="button"
                  color={theme.colors.orange}
                  onClick={onClickUseAnotherMethod}
                  clickable
                  fontWeight="bold"
                >
                  {t('ANOTHER_METHOD')}
                </Link>
              )}
            </>
          )}
          <Button
            type="submit"
            size="large"
            block
            data-testid="button-submit"
            disabled={!isValidOtp}
            fontWeight="bold"
            margin="30px 0 0"
            isLoading={isLoadingOtp}
          >
            {t('VERIFICATION')}
          </Button>
        </FooterSection>
      </form>
    </OtpFormContainer>
  )
}

export default OtpForm
