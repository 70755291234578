import { action, thunk } from 'easy-peasy'
import IOrderNotificationModel from 'services/Notification/models/OrderNotificationModel/interface'

const OrderNotificationModel: IOrderNotificationModel = {
  isLoading: true,
  isError: false,
  errorMessage: '',
  unread: 0,
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
  data: [],
  setData: action((state, payload) => {
    state.isLoading = false
    state.unread = payload.filter((item) => !item.is_read).length
    state.data = payload
  }),
  getOrderNotification: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: '/core/api/v4/notification/order-notification',
          method: 'GET',
          params: payload,
        })
        actions.setData(
          response.data?.result?.length ? response.data.result : [],
        )
      } catch (err) {
        actions.error(err.message)
      }
    },
  ),
  readOrderNotification: thunk(
    async (actions, notifId, { injections }) => {
      try {
        const { apiClient } = injections
        await apiClient({
          url: `/core/api/v4/notification/read-notification`,
          method: 'POST',
          data: {
            notif_id: notifId,
          },
        })
        actions.successReadOrderNotification(notifId)
      } catch (err) {
        actions.error(err.message)
      }
    },
  ),
  successReadOrderNotification: action((state, notifId) => {
    const index = state.data.findIndex(
      (notif) => notif.notif_id === notifId,
    )
    if (index > -1) {
      state.data[index].is_read = 1
      state.unread = state.data.filter((item) => !item.is_read).length
    }
  }),
  readAllOrderNotification: thunk(
    async (actions, notifId, { injections }) => {
      try {
        actions.setLoading(true)
        const { apiClient } = injections
        await apiClient({
          url: `/core/api/v4/notification/read-notification`,
          method: 'POST',
          data: {
            notif_id: notifId,
          },
        })
        actions.getOrderNotification({})
      } catch (err) {
        actions.error(err.message)
      }
    },
  ),
}

export default OrderNotificationModel
