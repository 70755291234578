export default {
  CHOOSE_METHOD: 'Choose Verification Method',
  DESC_METHOD:
    'Choose one of the methods below to receive the verification code',
  DESC_METHOD_COUNTDOWN:
    'Choose one of the methods below within <strong>{{countdown}}</strong> to receive the verification code',
  SEND_VIA: 'Via {{channel}}',
  SEND_TO: 'to {{channel}}',
  PLEASE_WAIT: 'Please wait for <strong>{{countdown}}</strong>',
  ANOTHER_METHOD: 'Use Another Verification Method',
  VERIFICATION: 'Verify',
  ENTER_PIN: 'Enter PIN',
  ENTER_CODE: 'Enter Verification Code',
  ENTER_6_PIN: 'Please enter your 6-digit PIN.',
  SUCCESS_SEND_VIA:
    'Verification code successfully sent via <strong>{{channel}}</strong> to <strong>{{selectedChannel}}</strong>.',
  RESEND: 'Resend',
  DESC_ENTER_PIN: 'Please enter the PIN within {{countdown}}',
  DESC_ENTER_CODE:
    'Please enter the verification code within {{countdown}}',
  ACCOUNT_HAS_BEEN_REGISTER: 'Your Account Has Been Registered',
  DESC_PLEASE_LOGIN:
    'Your account has been registered in our system. Please log in to use your account.',
  SIGNUP_RALALI: 'Sign Up Now',
  ALREADY_SIGNUP: 'Already have a Ralali account?',
  SIGNIN: 'Sign In',
  SIGNUP: 'Sign Up',
  FULLNAME: 'Full Name',
  EXAMPLE_NAME: 'e.g., John Doe',
  INVALID_NAME: 'Invalid name',
  EMAIL_PHONE: 'Email or Phone Number',
  EXAMPLE_EMAIL_PHONE: 'e.g., johndoe@gmail.com or 081234567890',
  INVALID_EMAIL_PHONE: 'Invalid Email or Phone Number',
  PASSWORD: 'Password',
  REPEAT_PASSWORD: 'Confirm Password',
  INVALID_PASSWORD:
    'Min. 8-50 characters, lowercase/uppercase letters, numbers, symbols.',
  INVALID_CONFIRM_PASSWORD:
    'The password confirmation column does not match',
  SIGNUP_AGGREE: 'By signing up, I agree to',
  AND: 'and',
  TERMS_CONDITION: 'Terms and Conditions',
  PRIVACY_POLICY: 'Privacy Policy',
  SIGNIN_RALALI: 'Sign In Ralali',
  PLACEHOLDER_SIGNIN_PASSWORD: 'Min 6 letters or characters',
  INVALID_SIGNIN_PASSWORD: `Password shouldn't be less than 6 characters`,
  REMIND_ME: 'Remind me',
  FORGOT_PASSWORD: 'Forgot password?',
  DONT_HAVE_ACCOUNT: "Don't have an account ?",
  REDIRECT_SELLERPANEL:
    'You will be directed to the seller panel page after logging in.',
  RESET_PASSWORD: 'Reset Password',
  RESET_PASSWORD_DESC:
    'Input the registered email. We will send you a verification code to reset your password.',
  EXAMPLE_EMAIL: 'e.g. : johndoe@gmail.com',
  LOGIN_WITH_PHONE_DESC: 'Login with phone number?',
  RETRY_FORGOT_PASSWORD: 'Retry Forgot Password',
  TIMEOUT: 'Time Out',
  RETRY_FORGOT_PASSWORD_DESC:
    'Sorry, the time has already expired. Please try again.',
  PASSWORD_HAS_CHANGED: 'The Password Has Changed',
  PASSWORD_HAS_CHANGED_DESC:
    'Use the new password to sign in to Ralali. Do not share your password with anyone.',
  NEW_PASSWORD: 'New Password',
  CREATE_NEW_PASSWORD_DESC: 'Create a new password within ',
}
