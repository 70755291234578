import CancelIcon from 'assets/svg/cancel-login/cancel.svg'
import CorrectIcon from 'assets/svg/correct/feather-check.svg'
import Alert from 'common/components/Alert'
import Button from 'common/components/Button'
import Flex from 'common/components/Flex'
import TextInput from 'common/components/TextInput'
import Typography from 'common/components/Typography'
import theme from 'common/theme'
import validateEmailFormat from 'lib/common/is-email'
import redirect from 'lib/common/redirect'
import {
  destroyLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from 'lib/local-storage'
import validatePassword from 'lib/validator/validate-password'
import validateReferrerUrl from 'lib/validator/validate-referrer-url'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SocialLogin from 'services/Auth/components/SocialLogin'
import UserBlocked from 'services/Auth/components/UserBlocked'
import UserNotRegistered from 'services/Auth/components/UserNotRegistered'
import { useStoreActions, useStoreState } from 'stores/hooks'
import styled from 'styled-components'

const LoginFormContainer = styled(Flex)`
  padding: 50px;
  width: 100%;
  max-width: 480px;
  ${(props) =>
    props.responsive &&
    `
    @media (max-width: 1199.98px) { 
      padding: 16px; 
    }
  `}
`

const HeaderSection = styled.div``

const Title = styled(Typography)`
  @media (min-width: 768px) {
    font-size: ${theme.typography.bh3.fontSize};
  }
  @media (min-width: 1200px) {
    font-size: ${theme.typography.bh2.fontSize};
  }
`

const WrapperBody = styled.div`
  display: flex;
  justify-content: space-between;
`
const CheckboxContainer = styled.div`
  display: flex;
`
const Checkbox = styled.span`
  position: relative;

  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox'] + span {
    display: inline-block;
    position: relative;
    top: -1px;
    width: 16px;
    height: 16px;
    margin: -1px 0px 0 0;
    vertical-align: middle;
    background: white left top no-repeat;
    cursor: pointer;
    border: 0.5px solid ${theme.colors.gray};
    border-radius: 4px;
  }
  input[type='checkbox']:checked + span {
    border: 0.5px solid ${theme.colors.orange};
    background: ${theme.colors.orange} -19px top no-repeat;
  }
`
const Icon = styled.i`
  position: relative;
  bottom: 3px;
  left: 2px;
`
const StyledCancelIcon = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`
const BodySection = styled.div`
  margin-top: 30px;
`
const StyledLinkBody = styled.a`
  text-decoration: none;
  color: ${theme.colors.orange};
`
const FooterSection = styled.div`
  margin-top: 30px;
`
const StyledLinkFooter = styled.a`
  text-decoration: none;
  font-weight: bold;
  color: ${theme.colors.orange};
`
const StyledTextInput = styled(TextInput)`
  font-weight: bold;
  ::placeholder {
    font-weight: normal;
  }
  ${(props) =>
    props.responsive &&
    `
    @media (max-width: 1199.98px) { 
      width: 100%;
    }
  `}
`
const StyledButton = styled(Button)`
  font-size: 16px;
`

interface ILoginForm {
  handleCloseLogin?: () => void
  responsive?: boolean
  inputWidth?: string
}

function LoginForm({
  handleCloseLogin,
  responsive = false,
  inputWidth = '480px',
}: ILoginForm): JSX.Element {
  const { t } = useTranslation()
  const [enteredUsername, setEnteredUsername] = useState<string>('')
  const [enteredPassword, setEnteredPassword] = useState<string>('')
  const [usernameValidated, setUsernameValidated] = useState<boolean>(
    false,
  )
  const [passwordValidated, setPasswordValidated] = useState<boolean>(
    false,
  )
  const [isPhoneNumber, setIsPhoneNumber] = useState<boolean>(false)
  const [isEmail, setIsEmail] = useState<boolean>(false)
  const [isValidated, setIsValidated] = useState<boolean>(false)
  const [focusField, setFocusField] = useState<string>('')
  const [isFromSellerPage, setIsFromSellerPage] = useState<boolean>(
    false,
  )

  const {
    isLoading,
    errorMessage,
    isKeepSignedIn,
    isUserBlocked,
    isUserNotRegistered,
  } = useStoreState((state) => state.login)
  const {
    getOtpChannel,
    postLoginEmail,
    setIsKeepSignedIn,
    setIsUserBlocked,
    setIsUserNotRegistered,
    setIsUserNotVerified,
    error,
  } = useStoreActions((actions) => actions.login)

  const validateUsername = (value) => {
    const regexNumber = /^[0-9\b]{9,15}$/
    if (regexNumber.test(value)) {
      setIsPhoneNumber(true)
      setIsEmail(false)
      setUsernameValidated(true)
    } else if (validateEmailFormat(value)) {
      setIsPhoneNumber(false)
      setIsEmail(true)
      setUsernameValidated(true)
    } else {
      setEnteredPassword('')
      setIsEmail(false)
      setIsPhoneNumber(false)
      setIsKeepSignedIn(true)
      setUsernameValidated(false)
    }
  }

  useEffect(() => {
    error('')
    if (isEmail) {
      setIsValidated(usernameValidated && passwordValidated)
    } else {
      setIsValidated(usernameValidated)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [usernameValidated, passwordValidated])

  const usernameInputHandler = (event) => {
    validateUsername(event.target.value)
    setEnteredUsername(event.target.value)
  }

  const toggleCheck = () => {
    setIsKeepSignedIn(!isKeepSignedIn)
  }

  const passwordInputHandler = (event) => {
    const passwordValidation = validatePassword(event.target.value, 6)
    setPasswordValidated(passwordValidation.isLengthValidate)
    setEnteredPassword(event.target.value)
  }

  const postPasswordLogin = async () => {
    if (!isLoading) {
      postLoginEmail({
        username: enteredUsername,
        password: enteredPassword,
      })
    }
  }

  const formSubmissionHandler = (event) => {
    event.preventDefault()
    setIsUserNotVerified(false)
    if (isEmail && isValidated && !isLoading) {
      postPasswordLogin()
    }
    if (!isEmail && isPhoneNumber && isValidated && !isLoading) {
      getOtpChannel(enteredUsername)
    }
  }

  const redirectRegisterPage = () => {
    setLocalStorage('username_register', enteredUsername)
    setIsUserNotRegistered(false)
    redirect('/signup')
  }

  useEffect(() => {
    if (getLocalStorage('username_login')) {
      validateUsername(String(getLocalStorage('username_login')))
      setEnteredUsername(String(getLocalStorage('username_login')))
      destroyLocalStorage('username_login')
    }

    /**
     * validate referrer url from sellerpanel and set it to isFromSellerPage state
     */
    const searchParams = window.location.href.match(
      /ref*?=(.*?)($|&)/g,
    )
    const referrer =
      searchParams && searchParams[0].replace(/((ref=)|&)/g, '')
    const decodedReferrer = decodeURIComponent(
      referrer || document.referrer,
    )
    const sellerPanelRegex = /sellerpanel(.*).ralali.(xyz|com)/

    setIsFromSellerPage(
      window.location.pathname === '/login' &&
        validateReferrerUrl(decodedReferrer) &&
        !!sellerPanelRegex.exec(new URL(decodedReferrer).hostname),
    )
  }, [])

  const Description = (): JSX.Element => {
    if (!isFromSellerPage) {
      return <></>
    }

    return (
      <Typography type="rh6" textAlign="center" marginTop={15}>
        Anda akan diarahkan ke halaman sellerpanel setelah login
      </Typography>
    )
  }

  const isUsernameValidated = () => {
    return enteredUsername && !usernameValidated
  }

  const isPasswordValidated = () => {
    return enteredPassword && !passwordValidated
  }

  return (
    <LoginFormContainer
      flexDirection="column"
      responsive={responsive}
      data-testid="login-test"
    >
      <HeaderSection>
        {handleCloseLogin && (
          <StyledCancelIcon
            onClick={handleCloseLogin}
            data-testid="close-login"
          >
            <CancelIcon />
          </StyledCancelIcon>
        )}
        <Title type="bh4" textAlign="center">
          {t('SIGNIN_RALALI', { ns: 'auth' })}
        </Title>
        <Description />
        <SocialLogin />
      </HeaderSection>
      <form onSubmit={formSubmissionHandler}>
        <BodySection>
          <StyledTextInput
            id="username"
            width={inputWidth}
            onChange={usernameInputHandler}
            floatingLabel={t('EMAIL_PHONE', { ns: 'auth' })}
            data-testid="input-username"
            value={enteredUsername}
            placeholder={
              focusField === 'username'
                ? t('EXAMPLE_EMAIL_PHONE', { ns: 'auth' })
                : ''
            }
            onFocus={() => setFocusField('username')}
            onBlur={() => setFocusField('')}
            helperText={
              isUsernameValidated() &&
              t('INVALID_EMAIL_PHONE', { ns: 'auth' })
            }
            hideHelperText={!isUsernameValidated()}
            margin={isUsernameValidated() ? '0 0 10px' : '0 0 20px'}
            error={isUsernameValidated()}
            responsive={responsive}
          />
          {!isPhoneNumber && isEmail && (
            <StyledTextInput
              isVisibilityToggleable
              floatingLabel={t('PASSWORD', { ns: 'auth' })}
              width={inputWidth}
              onChange={passwordInputHandler}
              data-testid="input-password"
              value={enteredPassword}
              maxLength="50"
              placeholder={
                focusField === 'password'
                  ? t('PLACEHOLDER_SIGNIN_PASSWORD', { ns: 'auth' })
                  : ''
              }
              onFocus={() => setFocusField('password')}
              onBlur={() => setFocusField('')}
              helperText={
                isPasswordValidated() &&
                t('INVALID_SIGNIN_PASSWORD', { ns: 'auth' })
              }
              hideHelperText={!isPasswordValidated()}
              margin={isPasswordValidated() ? '0 0 10px' : '0 0 20px'}
              error={isPasswordValidated()}
              responsive={responsive}
            />
          )}
          {errorMessage && (
            <Alert
              type="error"
              margin="0 0 25px 0"
              key={errorMessage}
            >
              {errorMessage}
            </Alert>
          )}
          {!isPhoneNumber && isEmail ? (
            <WrapperBody>
              <CheckboxContainer>
                <Checkbox
                  onClick={toggleCheck}
                  data-testid="checkbox-input"
                >
                  <input
                    type="checkbox"
                    checked={isKeepSignedIn}
                    readOnly
                  />
                  <span>
                    {isKeepSignedIn && (
                      <Icon>
                        <CorrectIcon />
                      </Icon>
                    )}
                  </span>
                </Checkbox>
                <Typography marginLeft="10px">
                  {t('REMIND_ME', { ns: 'auth' })}
                </Typography>
              </CheckboxContainer>
              <Typography textAlign="right">
                <StyledLinkBody href="/reset-password">
                  {t('FORGOT_PASSWORD', { ns: 'auth' })}
                </StyledLinkBody>
              </Typography>
            </WrapperBody>
          ) : (
            <Typography textAlign="right" marginTop="6px">
              <StyledLinkBody href="/reset-password">
                {t('FORGOT_PASSWORD', { ns: 'auth' })}
              </StyledLinkBody>
            </Typography>
          )}
        </BodySection>
        <FooterSection>
          <StyledButton
            type="submit"
            size="large"
            block
            disabled={!isValidated}
            data-testid="button-submit"
            fontWeight="bold"
            isLoading={isLoading}
          >
            {t('SIGNIN', { ns: 'auth' })}
          </StyledButton>
          <Typography type="rh6" textAlign="center" marginTop="40px">
            <>
              {t('DONT_HAVE_ACCOUNT', { ns: 'auth' })}
              <StyledLinkFooter href="/signup">
                {' '}
                {t('SIGNUP', { ns: 'auth' })}
              </StyledLinkFooter>
            </>
          </Typography>
        </FooterSection>
      </form>
      <UserBlocked
        isOpen={isUserBlocked}
        onClose={() => setIsUserBlocked(false)}
      />
      <UserNotRegistered
        isOpen={isUserNotRegistered}
        onClose={() => setIsUserNotRegistered(false)}
        onButtonClick={() => redirectRegisterPage()}
      />
    </LoginFormContainer>
  )
}

export default LoginForm
