import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import storeStatisticsEvent from 'services/Seller/events'
import IStoreStatisticsModel from 'services/Seller/models/StoreStatisticsModel/interface'

const StoreStatisticsModel: IStoreStatisticsModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  data: null,
  setData: action((state, payload) => {
    state.data = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setErrorMessage: action((state, payload) => {
    state.errorMessage = payload
  }),
  resetError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
  error: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  getStoreStatistics: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.resetError()

        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/stores/${payload.storeId}/statistics`,
          method: 'GET',
          params: {
            start_date: payload.startDate,
            end_date: payload.endDate,
            interval_group: payload.interval ?? 'day',
          },
        })

        sendLogHitEndpoint(
          storeStatisticsEvent.getStoreStatistics.event,
          dependencyContextMapper(
            storeStatisticsEvent.getStoreStatistics.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )

        if (response.status === 200) {
          actions.setData(objectToCamelCase(response.data.data))
        }
      } catch (err) {
        sendLogHitEndpoint(
          storeStatisticsEvent.getStoreStatistics.event,
          dependencyContextMapper(
            storeStatisticsEvent.getStoreStatistics.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.error(err?.response?.data?.message)
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default StoreStatisticsModel
