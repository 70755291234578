import {
  DEFAULT_LANG,
  LANG_COOKIE_NAME,
  SSO_CODE,
  WEB_DESKTOP_RALALI_URL,
} from 'common/constants'
import apiConfig from 'config/api'
import authConfig from 'config/auth'
import { action, persist, thunk } from 'easy-peasy'
import afterLogin from 'lib/auth/after-login'
import { destroyCookie, getCookie, setCookie } from 'lib/cookie'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventTracking from 'lib/event-tracking'
import { GAUniversalEvent } from 'lib/google-analytics'
import GA4 from 'lib/google-analytics-4'
import randomizeNumber from 'lib/randomize-number'
import rudderstack from 'lib/rudderstack/tracker'
import parseJWT from 'lib/transform/jwt-parser'
import {
  emailMasking,
  phoneNumberMasking,
} from 'lib/transform/masking'
import validationResponseHelper from 'lib/validation-response-handler'
import validatePassword from 'lib/validator/validate-password'
import validateReferrerUrl from 'lib/validator/validate-referrer-url'
import { eventLogin, eventRegister } from 'services/Auth/event'
import loginCategory from 'services/Auth/lib/login-category'
import ILoginModel from 'services/Auth/stores/models/LoginModel/interface'
import { parsePhoneFormat } from 'services/Auth/widgets/Login'
import store from 'stores/index'

const { baseAccessUrl } = apiConfig

const randomPassword = () => {
  const uppercaseChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  const randomString =
    randomizeNumber().toString(36) + randomizeNumber().toString(36)

  const randomUppercaseChar = Math.floor(
    randomizeNumber(0, uppercaseChar.length - 1),
  )

  const randomStringPos = Math.floor(
    randomizeNumber(0, randomString.length - 1),
  )
  const randomCharPos = Math.floor(
    randomizeNumber(0, randomString.length - 1),
  )

  return randomString
    .replace(randomString.charAt(randomStringPos), '!')
    .replace(
      randomString.charAt(
        randomCharPos === randomStringPos
          ? randomString.length - randomCharPos
          : randomCharPos,
      ),
      uppercaseChar.charAt(randomUppercaseChar),
    )
}

const LoginModel: ILoginModel = {
  loginType: persist(null),
  isLoading: false,
  errorMessage: '',
  step: 'login-form',
  username: '',
  password: '',
  isEmail: false,
  isKeepSignedIn: true,
  isUserBlocked: false,
  isUserNotRegistered: false,
  isUserNotVerified: false,
  setLoginType: action((state, payload) => {
    state.loginType = { value: payload }
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
    state.errorMessage = ''
  }),
  setStep: action((state, payload) => {
    state.step = payload
  }),
  setUsername: action((state, payload) => {
    state.username = payload
  }),
  setPassword: action((state, payload) => {
    state.password = payload
  }),
  setIsEmail: action((state, payload) => {
    state.isEmail = payload
  }),
  setIsKeepSignedIn: action((state, payload) => {
    state.isKeepSignedIn = payload
  }),
  setIsUserBlocked: action((state, payload) => {
    state.isUserBlocked = payload
  }),
  setIsUserNotRegistered: action((state, payload) => {
    state.isUserNotRegistered = payload
  }),
  setIsUserNotVerified: action((state, payload) => {
    state.isUserNotVerified = payload
  }),
  getOtpChannel: thunk(
    async (actions, payload, { injections, getState }) => {
      if (
        parsePhoneFormat(payload) ===
          parsePhoneFormat(getState().username) &&
        new Date(store.getState().otp.otpTimer.replace(/-/g, '/')) >
          new Date()
      ) {
        actions.setStep('otp-form')
        actions.error('')
        return
      }
      const phone = payload
      actions.setIsLoading(true)
      actions.setIsEmail(false)
      actions.setUsername(phone)
      store.getActions().otp.setChannels({})
      try {
        const { apiClient } = injections
        const response = await apiClient({
          apiHost: baseAccessUrl,
          url: `/sso/v1/available-verification-channels/${parsePhoneFormat(
            phone,
          )}?sso_code=${SSO_CODE}`,
          method: 'GET',
          headers: {
            'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
          },
        })
        if (response?.status === 200 && response?.data?.data) {
          store
            .getActions()
            .otp.setChannels(response?.data?.data?.channels)
          actions.setStep('otp-channel')
          actions.setIsLoading(false)
          store.getActions().otp.setIsLoadingChannel(false)
          sendLogHitEndpoint(
            {
              ...eventLogin.getVerificationChannel.event,
              phone_number: parsePhoneFormat(phone, '+62'),
            },
            dependencyContextMapper(
              eventLogin.getVerificationChannel.dependency,
              requestMapper(response.config),
              responseMapper(response),
            ),
          )
        }
      } catch (err) {
        actions.errorLogin(err)
        sendLogHitEndpoint(
          {
            ...eventLogin.getVerificationChannel.event,
            phone_number: parsePhoneFormat(phone, '+62'),
          },
          dependencyContextMapper(
            eventLogin.getVerificationChannel.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
      }
    },
  ),
  postLoginEmail: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!getState().isLoading) {
        actions.setIsLoading(true)
        actions.setIsEmail(true)
        actions.setUsername(payload.username)
        actions.setPassword(payload.password)
        try {
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url: '/sso/v1/login',
            method: 'POST',
            data: {
              sso_code: SSO_CODE,
              grant_type: 'login_password',
              email: payload.username,
              password: payload.password,
            },
            headers: {
              'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
            },
          })
          if (response?.status === 200) {
            GAUniversalEvent(
              'Login and Register on Home page',
              'Success login by email',
              'Success_login_by_email',
            )
            sendLogHitEndpoint(
              {
                ...eventLogin.loginPassword.event,
                email: payload.username,
              },
              dependencyContextMapper(
                eventLogin.loginPassword.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )

            actions.setLoginType('email')
            actions.successLogin(response)
          }
        } catch (err) {
          sendLogHitEndpoint(
            {
              ...eventLogin.loginPassword.event,
              email: payload.username,
            },
            dependencyContextMapper(
              eventLogin.loginPassword.dependency,
              requestMapper(err.config),
              errorMapper(err),
            ),
          )
          actions.errorLogin(err)
        } finally {
          actions.setPassword('')
        }
      }
    },
  ),
  postVerifyOtp: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!store.getState().otp.isLoadingOtp) {
        store.getActions().otp.setIsLoadingOtp(true)
        let url = ''
        let data = {}
        let log
        if (!getState().isUserNotVerified) {
          url = '/sso/v1/login'
          data = {
            grant_type: 'login_otp',
            phone_number: parsePhoneFormat(
              getState().username,
              '+62',
            ),
            otp: payload,
          }
          log = {
            event: {
              ...eventLogin.loginOTP.event,
              phone_number: parsePhoneFormat(
                getState().username,
                '+62',
              ),
            },
            dependency: eventLogin.loginOTP.dependency,
          }
        } else {
          url = '/sso/v1/verify-register'
          if (store.getState().otp.selectedChannel === 'email') {
            data = {
              otp: payload,
              validate_by: getState().username,
            }
            log = {
              event: {
                ...eventRegister.verifyRegister.event,
                email: getState().username,
              },
              dependency: eventRegister.verifyRegister.dependency,
            }
          } else {
            data = {
              otp: payload,
              validate_by: parsePhoneFormat(
                getState().username,
                '+62',
              ),
            }
            log = {
              event: {
                ...eventRegister.verifyRegister.event,
                phone_number: parsePhoneFormat(
                  getState().username,
                  '+62',
                ),
              },
              dependency: eventRegister.verifyRegister.dependency,
            }
          }
        }
        try {
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url,
            method: 'POST',
            data: {
              sso_code: SSO_CODE,
              ...data,
            },
            headers: {
              'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
            },
          })
          if (response?.status === 200) {
            sendLogHitEndpoint(
              log.event,
              dependencyContextMapper(
                log.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )
            if (!getState().isUserNotVerified) {
              GAUniversalEvent(
                'Login and Register on Home page',
                'Success login by phone number',
                'Sucess_login_by_phone_number',
              )
            } else if (
              getState().isUserNotVerified &&
              store.getState().otp.selectedChannel === 'email'
            ) {
              GAUniversalEvent(
                'Login and Register on Home page',
                'Login with unverified email account',
                'Login_with_unverified_email_account',
              )
            } else {
              GAUniversalEvent(
                'Login and Register on Home page',
                'Login with unverified phone number',
                'Login_with_unverified_phone_number',
              )
            }

            actions.setLoginType('phone number')
            actions.successLogin(response)
          }
        } catch (err) {
          const errorMessage = validationResponseHelper(err)
          store.getActions().otp.errorOtp(errorMessage)
          sendLogHitEndpoint(
            log.event,
            dependencyContextMapper(
              log.dependency,
              requestMapper(err.config),
              errorMapper(err),
            ),
          )
        }
      }
    },
  ),
  postSendOtp: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!store.getState().otp.isLoadingChannel) {
        const otpChannel = payload
        store.getActions().otp.setIsLoadingChannel(true)
        let url = ''
        let data = {}
        let log
        if (!getState().isUserNotVerified) {
          url = '/sso/v1/otp'
          data = {
            otp_channel: otpChannel,
            phone_number: parsePhoneFormat(
              getState().username,
              '+62',
            ),
          }
          log = {
            event: {
              ...eventLogin.sendOTP.event,
              phone_number: parsePhoneFormat(
                getState().username,
                '+62',
              ),
            },
            dependency: eventLogin.sendOTP.dependency,
          }
        } else {
          url = '/sso/v1/register'
          if (otpChannel === 'email') {
            data = {
              otp_channel: otpChannel,
              validate_by: getState().username,
              password: randomPassword(),
              name: 'verification',
            }
            log = {
              event: {
                ...eventRegister.sendOTP.event,
                email: getState().username,
              },
              dependency: eventRegister.sendOTP.dependency,
            }
          } else {
            data = {
              otp_channel: otpChannel,
              validate_by: parsePhoneFormat(
                getState().username,
                '+62',
              ),
              name: 'verification',
            }
            log = {
              event: {
                ...eventRegister.sendOTP.event,
                phone_number: parsePhoneFormat(
                  getState().username,
                  '+62',
                ),
              },
              dependency: eventRegister.sendOTP.dependency,
            }
          }
        }
        try {
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url,
            method: 'POST',
            data: {
              sso_code: SSO_CODE,
              ...data,
            },
            headers: {
              'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
            },
          })
          if (response?.status === 200) {
            store.getActions().otp.setSelectedChannel(otpChannel)
            store
              .getActions()
              .otp.setOtpTimer(
                response?.data?.data?.expiry_time?.expiry_date,
              )
            actions.setStep('otp-form')
            store.getActions().otp.setIsLoadingChannel(false)
            sendLogHitEndpoint(
              log.event,
              dependencyContextMapper(
                log.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )
          }
        } catch (err) {
          const errorMessage = validationResponseHelper(err)
          store.getActions().otp.errorChannel(errorMessage)
          sendLogHitEndpoint(
            log.event,
            dependencyContextMapper(
              log.dependency,
              requestMapper(err.config),
              errorMapper(err),
            ),
          )
        }
      }
    },
  ),
  postResendOtp: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!store.getState().otp.isLoadingOtp) {
        const otpChannel = store.getState().otp.selectedChannel
        store.getActions().otp.setIsLoadingOtp(true)
        let url = ''
        let data = {}
        let log
        if (!getState().isUserNotVerified) {
          url = '/sso/v1/otp'
          data = {
            otp_channel: otpChannel,
            phone_number: parsePhoneFormat(
              getState().username,
              '+62',
            ),
          }
          log = {
            event: {
              ...eventLogin.resendOTP.event,
              phone_number: parsePhoneFormat(
                getState().username,
                '+62',
              ),
            },
            dependency: eventLogin.sendOTP.dependency,
          }
        } else {
          url = '/sso/v1/register'
          if (otpChannel === 'email') {
            data = {
              otp_channel: otpChannel,
              validate_by: getState().username,
              password: randomPassword(),
              name: 'verification',
            }
            log = {
              event: {
                ...eventRegister.resendOTP.event,
                email: getState().username,
              },
              dependency: eventRegister.resendOTP.dependency,
            }
          } else {
            data = {
              otp_channel: otpChannel,
              validate_by: parsePhoneFormat(
                getState().username,
                '+62',
              ),
              name: 'verification',
            }
            log = {
              event: {
                ...eventRegister.resendOTP.event,
                phone_number: parsePhoneFormat(
                  getState().username,
                  '+62',
                ),
              },
              dependency: eventRegister.resendOTP.dependency,
            }
          }
        }
        try {
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url,
            method: 'POST',
            data: {
              sso_code: SSO_CODE,
              ...data,
            },
            headers: {
              'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
            },
          })
          if (response?.status === 200) {
            store.getActions().otp.setSelectedChannel(otpChannel)
            store
              .getActions()
              .otp.setOtpTimer(
                response?.data?.data?.expiry_time?.expiry_date,
              )
            actions.setStep('otp-form')
            store.getActions().otp.setIsLoadingOtp(false)
            sendLogHitEndpoint(
              log.event,
              dependencyContextMapper(
                log.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )
          }
        } catch (err) {
          const errorMessage = validationResponseHelper(err)
          store.getActions().otp.errorOtp(errorMessage)
          sendLogHitEndpoint(
            log.event,
            dependencyContextMapper(
              log.dependency,
              requestMapper(err.config),
              errorMapper(err),
            ),
          )
        }
      }
    },
  ),
  successLogin: thunk((_actions, payload, { getState }) => {
    destroyCookie('user_profiling')
    if (
      !!getCookie(authConfig.accessTokenName) ||
      !!getCookie(authConfig.refreshTokenName)
    ) {
      store.getActions().logOut.postLogout()
      return
    }
    const token = {
      ...payload?.data?.data,
    }
    const searchParams = window.location.href.match(
      /ref*?=(.*?)($|&)/g,
    )
    const referrer =
      searchParams && searchParams[0].replace(/((ref=)|&)/g, '')
    const decodedReferrer = decodeURIComponent(
      referrer || document.referrer,
    )
    let referrerUrl = `${WEB_DESKTOP_RALALI_URL}/marketplace`
    const accessToken = token?.access_token?.value ?? ''
    const parsedToken = parseJWT(accessToken)
    const referralCode = getCookie('rll_referral_code')

    const ga = GA4()
    ga.trackEvent({
      eventName: 'login',
      eventCategory: 'User Session',
      params: {
        user_id: parsedToken.client_user_id,
        login_type: getState().loginType.value,
        sso_id: parsedToken.sub,
      },
    })

    if (
      validateReferrerUrl(decodedReferrer) &&
      window.location.pathname === '/login'
    ) {
      referrerUrl = decodedReferrer
    }
    if (getState().isKeepSignedIn) {
      setCookie(
        'isKeepSignIn',
        'true',
        '/',
        new Date(
          payload?.data?.data?.refresh_token?.expired_at * 1000,
        ),
      )
    }
    store.getActions().profile.setUserProfile(persist(null))

    let navigateUrl = referrerUrl
    if (getState().password) {
      const passwordValidation = validatePassword(getState().password)
      const isStrongPassword = passwordValidation.isRegexValidate
      if (!isStrongPassword) {
        navigateUrl = referrer
          ? `/security-updates?ref=${referrer}`
          : '/security-updates'
      }
    }

    afterLogin(token, async () => {
      if (referralCode) {
        await store
          .getActions()
          .register.postUserReferral(referralCode)
      }
      await store.getActions().profile.getUserProfile()
      const tracker = eventTracking(rudderstack())
      tracker.identify(
        store.getState().profile.profileData.sso_id,
        {
          Identity: store.getState().profile.profileData.sso_id,
          Name: store.getState().profile.profileData.name,
          Email: store.getState().profile.profileData.email,
        },
        () => {
          tracker.track(
            'Login',
            {
              Name: store.getState().profile.profileData.name,
              Email: store.getState().profile.profileData.email,
              'Phone Number': store.getState().profile.profileData
                .handphone,
              'Login Type': loginCategory(getState().loginType.value),
              'Login Method': getState().loginType.value,
              'User Agent': window.navigator.userAgent,
            },
            () => {
              window.location.assign(navigateUrl)
            },
          )
        },
      )
    })
  }),
  getOtpChannelRegister: thunk((actions, payload, { getState }) => {
    if (getState().isEmail) {
      store.getActions().otp.setChannels({
        email: emailMasking(getState().username),
      })
    } else {
      store.getActions().otp.setChannels({
        sms: phoneNumberMasking(getState().username),
        whatsapp: phoneNumberMasking(getState().username),
      })
    }
    actions.setUsername(getState().username)
    actions.setStep('otp-channel')
    store.getActions().otp.errorChannel('')
  }),
  errorLogin: thunk((actions, payload) => {
    if (
      payload?.response?.data?.name &&
      payload.response.data.name === 'ACCOUNT_DISABLED'
    ) {
      actions.setIsUserBlocked(true)
      actions.setIsLoading(false)
    } else if (
      payload?.response?.data?.name &&
      (payload.response.data.name ===
        'INVALID_ACCOUNT_NOT_REGISTERED' ||
        payload.response.data.name === 'DATA_NOT_FOUND')
    ) {
      actions.setIsUserNotRegistered(true)
      actions.setIsLoading(false)
    } else if (
      payload?.response?.data?.name &&
      payload.response.data.name === 'ACCOUNT_NOT_VERIFIED'
    ) {
      actions.setIsUserNotVerified(true)
      actions.getOtpChannelRegister()
      actions.setIsLoading(false)
    } else {
      const errorMessage = validationResponseHelper(payload)
      actions.error(errorMessage)
    }
  }),
  error: action((state, payload) => {
    state.isLoading = false
    state.errorMessage = payload
  }),
}

export default LoginModel
