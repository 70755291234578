import Toast from 'common/components/Toast'
import {
  DEFAULT_LANG,
  LANG_COOKIE_NAME,
  WEB_DESKTOP_RALALI_URL,
} from 'common/constants'
import { action, thunk } from 'easy-peasy'
import { getCookie } from 'lib/cookie'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import GA4 from 'lib/google-analytics-4'
import eventBusinessSolution from 'services/BusinessSolution/event'
import IBusinessSolutionModel from 'services/BusinessSolution/models/BusinessSolutionModel/interface'

const BusinessSolutionModel: IBusinessSolutionModel = {
  data: null,
  isLoading: false,
  setData: action((state, payload) => {
    state.data = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  getData: thunk(async (actions, payload, { injections }) => {
    actions.setIsLoading(true)
    try {
      const { apiClient } = injections
      const res = await apiClient({
        url: '/business-solution/v1/pages',
        method: 'GET',
        params: payload,
        headers: {
          'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
        },
      })

      if (res.status === 200) {
        actions.setData(res.data.data)
      }

      actions.setIsLoading(false)

      sendLogHitEndpoint(
        eventBusinessSolution.getData.event,
        dependencyContextMapper(
          eventBusinessSolution.getData.dependency,
          requestMapper(res.config),
          responseMapper(res),
        ),
      )
    } catch (err) {
      sendLogHitEndpoint(
        eventBusinessSolution.getData.event,
        dependencyContextMapper(
          eventBusinessSolution.getData.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )

      Toast(err.message, {
        type: 'error',
        theme: 'colored',
        hideProgressBar: false,
        autoClose: 5000,
        pauseOnHover: true,
        position: 'top-center',
        toastId: 'error-bss-get-data',
      })
    }
  }),
  verifyCaptcha: thunk(async (actions, payload, { injections }) => {
    try {
      const { trackEvent } = GA4()
      const { apiClient } = injections

      trackEvent({
        eventName: 'form_submission',
        eventCategory: 'Form Submission',
        params: {
          socialmedia_account: '',
          form_name: 'Contact Us',
          form_step: 'Step1',
          form_reference_id: WEB_DESKTOP_RALALI_URL,
        },
      })

      const res = await apiClient({
        method: 'POST',
        url: '/business-solution/v1/contact-us',
        data: {
          name: payload.data.name,
          email: payload.data.email,
          phone: payload.data.phone,
          company_name: payload.data.companyName,
          solution_needs: payload.data.kebutuhanPerihal,
          message: payload.data.message,
          page: WEB_DESKTOP_RALALI_URL,
          token: payload.token,
        },
      })

      return res.status
    } catch (err) {
      Toast('Gagal Melakukan Verifikasi, Silahkan Coba Lagi', {
        type: 'error',
        theme: 'colored',
        hideProgressBar: false,
        autoClose: 5000,
        pauseOnHover: true,
        position: 'top-center',
        toastId: 'error-bss-verify-captcha',
      })

      return err.status
    }
  }),
}

export default BusinessSolutionModel
