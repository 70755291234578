import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import ArrayofObjectToCamelCase from 'lib/transform/array-to-camel-case'
import camelToSnakeKeys from 'lib/transform/camel-to-snake-case'
import { eventBank } from 'services/Bank/event'
import IBankAccountModel from 'services/Bank/models/BankAccountModel/interface'

const BankAccountModel: IBankAccountModel = {
  data: [],
  dataV2: [],
  isLoading: false,
  isLoadingUpload: false,
  isError: false,
  errorMessage: '',
  selectedBank: null,
  setData: action((state, payload) => {
    state.data = payload
  }),
  setDataV2: action((state, payload) => {
    state.dataV2 = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsLoadingUpload: action((state, payload) => {
    state.isLoadingUpload = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setSelectedBank: action((state, payload) => {
    state.selectedBank = payload
  }),
  postBankAccounts: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setIsError(false)

        const { apiClient } = injections
        const res = await apiClient({
          url: '/core/api/v2/digital-goods/buyer/bank-account',
          method: 'POST',
          data: payload,
        })
        if (res.status === 200) {
          sendLogHitEndpoint(
            eventBank.postBankAccount.event,
            dependencyContextMapper(
              eventBank.postBankAccount.dependency,
              requestMapper(res.config),
              responseMapper(res),
            ),
          )
          actions.setData(res?.data?.result)
        }
      } catch (err) {
        sendLogHitEndpoint(
          eventBank.postBankAccount.event,
          dependencyContextMapper(
            eventBank.postBankAccount.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setIsError(true)
        actions.setErrorMessage(
          err?.response?.data?.message ?? err.message,
        )
        Toast(err?.response?.data?.message?.id, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'POST_BANK_ACCOUNT_ERROR',
        })
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  postPrimaryBankAccount: thunk(
    async (actions, id, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setIsError(false)

        const { apiClient } = injections
        const res = await apiClient({
          url:
            '/core/api/v2/digital-goods/buyer/set-primary-bank-account',
          method: 'POST',
          data: {
            id,
          },
        })
        sendLogHitEndpoint(
          eventBank.postPrimaryBankAccount.event,
          dependencyContextMapper(
            eventBank.postPrimaryBankAccount.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )
        return res
      } catch (err) {
        sendLogHitEndpoint(
          eventBank.postPrimaryBankAccount.event,
          dependencyContextMapper(
            eventBank.postPrimaryBankAccount.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setIsError(true)
        const message = err.response.data.message ?? err.message
        Toast(message, {
          type: 'error',
          theme: 'colored',
          hideProgressBar: false,
          autoClose: 5000,
          pauseOnHover: true,
          position: 'top-center',
          toastId: 'error-post-primary-bank-account',
        })

        return err
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  getUserBankAccounts: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setIsError(false)

        const { apiClient } = injections
        const res = await apiClient({
          url: '/marketplace/v1/bank-accounts',
          method: 'GET',
        })
        if (res.status === 200) {
          actions.setDataV2(
            ArrayofObjectToCamelCase(res?.data?.data?.items),
          )
        }
        sendLogHitEndpoint(
          eventBank.getUserBankAccounts.event,
          dependencyContextMapper(
            eventBank.getUserBankAccounts.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )
      } catch (err) {
        sendLogHitEndpoint(
          eventBank.getUserBankAccounts.event,
          dependencyContextMapper(
            eventBank.getUserBankAccounts.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setDataV2([])
        actions.setIsError(true)
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  deleteUserBankAccount: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setIsError(false)
        const { apiClient } = injections
        const res = await apiClient({
          url: `/marketplace/v1/bank-accounts/${payload}`,
          method: 'DELETE',
        })
        sendLogHitEndpoint(
          eventBank.deleteUserBankAccount.event,
          dependencyContextMapper(
            eventBank.deleteUserBankAccount.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )
        return res
      } catch (err) {
        sendLogHitEndpoint(
          eventBank.deleteUserBankAccount.event,
          dependencyContextMapper(
            eventBank.deleteUserBankAccount.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setIsError(true)
        return undefined
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  updateUserBankAccount: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setIsError(false)
        const { apiClient } = injections
        const res = await apiClient({
          url: `/marketplace/v1/bank-accounts/${payload.bankId}`,
          method: 'PATCH',
          data: camelToSnakeKeys(payload.data),
        })
        sendLogHitEndpoint(
          eventBank.updateUserBankAccount.event,
          dependencyContextMapper(
            eventBank.updateUserBankAccount.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )
        return res
      } catch (err) {
        sendLogHitEndpoint(
          eventBank.updateUserBankAccount.event,
          dependencyContextMapper(
            eventBank.updateUserBankAccount.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setIsError(true)
        return undefined
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  uploadFileSavingBooks: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoadingUpload(true)
        actions.setIsError(false)

        const { apiClient } = injections
        const res = await apiClient({
          url: '/marketplace/v1/bank-accounts/upload-savings-book',
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: payload,
        })
        sendLogHitEndpoint(
          eventBank.uploadFileSavingBooks.event,
          dependencyContextMapper(
            eventBank.uploadFileSavingBooks.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )
        return res
      } catch (err) {
        sendLogHitEndpoint(
          eventBank.uploadFileSavingBooks.event,
          dependencyContextMapper(
            eventBank.uploadFileSavingBooks.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setIsError(true)
        return undefined
      } finally {
        actions.setIsLoadingUpload(false)
      }
    },
  ),
  createUserBankAccount: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setIsError(false)

        const { apiClient } = injections
        const res = await apiClient({
          url: '/marketplace/v1/bank-accounts',
          method: 'POST',
          data: camelToSnakeKeys(payload),
        })
        sendLogHitEndpoint(
          eventBank.createUserBankAccount.event,
          dependencyContextMapper(
            eventBank.createUserBankAccount.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )
        return res
      } catch (err) {
        Toast(err.response.data.message, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          autoClose: 5000,
          pauseOnHover: true,
          toastId: 'error-create-bank-account',
        })
        sendLogHitEndpoint(
          eventBank.createUserBankAccount.event,
          dependencyContextMapper(
            eventBank.createUserBankAccount.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setIsError(true)
        return undefined
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  setErrorMessage: action((state, payload) => {
    state.errorMessage = payload
  }),
}

export default BankAccountModel
