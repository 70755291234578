export default {
  getUserWalletData: {
    event: { name: 'Wallet', state: 'GetUserWalletData' },
    dependency: {
      name: 'API Gateway',
      event: 'GetUserWalletData',
      service: 'rll-gw-api',
    },
  },
  getWalletTransactionKey: {
    event: {
      name: 'getWalletTransactionKey',
      state: 'APICall',
    },
    dependency: {
      name: 'API Gateway',
      event: 'getWalletTransactionKey',
      service: 'rll-gw-api',
    },
  },
  postCreateXRllPin: {
    event: {
      name: 'postCreateXRllPin',
      state: 'APICall',
    },
    dependency: {
      name: 'API Gateway',
      event: 'postCreateXRllPin',
      service: 'rll-gw-api',
    },
  },
  getExchangePin: {
    event: {
      name: 'getExchangePin',
      state: 'APICall',
    },
    dependency: {
      name: 'API Gateway',
      event: 'getExchangePin',
      service: 'rll-gw-api',
    },
  },
  getPinStatus: {
    event: {
      name: 'getPinStatus',
      state: 'APICall',
    },
    dependency: {
      name: 'API Gateway',
      event: 'getPinStatus',
      service: 'rll-gw-api',
    },
  },
  postCreatePin: {
    event: {
      name: 'postCreatePin',
      state: 'APICall',
    },
    dependency: {
      name: 'API Gateway',
      event: 'postCreatePin',
      service: 'rll-gw-api',
    },
  },
  getSecurityQuestion: {
    event: { name: 'Wallet', state: 'getSecurityQuestion' },
    dependency: {
      name: 'API Gateway',
      event: 'getSecurityQuestion',
      service: 'rll-gw-api',
    },
  },
  postForgotPin: {
    event: { name: 'Wallet', state: 'postForgotPin' },
    dependency: {
      name: 'API Gateway',
      event: 'postForgotPin',
      service: 'rll-gw-api',
    },
  },
  postActivateWallet: {
    event: { name: 'Wallet', state: 'postActivateWallet' },
    dependency: {
      name: 'API Gateway',
      event: 'postActivateWallet',
      service: 'rll-gw-api',
    },
  },
  getWalletBalance: {
    event: { name: 'Wallet', state: 'getWalletBalance' },
    dependency: {
      name: 'API Gateway',
      event: 'getWalletBalance',
      service: 'rll-gw-api',
    },
  },
}
