export default {
  getProfiling: {
    event: { name: 'Profiling', state: 'GetProfilingData' },
    dependency: {
      name: 'API Gateway',
      event: 'GetProfilingData',
      service: 'rll-gw-api',
    },
  },
}
