import { WEB_DESKTOP_RALALI_URL } from 'common/constants'
import { NextPageContext } from 'next'
import Router from 'next/router'

/**
 * Handle redirect on both server-side and client-side
 *
 * @param res response object from NextPageContext
 * @param target
 * @param referrer
 * @param ctx
 * @param method
 */
const redirect = (
  target: string,
  referrer?: string,
  ctx?: NextPageContext,
  method: 'push' | 'replace' = 'push',
): void => {
  const isRefererRelativePath = referrer?.startsWith('/')
  if (isRefererRelativePath) {
    referrer = `${WEB_DESKTOP_RALALI_URL}${referrer}`
  }
  const urlDest = referrer ? `${target}?ref=${referrer}` : target
  if (ctx?.res) {
    ctx.res.writeHead(302, {
      Location: urlDest,
    })
    ctx.res.end()
  } else {
    Router[method](urlDest)
  }
}

export default redirect
