import theme from 'common/theme'
import type { Property } from 'csstype'
import setCSS from 'lib/css'
import { ReactNode } from 'react'
import styled from 'styled-components'

export type TTypographyType = keyof typeof theme.typography
type TStringNumber = string | number

export interface ITypographyProps {
  type?: TTypographyType
  fontSize?: TStringNumber
  fontWeight?: Property.FontWeight
  fontStyle?: Property.FontStyle
  fontStretch?: Property.FontStretch
  textAlign?: Property.TextAlign
  lineHeight?: Property.LineHeight
  letterSpacing?: TStringNumber
  display?: Property.Display
  wordBreak?: Property.WordBreak
  marginLeft?: TStringNumber
  marginTop?: TStringNumber
  marginRight?: TStringNumber
  marginBottom?: TStringNumber
  marginX?: TStringNumber
  marginY?: TStringNumber
  maxLine?: TStringNumber
  color?: string
  title?: string
  ellipsis?: boolean
  children: JSX.Element | ReactNode
}

// Styling functions

const setFontSize = (size) => {
  return setCSS('font-size', {
    value: size,
    defaultValue: '14px',
    unit: 'px',
  })
}

const setFontWeight = (weight) => {
  return setCSS('font-weight', {
    value: weight,
    defaultValue: 'normal',
  })
}

const setFontStyle = (style) => {
  return setCSS('font-style', {
    value: style,
    defaultValue: 'normal',
  })
}

const setFontStretch = (stretch) => {
  return setCSS('font-stretch', {
    value: stretch,
    defaultValue: 'normal',
  })
}

const setTextAlign = (align) => {
  return setCSS('text-align', {
    value: align,
    defaultValue: 'left',
  })
}

const setLineHeight = (height) => {
  return setCSS('line-height', {
    value: height,
    defaultValue: 'normal',
  })
}

const setLetterSpacing = (spacing) => {
  return setCSS('letter-spacing', {
    value: spacing,
    defaultValue: 'normal',
    unit: 'px',
  })
}

const setMarginLeft = (margin) => {
  return setCSS('margin-left', {
    value: margin,
    defaultValue: 0,
    unit: 'px',
  })
}

const setMarginTop = (margin) => {
  return setCSS('margin-top', {
    value: margin,
    defaultValue: 0,
    unit: 'px',
  })
}

const setMarginRight = (margin) => {
  return setCSS('margin-right', {
    value: margin,
    defaultValue: 0,
    unit: 'px',
  })
}

const setMarginBottom = (margin) => {
  return setCSS('margin-bottom', {
    value: margin,
    defaultValue: 0,
    unit: 'px',
  })
}

const setMarginX = (margin) => {
  switch (typeof margin) {
    case 'number':
      return `margin-left: ${margin}px; margin-right: ${margin}px;`
    case 'string':
      return `margin-left: ${margin}; margin-right: ${margin};`
    default:
      return ''
  }
}

const setMarginY = (margin) => {
  switch (typeof margin) {
    case 'number':
      return `margin-top: ${margin}px; margin-bottom: ${margin}px;`
    case 'string':
      return `margin-top: ${margin}; margin-bottom: ${margin};`
    default:
      return ''
  }
}

const setColor = (color) => {
  return color
    ? `color: ${theme.colors[color]};`
    : `color: ${theme.colors.black};`
}

const setDisplay = (display) => {
  if (!display) return ``
  return `display: ${display};`
}

const setMaxLine = (maxLine) => {
  if (!maxLine) return ``
  return `
    display: -webkit-box;
    -webkit-line-clamp: ${maxLine};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `
}

const setEllipsis = (ellipsis: boolean) => {
  if (!ellipsis) return ``
  return `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
}

const setWordBreak = (wordBreak) => {
  return setCSS('word-break', {
    value: wordBreak,
    defaultValue: 'normal',
  })
}

const defaultStyles = () => {
  return 'margin: 0;'
}
// Styling functions: END

// Styled Components
const StyledTypography = styled.p`
  ${defaultStyles()}
  ${(props) =>
    setFontSize(
      props.fontSize
        ? props.fontSize
        : theme?.typography?.[props.type]?.fontSize,
    )}
  ${(props) =>
    setFontWeight(
      props.fontWeight
        ? props.fontWeight
        : theme?.typography?.[props.type]?.fontWeight,
    )}
  ${(props) =>
    setFontStyle(
      props.fontStyle
        ? props.fontStyle
        : theme?.typography?.[props.type]?.fontStyle,
    )}
  ${(props) =>
    setFontStretch(
      props.fontStretch
        ? props.fontStretch
        : theme?.typography?.[props.type]?.fontStretch,
    )}
  ${(props) =>
    setLineHeight(
      props.lineHeight
        ? props.lineHeight
        : theme?.typography?.[props.type]?.lineHeight,
    )}
  ${(props) =>
    setLetterSpacing(
      props.letterSpacing
        ? props.letterSpacing
        : theme?.typography?.[props.type]?.letterSpacing,
    )}
  ${(props) => setTextAlign(props.textAlign)}
  ${(props) => setColor(props.color)}
  ${(props) => setDisplay(props.display)}
  ${(props) => setMarginLeft(props.marginLeft)}
  ${(props) => setMarginTop(props.marginTop)}
  ${(props) => setMarginRight(props.marginRight)}
  ${(props) => setMarginBottom(props.marginBottom)}
  ${(props) => setMarginX(props.marginX)}
  ${(props) => setMarginY(props.marginY)}
  ${(props) => setMaxLine(props.maxLine)}
  ${(props) => setEllipsis(props.ellipsis)}
  ${(props) => setWordBreak(props.wordBreak)}
`
// Styled Components: END

const MAP_TYPE: Record<
  TTypographyType,
  keyof JSX.IntrinsicElements
> = {
  bh1: 'h1',
  rh1: 'h1',
  bh2: 'h2',
  rh2: 'h2',
  bh3: 'h3',
  rh3: 'h3',
  bh4: 'h4',
  rh4: 'h4',
  bh5: 'h5',
  rh5: 'h5',
  bh6: 'h6',
  rh6: 'h6',
  blabel: 'label',
  rlabel: 'label',
  bparagraph: 'p',
  rparagraph: 'p',
  bsubtitle: 'p',
  rsubtitle: 'p',
  bcaption: 'span',
  rcaption: 'span',
  blead: 'span',
  rlead: 'span',
  bsmall: 'span',
  rsmall: 'span',
  btiny: 'span',
  rtiny: 'span',
}
/**
 * @return {React.Node}
 */
function Typography({
  type = 'rparagraph',
  children,
  ...props
}: ITypographyProps): JSX.Element {
  return (
    <StyledTypography as={MAP_TYPE[type]} type={type} {...props}>
      {children}
    </StyledTypography>
  )
}

export default Typography
