import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import onboardingEvent from 'services/Seller/events'
import IStoreSlugModel from 'services/Seller/models/StoreSlugModel/interface'

const StoreSlugModel: IStoreSlugModel = {
  isError: false,
  isLoading: false,
  isValidSlug: true,
  errorMessage: '',
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsValidSlug: action((state, payload) => {
    state.isValidSlug = payload
  }),
  setErrorMessage: action((state, payload) => {
    state.errorMessage = payload
  }),
  error: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  validateSlug: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setIsLoading(true)
      actions.setErrorMessage('')
      const { apiClient } = injections
      const response = await apiClient({
        url: '/store/v1/slug-validation',
        method: 'POST',
        data: {
          slug: payload,
        },
      })
      sendLogHitEndpoint(
        onboardingEvent.validateSlug.event,
        dependencyContextMapper(
          onboardingEvent.validateSlug.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      actions.setIsValidSlug(response.status === 200)
    } catch (err) {
      sendLogHitEndpoint(
        onboardingEvent.validateSlug.event,
        dependencyContextMapper(
          onboardingEvent.validateSlug.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.setIsValidSlug(false)
      actions.error(err?.response?.data?.message)
    } finally {
      actions.setIsLoading(false)
    }
  }),
}

export default StoreSlugModel
