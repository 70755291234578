import { action, thunk } from 'easy-peasy'
import { IDigitalOrderModel } from 'services/DigitalGoods/models/DigitalOrderModel/interface'

const DigitalOrderModel: IDigitalOrderModel = {
  isLoading: false,
  digitalOrderDetail: null,
  isError: false,

  setError: action((state, payload) => {
    state.isLoading = false
    state.isError = payload
  }),

  setDigitalOrderDetail: action((state, payload) => {
    state.digitalOrderDetail = payload
    state.isLoading = false
    state.isError = false
  }),

  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),

  getDigitalOrderDetail: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        actions.setIsLoading(true)

        const response = await apiClient({
          url: '/digital/v2/orders/details',
          method: 'GET',
          params: payload,
        })

        if (response?.data?.data) {
          actions.setDigitalOrderDetail(response.data.data)
        } else {
          actions.setError(true)
        }

        actions.setIsLoading(false)
      } catch (error) {
        actions.setError(true)
        actions.setIsLoading(false)
      }
    },
  ),
}

export default DigitalOrderModel
