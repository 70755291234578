import isEmpty from 'lib/common/is-empty'
import {
  emailMasking,
  fullMasking,
  partialMasking,
  phoneNumberMasking,
} from 'lib/transform/masking'

const scrubList = {
  'x-access-token': 'partial',
  'x-refresh-token': 'partial',
  'x-grant-token': 'partial',
  Authorization: 'partial',
  password: 'full',
  otp: 'full',
  email: 'email',
  phone: 'phoneNumber',
  access_token: {
    value: 'partial',
  },
  refresh_token: {
    value: 'partial',
  },
  provider_code: 'partial',
}

const scrubSensitiveData = (
  data: Object,
  scrubData = scrubList,
): Object => {
  if (isEmpty(data)) {
    return data
  }

  const maskedData = Object.fromEntries(
    Object.entries(data).map((dataObject) => {
      if (typeof dataObject[1] === 'object') {
        let tempScrubData = scrubData
        if (Object.keys(scrubData).includes(dataObject[0])) {
          tempScrubData = scrubData[dataObject[0]]
        }
        const result = scrubSensitiveData(
          data[dataObject[0]],
          tempScrubData,
        )
        return [dataObject[0], result]
      }
      if (Object.keys(scrubData).includes(dataObject[0])) {
        // found sensitive data
        // eslint-disable-next-line default-case
        switch (scrubData[dataObject[0]]) {
          case 'partial':
            return [dataObject[0], partialMasking(dataObject[1])]
          case 'full':
            return [dataObject[0], fullMasking(dataObject[1])]
          case 'email':
            return [dataObject[0], emailMasking(dataObject[1])]
          case 'phoneNumber':
            return [dataObject[0], phoneNumberMasking(dataObject[1])]
        }
      }
      return dataObject
    }),
  )
  return maskedData
}
export { scrubSensitiveData }
export default scrubSensitiveData
