import cdn from 'lib/cdn'

export const WEB_DESKTOP_RALALI_URL =
  process.env.NEXT_PUBLIC_WEB_DESKTOP_RALALI_URL

export const SELLERPANEL_URL = process.env.NEXT_PUBLIC_SELLERPANEL_URL
export const MOBILE_WEB_URL = process.env.NEXT_PUBLIC_MOBILE_WEB_URL
export const M_RFQ_URL = process.env.NEXT_PUBLIC_M_RFQ_URL
export const APP_DOMAIN = process.env.NEXT_PUBLIC_APP_DOMAIN
export const WHATSAPP_URL =
  process.env.NEXT_PUBLIC_WHATSAPP_BUSINESS_URL
export const MIDTRANS_CREDIT_CARD_ID =
  process.env.NEXT_PUBLIC_MIDTRANS_CREDIT_CARD_ID
export const MIDTRANS_BCA_KLIKPAY_ID =
  process.env.NEXT_PUBLIC_MIDTRANS_BCA_KLIKPAY_ID
export const ASPIRE_CREDIT_CARD_ID =
  process.env.NEXT_PUBLIC_ASPIRE_CREDIT_CARD_ID
export const MEMBERSHIP_LANDING_PAGE_URL =
  process.env.NEXT_PUBLIC_MEMBERSHIP_LANDING_PAGE_URL
export const MEMBERSHIP_CHECKOUT_URL =
  process.env.NEXT_PUBLIC_MEMBERSHIP_CHECKOUT_URL
export const POSTHOG_URL = process.env.NEXT_PUBLIC_POSTHOG_URL
export const CONNECT_REGISTRATION_URL =
  process.env.NEXT_PUBLIC_CONNECT_REGISTRATION_URL

export const SSO_CODE = process.env.NEXT_PUBLIC_SSO_CODE

export const GRECAPTCHA_SITE_KEY =
  process.env.NEXT_PUBLIC_GRECAPTCHA_SITE_KEY
export const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_API_KEY

export const USERPANEL_URL = process.env.NEXT_PUBLIC_USERPANEL_URL

export const NATIONAL_COURIER_CODE = 'store-shipping-national'
export const LOCAL_COURIER_CODE = 'store-shipping-local'

export const PRIVATE_SHIPPING_CODES = [
  NATIONAL_COURIER_CODE,
  LOCAL_COURIER_CODE,
]

export const USER_ONBOARDING_IMAGE = cdn({
  path: '/assets/img/seller/onboard.png',
})
export const RUDDERSTACK_URL = process.env.NEXT_PUBLIC_RUDDERSTACK_URL
export const RUDDERSTACK_ID = process.env.NEXT_PUBLIC_RUDDERSTACK_ID

export const STORE_NAME_REGEX = /^[-&`/0-9a-zA-Z.() ]{3,50}$/

export const RALALI_PLUS_REGISTRATION_URL =
  process.env.NEXT_PUBLIC_RALALI_PLUS_REGISTRATION_URL

export const LANG_COOKIE_NAME =
  process.env.NEXT_PUBLIC_LANG_COOKIE_NAME
export const DEFAULT_LANG = process.env.NEXT_PUBLIC_DEFAULT_LANG
export const COACHMARK_SELLER_STORE_INFORMATION =
  'coachmark-user-panel-store-information'
export const COACHMARK_SIDEBAR_OPEN_STORE =
  'coachmark-user-panel-open-store'

export const ONBOARDING_STORE_GROUP_CODE = 'onboarding-store'

export const CLEVERTAP_ACCOUNT_ID =
  process.env.NEXT_PUBLIC_CLEVERTAP_ACCOUNT_ID
export const CLEVERTAP_REGION =
  process.env.NEXT_PUBLIC_CLEVERTAP_REGION
export const ENABLE_CLEVERTAP =
  process.env.NEXT_PUBLIC_ENABLE_CLEVERTAP

// eslint-disable-next-line no-shadow
export enum STORE_SETTING_TABS {
  'information',
  'operation',
  'location',
  'shipping',
}

export const ENABLE_CUSTOM_THEME =
  process.env.NEXT_PUBLIC_ENABLE_CUSTOM_THEME === 'true'

export const ACTIVE_THEME_NAME =
  process.env.NEXT_PUBLIC_ACTIVE_THEME_NAME

// eslint-disable-next-line no-shadow
export enum PROFILE_SETTING_TABS {
  'personal-information',
  'address',
  'bank-accounts',
  'security',
}

// eslint-disable-next-line no-shadow
export enum CUSTOMER_CHAT_TABS {
  'buyer',
  'seller',
}

export const INSURANCE_FAQ_URL =
  process.env.NEXT_PUBLIC_INSURANCE_FAQ_URL

// eslint-disable-next-line no-shadow
export enum TransactionsTabQuery {
  'all',
  'new',
  'processing',
  'ready-pickup',
  'shipped',
  'done',
  'settlement',
}

// eslint-disable-next-line no-shadow
export enum CustomerTransactionsTabQuery {
  'all',
  'payment-process',
  'paid',
  'processing',
  'shipped',
  'done',
}

// eslint-disable-next-line no-shadow
export enum TransactionsSubTabQuery {
  'received',
  'reject',
}

export const NEWS_URL = process.env.NEXT_PUBLIC_NEWS_URL
export const CERTIPEDIA_URL =
  'https://www.certipedia.com/quality_marks/9000024829'

// Contacts
export const CONTACTS = {
  EMAIL: 'hello@ralali.com',
  PHONE: '1500 465',
}

export const SUBMIT_RPLUS_ACTION_STATE_CODE = 'submit-rplus-form'
export const EDIT_RPLUS_ACTION_STATE_CODE = 'edit-rplus-form'

export const JOTFORM_URL = process.env.NEXT_PUBLIC_JOTFORM_URL
