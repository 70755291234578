export default {
  getData: {
    event: { name: 'BusinessSolution', state: 'GetData' },
    dependency: {
      name: 'API Gateway',
      event: 'GetData',
      service: 'rll-gw-api',
    },
  },
  getPavilions: {
    event: { name: 'BusinessSolution', state: 'GetPavilion' },
    dependency: {
      name: 'API Gateway',
      event: 'GetPavilion',
      service: 'rll-gw-api',
    },
  },
}
