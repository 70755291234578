import { NextPageContext } from 'next'

function parseJWT(
  token: string,
  context?: NextPageContext,
): Record<string, any> | null {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload: string
    if (context) {
      const base64String = Buffer.from(base64, 'base64').toString(
        'utf-8',
      )
      jsonPayload = decodeURIComponent(
        base64String
          .split('')
          .map((c) => {
            const hexa = `00${c.charCodeAt(0).toString(16)}`
            return `%${hexa.slice(-2)}`
          })
          .join(''),
      )
    } else {
      jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map((c) => {
            const hexa = `00${c.charCodeAt(0).toString(16)}`
            return `%${hexa.slice(-2)}`
          })
          .join(''),
      )
    }

    return JSON.parse(jsonPayload)
  } catch (err) {
    return null
  }
}

export default parseJWT
