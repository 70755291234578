import CancelIcon from 'assets/svg/cancel-login/cancel.svg'
import Button from 'common/components/Button'
import Flex from 'common/components/Flex'
import Modal from 'common/components/Modal'
import Typography from 'common/components/Typography'
import redirect from 'lib/common/redirect'
import EmailIcon from 'services/Auth/assets/svg/error/error-illustration.svg'
import styled from 'styled-components'

const Icon = styled.span`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`

const StyledButton = styled(Button)`
  font-size: 16px;
`

interface IUserNotRegistered {
  isOpen?: boolean
  onClose: () => void
}

function UserBlocked({
  isOpen = false,
  onClose,
}: IUserNotRegistered): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={onClose}
      width="560px"
      height="auto"
      padding="0"
      borderRadius="10px"
    >
      <>
        <Icon>
          <CancelIcon onClick={onClose} />
        </Icon>
        <Flex
          flexDirection="column"
          padding="50px"
          data-testid="user-blocked"
        >
          <>
            <Flex justifyContent="center">
              <EmailIcon />
            </Flex>
            <Typography
              marginTop="15px"
              type="bh4"
              textAlign="center"
            >
              Akun Anda Telah Diblokir
            </Typography>
            <Typography
              marginTop="15px"
              type="rh6"
              textAlign="center"
            >
              Silahkan hubungi Customer Service kami untuk informasi
              lebih lanjut
            </Typography>
            <StyledButton
              fontWeight="bold"
              size="large"
              onClick={() => redirect(`/hubungikami`)}
              margin="50px 0 0"
              block
              data-testid="user-blocked-btn"
            >
              Hubungi Kami
            </StyledButton>
          </>
        </Flex>
      </>
    </Modal>
  )
}

export default UserBlocked
