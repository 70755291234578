import styled, { css } from 'styled-components'

interface IFlexProps {
  children:
    | Node
    | Element
    | JSX.Element
    | JSX.Element[]
    | ((props: any) => any)
  backgroundColor?: string
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  flexWrap?: string
  flexGrow?: string
  margin?: string
  padding?: string
  gap?: string
}
const StyledFlex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  flex-wrap: ${(props) => props.flexWrap};
  flex-grow: ${(props) => props.flexGrow};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  gap: ${(props) => props.gap};
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
`

function Flex({
  children,
  backgroundColor = 'transparent',
  flexDirection = 'row',
  justifyContent = 'flex-start',
  alignItems = 'stretch',
  flexWrap = 'nowrap',
  flexGrow = '0',
  margin = '0',
  padding = '0',
  ...otherProps
}: IFlexProps): JSX.Element {
  return (
    <StyledFlex
      backgroundColor={backgroundColor}
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      flexWrap={flexWrap}
      flexGrow={flexGrow}
      margin={margin}
      padding={padding}
      {...otherProps}
    >
      {children}
    </StyledFlex>
  )
}

export default Flex
