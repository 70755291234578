import cdn from 'lib/cdn'

export default {
  colors: {
    custom: {
      primary: '#e50000',
      white: '#fff',
      headerBG: '#ff1b00',
      grayDark: '#747474',
      lightGray: '#F6F6F6',
      orange: '#F58220',
    },
  },
  backgroundImage: {
    anniversary: `url(${cdn({
      path: '/assets/img/custom-theme/header-bg.png',
    })})`,
    ramadhan: `url(${cdn({
      path: '/assets/img/custom-theme/header-bg-ramadhan.png',
    })})`,
    'one-plus-one-summit': `url(${cdn({
      path:
        '/assets/img/custom-theme/header-bg-one-plus-one-summit.png',
    })})`,
  },
}
