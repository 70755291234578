import { action, thunk } from 'easy-peasy'
import IRequestModel from 'services/Rfq/models/RequestModel/interface'

const RequestModel: IRequestModel = {
  isLoading: true,
  isError: false,
  data: [],
  reset: action((state) => {
    state.isLoading = true
    state.isError = false
    state.data = []
  }),
  addRequest: action((state, payload) => {
    state.isLoading = false
    state.data = payload
  }),
  getRequest: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/rfq/v1/requests',
        method: 'GET',
        params: { ...payload, statuses: payload.statuses?.join(',') },
      })
      if (response.data?.data?.length) {
        actions.addRequest(response.data.data)
      } else {
        actions.addRequest([])
      }
    } catch (err) {
      if (err?.response?.status === 422) actions.addRequest([])
      else actions.error()
    }
  }),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
}

export default RequestModel
