import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventPermission from 'services/Permission/event'
import IPermissionModel from 'services/Permission/models/PermissionModel/interface'

const PermissionModel: IPermissionModel = {
  permissionList: {},
  isLoading: false,
  isError: false,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  error: action((state, payload) => {
    state.isError = payload
  }),
  setPermissionList: action((state, payload) => {
    state.permissionList = {
      ...state.permissionList,
      ...payload,
    }
  }),
  getPermissionList: thunk(
    async (actions, payload, { injections }) => {
      actions.setIsLoading(true)
      const { apiClient } = injections

      try {
        const res = await apiClient({
          url: '/plcs/v2/check',
          method: 'POST',
          data: payload,
        })

        sendLogHitEndpoint(
          eventPermission.getPermissionList.event,
          dependencyContextMapper(
            eventPermission.getPermissionList.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )

        if (res?.status === 200) {
          actions.setPermissionList(res?.data?.data?.actions)
          return res?.data?.data?.actions
        }

        return res
      } catch (error) {
        actions.error(true)

        if (error?.response?.status !== 401) {
          Toast(
            'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi.',
            {
              type: 'error',
              position: 'top-center',
              theme: 'colored',
              hideProgressBar: false,
              autoClose: 5000,
              pauseOnHover: true,
              toastId: 'error-get-permission-list',
            },
          )
        }

        sendLogHitEndpoint(
          eventPermission.getPermissionList.event,
          dependencyContextMapper(
            eventPermission.getPermissionList.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
        return error
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default PermissionModel
