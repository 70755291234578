export default {
  deleteCartItem: {
    event: {
      name: 'Cart',
      state: 'DeleteCartItem',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetCart',
      service: 'rll-gw-api',
    },
  },
  updateCartItem: {
    event: {
      name: 'Cart',
      state: 'UpdateCartItem',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetCart',
      service: 'rll-gw-api',
    },
  },
  getCart: {
    event: {
      name: 'Cart',
      state: 'GetCart',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetCart',
      service: 'rll-gw-api',
    },
  },
}
