import { createStore } from 'easy-peasy'
import StoreConfig from 'stores/config'
import StoreModel from 'stores/models'
import IStoreModel from 'stores/models/interface'

const store = createStore<IStoreModel>(
  StoreModel,
  StoreConfig({ name: 'ralali-store' }),
)

export default store
