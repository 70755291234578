import {
  IEventProvider,
  IEventTracking,
} from 'lib/event-tracking/interface'

export default function eventTracking(
  provider: IEventProvider,
): IEventTracking {
  return {
    identify: (userId, properties, callback = () => null): void => {
      provider.identify(userId, properties, () => callback())
    },
    track: (
      eventType,
      eventProperties,
      callback = () => null,
    ): void => {
      provider.track(eventType, eventProperties, () => callback())
    },
    getUserId: (): string => {
      const userId = provider.getUserId()
      return userId
    },
  }
}
