import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventMembership from 'services/Membership/event'
import IMembershipModel from 'services/Membership/models/MembershipModel/interface'

const MembershipModel: IMembershipModel = {
  data: null,
  isError: false,
  isLoading: false,
  errorMessage: '',
  resetError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  getUserMembership: thunk(async (actions, _, { injections }) => {
    try {
      actions.resetError()
      actions.setIsLoading(true)

      const { apiClient } = injections
      const response = await apiClient({
        url: `/membership/v1/members`,
        method: 'GET',
      })

      sendLogHitEndpoint(
        {
          ...eventMembership.getUserMembership.event,
        },
        dependencyContextMapper(
          eventMembership.getUserMembership.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      if (response.status === 200) {
        actions.setData(response.data.data)
      }
    } catch (err) {
      sendLogHitEndpoint(
        {
          ...eventMembership.getUserMembership.event,
        },
        dependencyContextMapper(
          eventMembership.getUserMembership.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.setError(err?.response?.data?.message)
    } finally {
      actions.setIsLoading(false)
    }
  }),
}

export default MembershipModel
