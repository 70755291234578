import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import events from 'services/Seller/events'
import IActionStateModel from 'services/UserAction/models/ActionStateModel/interface'

/**
 * this model are using user-actions-states path and previously was incorrectly placed in seller service
 */

const ActionStateModel: IActionStateModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  data: null,
  shouldOpenCoachmark: false,
  actionStates: null,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setErrorMessage: action((state, payload) => {
    state.errorMessage = payload
  }),
  setData: action((state, { code, data }) => {
    state.data = {
      ...state.data,
      [code]: data,
    }
  }),
  setShouldOpenCoachmark: action((state, payload) => {
    state.shouldOpenCoachmark = payload
  }),
  setActionStates: action((state, { code, data }) => {
    state.actionStates = {
      ...state.actionStates,
      [code]: data,
    }
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  error: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  getUserActionStates: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setIsError(false)
        actions.setErrorMessage('')
        const { apiClient } = injections
        const response = await apiClient({
          url: `/user-action-states/v1/states`,
          method: 'GET',
          params: {
            group_code: payload.groupCode,
            code: payload.code,
          },
        })
        sendLogHitEndpoint(
          events.getUserActionState.event,
          dependencyContextMapper(
            events.getUserActionState.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )

        if (response.status === 200) {
          const data = objectToCamelCase(response?.data?.data)

          actions.setData({
            code: payload.groupCode,
            data: objectToCamelCase(data),
          })
          actions.setActionStates({
            code: payload.groupCode,
            data: data?.details,
          })
        }
      } catch (err) {
        sendLogHitEndpoint(
          events.getUserActionState.event,
          dependencyContextMapper(
            events.getUserActionState.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.error(err?.response?.data?.message)
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  updateUserActionStates: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.setIsError(false)
        actions.setErrorMessage('')
        const { apiClient } = injections
        const response = await apiClient({
          url: `/user-action-states/v1/states`,
          method: 'PATCH',
          data: payload,
        })
        sendLogHitEndpoint(
          events.updateUserActionState.event,
          dependencyContextMapper(
            events.updateUserActionState.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return response.data
      } catch (err) {
        sendLogHitEndpoint(
          events.updateUserActionState.event,
          dependencyContextMapper(
            events.updateUserActionState.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.error(err?.response?.data?.message)
        return undefined
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default ActionStateModel
