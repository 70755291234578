import { action, thunk } from 'easy-peasy'
import DateFormatter from 'lib/date-formatter'
import IDailyDealsModel from 'services/Home/stores/models/DailyDealsModel/interface'

const DailyDealsModel: IDailyDealsModel = {
  isLoading: true,
  isError: false,
  errorMessage: '',
  activeDailyDeals: [],
  setActiveDailyDeals: action((state, payload) => {
    state.isLoading = false
    state.activeDailyDeals = payload
  }),
  products: [],
  setProducts: action((state, payload) => {
    state.isLoading = false
    state.products = payload
  }),
  getActiveDailyDeals: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        const { data } = await apiClient({
          url: '/campaign/v1/dailydeals',
          method: 'GET',
        })
        const today = new Date()
        const currentActiveDailyDeals = data.data.filter(
          (dailyDeals) =>
            DateFormatter(dailyDeals.start_date) < today &&
            today <= DateFormatter(dailyDeals.end_date),
        )
        if (currentActiveDailyDeals) {
          actions.setActiveDailyDeals(currentActiveDailyDeals)
          try {
            const response = await apiClient({
              url: `/campaign/v1/dailydeals/${currentActiveDailyDeals[0].id}/items`,
              method: 'GET',
            })
            actions.setProducts(response?.data?.data)
          } catch (err) {
            actions.error(err.message)
          }
        } else {
          actions.setActiveDailyDeals([])
          actions.setProducts([])
        }
      } catch (err) {
        actions.error(err.message)
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default DailyDealsModel
