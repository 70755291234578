import Loader from 'common/components/Loader'
import theme from 'common/theme'
import styled from 'styled-components'

/**
 * @param {String} variant
 * @param {String} color
 * @return {String}
 */
const ButtonSelectionColors = (
  variant: string,
  color: string,
): string => {
  const baseColor = theme.colors[color]
    ? theme.colors[color]
    : theme.colors.orange
  let style = ''
  switch (variant) {
    case 'outlined':
      style = `
      border: 1px solid ${baseColor};
      background: ${theme.colors.white};
      color: ${baseColor};`
      break
    case 'text':
      style = `
        border: none;
        background: none;
        color: ${baseColor};
      `
      break
    default:
      style = `
      border: none;
      background: ${baseColor};
      color: ${theme.colors.white};`
      break
  }
  return style
}

/**
 * @param {String} size
 * @return {String}
 */
const ButtonSelectionSize = (
  size: string,
  padding: string,
  height: string,
  fontSize: string,
): string => {
  let style = ''
  switch (size) {
    case 'large':
      style = `
      padding: ${padding || '0 27px'};
      height: ${height || '50px'};
      font-size: ${fontSize || '13px'};
      text-transform: uppercase;`
      break
    default:
      style = `
      padding: ${padding || '0 27px'};
      height: ${height || '40px'};
      font-size: ${fontSize || '13px'};
      text-transform: uppercase;`
      break
  }
  return style
}

/**
 * @param {Boolean} block
 * @return {String}
 */
const ButtonSelectionBlock = (block: boolean): string => {
  return block ? 'width: 100%;' : ''
}

/**
 * @param {Boolean} transparent
 * @return {String}
 */
const ButtonSelectionTransparent = (transparent: boolean): string => {
  return transparent ? 'background-color: transparent;' : ''
}

/**
 * @param {String} letterCase
 * @return {String}
 */
const ButtonSelectionLetterCase = (letterCase: string): string => {
  switch (letterCase) {
    case 'lowercase':
      return `
        text-transform: lowercase;`
    case 'capitalize':
      return `
        text-transform: capitalize;`
    case 'uppercase':
      return `
        text-transform: uppercase;`
    default:
      return `
        text-transform: none;`
  }
}

/**
 * @param {String} width
 * @return {String}
 */
const ButtonSelectionWidth = (width: string): string => {
  return width ? `width: ${width};` : ''
}

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => props.borderRadius};
  margin: ${(props) => (!props.margin ? '0' : props.margin)};
  padding: ${(props) => (!props.padding ? '0' : props.padding)};
  ${(props) => ButtonSelectionColors(props.variant, props.color)}
  ${(props) =>
    ButtonSelectionSize(
      props.size,
      props.padding,
      props.height,
      props.fontSize,
    )}
  ${(props) => ButtonSelectionBlock(props.block)}
  ${(props) => ButtonSelectionWidth(props.width)}
  ${(props) => ButtonSelectionTransparent(props.transparent)}
  ${(props) => ButtonSelectionLetterCase(props.letterCase)}
  font-weight: ${(props) =>
    props.fontWeight === 'bold' ? 'bold' : 'none'};
  outline: none;
  cursor: pointer;

  :active {
    opacity: 0.8;
    -webkit-tap-highlight-color: transparent;
    -moz-tap-highlight-color: transparent;
  }

  :disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: ${({ variant }) =>
      variant === 'outlined'
        ? theme.colors.gray
        : theme.colors.white};
    background: ${({ variant }) =>
      variant === 'outlined'
        ? theme.colors.white
        : theme.colors.gray};
    border: ${({ variant }) =>
      variant === 'outlined' && `1.5px solid ${theme.colors.gray}`};
    svg {
      path {
        fill: ${theme.colors.gray};
      }
    }
  }
`
const Icon = styled.div`
  display: flex;
  float: ${(props) => props.position};
  ${(props) => props.position === 'left' && 'margin: 0 8px 0 0'}
  ${(props) => props.position === 'right' && 'margin: 0 0 0 8px'}
`

interface IconType {
  icon: JSX.Element | null
  position: string
}

interface ButtonType extends Record<string, any> {
  children: string | JSX.Element
  variant?: string
  color?: string
  size?: string
  fontWeight?: string | number
  disabled?: boolean
  block?: boolean
  transparent?: boolean
  onClick?: any
  letterCase?: string
  width?: string
  margin?: string
  padding?: string
  withIcon?: IconType
  type?: string
  isLoading?: boolean
  borderRadius?: string
  height?: string
  fontSize?: string
  hidden?: boolean
}

const renderIcon = (withIcon: IconType): JSX.Element => {
  if (withIcon.icon) {
    return <Icon position={withIcon.position}>{withIcon.icon}</Icon>
  }
  return null
}

/**
 * @param {String | JSX.Element} props.children
 * @param {String} props.variant
 * @param {String} props.size
 * @param {String | Number} props.fontWeight
 * @param {Boolean} props.disabled
 * @param {Boolean} props.block
 * @param {Boolean} props.transparent
 * @param {Any} props.onClick
 * @param {String} props.letterCase
 * @param {String} props.width
 * @param {Object} props.withIcon
 */

function Button({
  children,
  variant = 'contained',
  color = 'orange',
  size = 'normal',
  fontWeight = 'normal',
  disabled = false,
  block = false,
  transparent = false,
  onClick = null,
  letterCase = 'none',
  width = '',
  margin,
  withIcon = {
    icon: null,
    position: 'left',
  },
  type = 'button',
  padding,
  isLoading = false,
  borderRadius = theme.radius.lg,
  height,
  fontSize,
  hidden = false,
  ...otherProps
}: ButtonType): JSX.Element {
  const renderContent = (): JSX.Element => {
    if (isLoading)
      return (
        <Loader
          radius={size === 'large' ? '80px' : '40px'}
          primaryColor={theme.colors.white}
          secondaryColor={theme.colors.redOrange}
        />
      )
    return (
      <>
        {withIcon.position === 'left' && renderIcon(withIcon)}
        {children}
        {withIcon.position === 'right' && renderIcon(withIcon)}
      </>
    )
  }
  const handleOnClick = () => {
    if (isLoading) return null
    if (onClick) return onClick()
    return null
  }

  return (
    <StyledButton
      variant={variant}
      color={color}
      size={size}
      fontWeight={fontWeight}
      disabled={disabled}
      block={block}
      transparent={transparent}
      onClick={handleOnClick}
      letterCase={letterCase}
      width={width}
      margin={margin}
      type={type}
      padding={padding}
      borderRadius={borderRadius}
      height={height}
      fontSize={fontSize}
      hidden={hidden}
      {...otherProps}
    >
      {renderContent()}
    </StyledButton>
  )
}

export default Button
