export default {
  postRedeemGiftCard: {
    event: { name: 'Digital', state: 'PostRedeemGiftCard' },
    dependency: {
      name: 'API Gateway',
      event: 'PostRedeemGiftCard',
      service: 'rll-gw-api',
    },
  },
}
