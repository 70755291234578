import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import ArrayofObjectToCamelCase from 'lib/transform/array-to-camel-case'
import { eventBank } from 'services/Bank/event'
import IBankListModel from 'services/Bank/models/BankListModel/interface'

const BankListModel: IBankListModel = {
  data: [],
  isLoading: false,
  isError: false,
  setData: action((state, payload) => {
    state.data = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  getBankList: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setIsLoading(true)
      actions.setIsError(false)

      const { apiClient } = injections
      const res = await apiClient({
        url:
          payload === 'v2'
            ? '/marketplace/v1/banks'
            : '/core/api/v2/digital-goods/buyer/banks',
        method: 'GET',
      })

      if (res.status === 200) {
        sendLogHitEndpoint(
          eventBank.getBankList.event,
          dependencyContextMapper(
            eventBank.getBankList.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )
        actions.setData(
          ArrayofObjectToCamelCase(
            payload === 'v2'
              ? res?.data?.data?.items
              : res?.data?.result,
          ),
        )
      }
    } catch (err) {
      sendLogHitEndpoint(
        eventBank.getBankList.event,
        dependencyContextMapper(
          eventBank.getBankList.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.setIsError(true)
    } finally {
      actions.setIsLoading(false)
    }
  }),
}

export default BankListModel
