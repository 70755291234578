const fixKeys = (fn) => (obj) =>
  Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [
      fn(k),
      (() => {
        if (v !== null) {
          if (Array.isArray(v)) {
            if (typeof v[0] === 'string') {
              return v.map((item) => item)
            }
            return v.map(fixKeys(fn))
          }
          if (typeof v === 'object') {
            return fixKeys(fn)(v)
          }
          return v
        }
        return ''
      })(),
    ]),
  )

const camelCase = (s) => s.replace(/_(.)/g, (_, c) => c.toUpperCase())

const objectToCamelCase = fixKeys(camelCase)

export default objectToCamelCase
