const loginCategory = (loginMethod: string): string => {
  if (loginMethod === 'google' || loginMethod === 'facebook') {
    return 'login_social'
  }
  if (loginMethod === 'email') {
    return 'login_password'
  }
  if (loginMethod === 'phone number') {
    return 'login_otp'
  }
  return ''
}

export default loginCategory
