import isEmpty from 'lib/common/is-empty'
import { TFeatureFlags } from 'lib/feature-flags/interface'
import usePosthog from 'lib/feature-flags/providers/Posthog'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

interface IContext {
  flags: TFeatureFlags
  isLoaded: boolean
}

export const FeatureFlagContext = createContext<IContext>({
  flags: {},
  isLoaded: false,
})

function FeatureFlagsProvider({
  children,
}: {
  children: ReactNode
}): JSX.Element {
  const [flags, setFlags] = useState<TFeatureFlags>()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const values = useMemo<IContext>(
    () => ({
      flags,
      isLoaded,
    }),
    [flags, isLoaded],
  )

  // Put your provider here
  const posthog = usePosthog({ setFlags })

  useEffect(() => {
    if (!posthog) return
    setIsLoaded(posthog.isReady)
  }, [posthog])

  return (
    <FeatureFlagContext.Provider value={values}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

export function useFeatureFlags(key: string): boolean {
  const { flags } = useContext(FeatureFlagContext)

  if (!isEmpty(flags)) {
    return flags[key]
  }

  return false
}

export function useFeatureFlagLoaded(): boolean {
  const { isLoaded } = useContext(FeatureFlagContext)

  return isLoaded
}

export function useLoadedFlag(key: string): boolean {
  const isFlagLoaded = useFeatureFlagLoaded()
  const flags = useFeatureFlags(key)

  return isFlagLoaded && flags
}

export default FeatureFlagsProvider
