function partialMasking(
  value: string,
  mask = '*',
  last = 10,
): string {
  return (
    `${value}`.slice(0, -last).replace(/./g, mask) +
    `${value}`.slice(-last)
  )
}

function fullMasking(value: string, mask = '*'): string {
  return `${value}`.replace(/./g, mask)
}

function emailMasking(value: string, mask = '*'): string {
  if (!value) return ''
  const maskedEmail = value.replace(/([^@.])/g, mask).split('')
  const notMaskedUntil =
    maskedEmail.findIndex((letter) => letter === '@') - 3
  let previous = ''
  maskedEmail.forEach((letter, i) => {
    if (
      i <= 1 ||
      i < notMaskedUntil ||
      previous === '.' ||
      previous === '@'
    ) {
      maskedEmail[i] = value[i]
    }
    previous = value[i]
  })
  return maskedEmail.join('')
}

function phoneNumberMasking(value: string, mask = '*'): string {
  if (!value) return ''
  const maskedPhoneNumberChunks = value
    .toString()
    .replace(/.(?=.{4,7}$)/g, mask)
  return maskedPhoneNumberChunks
}

export {
  partialMasking,
  fullMasking,
  emailMasking,
  phoneNumberMasking,
}
