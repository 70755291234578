import objectToCamelCase from 'lib/transform/object-to-camel-case'

const ArrayofObjectToCamelCase = (arr): Array<any> => {
  if (!Array.isArray(arr)) return []
  const convertedArray = []

  arr.forEach((element) => {
    convertedArray.push(objectToCamelCase(element))
  })
  return convertedArray
}

export default ArrayofObjectToCamelCase
