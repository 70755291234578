import { action, thunk } from 'easy-peasy'
import IProductHomepage from 'services/Home/stores/models/ProductHomepage/interface'

const DailyDealsModel: IProductHomepage = {
  isLoading: true,
  isError: false,
  errorMessage: '',
  categoryProduct: [],
  setCategoryProduct: action((state, payload) => {
    state.isLoading = false
    state.categoryProduct = payload
  }),
  getCategoryProduct: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        const { data } = await apiClient({
          url: '/core/api/v2/home/products',
          method: 'GET',
        })
        actions.setCategoryProduct(data?.result)
      } catch (err) {
        actions.error(err.response)
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default DailyDealsModel
