import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import ArrayofObjectToCamelCase from 'lib/transform/array-to-camel-case'
import camelToSnakeKeys from 'lib/transform/camel-to-snake-case'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import tokoPosEvent from 'services/LocalStorefront/events'
import IRalaliConnectModel from 'services/LocalStorefront/models/RalaliConnectModel/interface'

const RalaliConnectModel: IRalaliConnectModel = {
  // state
  isLoading: false,
  isLoadingStorefront: false,

  storefrontData: [],
  storefrontMeta: null,
  provinces: [],
  cities: [],
  businessTypes: [],
  subBusinessTypes: [],

  isErrorStoreFront: false,
  isErrorProvince: false,
  isErrorCity: false,
  isErrorBusinessType: false,
  isErrorSubBusinessType: false,

  errorStorefront: '',
  errorProvince: '',
  errorCity: '',
  errorBusinessType: '',
  errorSubBusinessType: '',

  // action
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsLoadingStorefront: action((state, payload) => {
    state.isLoadingStorefront = payload
  }),

  setStorefrontData: action((state, payload) => {
    state.storefrontData = payload
  }),
  setStorefrontMeta: action((state, payload) => {
    state.storefrontMeta = payload
  }),
  setProvinces: action((state, payload) => {
    state.provinces = payload
  }),
  setCities: action((state, payload) => {
    state.cities = payload
  }),
  setBusinessTypes: action((state, payload) => {
    state.businessTypes = payload
  }),
  setSubBusinessTypes: action((state, payload) => {
    state.subBusinessTypes = payload
  }),

  setErrorStoreFront: action((state, payload) => {
    state.isErrorStoreFront = payload
  }),
  setErrorProvince: action((state, payload) => {
    state.isErrorProvince = payload
  }),
  setErrorCity: action((state, payload) => {
    state.isErrorCity = payload
  }),
  setErrorBusinessType: action((state, payload) => {
    state.isErrorBusinessType = payload
  }),
  setErrorSubBusinessType: action((state, payload) => {
    state.isErrorSubBusinessType = payload
  }),

  setErrorStorefrontMsg: action((state, payload) => {
    state.errorStorefront = payload
  }),
  setErrorProvinceMsg: action((state, payload) => {
    state.errorProvince = payload
  }),
  setErrorCityMsg: action((state, payload) => {
    state.errorCity = payload
  }),
  setErrorBusinessTypeMsg: action((state, payload) => {
    state.errorBusinessType = payload
  }),
  setErrorSubBusinessTypeMsg: action((state, payload) => {
    state.errorSubBusinessType = payload
  }),

  // thunk
  getStorefronts: thunk(async (actions, payload, { injections }) => {
    actions.setErrorStoreFront(false)
    actions.setIsLoading(true)
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/tokopos/v1/storefronts',
        method: 'GET',
        params: {
          ...camelToSnakeKeys(payload),
        },
      })

      actions.setStorefrontData(
        ArrayofObjectToCamelCase(response?.data?.data),
      )
      actions.setStorefrontMeta(
        objectToCamelCase(response?.data?.meta),
      )
      sendLogHitEndpoint(
        {
          ...tokoPosEvent.getStorefronts.event,
        },
        dependencyContextMapper(
          tokoPosEvent.getStorefronts.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (error) {
      actions.setErrorStoreFront(true)
      actions.setErrorStorefrontMsg(error?.response?.data?.message)
      sendLogHitEndpoint(
        {
          ...tokoPosEvent.getStorefronts.event,
        },
        dependencyContextMapper(
          tokoPosEvent.getStorefronts.dependency,
          requestMapper(error.config),
          errorMapper(error),
        ),
      )
    } finally {
      actions.setIsLoading(false)
    }
  }),
  getProvinces: thunk(async (actions, payload, { injections }) => {
    actions.setErrorProvince(false)
    actions.setIsLoading(true)
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/tokopos/v1/provinces',
        method: 'GET',
        params: {
          ...camelToSnakeKeys(payload),
        },
      })
      actions.setProvinces(
        ArrayofObjectToCamelCase(response?.data?.data),
      )
      sendLogHitEndpoint(
        {
          ...tokoPosEvent.getProvinces.event,
        },
        dependencyContextMapper(
          tokoPosEvent.getProvinces.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (error) {
      actions.setErrorProvince(true)
      actions.setErrorProvinceMsg(error?.response?.data?.message)
      sendLogHitEndpoint(
        {
          ...tokoPosEvent.getProvinces.event,
        },
        dependencyContextMapper(
          tokoPosEvent.getProvinces.dependency,
          requestMapper(error.config),
          errorMapper(error),
        ),
      )
    } finally {
      actions.setIsLoading(false)
    }
  }),
  getCities: thunk(async (actions, payload, { injections }) => {
    actions.setErrorCity(false)
    actions.setIsLoading(true)
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/tokopos/v1/cities',
        method: 'GET',
        params: {
          ...camelToSnakeKeys(payload),
        },
      })
      actions.setCities(
        ArrayofObjectToCamelCase(response?.data?.data),
      )
      sendLogHitEndpoint(
        {
          ...tokoPosEvent.getCities.event,
        },
        dependencyContextMapper(
          tokoPosEvent.getCities.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (error) {
      actions.setErrorCity(true)
      actions.setErrorCityMsg(error?.response?.data?.message)
      sendLogHitEndpoint(
        {
          ...tokoPosEvent.getCities.event,
        },
        dependencyContextMapper(
          tokoPosEvent.getCities.dependency,
          requestMapper(error.config),
          errorMapper(error),
        ),
      )
    } finally {
      actions.setIsLoading(false)
    }
  }),
  getBusinessTypes: thunk(
    async (actions, payload, { injections }) => {
      actions.setErrorBusinessType(false)
      actions.setIsLoading(true)
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: '/tokopos/v1/business-types',
          method: 'GET',
          params: {
            ...camelToSnakeKeys(payload),
          },
        })
        actions.setBusinessTypes(
          ArrayofObjectToCamelCase(response?.data?.data),
        )
        sendLogHitEndpoint(
          {
            ...tokoPosEvent.getBusinessTypes.event,
          },
          dependencyContextMapper(
            tokoPosEvent.getBusinessTypes.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (error) {
        actions.setErrorBusinessType(true)
        actions.setErrorBusinessTypeMsg(
          error?.response?.data?.message,
        )
        sendLogHitEndpoint(
          {
            ...tokoPosEvent.getBusinessTypes.event,
          },
          dependencyContextMapper(
            tokoPosEvent.getBusinessTypes.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  getSubBusinessTypes: thunk(
    async (actions, payload, { injections }) => {
      actions.setErrorSubBusinessType(false)
      actions.setIsLoading(true)
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: '/tokopos/v1/sub-business-types',
          method: 'GET',
          params: {
            ...camelToSnakeKeys(payload),
          },
        })
        actions.setSubBusinessTypes(
          ArrayofObjectToCamelCase(response?.data?.data),
        )
        sendLogHitEndpoint(
          {
            ...tokoPosEvent.getSubBusinessTypes.event,
          },
          dependencyContextMapper(
            tokoPosEvent.getSubBusinessTypes.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (error) {
        actions.setErrorSubBusinessType(true)
        actions.setErrorSubBusinessTypeMsg(
          error?.response?.data?.message,
        )
        sendLogHitEndpoint(
          {
            ...tokoPosEvent.getSubBusinessTypes.event,
          },
          dependencyContextMapper(
            tokoPosEvent.getSubBusinessTypes.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default RalaliConnectModel
