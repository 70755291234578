import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import validationResponseHelper from 'lib/validation-response-handler'
import eventCart from 'services/Cart/event'
import ICartModelModel from 'services/Cart/models/CartModel/interface'

function getTotalItems(data) {
  return data?.detail
    ?.map((vendor) => vendor.details.length)
    .reduce((total, currentValue) => total + currentValue, 0)
}

const CartModel: ICartModelModel = {
  isLoading: true,
  isError: false,
  data: null,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setData: action((state, payload) => {
    state.isLoading = false
    state.isError = false
    payload.total_items = getTotalItems(payload)
    state.data = payload
  }),
  deleteCartItem: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/cart/graphql',
        method: 'POST',
        data: payload,
      })
      sendLogHitEndpoint(
        {
          ...eventCart.deleteCartItem.event,
        },
        dependencyContextMapper(
          eventCart.deleteCartItem.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      return response?.data?.data?.V1DeleteCart?.result
    } catch (error) {
      const message =
        error?.response?.data?.data?.V1DeleteCart?.result
          ?.atc_validation_result?.message ||
        validationResponseHelper(error, 'top-center', true, {
          toastClientSideError: false,
        })

      Toast(message, {
        type: 'error',
        theme: 'colored',
        hideProgressBar: false,
        autoClose: 5000,
        pauseOnHover: true,
        position: 'top-center',
        toastId: 'error-delete-cart-item',
      })
      sendLogHitEndpoint(
        {
          ...eventCart.deleteCartItem.event,
        },
        dependencyContextMapper(
          eventCart.deleteCartItem.dependency,
          requestMapper(error.config),
          errorMapper(error),
        ),
      )
      throw error
    }
  }),
  updateCartItem: thunk(async (actions, payload, { injections }) => {
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/cart/graphql',
        method: 'POST',
        data: payload,
      })
      sendLogHitEndpoint(
        {
          ...eventCart.updateCartItem.event,
        },
        dependencyContextMapper(
          eventCart.updateCartItem.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (error) {
      const message =
        error?.response?.data?.data?.V1DeleteCart?.result
          ?.atc_validation_result?.message ||
        validationResponseHelper(error, 'top-center', true, {
          toastClientSideError: false,
        })

      if (message !== '') {
        Toast(message, {
          type: 'error',
          theme: 'colored',
          hideProgressBar: false,
          autoClose: 5000,
          pauseOnHover: true,
          position: 'top-center',
          toastId: 'error-update-cart-item',
        })
      }
      sendLogHitEndpoint(
        {
          ...eventCart.updateCartItem.event,
        },
        dependencyContextMapper(
          eventCart.updateCartItem.dependency,
          requestMapper(error.config),
          errorMapper(error),
        ),
      )
    }
  }),
  getCart: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setIsLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        url: '/cart/graphql',
        method: 'POST',
        data: payload,
      })
      actions.setData(response.data?.data?.V1GetCart?.result)
      sendLogHitEndpoint(
        {
          ...eventCart.getCart.event,
        },
        dependencyContextMapper(
          eventCart.getCart.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (err) {
      actions.error()
      sendLogHitEndpoint(
        {
          ...eventCart.getCart.event,
        },
        dependencyContextMapper(
          eventCart.getCart.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    }
  }),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
}

export default CartModel
