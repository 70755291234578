import styled from 'styled-components'

interface IOverlay {
  isActive?: boolean
  padding?: string
  zIndex?: string
  children?: string | JSX.Element
  onOverlayClick?: () => void
}

const StyledOverlay = styled.div`
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  padding: ${(props) => props.padding};
  background-color: rgba(0, 0, 0, 0.35);
  z-index: ${(props) => props.zIndex};
  overflow-x: hidden;
  overflow-y: auto;
`

function Overlay({
  children,
  isActive = false,
  zIndex = '6',
  padding = '0',
  onOverlayClick,
  ...props
}: IOverlay): JSX.Element {
  if (!isActive) return <>{children}</>
  return (
    <>
      <StyledOverlay
        zIndex={zIndex}
        onClick={onOverlayClick}
        padding={padding}
        data-testid="modal-test"
        {...props}
      >
        {children}
      </StyledOverlay>
    </>
  )
}

export default Overlay
