import { SSO_CODE } from 'common/constants'
import apiConfig from 'config/api'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import validationResponseHelper from 'lib/validation-response-handler'
import { eventOtp, eventResetPassword } from 'services/Auth/event'
import IResetPasswordModel from 'services/Auth/stores/models/ResetPasswordModel/interface'
import store from 'stores/index'

const { baseAccessUrl } = apiConfig

const ResetPasswordModel: IResetPasswordModel = {
  isLoading: false,
  errorMessage: '',
  step: 'forgot-password-form',
  requestId: '',
  email: '',
  otaTimer: new Date().toString(),
  ota: '',
  otaInvalid: false,
  successResetPassword: false,
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setStep: action((state, payload) => {
    state.step = payload
  }),
  setRequestId: action((state, payload) => {
    state.requestId = payload
  }),
  setOtaTimer: action((state, payload) => {
    state.otaTimer = payload
  }),
  setOta: action((state, payload) => {
    state.ota = payload
  }),
  setOtaInvalid: action((state, payload) => {
    state.otaInvalid = payload
  }),
  setSuccessResetPassword: action((state, payload) => {
    state.successResetPassword = payload
  }),
  postVerificationChannel: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!getState().isLoading) {
        actions.error('')
        try {
          actions.setLoading(true)
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url: '/sso/v1/users/verification',
            method: 'POST',
            data: {
              sso_code: SSO_CODE,
              type: payload.type,
              email: payload.email,
            },
          })
          if (response?.status === 200) {
            sendLogHitEndpoint(
              eventOtp.getVerificationChannel.event,
              dependencyContextMapper(
                eventOtp.getVerificationChannel.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )

            actions.setLoading(false)
            actions.setStep('otp-channel')
            actions.setRequestId(response?.data?.data?.request_id)
            store
              .getActions()
              .otp.setChannels(response?.data?.data?.channels)
          }
          store
            .getActions()
            .otp.setOtpTimer(
              new Date(
                response?.data?.data?.expired_at * 1000,
              ).toString(),
            )
        } catch (error) {
          sendLogHitEndpoint(
            eventOtp.getVerificationChannel.event,
            dependencyContextMapper(
              eventOtp.getVerificationChannel.dependency,
              requestMapper(error.config),
              errorMapper(error),
            ),
          )

          const errorMessage = validationResponseHelper(error)
          actions.error(errorMessage)
        }
      }
    },
  ),
  postVerificationNumber: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!store.getState().otp.isLoadingChannel) {
        store.getActions().otp.setIsLoadingChannel(true)
        try {
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url: '/sso/v1/users/verification',
            method: 'POST',
            data: {
              sso_code: SSO_CODE,
              type: 'request_verification_number',
              request_id: getState().requestId,
              channel: payload,
            },
          })
          if (response?.status === 200) {
            sendLogHitEndpoint(
              eventOtp.postRequestOtpForOta.event,
              dependencyContextMapper(
                eventOtp.postRequestOtpForOta.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )

            actions.setStep('otp-form')
            store.getActions().otp.setSelectedChannel(payload)
            store.getActions().otp.setIsLoadingChannel(false)
          }
        } catch (error) {
          sendLogHitEndpoint(
            eventOtp.postRequestOtpForOta.event,
            dependencyContextMapper(
              eventOtp.postRequestOtpForOta.dependency,
              requestMapper(error.config),
              errorMapper(error),
            ),
          )

          const errorMessage = validationResponseHelper(error)
          store.getActions().otp.errorChannel(errorMessage)
        }
      }
    },
  ),
  postOneTimePassword: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!store.getState().otp.isLoadingOtp) {
        store.getActions().otp.setIsLoadingOtp(true)
        try {
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url: '/sso/v1/users/verification',
            method: 'POST',
            data: {
              sso_code: SSO_CODE,
              type: 'request_ota',
              request_id: getState().requestId,
              verification_number: payload,
            },
          })
          if (response?.status === 200) {
            sendLogHitEndpoint(
              eventOtp.postRequestOta.event,
              dependencyContextMapper(
                eventOtp.postRequestOta.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )

            actions.setOta(response?.data?.data?.ota)
            actions.setOtaTimer(
              new Date(
                response?.data?.data?.expired_at * 1000,
              ).toString(),
            )
            actions.setStep('new-password-form')
            store.getActions().otp.setIsLoadingOtp(false)
          }
        } catch (error) {
          sendLogHitEndpoint(
            eventOtp.postRequestOta.event,
            dependencyContextMapper(
              eventOtp.postRequestOta.dependency,
              requestMapper(error.config),
              errorMapper(error),
            ),
          )

          const errorMessage = validationResponseHelper(error)
          store.getActions().otp.errorOtp(errorMessage)
        }
      }
    },
  ),
  postResetPassword: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!getState().isLoading) {
        actions.error('')
        try {
          actions.setLoading(true)
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url: '/sso/v1/passwords/reset',
            method: 'POST',
            data: {
              new_password: payload,
            },
            headers: {
              'X-Ota': getState().ota,
            },
          })
          if (response?.status === 200) {
            sendLogHitEndpoint(
              eventResetPassword.postResetPassword.event,
              dependencyContextMapper(
                eventResetPassword.postResetPassword.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )

            actions.setLoading(false)
            actions.setSuccessResetPassword(true)
          }
        } catch (error) {
          sendLogHitEndpoint(
            eventResetPassword.postResetPassword.event,
            dependencyContextMapper(
              eventResetPassword.postResetPassword.dependency,
              requestMapper(error.config),
              errorMapper(error),
            ),
          )

          const errorMessage = validationResponseHelper(error)
          actions.error(errorMessage)
          actions.setOtaInvalid(true)
        }
      }
    },
  ),
  postChangePassword: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!getState().isLoading) {
        actions.error('')
        try {
          actions.setLoading(true)
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url: '/sso/v1/passwords/change',
            method: 'POST',
            data: {
              new_password: payload.newPassword,
            },
            headers: {
              'X-Ota': getState().ota,
            },
          })
          if (response?.status === 200) {
            sendLogHitEndpoint(
              eventResetPassword.postChangePassword.event,
              dependencyContextMapper(
                eventResetPassword.postChangePassword.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )

            actions.setLoading(false)
            actions.setSuccessResetPassword(true)
          }
        } catch (error) {
          sendLogHitEndpoint(
            eventResetPassword.postChangePassword.event,
            dependencyContextMapper(
              eventResetPassword.postChangePassword.dependency,
              requestMapper(error.config),
              errorMapper(error),
            ),
          )

          const errorMessage = validationResponseHelper(error)
          actions.error(errorMessage)
          actions.setOtaInvalid(true)
        }
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoading = false
    state.errorMessage = payload
  }),
}

export default ResetPasswordModel
