import apiClient from 'lib/api-client'

const getIPAddress = async () => {
  const response = await apiClient({
    apiHost: 'https://api.ipify.org',
    url: '?format=json',
    method: 'GET',
  })
  return response.data.ip
}
export default getIPAddress
