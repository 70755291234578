/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import axios, {
  AxiosPromise,
  AxiosRequestConfig,
  Method,
  ResponseType,
} from 'axios'
import apiConfig from 'config/api'
import authConfig from 'config/auth'
import afterLogin from 'lib/auth/after-login'
import logout from 'lib/auth/after-logout'
import isEmpty from 'lib/common/is-empty'
import redirect from 'lib/common/redirect'
import { destroyCookie, getCookie, setCookie } from 'lib/cookie'
import { NextPageContext } from 'next'

const baseApi = apiConfig.baseApiUrl

export interface IFetchApiParams {
  apiHost?: string
  url?: string
  method?: Method
  data?: object
  headers?: object
  params?: object
  context?: NextPageContext
  basicAuth?: string
  requireLogin?: boolean
  useAuthorization?: boolean
  responseType?: ResponseType
}

const fetchApi = ({
  apiHost = baseApi,
  url,
  method = 'GET',
  data = {},
  headers = {},
  params = {},
  context,
  basicAuth,
  requireLogin = false,
  useAuthorization = true,
  responseType = 'json',
}: IFetchApiParams): AxiosPromise => {
  const isBasicAuth = !isEmpty(basicAuth)
  let token = ''
  const userToken = getCookie(authConfig.accessTokenName)
  const guestToken = getCookie(authConfig.grantTokenName)
  const refreshToken = getCookie(authConfig.refreshTokenName)

  if (requireLogin && !userToken) {
    redirect('/login', '', context)
    return
  }

  if (userToken || refreshToken) {
    token = userToken || 'invalid'
  } else {
    token = guestToken
  }

  const config: AxiosRequestConfig = {
    baseURL: apiHost,
    data:
      headers && headers['Content-Type'] === 'multipart/form-data'
        ? data
        : { ...data },
    params: {
      ...params,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(apiHost === baseApi && {
        'X-Application-Context': 'website:buyer',
      }),
      ...headers,
      ...(useAuthorization &&
        token && { Authorization: `Bearer ${token}` }),
      ...(useAuthorization &&
        isBasicAuth && { Authorization: `Basic ${basicAuth}` }),
    },
    responseType,
    method,
    url,
  }
  const axiosInstance = axios.create()
  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error?.response) {
        const originalRequest = error.config
        if (
          (error.response.status === 401 ||
            error.response.status === 403) &&
          (originalRequest.url.includes('sso/v1/retoken') ||
            originalRequest.url.includes('sso/v1/authorize'))
        ) {
          return Promise.reject(error)
        }

        if (
          (token === 'invalid' || userToken) &&
          error.response.status === 401
        ) {
          return (
            axiosInstance
              .post(
                `${apiConfig.baseAccessUrl}/sso/v1/retoken`,
                null,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${getCookie(
                      authConfig.refreshTokenName,
                    )}`,
                  },
                },
              )
              // eslint-disable-next-line consistent-return
              .then((res) => {
                if (res.status === 200) {
                  if (res?.data?.name === 'SUCCESS') {
                    const retoken = {
                      access_token: {
                        value: res?.data?.data?.access_token?.value,
                        expired_at:
                          res?.data?.data?.access_token?.expired_at,
                      },
                      chat_token: {
                        value: res?.data?.data?.chat_token?.value,
                        expired_at:
                          res?.data?.data?.chat_token?.expired_at,
                      },
                      refresh_token: {
                        value: res?.data?.data?.refresh_token?.value,
                        expired_at:
                          res?.data?.data?.refresh_token?.expired_at,
                      },
                    }
                    afterLogin(retoken, () => null)
                    originalRequest.headers.Authorization = `Bearer ${res?.data?.data?.access_token?.value}`
                    return axios(originalRequest)
                  }
                }
              })
              .catch((err) => {
                if (err.response.status !== 401) {
                  throw err
                }

                if (context?.req && context?.req.headers) {
                  // server side forced logout, adjust as needed
                  destroyCookie(
                    authConfig.accessTokenName,
                    '/',
                    context,
                  )

                  destroyCookie(
                    authConfig.refreshTokenName,
                    '/',
                    context,
                  )

                  redirect('/login', '', context)
                } else {
                  // client side forced logout, adjust as needed
                  logout(() => redirect('/login'))
                }
                throw err
              })
          )
        }

        if (
          !userToken &&
          (error.response.status === 401 ||
            error.response.status === 403) &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true

          return axiosInstance
            .get(`${apiConfig.baseAccessUrl}/sso/v1/authorize`, {
              data: null,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: apiConfig.baseApiKey,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                if (res?.data?.name === 'SUCCESS') {
                  const expires = new Date(
                    res.data.data.expired_at * 1000,
                  )
                  setCookie(
                    authConfig.grantTokenName,
                    res.data.data.guest_token,
                    '/',
                    expires,
                  )
                  // workaround direct inject to original request since there's problem with default axios header
                  originalRequest.headers.Authorization = `Bearer ${res.data.data.guest_token}`
                  return axios(originalRequest)
                }
              }
              return axios(originalRequest)
            })
        }
        return Promise.reject(error)
      }
      const newError = {
        response: {
          data: {
            name: 'client_side_error',
            message:
              'Mohon maaf terjadi kesalahan, silahkan coba beberapa saat lagi.',
          },
        },
        config: {
          ...config,
          data: JSON.stringify(config.data),
        },
      }

      return Promise.reject(newError)
    },
  )

  return axiosInstance
    .request(config)
    .then((res) => {
      return res
    })
    .catch((error) => {
      throw error
    })
}

export default fetchApi
