export default {
  getPermissionList: {
    event: { name: 'Permission', state: 'GetPermissionList' },
    dependency: {
      name: 'API Gateway',
      event: 'GetPermissionList',
      service: 'rll-gw-api',
    },
  },
}
