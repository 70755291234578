import { POSTHOG_API_KEY } from 'common/constants'
import { TFeatureFlags } from 'lib/feature-flags/interface'
import {
  config,
  FEATURE_FLAGS,
} from 'lib/feature-flags/providers/Posthog/config'
import initPosthog from 'lib/feature-flags/providers/Posthog/utils'
import validateBot from 'lib/validator/validate-bot'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useStoreState } from 'stores/hooks'

interface IPosthog {
  setFlags: Dispatch<SetStateAction<TFeatureFlags>>
}

interface IProvider {
  isReady: boolean
}

function usePosthog({ setFlags }: IPosthog): IProvider {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [isReady, setIsReady] = useState<boolean>(false)
  const profileData = useStoreState(
    (state) => state.profile.profileData,
  )

  const client = useMemo(
    () =>
      typeof window !== 'undefined' &&
      !validateBot() &&
      initPosthog(POSTHOG_API_KEY, {
        ...config,
        loaded() {
          setIsLoaded(true)
        },
      }),
    [],
  )

  const handleReady = useCallback(() => {
    if (!client) return

    const featureFlags = {}
    FEATURE_FLAGS.forEach((flag) => {
      featureFlags[flag] = client.isFeatureEnabled(flag, {
        send_event: false,
      })
    })
    setFlags((prevstate) => ({
      ...prevstate,
      ...featureFlags,
    }))
    setIsReady(true)
  }, [])

  useEffect(() => {
    if (!client || !isLoaded) return

    client.onFeatureFlags(handleReady)
  }, [isLoaded])

  useEffect(() => {
    if (!client || !isLoaded) return

    if (
      profileData.sso_id &&
      profileData.sso_id !== client.get_distinct_id()
    ) {
      client.identify(profileData?.sso_id || '')
      client.people.set({
        id: profileData?.sso_id,
        email: profileData.email,
        phone: profileData.handphone,
      })
    }
  }, [isLoaded, profileData])

  return {
    isReady,
  }
}

export default usePosthog
