const setLocalStorage = (
  key: string,
  value: string | number | object | Array<any>,
): void => {
  localStorage.setItem(key, JSON.stringify(value))
}

const getLocalStorage = (key: string): any => {
  const storageValue = localStorage.getItem(key)
  if (storageValue === null) return undefined
  return JSON.parse(storageValue)
}

const destroyLocalStorage = (key: string): void => {
  localStorage.removeItem(key)
}

export { setLocalStorage, destroyLocalStorage, getLocalStorage }
