import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import profilingEvent from 'services/Profile/events'
import IProfilingModel from 'services/Profile/stores/models/ProfilingModel/interface'

const ProfilingModel: IProfilingModel = {
  isLoading: false,
  isError: false,
  data: [],
  errorMessage: '',
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  error: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  getProfiling: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setIsLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        url: '/profiling/v1/profiles',
        method: 'GET',
      })
      sendLogHitEndpoint(
        profilingEvent.getProfiling.event,
        dependencyContextMapper(
          profilingEvent.getProfiling.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      actions.setData(response.data.data)
    } catch (err) {
      sendLogHitEndpoint(
        profilingEvent.getProfiling.event,
        dependencyContextMapper(
          profilingEvent.getProfiling.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      actions.error(err.message)
    } finally {
      actions.setIsLoading(false)
    }
  }),
}

export default ProfilingModel
