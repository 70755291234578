const eventMembership = {
  getUserMembership: {
    event: { name: 'Address', state: 'getUserMembership' },
    dependency: {
      name: 'API Gateway',
      event: 'getUserMembership',
      service: 'rll-gw-api',
    },
  },
}

export default eventMembership
