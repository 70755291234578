import '@sendbird/uikit-react/dist/index.css'

import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider'
import theme from 'common/theme'
import auth from 'config/auth'
import { getCookie } from 'lib/cookie'
import { useRouter } from 'next/router'
import { useStoreState } from 'stores/hooks'

export const RalaliColorSet = {
  '--sendbird-light-primary-100': theme.colors.white,
  '--sendbird-light-primary-300': theme.colors.orange,
  '--sendbird-light-primary-400': theme.colors.orange,
}

function SendbirdWrapper({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const { profileData } = useStoreState((state) => state.profile)

  const SENDBIRD_APP_ID = process.env.NEXT_PUBLIC_SB_APP_ID
  const sessionToken = getCookie(auth.chatTokenName)

  const router = useRouter()
  const { route } = router
  const whitelistedRoutes = ['/customer/chat/[tab]']
  const needSendbird = whitelistedRoutes.includes(route)

  if (needSendbird) {
    return (
      <SendbirdProvider
        appId={SENDBIRD_APP_ID}
        userId={profileData?.sso_id}
        nickname={profileData?.name}
        accessToken={sessionToken}
        colorSet={RalaliColorSet}
      >
        <>{children}</>
      </SendbirdProvider>
    )
  }
  return <>{children}</>
}

export default SendbirdWrapper
