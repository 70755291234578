import theme from 'common/theme'
import lazysizes from 'config/lazysizes'
import cdn from 'lib/cdn'
import { SyntheticEvent, useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledImage = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.radius};
  object-fit: ${(props) => props.objectFit};
  border: ${(props) => props.border};
  vertical-align: middle;
  :not([src]) {
    visibility: hidden;
  }
  border-top-left-radius: ${(props) => props.borderTopLeftRadius};
  border-top-right-radius: ${(props) => props.borderTopRightRadius};
  border-bottom-left-radius: ${(props) =>
    props.borderBottomLeftRadius};
  border-bottom-right-radius: ${(props) =>
    props.borderBottomRightRadius};
`

const WrapperErrorImage = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${theme.colors.grayLighter};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.radius};
  border-top-left-radius: ${(props) => props.borderTopLeftRadius};
  border-top-right-radius: ${(props) => props.borderTopRightRadius};
  border-bottom-left-radius: ${(props) =>
    props.borderBottomLeftRadius};
  border-bottom-right-radius: ${(props) =>
    props.borderBottomRightRadius};
`

const ErrorImage = styled.img`
  width: 50%;
  height: 50%;
`

interface ImageProps {
  src: string
  alt?: string
  width?: string
  height?: string
  radius?: string | number
  lazy?: boolean
  id?: string
  objectFit?: string
  onClick?: Function
  border?: string
  borderTopLeftRadius?: string
  borderTopRightRadius?: string
  borderBottomLeftRadius?: string
  borderBottomRightRadius?: string
  className?: string
}

function Image({
  src,
  alt = '',
  width = '100%',
  height = 'auto',
  radius = '0',
  lazy = false,
  id,
  objectFit = 'fill',
  onClick = () => null,
  border = 'none',
  borderTopLeftRadius = '',
  borderTopRightRadius = '',
  borderBottomLeftRadius = '',
  borderBottomRightRadius = '',
  className = '',
  ...otherProps
}: ImageProps): JSX.Element {
  const [isError, setIsError] = useState<boolean>(false)
  const handleImgError = (
    e: SyntheticEvent<HTMLImageElement, Event>,
  ) => {
    e.currentTarget.style.visibility = 'hidden'
  }

  let lazyClassName = lazysizes.lazyClass
  if (className) lazyClassName = `${className} ${lazyClassName}`

  useEffect(() => {
    setIsError(false)
  }, [src])
  if (isError) {
    return (
      <WrapperErrorImage
        width={width}
        height={height}
        radius={radius}
        borderTopLeftRadius={borderTopLeftRadius}
        borderTopRightRadius={borderTopRightRadius}
        borderBottomLeftRadius={borderBottomLeftRadius}
        borderBottomRightRadius={borderBottomRightRadius}
      >
        <ErrorImage
          id={id}
          src={cdn({ path: '/assets/img/default-img.svg' })}
          alt={alt}
          onError={handleImgError}
        />
      </WrapperErrorImage>
    )
  }

  if (lazy) {
    return (
      <StyledImage
        id={id}
        data-src={src}
        alt={alt}
        width={width}
        height={height}
        radius={radius}
        objectFit={objectFit}
        onClick={onClick}
        border={border}
        borderTopLeftRadius={borderTopLeftRadius}
        borderTopRightRadius={borderTopRightRadius}
        borderBottomLeftRadius={borderBottomLeftRadius}
        borderBottomRightRadius={borderBottomRightRadius}
        onError={() => setIsError(true)}
        className={lazyClassName}
        {...otherProps}
      />
    )
  }

  return (
    <StyledImage
      id={id}
      src={src}
      alt={alt}
      width={width}
      height={height}
      radius={radius}
      objectFit={objectFit}
      onClick={onClick}
      border={border}
      borderTopLeftRadius={borderTopLeftRadius}
      borderTopRightRadius={borderTopRightRadius}
      borderBottomLeftRadius={borderBottomLeftRadius}
      borderBottomRightRadius={borderBottomRightRadius}
      onError={() => setIsError(true)}
      className={className}
      {...otherProps}
    />
  )
}

export default Image
