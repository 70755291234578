import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import events from 'services/Seller/events'
import IOrderDetailModel from 'services/Seller/models/OrderDetailModel/interface'

const OrderDetailModel: IOrderDetailModel = {
  isLoading: false,
  isLoadingUpload: false,
  isError: false,
  data: null,
  errorMessage: '',
  isNotFound: false,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsLoadingUpload: action((state, payload) => {
    state.isLoadingUpload = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  error: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  resetError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
  setIsNotFound: action((state, payload) => {
    state.isNotFound = payload
  }),
  uploadFileOrders: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.resetError()
        actions.setIsLoadingUpload(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/orders/${payload.orderSerial}/files`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: payload.file,
        })
        sendLogHitEndpoint(
          events.uploadFileOrders.event,
          dependencyContextMapper(
            events.uploadFileOrders.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return response.data.data
      } catch (err) {
        actions.error(err?.response?.data?.message)
        sendLogHitEndpoint(
          events.uploadFileOrders.event,
          dependencyContextMapper(
            events.uploadFileOrders.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        return undefined
      } finally {
        actions.setIsLoadingUpload(false)
      }
    },
  ),
  getOrderDetail: thunk(async (actions, payload, { injections }) => {
    try {
      actions.resetError()
      actions.setIsLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        url: `/marketplace/v1/orders/${payload}?user_type=seller`,
        method: 'GET',
        data: payload,
      })

      actions.setData(objectToCamelCase(response.data.data))
      sendLogHitEndpoint(
        events.getOrderDetail.event,
        dependencyContextMapper(
          events.getOrderDetail.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (err) {
      const message = err?.response?.data?.message
      actions.error(message)
      actions.setIsNotFound(err?.response?.status === 400)
      Toast(message, {
        type: 'error',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        pauseOnHover: true,
        toastId: 'error-get-order-detail',
      })
      sendLogHitEndpoint(
        events.updateUserActionState.event,
        dependencyContextMapper(
          events.updateUserActionState.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    } finally {
      actions.setIsLoading(false)
    }
  }),
  updateOrderStatus: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.resetError()
        actions.setIsLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/orders/${payload.orderId}`,
          method: 'PATCH',
          data: {
            status: payload.orderStatus,
            update_order_by: {
              identifier: 'seller_id',
              params: [
                {
                  id: payload.sellerId,
                  remark: '',
                  shipping_number: payload.shippingNumber, // for personal courier
                  file_url: payload.fileUrl, // proof of delivery, for personal courier
                },
              ],
            },
          },
        })
        sendLogHitEndpoint(
          events.updateOrderStatus.event,
          dependencyContextMapper(
            events.updateOrderStatus.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (err) {
        actions.error(err?.response?.data?.message)
        Toast(err?.response?.data?.message, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'error-update-order-status',
        })
        sendLogHitEndpoint(
          events.updateOrderStatus.event,
          dependencyContextMapper(
            events.updateOrderStatus.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default OrderDetailModel
