import Overlay from 'common/components/Overlay'
import Portal from 'common/components/Portal'
import theme from 'common/theme'
import styled, { keyframes } from 'styled-components'

interface IModal {
  children?: string | JSX.Element | JSX.Element[]
  isOpen?: boolean
  width?: string
  height?: string
  padding?: string
  backgroundColor?: string
  borderRadius?: string
  position?: string
  onOverlayClick: () => void
  additionalStyle?: string
  responsive?: boolean
}

const setPosition = (position: string): string => {
  switch (position) {
    case 'top-center': {
      return `position: relative;`
    }
    case 'with-snackbar': {
      return `
        position: fixed;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
      `
    }
    case 'head-center': {
      return `
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
      `
    }
    default: {
      return `
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `
    }
  }
}

const fadeAnimation = keyframes`
  from {opacity: .4}
  to {opacity: 1}
`

const ModalContent = styled.div`
  max-width: ${(props) => props.width};
  margin: 0 auto;
  padding: ${(props) => props.padding};
  width: 100%;
  height: ${(props) => props.height};
  max-height: ${(props) => props.height};
  pointer-events: auto;
  background-color: ${(props) => props.backgroundColor};
  background-clip: padding-box;
  border-radius: ${(props) => props.borderRadius};
  outline: 0;
  animation-name: ${fadeAnimation};
  animation-duration: 400ms;
  ${({ position }) => setPosition(position)}
  ${({ additionalStyle }) => {
    return additionalStyle
  }}
  ${(props) =>
    props.responsive &&
    `
    @media (min-width: 320px) {
      margin: 16px;
      width: auto;
      position: relative;
      top: auto;
      left: auto;
      transform: none;
    }
    @media (min-width: 576px) {
      margin: 0 auto;
    }
    @media (min-width: 992px) {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `};
`

function Modal({
  children,
  isOpen = false,
  width = '500px',
  height = '25rem',
  padding = '20px',
  backgroundColor = theme.colors.white,
  borderRadius = '10px',
  position = 'center',
  onOverlayClick,
  additionalStyle,
  responsive = false,
}: IModal): JSX.Element {
  if (!isOpen) return null
  return (
    <Portal id="modal">
      <Overlay
        zIndex={`${theme.zIndex.modal}`}
        padding="50px 0"
        onOverlayClick={onOverlayClick}
        isActive={isOpen}
      >
        <ModalContent
          data-testid="modal-content-test"
          backgroundColor={backgroundColor}
          borderRadius={borderRadius}
          width={width}
          padding={padding}
          height={height}
          position={position}
          additionalStyle={additionalStyle}
          responsive={responsive}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </ModalContent>
      </Overlay>
    </Portal>
  )
}

export default Modal
