import { datadogLogs } from '@datadog/browser-logs'
// import DeviceDetector from 'device-detector-js'
import { scrubSensitiveData } from 'lib/transform/scrub-sensitive-data'
import { NextPageContext } from 'next'
import {
  browserName,
  browserVersion,
  deviceType,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect'
import store from 'stores/index'

function initLogger(): void {
  if (process.env.NEXT_PUBLIC_DD_ENABLE === 'true') {
    if (process.env.NEXT_IS_SERVER !== 'true') {
      // init client side logger
      datadogLogs.init({
        clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
        env: process.env.NEXT_PUBLIC_DD_ENV,
        site: process.env.NEXT_PUBLIC_DD_SITE,
        service: process.env.NEXT_PUBLIC_DD_SERVICE,
        forwardErrorsToLogs: false,
        sampleRate: Number(process.env.NEXT_PUBLIC_DD_SAMPLE_RATE),
        silentMultipleInit: true,
      })
      // add global context for client side
      datadogLogs.setLoggerGlobalContext({
        user_agent: {
          device_type: deviceType,
          browser_name: browserName,
          browser_version: browserVersion,
          mobile_vendor: mobileVendor,
          mobile_model: mobileModel,
          os_name: osName,
          os_version: osVersion,
        },
      })
    } else {
      // TO DO Implement proper server side logging
      // init server side logger
    }
  }
}

function sendLog(
  level: 'info' | 'error' | 'warn' | 'debug',
  message: string,
  eventContext: Object,
  dependencyContext?: Object,
  otherContext?: Object,
  nextPageContext?: NextPageContext,
): void {
  if (process.env.NEXT_PUBLIC_DD_ENABLE === 'true') {
    const profile = store.getState().profile.profileData
    // setup context payload
    const context = {
      event: {
        browser: browserName,
        email: profile.email,
        phone_number: profile.handphone,
        user_id: profile.id,
        ...eventContext,
        dependency: {
          ...dependencyContext,
        },
      },

      ...otherContext,
    }
    if (
      process.env.NEXT_IS_SERVER === 'true' &&
      nextPageContext?.req &&
      nextPageContext?.req.headers
    ) {
      // server side logging
      // const deviceDetector = new DeviceDetector()
      // const user_agent = deviceDetector.parse(
      //   nextPageContext.req.headers['user-agent'],
      // )
      // TO DO Implement working server side logging
    } else {
      // client side logging
      datadogLogs.logger.log(message, context, level)
    }
  }
}

function dependencyContextMapper(
  dependency: {
    name: string
    event: string
    service: string
  },
  request: Object,
  response: Object,
): Object {
  return {
    name: dependency.name,
    event: dependency.event,
    service: dependency.service,
    http: {
      request: {
        ...request,
      },
      response: {
        ...response,
      },
    },
  }
}

function requestMapper(request: any): Object {
  return {
    method: String(request?.method).toUpperCase(),
    url: String(request?.baseURL) + String(request?.url),
    headers: scrubSensitiveData(request?.headers),
    params: scrubSensitiveData(request?.params),
    body: scrubSensitiveData(
      request?.data === 'string' ? JSON.parse(request.data) : null,
    ),
  }
}

function responseMapper(response: any): Object {
  return {
    status_code: response?.status,
    headers: scrubSensitiveData(response?.headers),
    body: scrubSensitiveData(response?.data),
  }
}

function errorMapper(error: any): Object {
  if (error?.response?.status) {
    return {
      status_code: error.response.status,
      body: error.response.data,
      headers: scrubSensitiveData(error.response.headers),
    }
  }
  return {
    status_code: 0,
    body: {
      status: 'Client-Side Request Error',
      ...error?.response?.data,
    },
  }
}

function sendLogHitEndpoint(
  eventContext: Object,
  dependencyContext?: any,
  otherContext?: Object,
  nextPageContext?: NextPageContext,
): void {
  const request = dependencyContext?.http?.request
  const response = dependencyContext?.http?.response
  const statusCode = response?.status_code
  const endpoint = String(request?.url)
  let level
  let message
  switch (true) {
    case statusCode >= 500:
      level = 'error'
      message = `Error hit endpoint ${endpoint}`
      break
    case statusCode >= 400:
      level = 'warn'
      message = `Warning hit endpoint ${endpoint}`
      break
    case statusCode >= 200:
      level = 'info'
      message = `Success hit endpoint ${endpoint}`
      break
    default:
      // client side error
      level = 'error'
      message = `Error hit endpoint ${endpoint}`
  }

  sendLog(
    level,
    message,
    eventContext,
    dependencyContext,
    otherContext,
    nextPageContext,
  )
}

export {
  initLogger,
  sendLog,
  sendLogHitEndpoint,
  dependencyContextMapper,
  requestMapper,
  responseMapper,
  errorMapper,
}
