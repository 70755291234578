import {
  DEFAULT_LANG,
  LANG_COOKIE_NAME,
  SSO_CODE,
  WEB_DESKTOP_RALALI_URL,
} from 'common/constants'
import apiConfig from 'config/api'
import authConfig from 'config/auth'
import { action, thunk } from 'easy-peasy'
import afterLogin from 'lib/auth/after-login'
import { destroyCookie, getCookie, setCookie } from 'lib/cookie'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import { GAUniversalEvent } from 'lib/google-analytics'
import GA4 from 'lib/google-analytics-4'
import parseJWT from 'lib/transform/jwt-parser'
import {
  emailMasking,
  phoneNumberMasking,
} from 'lib/transform/masking'
import validationResponseHelper from 'lib/validation-response-handler'
import validateReferrerUrl from 'lib/validator/validate-referrer-url'
import { eventLogin, eventRegister } from 'services/Auth/event'
import IRegisterModel from 'services/Auth/stores/models/RegisterModel/interface'
import { parsePhoneFormat } from 'services/Auth/widgets/Login'
import IdProfiling from 'services/Questionnaire/constants'
import store from 'stores/index'

const { baseAccessUrl } = apiConfig

const RegisterModel: IRegisterModel = {
  errorMessage: '',
  step: 'register-form',
  fullName: '',
  username: '',
  password: '',
  referralCode: '',
  isExistingUser: false,
  setStep: action((state, payload) => {
    state.step = payload
  }),
  setFullName: action((state, payload) => {
    state.fullName = payload
  }),
  setUsername: action((state, payload) => {
    state.username = payload
  }),
  setPassword: action((state, payload) => {
    state.password = payload
  }),
  setIsExistingUser: action((state, payload) => {
    state.isExistingUser = payload
  }),
  setReferralCode: action((state, payload) => {
    state.referralCode = payload
    const currentDate = new Date()
    setCookie(
      'rll_referral_code',
      payload,
      '/',
      new Date(currentDate.getTime() + 24 * 60 * 60 * 1000),
    )
  }),
  getOtpChannel: thunk(async (actions, payload, { getState }) => {
    const isOtpExpired =
      new Date(store.getState().otp.otpTimer.replace(/-/g, '/')) <
      new Date()
    if (
      !isOtpExpired &&
      ((payload.isEmail &&
        payload.username === getState().username) ||
        (!payload.isEmail &&
          parsePhoneFormat(payload.username) ===
            parsePhoneFormat(getState().username)))
    ) {
      actions.setStep('otp-form')
      actions.error('')
    } else {
      if (payload.isEmail) {
        store.getActions().otp.setChannels({
          email: emailMasking(payload.username),
        })
      } else {
        store.getActions().otp.setChannels({
          sms: phoneNumberMasking(payload.username),
          whatsapp: phoneNumberMasking(payload.username),
        })
      }
      actions.setFullName(payload.fullName)
      actions.setUsername(payload.username)
      actions.setPassword(payload.password)
      actions.setStep('otp-channel')
      store.getActions().otp.errorChannel('')
    }
  }),
  postOtpLogin: thunk(
    async (actions, payload, { injections, getState }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          apiHost: baseAccessUrl,
          url: '/sso/v1/otp',
          method: 'POST',
          data: {
            sso_code: SSO_CODE,
            otp_channel:
              payload || store.getState().otp.selectedChannel,
            phone_number: parsePhoneFormat(
              getState().username,
              '+62',
            ),
          },
        })
        if (response?.status === 200) {
          store.getActions().otp.setSelectedChannel(payload)
          store
            .getActions()
            .otp.setOtpTimer(
              response?.data?.data?.expiry_time?.expiry_date,
            )
          actions.setStep('otp-form')
          actions.setIsExistingUser(true)
          store.getActions().otp.setIsLoadingChannel(false)
          sendLogHitEndpoint(
            {
              ...eventLogin.sendOTP.event,
              phone_number: parsePhoneFormat(
                getState().username,
                '+62',
              ),
            },
            dependencyContextMapper(
              eventLogin.sendOTP.dependency,
              requestMapper(response.config),
              responseMapper(response),
            ),
          )
        }
        return ''
      } catch (err) {
        const errorMessage = validationResponseHelper(err)
        sendLogHitEndpoint(
          {
            ...eventLogin.sendOTP.event,
            phone_number: parsePhoneFormat(
              getState().username,
              '+62',
            ),
          },
          dependencyContextMapper(
            eventLogin.sendOTP.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        return errorMessage
      }
    },
  ),
  postSendOtp: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!store.getState().otp.isLoadingChannel) {
        const otpChannel = payload
        store.getActions().otp.setIsLoadingChannel(true)
        let logEvent
        try {
          let data = {}
          if (otpChannel === 'email') {
            data = {
              otp_channel: otpChannel,
              validate_by: getState().username,
              password: getState().password,
              name: getState().fullName,
            }
            logEvent = {
              ...eventRegister.sendOTP.event,
              email: getState().username,
            }
          } else {
            data = {
              otp_channel: otpChannel,
              validate_by: parsePhoneFormat(
                getState().username,
                '+62',
              ),
              name: getState().fullName,
            }
            logEvent = {
              ...eventRegister.sendOTP.event,
              phone_number: parsePhoneFormat(
                getState().username,
                '+62',
              ),
            }
          }
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url: '/sso/v1/register',
            method: 'POST',
            data: {
              sso_code: SSO_CODE,
              ...data,
            },
            headers: {
              'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
            },
          })
          if (response?.status === 200) {
            store.getActions().otp.setSelectedChannel(otpChannel)
            store
              .getActions()
              .otp.setOtpTimer(
                response?.data?.data?.expiry_time?.expiry_date,
              )
            actions.setIsExistingUser(false)
            actions.setStep('otp-form')
            store.getActions().otp.setIsLoadingChannel(false)
            sendLogHitEndpoint(
              logEvent,
              dependencyContextMapper(
                eventRegister.sendOTP.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )
          }
        } catch (error) {
          if (
            error?.response?.data?.name &&
            error.response.data.name === 'ACCOUNT_EXIST'
          ) {
            if (otpChannel === 'email') {
              actions.setStep('existing-user')
              store.getActions().otp.setIsLoadingChannel(false)
            } else {
              actions.postOtpLogin(otpChannel).then((message) => {
                store.getActions().otp.errorChannel(message)
              })
            }
          } else {
            const errorMessage = validationResponseHelper(error)
            store.getActions().otp.errorChannel(errorMessage)
          }
          sendLogHitEndpoint(
            logEvent,
            dependencyContextMapper(
              eventRegister.sendOTP.dependency,
              requestMapper(error.config),
              errorMapper(error),
            ),
          )
        }
      }
    },
  ),
  postResendOtp: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!store.getState().otp.isLoadingOtp) {
        const otpChannel = store.getState().otp.selectedChannel
        store.getActions().otp.setIsLoadingOtp(true)
        let logEvent
        try {
          let data = {}
          if (otpChannel === 'email') {
            data = {
              otp_channel: otpChannel,
              validate_by: getState().username,
              password: getState().password,
              name: getState().fullName,
            }
            logEvent = {
              ...eventRegister.resendOTP.event,
              email: getState().username,
            }
          } else {
            data = {
              otp_channel: otpChannel,
              validate_by: parsePhoneFormat(
                getState().username,
                '+62',
              ),
              name: getState().fullName,
            }
            logEvent = {
              ...eventRegister.resendOTP.event,
              phone_number: parsePhoneFormat(
                getState().username,
                '+62',
              ),
            }
          }
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url: '/sso/v1/register',
            method: 'POST',
            data: {
              sso_code: SSO_CODE,
              ...data,
            },
            headers: {
              'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
            },
          })
          if (response?.status === 200) {
            store.getActions().otp.setSelectedChannel(otpChannel)
            store
              .getActions()
              .otp.setOtpTimer(
                response?.data?.data?.expiry_time?.expiry_date,
              )
            actions.setIsExistingUser(false)
            actions.setStep('otp-form')
            store.getActions().otp.setIsLoadingOtp(false)
            sendLogHitEndpoint(
              logEvent,
              dependencyContextMapper(
                eventRegister.resendOTP.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )
          }
        } catch (error) {
          if (
            error?.response?.data?.name &&
            error.response.data.name === 'ACCOUNT_EXIST'
          ) {
            if (otpChannel === 'email') {
              actions.setStep('existing-user')
              store.getActions().otp.setIsLoadingOtp(false)
            } else {
              actions.postOtpLogin(otpChannel).then((message) => {
                store.getActions().otp.errorOtp(message)
              })
            }
          } else {
            const errorMessage = validationResponseHelper(error)
            store.getActions().otp.errorOtp(errorMessage)
          }
          sendLogHitEndpoint(
            logEvent,
            dependencyContextMapper(
              eventRegister.resendOTP.dependency,
              requestMapper(error.config),
              errorMapper(error),
            ),
          )
        }
      }
    },
  ),
  postVerifyOtp: thunk(
    async (actions, payload, { injections, getState }) => {
      if (!store.getState().otp.isLoadingOtp) {
        store.getActions().otp.setIsLoadingOtp(true)
        let url = ''
        let data = {}
        let log
        if (!getState().isExistingUser) {
          url = '/sso/v1/verify-register'
          if (store.getState().otp.selectedChannel === 'email') {
            data = {
              otp: payload,
              validate_by: getState().username,
            }
            log = {
              event: {
                ...eventRegister.verifyRegister.event,
                email: getState().username,
              },
              dependency: eventRegister.verifyRegister.dependency,
            }
          } else {
            data = {
              otp: payload,
              validate_by: parsePhoneFormat(
                getState().username,
                '+62',
              ),
            }
            log = {
              event: {
                ...eventRegister.verifyRegister.event,
                phone_number: parsePhoneFormat(
                  getState().username,
                  '+62',
                ),
              },
              dependency: eventRegister.verifyRegister.dependency,
            }
          }
        } else {
          url = '/sso/v1/login'
          data = {
            grant_type: 'login_otp',
            phone_number: parsePhoneFormat(
              getState().username,
              '+62',
            ),
            otp: payload,
          }
          log = {
            event: {
              ...eventLogin.loginOTP.event,
              phone_number: parsePhoneFormat(
                getState().username,
                '+62',
              ),
            },
            dependency: eventLogin.loginOTP.dependency,
          }
        }
        try {
          const { apiClient } = injections
          const response = await apiClient({
            apiHost: baseAccessUrl,
            url,
            method: 'POST',
            data: {
              sso_code: SSO_CODE,
              ...data,
            },
            headers: {
              'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
            },
          })
          if (response?.status === 200) {
            sendLogHitEndpoint(
              log.event,
              dependencyContextMapper(
                log.dependency,
                requestMapper(response.config),
                responseMapper(response),
              ),
            )
            const ga = GA4()
            const accessToken =
              response.data?.data?.access_token?.value ?? ''
            const parsedToken = parseJWT(accessToken)

            if (store.getState().otp.selectedChannel === 'email') {
              GAUniversalEvent(
                'Login and Register on Home page',
                'Success register by email',
                'Success_register_by_email',
              )
              store.getActions().login.setLoginType('email')
              ga.trackEvent({
                eventCategory: 'User Session',
                eventName: 'sign_up',
                params: {
                  login_type: store.getState().login.loginType.value,
                  user_id: parsedToken.client_user_id,
                  sso_id: parsedToken.sub,
                },
              })
            } else {
              GAUniversalEvent(
                'Login and Register on Home page',
                'Success register phone number',
                'Success_register_by_phone_number',
              )
              store.getActions().login.setLoginType('phone number')
              ga.trackEvent({
                eventCategory: 'User Session',
                eventName: 'sign_up',
                params: {
                  login_type: store.getState().login.loginType.value,
                  user_id: parsedToken.client_user_id,
                  sso_id: parsedToken.sub,
                },
              })
            }
            await actions.postVoucherReferral(
              response.data.data.access_token.value,
            )
            await actions.successLogin(response)
            if (
              store.getState().questionnaire?.answerList?.length > 0
            ) {
              await store
                .getActions()
                .questionnaire.submitQuestionnaire(
                  IdProfiling.profilingAccountType,
                )
              await store
                .getActions()
                .questionnaire.resetQuestionnaire()
            }
          }
        } catch (error) {
          const errorMessage = validationResponseHelper(error)
          store.getActions().otp.errorOtp(errorMessage)
          sendLogHitEndpoint(
            log.event,
            dependencyContextMapper(
              log.dependency,
              requestMapper(error.config),
              errorMapper(error),
            ),
          )
        }
      }
    },
  ),
  postVoucherReferral: thunk(
    async (_, payload, { injections, getState }) => {
      setCookie(authConfig.accessTokenName, payload)
      try {
        const { apiClient } = injections
        await apiClient({
          url: '/voucher/v4/voucher/referral',
          method: 'POST',
          data: {
            referrer_code: getState().referralCode,
          },
          requireLogin: true,
        })
      } catch (err) {
        const errorMessage = validationResponseHelper(err)
        return errorMessage
      }
      return null
    },
  ),
  postUserReferral: thunk(async (_, payload, { injections }) => {
    try {
      const { apiClient } = injections
      const response = await apiClient({
        url: '/marketplace/v1/user-referrals',
        method: 'POST',
        data: {
          referral_code: payload,
        },
        requireLogin: true,
      })
      if (response.status === 200) {
        destroyCookie('rll_referral_code')
      }
      return true
    } catch (error) {
      if (
        error.response?.status >= 400 &&
        error.response?.status < 500
      ) {
        destroyCookie('rll_referral_code')
      }
      return error
    }
  }),
  successLogin: thunk(async (actions, payload) => {
    const token = {
      ...payload.data.data,
    }
    const searchParams = window.location.href.match(
      /ref*?=(.*?)($|&)/g,
    )
    const referrer =
      searchParams && searchParams[0].replace(/((ref=)|&)/g, '')
    let referrerUrl = `${WEB_DESKTOP_RALALI_URL}/marketplace`
    const referralCode = getCookie('rll_referral_code')

    if (
      validateReferrerUrl(referrer || document.referrer) &&
      window.location.pathname === '/login'
    ) {
      referrerUrl = decodeURIComponent(referrer || document.referrer)
    }
    setCookie(
      'isKeepSignIn',
      'true',
      '/',
      new Date(payload?.data?.data?.refresh_token?.expired_at * 1000),
    )
    afterLogin(token, async () => {
      if (referralCode) {
        await actions.postUserReferral(referralCode)
      }
      window.location.assign(referrerUrl)
    })
  }),
  error: action((state, payload) => {
    state.errorMessage = payload
  }),
}

export default RegisterModel
