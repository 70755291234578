type validationType = 'default' | 'alphaFirst'

const isEmail = (input: string, type?: validationType): boolean => {
  /* eslint-disable-next-line no-useless-escape */
  let regexEmail = /^[+\w\-.]+@([\w-]+\.)+[\w-]{2,10}$/
  if (type === 'alphaFirst') {
    regexEmail = /^\d*[a-zA-Z.][a-zA-Z0-9._-]*@([\w-]+\.)+[\w-]{2,10}$/
    return regexEmail.test(input)
  }

  return regexEmail.test(input)
}

export default isEmail
