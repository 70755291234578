import { ONBOARDING_STORE_GROUP_CODE } from 'common/constants'
import store from 'stores/index'

const refetchProgress = (code: string): void => {
  const progressData = store.getState().actionState.actionStates[
    ONBOARDING_STORE_GROUP_CODE
  ]

  const isCompleted =
    progressData?.find((step) => step.code === code)?.isCompleted ===
    'yes'

  if (!isCompleted) {
    store.getActions().actionState.getUserActionStates({
      groupCode: ONBOARDING_STORE_GROUP_CODE,
    })
  }
}

export default refetchProgress
