import {
  ASPIRE_CREDIT_CARD_ID,
  MIDTRANS_BCA_KLIKPAY_ID,
  MIDTRANS_CREDIT_CARD_ID,
} from 'common/constants'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventPayment from 'services/Payment/event'
import IPaymentMethodModel from 'services/Payment/models/PaymentMethodModel/interface'

const PaymentMethodModel: IPaymentMethodModel = {
  isLoading: true,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  isError: false,
  data: [],
  setData: action((state, payload) => {
    state.data = payload
  }),
  ovoPaymentMethod: null,
  fetchOVOPaymentMethod: action((state, payload) => {
    state.ovoPaymentMethod = payload
  }),
  walletPaymentMethod: null,
  ralaliPlusPaymentMethod: null,
  fetchWalletPaymentMethod: action((state, payload) => {
    state.walletPaymentMethod = payload
  }),
  fetchRalaliPlusPaymentMethod: action((state, payload) => {
    state.ralaliPlusPaymentMethod = payload
  }),
  midtransCreditCardMethod: null,
  fetchMidtransCreditCardMethod: action((state, payload) => {
    state.midtransCreditCardMethod = payload
  }),
  midtransBcaKlikPayMethod: null,
  fetchMidtransBcaKlikPayMethod: action((state, payload) => {
    state.midtransBcaKlikPayMethod = payload
  }),
  aspireCreditCardMethod: null,
  fetchAspireCreditCardMethod: action((state, payload) => {
    state.aspireCreditCardMethod = payload
  }),
  getPaymentMethod: thunk(
    async (actions, payload, { injections }) => {
      actions.setIsLoading(true)
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: '/payment/v1/payment-methods',
          method: 'GET',
          params: payload,
        })
        if (response.status === 200 && response?.data?.data?.length) {
          actions.setData(response.data.data)

          const walletIndex = response?.data?.data?.findIndex(
            (payment) => payment?.code === 'wallet',
          )
          if (walletIndex >= 0) {
            const paymentRalaliWallet = response?.data?.data?.[
              walletIndex
            ]?.payment_methods?.find(
              (data) => data?.name === 'R-Wallet',
            )
            actions.fetchWalletPaymentMethod(paymentRalaliWallet)
          }

          const ralaliPlusIndex = response?.data?.data?.findIndex(
            (payment) => payment?.code === 'customer_wallet',
          )
          if (ralaliPlusIndex >= 0) {
            const paymentRalaliPlus = response?.data?.data?.[
              ralaliPlusIndex
            ]?.payment_methods?.find(
              (data) => data?.name === 'Ralali Plus',
            )
            actions.fetchRalaliPlusPaymentMethod(paymentRalaliPlus)
          }

          const digitalWalletIndex = response?.data?.data?.findIndex(
            (data) => data?.code === 'digital_wallet',
          )
          if (digitalWalletIndex >= 0) {
            actions.fetchOVOPaymentMethod(
              response?.data?.data[
                digitalWalletIndex
              ]?.payment_methods?.find(
                (data) => data?.name === 'Ovo',
              ),
            )
          }

          const creditCardIndex = response?.data?.data?.findIndex(
            (data) => data?.code === 'credit_card',
          )
          if (creditCardIndex >= 0) {
            actions.fetchMidtransCreditCardMethod({
              ...response?.data?.data[
                creditCardIndex
              ]?.payment_methods?.find(
                (data) =>
                  data?.id === Number(MIDTRANS_CREDIT_CARD_ID),
              ),
              fee_percentage: '1.25',
            })

            actions.fetchAspireCreditCardMethod({
              ...response?.data?.data[
                creditCardIndex
              ]?.payment_methods?.find(
                (data) => data?.id === Number(ASPIRE_CREDIT_CARD_ID),
              ),
              fee_percentage: '3.5',
            })
          }

          const bcaKlikPayIndex = response?.data?.data?.findIndex(
            (data) => data?.code === 'instant_payment',
          )
          if (bcaKlikPayIndex >= 0) {
            actions.fetchMidtransBcaKlikPayMethod({
              ...response?.data?.data[
                bcaKlikPayIndex
              ]?.payment_methods?.find(
                (data) =>
                  data?.id === Number(MIDTRANS_BCA_KLIKPAY_ID),
              ),
              fee_percentage: '1',
            })
          }
        } else {
          actions.setData([])
        }

        sendLogHitEndpoint(
          eventPayment.getPaymentMethod.event,
          dependencyContextMapper(
            eventPayment.getPaymentMethod.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (err) {
        actions.error(true)

        sendLogHitEndpoint(
          eventPayment.getPaymentMethod.event,
          dependencyContextMapper(
            eventPayment.getPaymentMethod.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  error: action((state, payload) => {
    state.isError = payload
  }),
  selectedPaymentIdentityProvider: [],
  setSelectedPaymentIdentityProvider: action((state, payload) => {
    state.selectedPaymentIdentityProvider = payload
  }),
}

export default PaymentMethodModel
