import { DEFAULT_LANG, LANG_COOKIE_NAME } from 'common/constants'
import { action, thunk } from 'easy-peasy'
import isEmpty from 'lib/common/is-empty'
import { getCookie } from 'lib/cookie'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import eventRalaliPlus from 'services/RalaliPlus/events'
import IRalaliPlusApplicantHistoriesModel from 'services/RalaliPlus/models/RalaliPlusApplicantHistoriesModel/interface'

const RalaliPlusApplicantHistoriesModel: IRalaliPlusApplicantHistoriesModel = {
  applicantData: null,
  hasApplicant: false,
  isLoading: false,
  isError: false,
  errorStatus: null,
  alreadyFetchData: false,
  setAlreadyFetchData: action((state, payload) => {
    state.alreadyFetchData = payload
  }),
  setApplicantData: action((state, payload) => {
    state.applicantData = payload
  }),
  setHasApplicantData: action((state, payload) => {
    state.hasApplicant = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setErrorStatus: action((state, payload) => {
    state.errorStatus = payload
  }),
  error: action((state) => {
    state.isError = true
  }),
  reset: action((state) => {
    state.isError = false
    state.isLoading = false
    state.alreadyFetchData = false
  }),
  getApplicantData: thunk(
    async (actions, version, { injections }) => {
      try {
        actions.reset()
        actions.setIsLoading(true)

        const { apiClient } = injections
        const res = await apiClient({
          url: `/wallet/${version}/applicant/histories`,
          method: 'GET',
          headers: {
            'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
          },
        })

        if (res?.status === 200) {
          const applicantData = objectToCamelCase(res?.data?.data)
          actions.setApplicantData(objectToCamelCase(applicantData))
          if (version === 'v1' && applicantData?.submissionId) {
            actions.setHasApplicantData(true)
          }
          if (
            version === 'v2' &&
            !isEmpty(applicantData?.submissions)
          ) {
            actions.setHasApplicantData(true)
          }
        }
        sendLogHitEndpoint(
          eventRalaliPlus.getApplicantData.event,
          dependencyContextMapper(
            eventRalaliPlus.getApplicantData.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )
      } catch (error) {
        sendLogHitEndpoint(
          eventRalaliPlus.getApplicantData.event,
          dependencyContextMapper(
            eventRalaliPlus.getApplicantData.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
        actions.setErrorStatus(error?.response?.status)
        if (
          error?.response?.data?.name !== 'DATA_NOT_FOUND' &&
          error?.response?.status < 500
        ) {
          actions.setIsError(true)
        }
      } finally {
        actions.setAlreadyFetchData(true)
        actions.setIsLoading(false)
      }
    },
  ),
}

export default RalaliPlusApplicantHistoriesModel
