import EyeDisableIcon from 'assets/svg/eye/eye-disable.svg'
import EyeEnableIcon from 'assets/svg/eye/eye-enable.svg'
import Typography from 'common/components/Typography'
import theme from 'common/theme'
import { useEffect, useState } from 'react'
import styled from 'styled-components'

interface IconType {
  icon: JSX.Element | null
  width?: string
  position: string
}
interface TextInputType {
  autoComplete?: string
  showHelperTextWhenTouched?: boolean
  autoFocus?: boolean
  id?: string
  type?: any
  name?: string
  disabled?: boolean
  maxLength?: string
  minLength?: string
  placeholder?: string
  required?: boolean
  bordered?: boolean
  fontSize?: string
  fontWeight?: string
  block?: boolean
  width?: string
  height?: string
  margin?: string
  padding?: string
  backgroundColor?: string
  color?: string
  caretColor?: string
  helperText?: string
  error?: boolean
  value?: string | number
  onChange?: Function
  onFocus?: Function
  onBlur?: Function
  withIcon?: IconType
  isVisibilityToggleable?: boolean
  floatingLabel?: string
  hideHelperText?: boolean
  pattern?: string
  borderActiveColor?: string
  borderRadius?: string
  disableBackgroundColor?: string
  ellipsis?: boolean
  clickIconFunction?: Function
  helperTextColor?: string
}

const setBorder = (bordered, borderActiveColor, error) => {
  const borderColor = error
    ? theme.colors.red
    : theme.colors.grayLight
  switch (bordered) {
    case false:
      return `
        border: none;
        :focus {
          outline: none;
        }
      `
    default:
      return `
        border: 1px solid ${borderColor};
        :focus {
          outline: none;
          border: 1px solid ${borderActiveColor};
        }
      `
  }
}

const setPadding = (padding, withIcon) => {
  if (withIcon.icon) {
    switch (withIcon.position) {
      case 'left':
        return `padding: ${padding};
        padding-left: ${withIcon.width || '60px'};`
      default:
        return `padding: ${padding};
        padding-right: ${withIcon.width || '60px'};`
    }
  }
  return `padding: ${padding};`
}

const FloatLabel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:focus-within label {
    transform: translate(18px, 8px) scale(0.95);
  }
`

const Label = styled.label`
  opacity: 50%;
  pointer-events: none;
  position: absolute;
  transform: translate(18px, 18px) scale(1);
  transform-origin: top left;
  transition: all 0.2s ease-out;
  ${(props) =>
    props.isActive
      ? 'transform: translate(18px, 8px) scale(0.95)'
      : ''};
`

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: ${(props) => props.margin};
  ${(props) => (props.block ? 'width: 100%;' : '')}
`
const InputWrapper = styled.div`
  display: inline-flex;
  position: relative;
  align-items: center;
  span {
    position: absolute;
    width: ${(props) => props.iconWidth || '60px'};
    display: flex;
    justify-content: center;
  }
`
const HelperTextWrapper = styled.div`
  min-height: 24px;
`
const Input = styled.input.attrs((props) => ({
  id: props.id,
  type: props.type,
  disabled: props.disabled,
  maxlength: props.maxLength,
  minlength: props.minLength,
  placeholder: props.placeholder,
  required: props.required,
}))`
  ${(props) =>
    setBorder(props.bordered, props.borderActiveColor, props.error)}
  ${(props) => setPadding(props.padding, props.withIcon)}
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  caret-color: ${(props) => props.caretColor};
  border-radius: ${(props) => props.borderRadius};
  ${(props) => (props.ellipsis ? 'text-overflow: ellipsis;' : '')}
  box-sizing: border-box;
  ::placeholder {
    color: ${theme.colors.black50};
    font-weight: normal;
  }
  :disabled {
    background-color: ${(props) => props.disableBackgroundColor};
  }
`
const Icon = styled.span`
  ${(props) => (props.position === 'left' ? '' : 'right: 0')};
  ${(props) => (props.clickable ? 'cursor: pointer' : '')};
  display: inline-block;
`

const renderIcon = (
  withIcon: IconType,
  clickFunction,
): JSX.Element => {
  if (withIcon.icon) {
    return (
      <Icon
        onClick={clickFunction}
        position={withIcon.position}
        data-testid="text-input-icon"
      >
        {withIcon.icon}
      </Icon>
    )
  }
  return null
}
const renderVisibilityIcon = (
  isVisibilityToggleable,
  visibility,
  clickFunction,
): JSX.Element => {
  if (!isVisibilityToggleable) return null
  return (
    <Icon onClick={clickFunction} position="right" clickable>
      {visibility ? <EyeEnableIcon /> : <EyeDisableIcon />}
    </Icon>
  )
}

export default function TextInput({
  autoComplete = 'off',
  autoFocus = false,
  id,
  type = 'text',
  name = '',
  disabled,
  maxLength,
  minLength,
  placeholder,
  floatingLabel,
  required,
  bordered = true,
  fontSize = '13px',
  fontWeight = 'normal',
  block = false,
  width = '100%',
  height = 'auto',
  margin = '',
  padding = floatingLabel ? '28px 18px 8px' : '18px',
  backgroundColor = `${theme.colors.white}`,
  disableBackgroundColor = `${theme.colors.grayLighter}`,
  color = `${theme.colors.black}`,
  borderActiveColor = `${theme.colors.orange}`,
  caretColor = `${theme.colors.black}`,
  helperText = '',
  showHelperTextWhenTouched = false,
  error = false,
  value,
  onChange,
  onFocus,
  onBlur,
  withIcon = {
    icon: null,
    position: 'right',
  },
  isVisibilityToggleable = false,
  hideHelperText = false,
  pattern = '',
  borderRadius = `${theme.radius.lg}`,
  ellipsis = false,
  clickIconFunction = null,
  helperTextColor = 'black',
  ...props
}: TextInputType): JSX.Element {
  const [visibility, setVisibility] = useState(true)
  const [isActive, setIsActive] = useState(false)
  const [touched, setTouched] = useState(false)
  const [labelInput, setLabelInput] = useState('')

  const isTouched = showHelperTextWhenTouched ? touched : true

  useEffect(() => {
    if (floatingLabel) {
      if (value) {
        setIsActive(true)
      } else {
        setIsActive(false)
      }
    }
  }, [isActive, value, floatingLabel])

  const toggleVisibility = () => {
    setVisibility(!visibility)
  }

  const handleChange = (e) => {
    setTouched(true)
    if (onChange) onChange(e)
  }

  useEffect(() => setLabelInput(placeholder), [placeholder])

  const content = (
    <Wrapper margin={margin} block={block}>
      <InputWrapper iconWidth={withIcon.width}>
        {renderIcon(withIcon, clickIconFunction)}
        {renderVisibilityIcon(
          isVisibilityToggleable,
          visibility,
          toggleVisibility,
        )}
        <Input
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          data-testid="textinput-test"
          id={id}
          type={
            isVisibilityToggleable && visibility ? 'password' : type
          }
          name={name}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          placeholder={labelInput}
          required={required}
          bordered={bordered}
          fontSize={fontSize}
          fontWeight={fontWeight}
          width={width}
          height={height}
          padding={padding}
          backgroundColor={backgroundColor}
          color={color}
          caretColor={caretColor}
          value={value}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          error={touched && error}
          helperText={helperText}
          withIcon={withIcon}
          borderActiveColor={borderActiveColor}
          floatingLabel={floatingLabel}
          borderRadius={borderRadius}
          disableBackgroundColor={disableBackgroundColor}
          ellipsis={ellipsis}
          {...(pattern && { pattern })}
          {...props}
        />
      </InputWrapper>
      {!hideHelperText && isTouched && (
        <HelperTextWrapper>
          <Typography
            color={error ? 'red' : helperTextColor}
            fontSize="12px"
            lineHeight="24px"
          >
            {helperText}
          </Typography>
        </HelperTextWrapper>
      )}
    </Wrapper>
  )
  return (
    <>
      {floatingLabel ? (
        <FloatLabel>
          {content}
          <Label isActive={placeholder || isActive}>
            <Typography>{floatingLabel}</Typography>
          </Label>
        </FloatLabel>
      ) : (
        content
      )}
    </>
  )
}
