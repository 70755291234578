import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import sellerEvent from 'services/Seller/events'
import ITopLocationModel from 'services/Seller/models/ReportModel/TopLocationModel/interface'

const TopLocationModel: ITopLocationModel = {
  isLoading: false,
  isError: false,
  data: null,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  getTopLocationList: thunk(
    async (actions, payload, { injections }) => {
      actions.setIsLoading(true)
      actions.setIsError(false)
      try {
        const { apiClient } = injections
        const res = await apiClient({
          url: `/seller/v2/sales-report/top-location?start_date=${payload.startDate}&end_date=${payload.endDate}&page=${payload.page}&limit=5`,
          method: 'GET',
        })
        actions.setData({
          ...objectToCamelCase(res?.data),
          page: Number(res?.data?.page),
        })
        sendLogHitEndpoint(
          sellerEvent.getTopLocation.event,
          dependencyContextMapper(
            sellerEvent.getTopLocation.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )
      } catch (err) {
        actions.setIsError(true)
        sendLogHitEndpoint(
          sellerEvent.getTopLocation.event,
          dependencyContextMapper(
            sellerEvent.getTopLocation.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default TopLocationModel
