import authConfig from 'config/auth'
import { setCookie } from 'lib/cookie'
import store from 'stores/index'

interface IToken {
  value: string
  /* eslint-disable camelcase */
  expired_at: number
}

interface ITokens {
  access_token: IToken
  refresh_token: IToken
  chat_token: IToken
  /* eslint-enable camelcase */
}

/**
 * Handles after login steps
 *
 * @param tokens object containts access token and refresh token value and expiries time
 * @param cb callback function, usually used to redirect to referer or main page
 * @param path cookie path
 *
 * @notes if all services already migrate to apigateway please remove cookies rll_acct and rll_reft
 */
const afterLogin = (
  tokens: ITokens,
  cb: (params?: any) => void,
  path = '/',
): void => {
  setCookie(
    authConfig.accessTokenName,
    tokens.access_token.value,
    path,
    new Date(tokens.access_token.expired_at * 1000),
  )

  setCookie(
    authConfig.chatTokenName,
    tokens.chat_token.value,
    path,
    new Date(tokens.chat_token.expired_at * 1000),
  )

  if (store.getState().login.isKeepSignedIn) {
    setCookie(
      authConfig.refreshTokenName,
      tokens.refresh_token.value,
      path,
      new Date(tokens.refresh_token.expired_at * 1000),
    )
  }

  cb()
}

export default afterLogin
