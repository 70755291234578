import theme from 'common/theme'

type Name = 'xxl' | 'xl' | 'lg' | 'md' | 'sm'

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
// Reference https://github.com/twbs/bootstrap/blob/main/scss/mixins/_breakpoints.scss
//
//    >> breakpointMax('md')
//    767.98px
const breakpointMax = (name: Name): string => {
  return `${theme.gridBreakpoints[name] - 0.02}px`
}

// eslint-disable-next-line import/prefer-default-export
export { breakpointMax }
