export default {
  getRalaliPlusBalance: {
    event: {
      name: 'RalaliPlus',
      state: 'getRalaliPlusBalance',
    },
    dependency: {
      name: 'API Gateway',
      event: 'getRalaliPlusBalance',
      service: 'rll-gw-api',
    },
  },
  getApplicantData: {
    event: {
      name: 'Wallet',
      state: 'GetApplicantData',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetApplicantData',
      service: 'rll-gw-api',
    },
  },
}
