// import { parsePhoneFormat } from 'services/Auth/widgets/Login'
import Toast from 'common/components/Toast'
import { SSO_CODE } from 'common/constants'
import apiConfig from 'config/api'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import { eventOtp } from 'services/Auth/event'
import IOtpModel from 'services/Auth/stores/models/OtpModel/interface'

const { baseAccessUrl } = apiConfig

const OtpModel: IOtpModel = {
  channels: {},
  selectedChannel: '',
  ota: '',
  otaTimer: '',
  otpTimer: new Date().toString(),
  otpStep: '',
  otaInvalid: false,
  otaSubmitted: false,
  successVerify: false,
  requestId: '',
  isLoadingChannel: false,
  errorMessageChannel: '',
  isLoadingOtp: false,
  isLoadingRequestOtp: false,
  errorMessageOtp: '',
  havePin: false,
  setChannels: action((state, payload) => {
    state.channels = payload
  }),
  setSelectedChannel: action((state, payload) => {
    state.selectedChannel = payload
  }),
  setOtpTimer: action((state, payload) => {
    state.otpTimer = payload
  }),
  setOtpStep: action((state, payload) => {
    state.otpStep = payload
  }),
  setOtaInvalid: action((state, payload) => {
    state.otaInvalid = payload
  }),
  setOtaSubmitted: action((state, payload) => {
    state.otaSubmitted = payload
  }),
  setSuccessVerify: action((state, payload) => {
    state.successVerify = payload
  }),
  setIsLoadingChannel: action((state, payload) => {
    state.isLoadingChannel = payload
    state.errorMessageChannel = ''
  }),
  setIsLoadingOtp: action((state, payload) => {
    state.isLoadingOtp = payload
    state.errorMessageOtp = ''
  }),
  setRequestId: action((state, payload) => {
    state.requestId = payload
  }),
  setOta: action((state, payload) => {
    state.ota = payload
  }),
  setOtaTimer: action((state, payload) => {
    state.otaTimer = payload
  }),
  setHavePin: action((state, payload) => {
    state.havePin = payload
  }),
  setIsLoadingRequestOtp: action((state, payload) => {
    state.isLoadingRequestOtp = payload
  }),
  postSendOtp: thunk(
    async (actions, payload, { injections, getState }) => {
      actions.setIsLoadingChannel(true)
      try {
        const { apiClient } = injections
        const response = await apiClient({
          apiHost: baseAccessUrl,
          url: '/sso/v1/identity',
          method: 'POST',
          data: {
            type: payload?.type,
            new_identity: payload?.newIdentity,
          },
          headers: {
            'X-Ota': getState().ota,
          },
        })
        if (response.status === 200) {
          sendLogHitEndpoint(
            eventOtp.sendOTP.event,
            dependencyContextMapper(
              eventOtp.sendOTP.dependency,
              requestMapper(response.config),
              responseMapper(response),
            ),
          )

          actions.setChannels(
            payload?.type === 'change_email'
              ? {
                  email: payload?.newIdentity,
                }
              : { sms: payload?.newIdentity },
          )
          actions.setSelectedChannel(
            payload?.type === 'change_email' ? 'email' : 'sms',
          )
          actions.setOtaSubmitted(true)
          actions.setOtpTimer(
            response.data?.data?.expiry_time?.expiry_date,
          )
        }
        actions.setOta('')
        actions.setIsLoadingChannel(false)
      } catch (err) {
        sendLogHitEndpoint(
          eventOtp.sendOTP.event,
          dependencyContextMapper(
            eventOtp.sendOTP.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )

        actions.setIsLoadingChannel(false)
        actions.errorOtp(err.response?.data?.message)
        actions.setOtaInvalid(true)
      }
    },
  ),
  postResendOtp: thunk(
    async (actions, payload, { injections, getState }) => {
      actions.setIsLoadingChannel(true)
      try {
        const { apiClient } = injections
        const response = await apiClient({
          apiHost: baseAccessUrl,
          url: '/sso/v1/identity',
          method: 'POST',
          data: payload,
          headers: {
            'X-Ota': getState().ota,
          },
        })
        if (response.status === 200) {
          sendLogHitEndpoint(
            eventOtp.resendOTP.event,
            dependencyContextMapper(
              eventOtp.resendOTP.dependency,
              requestMapper(response.config),
              responseMapper(response),
            ),
          )

          actions.setSelectedChannel(response.data?.data?.channel)
          actions.setOtpTimer(
            response.data?.data?.expiry_time?.expiry_date,
          )
        }
        actions.setIsLoadingChannel(false)
      } catch (err) {
        sendLogHitEndpoint(
          eventOtp.resendOTP.event,
          dependencyContextMapper(
            eventOtp.resendOTP.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )

        actions.setIsLoadingChannel(false)
        actions.errorOtp(err.response?.data?.message)
      }
    },
  ),
  postVerifyOtp: thunk(async (actions, payload, { injections }) => {
    actions.setIsLoadingOtp(true)
    try {
      const { apiClient } = injections
      const response = await apiClient({
        apiHost: baseAccessUrl,
        url: '/sso/v1/verify-identity',
        method: 'POST',
        data: {
          new_identity: payload.newIdentity,
          otp: payload.otp,
        },
        requireLogin: true,
      })
      if (response.status === 200) {
        sendLogHitEndpoint(
          eventOtp.verifyOTP.event,
          dependencyContextMapper(
            eventOtp.verifyOTP.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )

        actions.setSuccessVerify(true)
      }
      actions.setIsLoadingOtp(false)
    } catch (err) {
      sendLogHitEndpoint(
        eventOtp.verifyOTP.event,
        dependencyContextMapper(
          eventOtp.verifyOTP.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )

      actions.setIsLoadingOtp(false)
      actions.errorOtp(err?.response?.data?.message)
    }
  }),
  postVerificationChannel: thunk(
    async (actions, payload, { injections }) => {
      const { apiClient } = injections
      const url = '/sso/v1/users/verification'
      actions.setIsLoadingChannel(true)
      try {
        const res = await apiClient({
          apiHost: baseAccessUrl,
          url,
          method: 'POST',
          data: {
            sso_code: SSO_CODE,
            type: payload.type,
            ...(payload.payment_method_codes?.length && {
              payment_method_codes: payload.payment_method_codes,
            }),
          },
        })

        sendLogHitEndpoint(
          eventOtp.getVerificationChannel.event,
          dependencyContextMapper(
            eventOtp.getVerificationChannel.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )

        actions.setRequestId(res?.data?.data?.request_id)
        actions.setChannels(res?.data?.data?.channels)
        actions.setOtpTimer(
          new Date(res?.data?.data?.expired_at * 1000).toString(),
        )
        actions.setOtpStep('otp-channel')
        actions.setHavePin('pin' in res?.data?.data?.channels)
      } catch (error) {
        sendLogHitEndpoint(
          eventOtp.getVerificationChannel.event,
          dependencyContextMapper(
            eventOtp.getVerificationChannel.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )

        actions.errorChannel(error?.response?.data?.message)
        Toast(error?.response?.data?.message, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'error-post-verification-channel',
        })
      } finally {
        actions.setIsLoadingChannel(false)
      }
    },
  ),
  postRequestOtpForOta: thunk(
    async (actions, payload, { injections, getState }) => {
      const { apiClient } = injections
      const url = '/sso/v1/users/verification'
      actions.setIsLoadingChannel(true)
      actions.setIsLoadingRequestOtp(true)
      try {
        const res = await apiClient({
          apiHost: baseAccessUrl,
          url,
          method: 'POST',
          data: {
            sso_code: SSO_CODE,
            type: 'request_verification_number',
            request_id: getState().requestId,
            channel: payload,
          },
        })

        sendLogHitEndpoint(
          eventOtp.postRequestOtpForOta.event,
          dependencyContextMapper(
            eventOtp.postRequestOtpForOta.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )

        actions.setSelectedChannel(payload)
        actions.setOtpStep('otp-form')
        actions.setIsLoadingChannel(false)
      } catch (error) {
        sendLogHitEndpoint(
          eventOtp.postRequestOtpForOta.event,
          dependencyContextMapper(
            eventOtp.postRequestOtpForOta.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )

        actions.errorChannel(error?.response?.data?.message)
        actions.setIsLoadingChannel(false)
        Toast(error?.response?.data?.message, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'error-post-request-otp-for-ota',
        })
      } finally {
        actions.setIsLoadingRequestOtp(false)
      }
    },
  ),
  postRequestOta: thunk(
    async (actions, payload, { injections, getState }) => {
      const { apiClient } = injections
      const url = '/sso/v1/users/verification'
      actions.setIsLoadingOtp(true)
      try {
        const res = await apiClient({
          apiHost: baseAccessUrl,
          url,
          method: 'POST',
          data: {
            sso_code: SSO_CODE,
            type: 'request_ota',
            request_id: getState().requestId,
            verification_number: payload.verificationNumber,
          },
        })

        sendLogHitEndpoint(
          eventOtp.postRequestOta.event,
          dependencyContextMapper(
            eventOtp.postRequestOta.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )

        actions.setOta(res?.data?.data?.ota)
        actions.setOtaTimer(
          new Date(res?.data?.data?.expired_at * 1000).toString(),
        )
        actions.setIsLoadingOtp(false)
        actions.setOtpStep(payload.nextStep)
        return res
      } catch (error) {
        sendLogHitEndpoint(
          eventOtp.postRequestOta.event,
          dependencyContextMapper(
            eventOtp.postRequestOta.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )

        actions.setIsLoadingOtp(false)
        actions.errorOtp(error.response?.data?.message)
        return error
      }
    },
  ),
  postNewIdentity: thunk(async (actions, payload, { injections }) => {
    actions.setIsLoadingChannel(true)

    try {
      const { apiClient } = injections

      const res = await apiClient({
        apiHost: baseAccessUrl,
        url: '/sso/v1/new-identity',
        method: 'POST',
        data: {
          type: payload.type,
          new_identity: payload.newIdentity,
        },
      })
      if (res.status === 200) {
        sendLogHitEndpoint(
          eventOtp.postNewIdentity.event,
          dependencyContextMapper(
            eventOtp.postNewIdentity.dependency,
            requestMapper(res.config),
            responseMapper(res),
          ),
        )

        actions.setChannels(
          payload.type === 'new_email'
            ? {
                email: res.data?.data?.to,
              }
            : { sms: res.data?.data?.to },
        )
        actions.setSelectedChannel(
          payload?.type === 'new_email' ? 'email' : 'sms',
        )
        actions.setOtpTimer(res.data?.data?.expiry_time?.expiry_date)
      }
      return true
    } catch (err) {
      actions.errorOtp(err.response?.data?.message)
      actions.setOtaInvalid(true)
      sendLogHitEndpoint(
        eventOtp.postNewIdentity.event,
        dependencyContextMapper(
          eventOtp.postNewIdentity.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      return false
    } finally {
      actions.setIsLoadingChannel(false)
    }
  }),
  errorChannel: action((state, payload) => {
    state.isLoadingChannel = false
    state.errorMessageChannel = payload
  }),
  errorOtp: action((state, payload) => {
    state.isLoadingOtp = false
    state.errorMessageOtp = payload
  }),
}

export default OtpModel
