interface IValidatePassword {
  isLengthValidate: boolean
  isRegexValidate: boolean
}

function validatePassword(
  value: string,
  min = 8,
  max = 50,
  // eslint-disable-next-line no-useless-escape
  regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
): IValidatePassword {
  return {
    isLengthValidate: value.length >= min && value.length <= max,
    isRegexValidate: regex.test(value),
  }
}

export default validatePassword
