import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import ArrayofObjectToCamelCase from 'lib/transform/array-to-camel-case'
import sellerEvent from 'services/Seller/events'
import IShippingModel from 'services/Seller/models/ShippingModel/interface'

const ShippingModel: IShippingModel = {
  isError: false,
  isLoading: true,
  data: [],
  setData: action((state, payload) => {
    state.data = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  getShippingMethod: thunk(
    async (actions, storeId, { injections }) => {
      try {
        actions.setIsError(false)
        actions.setIsLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/stores/${storeId}/shippings`,
        })
        actions.setData(
          ArrayofObjectToCamelCase(response?.data?.data?.items ?? []),
        )
        sendLogHitEndpoint(
          sellerEvent.getShippingMethod.event,
          dependencyContextMapper(
            sellerEvent.getShippingMethod.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (err) {
        actions.setIsError(true)
        Toast(err?.response?.data?.message ?? err?.message, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'get-shipping-method-error',
        })
        sendLogHitEndpoint(
          sellerEvent.getShippingMethod.event,
          dependencyContextMapper(
            sellerEvent.getShippingMethod.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  updateShippingMethod: thunk(
    async (
      actions,
      { storeId, shippingMethods, isShippingLocalDisabled },
      { injections },
    ) => {
      try {
        actions.setIsError(false)
        actions.setIsLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/stores/${storeId}/shippings`,
          method: 'PUT',
          data: {
            shipping_methods: shippingMethods,
            is_shipping_local_disabled: isShippingLocalDisabled,
          },
        })

        sendLogHitEndpoint(
          sellerEvent.updateShippingMethod.event,
          dependencyContextMapper(
            sellerEvent.updateShippingMethod.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return true
      } catch (err) {
        actions.setIsError(true)
        Toast(err?.response?.data?.message ?? err?.message, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'update-shipping-method-error',
        })
        sendLogHitEndpoint(
          sellerEvent.updateShippingMethod.event,
          dependencyContextMapper(
            sellerEvent.updateShippingMethod.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        return false
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default ShippingModel
