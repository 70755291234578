import Arrow from 'assets/svg/arrow/arrow_forward.svg'
import CancelIcon from 'assets/svg/cancel-login/cancel.svg'
import Alert from 'common/components/Alert'
import Flex from 'common/components/Flex'
import Skeleton from 'common/components/Skeleton'
import Typography from 'common/components/Typography'
import theme from 'common/theme'
import { breakpointMax } from 'common/theme/breakpoints'
import countdownFormatter from 'lib/countdown-formatter'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import EmailIcon from 'services/Auth/assets/svg/email/mail.svg'
import PinIllustration from 'services/Auth/assets/svg/pin/pin-illustration.svg'
import SmsIcon from 'services/Auth/assets/svg/sms/sms.svg'
import TrustedIllustration from 'services/Auth/assets/svg/trusted-illustration/trusted-illustration.svg'
import WhatsappIcon from 'services/Auth/assets/svg/whatsapp/whatsapp.svg'
// To Do move to helper formatter
import { convertChannelNameCase } from 'services/Auth/widgets/Login'
import { useStoreState } from 'stores/hooks'
import styled from 'styled-components'

const OtpChannelContainer = styled(Flex)`
  padding: 50px;
  ${(props) =>
    props.responsive &&
    `
    @media (max-width: 1199.98px) {
      padding: 24px;
    }
  `}
`

const HeaderSection = styled.div``

const IconTopRight = styled.span`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`
const IconTopLeft = styled.span`
  position: absolute;
  top: 30px;
  left: 30px;
  cursor: pointer;
`
const ArrowIcon = styled(Arrow)`
  path {
    fill: ${theme.colors.black};
    opacity: 0.5;
  }
  transform: scale(2) rotate(180deg);
  transform-origin: 25% 30%;
`
const ArrowRightIcon = styled(Arrow)`
  path {
    fill: ${theme.colors.orange};
  }
  transform: scale(2);
`
const ChannelContainer = styled(Flex)`
  position: relative;
  padding: 20px;
  box-shadow: 0 0 6px 0 rgba(134, 134, 134, 0.14);
  border-radius: 10px;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border-color: ${theme.colors.orange};
  }

  svg {
    flex-shrink: 0;
  }
`

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const ChannelContentIcon = styled(Flex)`
  position: absolute;
  right: 30px;
  top: 45%;
  display: none;
  @media (min-width: 480px) {
    display: initial;
  }
`

const ChannelName = styled(Typography)`
  word-break: break-word;
`

const ChannelSkeleton = styled(Skeleton)`
  ${(props) =>
    props.responsive &&
    `
    @media (max-width: ${breakpointMax('sm')}) {
      width: 100%;
    }
  `}
`

interface IChannelIcon {
  channel: string
}
interface IOtpChannel {
  handleClose: () => void
  postSendOtp: Function
  back?: Function
  type?: 'OTP' | 'OTA'
  otpTimer?: string
  handleTimeOut?: Function
  closeable?: boolean
  isLoadingChannel?: boolean
  responsive?: boolean
}

export const ChannelIcon = ({
  channel,
}: IChannelIcon): JSX.Element => {
  if (channel === 'sms') return <SmsIcon width="48px" />
  if (channel === 'whatsapp') return <WhatsappIcon width="48px" />
  if (channel === 'pin') return <PinIllustration width="48px" />
  return <EmailIcon />
}

function OtpChannel({
  handleClose,
  postSendOtp,
  back,
  type = 'OTP',
  otpTimer,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleTimeOut = () => {},
  closeable = true,
  isLoadingChannel = false,
  responsive = false,
}: IOtpChannel): JSX.Element {
  const { t } = useTranslation(['auth'])
  const [countdownValue, setCountdownValue] = useState<string>('')
  const { channels, errorMessageChannel } = useStoreState(
    (state) => state.otp,
  )

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdownValue(
        countdownFormatter(otpTimer, { hours: false }),
      )
    }, 1000)

    return () => clearInterval(timer)
  }, [otpTimer])

  useEffect(() => {
    if (!isLoadingChannel && countdownValue === '00:00') {
      handleTimeOut()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownValue])

  return (
    <OtpChannelContainer
      flexDirection="column"
      responsive={responsive}
      data-testid="otp-channel-test"
    >
      {isLoadingChannel && (
        <SkeletonContainer data-testid="channel-skeleton">
          <Skeleton
            width="105px"
            height="105px"
            marginBottom="31px"
          />
          <Skeleton width="67%" height="30px" marginBottom="13px" />

          <Skeleton width="82%" height="12px" marginBottom="8px" />
          <Skeleton width="82%" height="12px" marginBottom="20px" />

          {[0, 1, 2].map((i) => (
            <ChannelSkeleton
              key={i}
              width="480px"
              height="105px"
              marginBottom="10px"
              responsive={responsive}
            />
          ))}
        </SkeletonContainer>
      )}
      {!isLoadingChannel && (
        <HeaderSection>
          <Flex
            alignItems="center"
            justifyContent={back ? 'space-between' : 'flex-end'}
          >
            <>
              {back && (
                <IconTopLeft onClick={back}>
                  <ArrowIcon fill={theme.colors.black} />
                </IconTopLeft>
              )}
              {closeable && (
                <IconTopRight
                  data-testid="otp-channel-close"
                  onClick={handleClose}
                >
                  <CancelIcon />
                </IconTopRight>
              )}
            </>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            padding="10px"
          >
            <TrustedIllustration width="140px" heigh="120px" />
          </Flex>
          <Typography type="bh2" textAlign="center" marginTop="10px">
            {t('CHOOSE_METHOD')}
          </Typography>
          <Typography textAlign="center" marginTop="10px">
            {type === 'OTP' ? (
              t('DESC_METHOD')
            ) : (
              <Trans>
                {t('DESC_METHOD_COUNTDOWN', {
                  countdown: countdownValue,
                })}
              </Trans>
            )}
          </Typography>
          <Flex flexDirection="column">
            <>
              {errorMessageChannel && (
                <Alert
                  data-testid="otp-channel-error-message"
                  type="error"
                  margin="0 0 20px"
                >
                  {errorMessageChannel}
                </Alert>
              )}
              {Object.keys(channels).map((channel) => {
                return (
                  <ChannelContainer
                    key={`channel-${channel}`}
                    data-testid={`channel-${channel}`}
                    margin="20px 0 0"
                    alignItems="center"
                    onClick={() => postSendOtp(channel)}
                  >
                    <>
                      <ChannelIcon channel={channel} />
                      <div>
                        <Typography
                          marginLeft="21px"
                          type="bparagraph"
                        >
                          {t('SEND_VIA', {
                            channel: convertChannelNameCase(channel),
                          })}
                        </Typography>
                        {channels[channel]?.trim() && (
                          <ChannelName
                            marginLeft="21px"
                            marginTop="5px"
                            type="rparagraph"
                          >
                            {t('SEND_TO', {
                              channel: channels[channel],
                            })}
                          </ChannelName>
                        )}
                      </div>
                      <ChannelContentIcon justifyContent="flex-end">
                        <ArrowRightIcon />
                      </ChannelContentIcon>
                    </>
                  </ChannelContainer>
                )
              })}
            </>
          </Flex>
        </HeaderSection>
      )}
    </OtpChannelContainer>
  )
}

export default OtpChannel
