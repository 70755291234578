import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import camelToSnakeKeys from 'lib/transform/camel-to-snake-case'
import objectToCamelCase from 'lib/transform/object-to-camel-case'
import events from 'services/Seller/events'
import IOrderPickupModel from 'services/Seller/models/OrderPickupModel/interface'

const OrderPickupModel: IOrderPickupModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  data: {
    timeSlots: null,
  },
  error: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  resetError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  getTimeSlotPickup: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.resetError()
        actions.setIsLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/orders/${payload}/pickup/timeslots`,
          method: 'GET',
        })

        actions.setData(objectToCamelCase(response.data.data))
        sendLogHitEndpoint(
          events.getTimeSlotPickup.event,
          dependencyContextMapper(
            events.getTimeSlotPickup.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (err) {
        const message = err?.response?.data?.message
        actions.error(message)
        Toast(message, {
          type: 'error',
          theme: 'colored',
          position: 'top-center',
          toastId: 'error-get-timeslot-pickup',
        })
        sendLogHitEndpoint(
          events.getTimeSlotPickup.event,
          dependencyContextMapper(
            events.getTimeSlotPickup.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  postRequestPickup: thunk(
    async (actions, { orderSerial, ...payload }, { injections }) => {
      try {
        actions.resetError()
        actions.setIsLoading(true)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/orders/${orderSerial}/pickup`,
          method: 'POST',
          data: camelToSnakeKeys(payload),
        })
        sendLogHitEndpoint(
          events.postRequestPickup.event,
          dependencyContextMapper(
            events.postRequestPickup.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )

        return response.data.data
      } catch (err) {
        const message = err?.response?.data?.message
        actions.error(message)
        Toast(message, {
          type: 'error',
          theme: 'colored',
          position: 'top-center',
          toastId: 'error-post-request-pickup',
        })
        sendLogHitEndpoint(
          events.postRequestPickup.event,
          dependencyContextMapper(
            events.postRequestPickup.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )

        return null
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default OrderPickupModel
