import CancelIcon from 'assets/svg/cancel-login/cancel.svg'
import Button from 'common/components/Button'
import Flex from 'common/components/Flex'
import Modal from 'common/components/Modal'
import Typography from 'common/components/Typography'
import EmailIcon from 'services/Auth/assets/svg/otp-illustration/mail-illustration.svg'
import styled from 'styled-components'

const Icon = styled.span`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`

const StyledButton = styled(Button)`
  font-size: 16px;
`

interface IUserNotRegistered {
  isOpen?: boolean
  onClose: () => void
  onButtonClick: () => void
}

function UserNotRegistered({
  isOpen = false,
  onClose,
  onButtonClick,
}: IUserNotRegistered): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={onClose}
      width="560px"
      height="auto"
      padding="0"
      borderRadius="10px"
    >
      <>
        <Icon>
          <CancelIcon onClick={onClose} />
        </Icon>
        <Flex flexDirection="column" padding="50px">
          <>
            <Flex justifyContent="center">
              <EmailIcon width="140px" />
            </Flex>
            <Typography
              marginTop="15px"
              type="bh4"
              textAlign="center"
            >
              Email atau No. Handphone Belum Terdaftar
            </Typography>
            <Typography
              marginTop="15px"
              type="rh6"
              textAlign="center"
            >
              Gagal login, harap daftar terlebih dahulu di Ralali.com
              Silahkan daftar Akun di Ralali.com
            </Typography>
            <StyledButton
              fontWeight="bold"
              size="large"
              data-testid="register-button-test"
              onClick={onButtonClick}
              margin="50px 0 0"
              block
            >
              Daftar
            </StyledButton>
          </>
        </Flex>
      </>
    </Modal>
  )
}

export default UserNotRegistered
