import Toast from 'common/components/Toast'
import { DEFAULT_LANG, LANG_COOKIE_NAME } from 'common/constants'
import apiConfig from 'config/api'
import { action, thunk } from 'easy-peasy'
import { getCookie } from 'lib/cookie'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import { GAUniversalEvent } from 'lib/google-analytics'
import validationResponseHelper from 'lib/validation-response-handler'
import { eventLogin } from 'services/Auth/event'
import IGoogleLoginModel from 'services/Auth/stores/models/GoogleLoginModel/interface'
import store from 'stores/index'

const GoogleLoginModel: IGoogleLoginModel = {
  isLoading: false,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  postGoogleLogin: thunk(async (actions, payload, { injections }) => {
    actions.setIsLoading(true)
    try {
      const { apiClient } = injections
      const response = await apiClient({
        apiHost: apiConfig.baseAccessUrl,
        url: '/sso/v1/login',
        method: 'POST',
        data: payload,
        headers: {
          'X-Lang': getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
        },
      })
      GAUniversalEvent(
        'Login and Register',
        'Success login by google',
        'Success_login_by_google',
      )
      sendLogHitEndpoint(
        eventLogin.loginGoogle.event,
        dependencyContextMapper(
          eventLogin.loginGoogle.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )

      store.getActions().login.setLoginType('google')
      store.getActions().login.successLogin(response)
    } catch (error) {
      actions.setIsLoading(false)
      sendLogHitEndpoint(
        eventLogin.loginGoogle.event,
        dependencyContextMapper(
          eventLogin.loginGoogle.dependency,
          requestMapper(error.config),
          errorMapper(error),
        ),
      )
      const message = validationResponseHelper(
        error,
        'top-center',
        true,
        {
          toastClientSideError: false,
        },
      )
      Toast(message, {
        type: 'error',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        autoClose: 10000,
        pauseOnHover: true,
        toastId: 'error-google-login-model',
      })
    }
  }),
}

export default GoogleLoginModel
