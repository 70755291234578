export default {
  getPaymentMethod: {
    event: { name: 'Payment', state: 'GetPaymentMethod' },
    dependency: {
      name: 'API Gateway',
      event: 'GetPaymentMethod',
      service: 'rll-gw-api',
    },
  },
  getOrderDetail: {
    event: { name: 'Payment', state: 'GetOrderDetail' },
    dependency: {
      name: 'API Gateway',
      event: 'GetOrderDetail',
      service: 'rll-gw-api',
    },
  },
  pushToPay: {
    event: { name: 'Payment', state: 'PushToPay' },
    dependency: {
      name: 'API Gateway',
      event: 'PushToPay',
      service: 'rll-gw-api',
    },
  },
}
