import Toast from 'common/components/Toast'
import apiConfig from 'config/api'
import auth from 'config/auth'
import { action, persist, thunk } from 'easy-peasy'
import { getCookie } from 'lib/cookie'
import IProfileModel from 'services/Profile/stores/models/ProfileModel/interface'

const { baseAccessUrl } = apiConfig

const ProfileModel: IProfileModel = {
  isLoading: true,
  isError: false,
  profileData: persist(null),
  errorMessage: '',
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = payload
  }),
  setUserProfile: action((state, payload) => {
    state.profileData = payload
  }),
  getUserProfile: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoading(true)
      actions.setError(false)
      const { apiClient } = injections
      const responseAuth =
        getCookie(auth.accessTokenName) ||
        getCookie(auth.refreshTokenName)
          ? await apiClient({
              url: '/auth/v3/users/profile',
              method: 'GET',
            })
          : { data: { data: [] } }
      const responseAccess =
        getCookie(auth.accessTokenName) ||
        getCookie(auth.refreshTokenName)
          ? await apiClient({
              apiHost: baseAccessUrl,
              url: '/sso/v1/users/profile',
              method: 'GET',
            })
          : { data: { data: [] } }

      if (
        responseAuth?.status === 200 &&
        responseAccess?.status === 200
      ) {
        actions.setUserProfile({
          ...responseAuth.data?.data,
          sso_id: responseAccess.data?.data.id,
          name: responseAccess.data?.data.name,
          email: responseAccess.data?.data.email,
          handphone: responseAccess.data?.data.phone_number,
          is_handphone_verified:
            responseAccess.data?.data.phone_number_verified === 'yes',
          is_email_verified:
            responseAccess.data?.data.email_verified === 'yes',
          pin_status: responseAccess.data?.data.pin_status,
        })
      }
      actions.setLoading(false)
      return true
    } catch (err) {
      actions.error(err.message)
      return false
    }
  }),
  updateUserProfile: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setLoading(true)
        actions.setError(false)
        const { apiClient } = injections
        const response = await apiClient({
          apiHost: baseAccessUrl,
          url: '/sso/v1/users',
          method: 'PUT',
          data: {
            name: payload,
          },
        })
        if (response?.status === 200) {
          Toast('Perubahan informasi berhasil diubah', {
            type: 'success',
            theme: 'colored',
            hideProgressBar: false,
            autoClose: 5000,
            pauseOnHover: true,
            position: 'top-center',
            toastId: 'success-update-user-profile',
          })
        }
        return true
      } catch (err) {
        Toast(err?.response?.data?.errors?.name[0], {
          type: 'error',
          theme: 'colored',
          hideProgressBar: false,
          autoClose: 5000,
          pauseOnHover: true,
          position: 'top-center',
          toastId: 'success-update-user-profile',
        })
        return false
      } finally {
        actions.setLoading(false)
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default ProfileModel
