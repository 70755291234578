import { DEFAULT_LANG, LANG_COOKIE_NAME } from 'common/constants'
import i18n from 'i18next'
import { getCookie } from 'lib/cookie'
import en from 'locales/en'
import id from 'locales/id'
import { initReactI18next } from 'react-i18next'

const translations = {
  en,
  id,
}

i18n.use(initReactI18next).init({
  lng: getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG,
  fallbackLng: DEFAULT_LANG,
  resources: translations,
})

export default i18n
