import { DEFAULT_LANG, LANG_COOKIE_NAME } from 'common/constants'
import { action, thunk } from 'easy-peasy'
import { getCookie } from 'lib/cookie'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventBusinessSolution from 'services/BusinessSolution/event'
import IRalaliPavilionModel from 'services/BusinessSolution/models/RalaliPavilion/interface'

const RalaliPavilionModel: IRalaliPavilionModel = {
  isLoading: false,
  isError: false,
  data: [],
  setData: action((state, payload) => {
    state.data = payload
  }),
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  error: action((state) => {
    state.isError = true
    state.isLoading = false
  }),
  getData: thunk(async (actions, _, { injections }) => {
    try {
      actions.setLoading(true)

      const { apiClient } = injections
      const response = await apiClient({
        url: `/business-solution/v1/pavilions`,
        method: 'GET',
      })

      const lang = getCookie(LANG_COOKIE_NAME) || DEFAULT_LANG
      let imageFiledName = 'Banner Home'
      if (lang === 'en') imageFiledName += ' -EN'

      const items = response.data?.data?.records
        ?.filter((record) => !!record?.fields?.Sort)
        .sort(
          (a, b) => Number(a?.fields?.Sort) - Number(b?.fields?.Sort),
        )
        .map((record) => ({
          url: record?.fields?.url,
          imageUrl: record?.fields?.[imageFiledName],
        }))

      actions.setData(items)
      actions.setLoading(false)
      sendLogHitEndpoint(
        {
          ...eventBusinessSolution.getPavilions,
        },
        dependencyContextMapper(
          eventBusinessSolution.getPavilions.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (error) {
      sendLogHitEndpoint(
        {
          ...eventBusinessSolution.getPavilions,
        },
        dependencyContextMapper(
          eventBusinessSolution.getPavilions.dependency,
          requestMapper(error.config),
          errorMapper(error),
        ),
      )
      actions.error()
    }
  }),
}

export default RalaliPavilionModel
