/**
 * @param {string} referrerUrl
 * @return {boolean}
 */
function validateReferrerUrl(referrerUrl: string): boolean {
  try {
    const decodedURL = decodeURIComponent(referrerUrl)
    const referrer = new URL(decodedURL)

    return !!referrer.host.match(
      /^(.*\.ralali|ralali)\.(com|xyz|loc)/g,
    )
  } catch (_) {
    return false
  }
}

export default validateReferrerUrl
