import { action, thunk } from 'easy-peasy'
import ICategoryModel from 'services/Category/models/CategoryModel/inferface'

const CategoryModel: ICategoryModel = {
  isLoading: true,
  isError: false,
  categories: [],
  isAlreadyGetCategories: false,

  reset: action((state) => {
    state.isLoading = true
    state.categories = []
    state.isError = false
    state.isAlreadyGetCategories = false
  }),
  setCategory: action((state, payload) => {
    state.isLoading = false
    state.categories = payload
  }),
  setAlreadyGetCategories: action((state, payload) => {
    state.isAlreadyGetCategories = payload
  }),
  getCategory: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setAlreadyGetCategories(true)
      const { apiClient } = injections
      const { data } = await apiClient({
        url: '/core/api/v2/categories/menu',
        method: 'GET',
      })
      if (data?.result?.length) {
        actions.setCategory(data?.result)
      } else {
        actions.setCategory([])
      }
    } catch (err) {
      actions.error()
    }
  }),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
}

export default CategoryModel
