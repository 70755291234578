function validateBot(): boolean {
  const isBotUseragent = navigator.userAgent.includes('bot')
  const isBotParams =
    typeof window !== undefined &&
    window.location.href.includes('utm_source=propeller')
  const isBotReferrer = document.referrer.includes(
    'utm_source=propeller',
  )

  return isBotUseragent || isBotParams || isBotReferrer
}

export default validateBot
