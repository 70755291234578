import { action, thunk } from 'easy-peasy'
import IBusinessModel from 'services/Home/stores/models/BusinessModel/interface'

const BusinessModel: IBusinessModel = {
  isLoading: true,
  isError: false,
  errorMessage: '',
  businessCollection: [],
  setBusinessCollection: action((state, payload) => {
    state.isLoading = false
    state.businessCollection = payload
  }),
  getBusinessCollection: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: '/business-collection/v1/homepage',
          method: 'GET',
          params: {
            limit_item: 6,
          },
        })
        if (response?.data?.data?.items?.length) {
          actions.setBusinessCollection(response.data.data.items)
        }
      } catch (err) {
        actions.error(err.message)
      }
    },
  ),
  error: action((state, payload) => {
    state.isLoading = false
    state.isError = true
    state.errorMessage = payload
  }),
}

export default BusinessModel
