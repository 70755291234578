import authConfig from 'config/auth'
import { persist } from 'easy-peasy'
import { destroyCookie } from 'lib/cookie'
import store from 'stores/index'

/**
 * Handle after logout steps
 *
 * @param cb callback function, usually used to redirect to referer or login page
 */
const afterLogout = (cb: (params?: any) => void): Function => {
  destroyCookie(authConfig.accessTokenName, '/')
  destroyCookie(authConfig.grantTokenName, '/')
  destroyCookie(authConfig.chatTokenName, '/')
  destroyCookie(authConfig.refreshTokenName, '/')
  destroyCookie('isKeepSignIn', '/')
  destroyCookie('user_profiling', '/')
  localStorage.clear()
  sessionStorage.clear()
  store.getActions().profile.setUserProfile(persist(null))

  const timeOut = setTimeout(() => {
    cb()
  }, 1000)
  return () => {
    clearTimeout(timeOut)
  }
}
export default afterLogout
