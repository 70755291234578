export default {
  getShippingCostEstimation: {
    event: {
      name: 'Logistic',
      state: 'GetShippingCostEstimation',
    },
    dependency: {
      name: 'API Gateway',
      event: 'GetShippingCostEstimation',
      service: 'rll-gw-api',
    },
  },
  getLocationList: {
    event: {
      name: 'Logistic',
      state: 'GetLocationList',
    },
    dependency: {
      name: 'API Gateaway',
      event: 'GetLocationList',
      service: 'rll-gw-api',
    },
  },
}
