export default {
  createGroupChannel: {
    event: { name: 'CreateGroupChannel', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'createGroupChannel',
      service: 'rll-gw-api',
    },
  },
  getTotalUnreadMessages: {
    event: { name: 'GetTotalUnreadMessages', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'getTotalUnreadMessages',
      service: 'rll-gw-api',
    },
  },
}
