import ErrorIcon from 'assets/svg/alert/error.svg'
import InfoIcon from 'assets/svg/alert/info.svg'
import SuccessIcon from 'assets/svg/alert/success.svg'
import IOptions from 'common/components/Toast/interface'
import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

const WrapperIcon = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  min-width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

const WrapperText = styled.div`
  margin-left: 10px;
`

const toastHelper = (
  text = '',
  options: IOptions = {
    type: 'success',
    theme: 'colored',
    closeButton: true,
    hideProgressBar: false,
    position: 'top-center',
    autoClose: 5000,
    pauseOnHover: true,
    toastId: '',
    icon: null,
  },
): any => {
  const renderIcon = () => {
    switch (options.type) {
      case 'success':
        return (
          <WrapperIcon>
            <SuccessIcon />
          </WrapperIcon>
        )
      case 'error':
        return (
          <WrapperIcon>
            <ErrorIcon />
          </WrapperIcon>
        )
      default:
        return (
          <WrapperIcon>
            <InfoIcon />
          </WrapperIcon>
        )
    }
  }

  const newOptions: any = {
    ...options,
    icon: renderIcon,
  }

  switch (options.type) {
    case 'success':
      return toast.success(
        <WrapperText>{text}</WrapperText>,
        newOptions,
      )
    case 'error':
      return toast.error(
        <WrapperText>{text}</WrapperText>,
        newOptions,
      )
    default:
      return toast.info(<WrapperText>{text}</WrapperText>, newOptions)
  }
}

export default toastHelper
