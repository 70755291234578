import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import ArrayofObjectToCamelCase from 'lib/transform/array-to-camel-case'
import sellerEvent from 'services/Seller/events'
import IStoreCategoriesModel from 'services/Seller/models/StoreCategoriesModel/interface'

const StoreCategoriesModel: IStoreCategoriesModel = {
  isError: false,
  isLoading: false,
  data: [],
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  error: action((state) => {
    state.isError = true
  }),
  reset: action((state) => {
    state.isLoading = false
    state.isError = false
    state.data = []
  }),
  getStoreCategories: thunk(
    async (actions, storeId, { injections }) => {
      try {
        actions.reset()
        actions.setIsLoading(true)

        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/v1/stores/${storeId}/categories`,
        })

        actions.setData(
          ArrayofObjectToCamelCase(response?.data?.data?.items ?? []),
        )

        sendLogHitEndpoint(
          sellerEvent.getStoreCategories.event,
          dependencyContextMapper(
            sellerEvent.getStoreCategories.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
      } catch (err) {
        actions.error()
        Toast(err?.response?.data?.message ?? err?.message, {
          type: 'error',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'get-store-categories-error',
        })

        sendLogHitEndpoint(
          sellerEvent.getStoreCategories.event,
          dependencyContextMapper(
            sellerEvent.getStoreCategories.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default StoreCategoriesModel
