export interface IEventModel {
  eventName?: string
  eventCategory?: string
  params?: object
}

export interface IItems {
  itemId: number
  itemName: string
  coupon?: string
  currency?: string
  discount?: any
  itemBrand?: string
  itemCategory?: string
  itemCategory2?: string
  itemListId?: string
  itemListName?: string
  itemVariant?: string
  locationId?: string
  cartId?: number
  price: string
  quantity: number
}

interface IViewItems {
  list: IItems[]
}

interface IGA4 {
  trackEvent: (event: IEventModel) => void
  trackSelectItem: (item: IItems) => void
  trackOnLoadItem: (list: IViewItems) => void
}

function sendEvent({
  eventName,
  eventCategory,
  params,
}: IEventModel): void {
  const eventEntity =
    eventName && eventCategory
      ? {
          event: eventName,
          event_category: eventCategory,
        }
      : {}

  window.dataLayer.push({
    ...eventEntity,
    ...params,
  })
}

function GA4(): IGA4 {
  return {
    trackEvent: sendEvent,
    trackSelectItem: ({
      itemId,
      itemName,
      coupon = null,
      currency = 'IDR',
      discount = '',
      itemBrand = '',
      itemCategory = '',
      itemCategory2 = '',
      itemListId = '',
      itemListName = '',
      itemVariant = '',
      locationId = '',
      cartId = null,
      price,
      quantity,
    }: IItems) => {
      sendEvent({ params: { ecommerce: null } })
      sendEvent({
        eventName: 'select_item',
        eventCategory: 'Ecommerce',
        params: {
          ecommerce: {
            cart_id: cartId,
            items: [
              {
                item_id: itemId,
                item_name: itemName,
                coupon,
                currency,
                discount,
                item_brand: itemBrand,
                item_category: itemCategory,
                item_category2: itemCategory2,
                item_list_id: itemListId,
                item_list_name: itemListName,
                item_variant: itemVariant,
                location_id: locationId,
                price,
                quantity,
              },
            ],
          },
        },
      })
    },
    trackOnLoadItem: ({ list }: IViewItems) => {
      sendEvent({ params: { ecommerce: null } })
      sendEvent({
        eventName: 'view_item_list',
        eventCategory: 'Ecommerce',
        params: {
          ecommerce: {
            items: list?.map((key) => {
              return {
                item_id: key.itemId,
                item_name: key.itemName,
                coupon: key.coupon,
                currency: 'IDR',
                discount: key.discount,
                item_brand: '',
                item_category: '',
                item_category2: '',
                item_list_id: '',
                item_list_name: '',
                item_variant: '',
                location_id: '',
                price: key.price,
                quantity: key.quantity,
              }
            }),
          },
        },
      })
    },
  }
}
export default GA4
