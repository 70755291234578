export default {
  CHOOSE_METHOD: 'Pilih Metode Verifikasi',
  DESC_METHOD:
    'Pilih salah satu metode dibawah ini untuk mendapatkan kode verifikasi',
  DESC_METHOD_COUNTDOWN:
    'Pilih salah satu metode dibawah ini dalam waktu <strong>{{countdown}}</strong> untuk mendapatkan kode verifikasi',
  SEND_VIA: 'Melalui {{channel}}',
  SEND_TO: 'ke {{channel}}',
  PLEASE_WAIT:
    'Mohon tunggu dalam waktu <strong>{{countdown}}</strong>',
  ANOTHER_METHOD: 'Gunakan Metode Verifikasi Lain',
  VERIFICATION: 'Verifikasi',
  ENTER_PIN: 'Masukkan PIN',
  ENTER_CODE: 'Masukkan Kode Verifikasi',
  ENTER_6_PIN: 'Silahkan masukkan 6 PIN Anda.',
  SUCCESS_SEND_VIA:
    'Kode verifikasi berhasil dikirim melalui <strong>{{channel}}</strong> ke <strong>{{selectedChannel}}</strong>.',
  RESEND: 'Kirim Ulang',
  DESC_ENTER_PIN: 'Silahkan input pin dalam waktu {{countdown}}',
  DESC_ENTER_CODE:
    'Silahkan input kode verifikasi dalam waktu {{countdown}}',
  ACCOUNT_HAS_BEEN_REGISTER: 'Akun Anda Telah Terdaftar',
  DESC_PLEASE_LOGIN:
    'Akun Anda telah terdaftar di sistem kami. Harap masuk untuk menggunakan akun Anda',
  SIGNUP_RALALI: 'Daftar di Ralali',
  ALREADY_SIGNUP: 'Sudah terdaftar?',
  SIGNIN: 'Masuk',
  SIGNUP: 'Daftar',
  FULLNAME: 'Nama Lengkap',
  EXAMPLE_NAME: 'Cth : John Doe',
  INVALID_NAME: 'Format Nama salah',
  EMAIL_PHONE: 'Email atau Nomor Ponsel',
  EXAMPLE_EMAIL_PHONE: 'Cth : johndoe@gmail.com atau 081234567890',
  INVALID_EMAIL_PHONE: 'Format Email atau Nomor Ponsel salah',
  PASSWORD: 'Kata Sandi',
  REPEAT_PASSWORD: 'Ulangi Kata Sandi',
  INVALID_PASSWORD:
    'Min. 8 - 50 karakter terdiri dari huruf kecil besar, angka, dan simbol',
  INVALID_CONFIRM_PASSWORD: 'Kolom ulangi kata sandi tidak sesuai',
  SIGNUP_AGGREE: 'Dengan mendaftar, saya menyetujui',
  AND: 'serta',
  TERMS_CONDITION: 'Syarat dan Ketentuan',
  PRIVACY_POLICY: 'Kebijakan Privasi',
  SIGNIN_RALALI: 'Masuk Akun Ralali',
  PLACEHOLDER_SIGNIN_PASSWORD: 'Min 6 huruf atau karakter',
  INVALID_SIGNIN_PASSWORD: 'Kata sandi kurang dari 6 karakter',
  REMIND_ME: 'Ingatkan saya',
  FORGOT_PASSWORD: 'Lupa kata sandi?',
  DONT_HAVE_ACCOUNT: 'Tidak punya akun ?',
  REDIRECT_SELLERPANEL:
    'Anda akan diarahkan ke halaman sellerpanel setelah login',
  RESET_PASSWORD: 'Atur Ulang Kata Sandi',
  RESET_PASSWORD_DESC:
    'Masukkan email yang terdaftar. Kami akan mengirimkan kode verifikasi untuk mengatur ulang kata sandi.',
  EXAMPLE_EMAIL: 'Cth : johndoe@gmail.com',
  LOGIN_WITH_PHONE_DESC: 'Masuk langsung dengan nomor ponsel?',
  RETRY_FORGOT_PASSWORD: 'Ulangi Lupa Kata Sandi',
  TIMEOUT: 'Waktu Habis',
  RETRY_FORGOT_PASSWORD_DESC:
    'Mohon maaf, waktu telah habis. Silahkan coba kembali.',
  PASSWORD_HAS_CHANGED: 'Kata Sandi Sudah Berubah',
  PASSWORD_HAS_CHANGED_DESC:
    'Pakai kata sandimu yang yang baru untuk masuk lagi ke Ralali. Jangan bagikan kata sandi ke siapapun.',
  NEW_PASSWORD: 'Kata Sandi Baru',
  CREATE_NEW_PASSWORD_DESC: 'Buat kata sandi baru dalam waktu ',
}
