export default {
  postCreateOrder: {
    event: { name: 'Order', state: 'CreateOrder' },
    dependency: {
      name: 'API Gateway',
      event: 'CreateOrder',
      service: 'rll-gw-api',
    },
  },
  getOrderList: {
    event: { name: 'getOrderList', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'getOrderList',
      service: 'rll-gw-api',
    },
  },
  getShippingTracks: {
    event: { name: 'getShippingTracks', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'getShippingTracks',
      service: 'rll-gw-api',
    },
  },
}
