import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import events from 'services/Seller/events'
import IDownloadOrderModel from 'services/Seller/models/DownloadOrderModel/interface'

const DownloadOrderModel: IDownloadOrderModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  error: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  resetError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
  getDeliveryOrder: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.resetError()

        const { apiClient } = injections
        const deliveryOrderRes = await apiClient({
          url: `/marketplace/v1/orders/${payload}/delivery-order`,
          method: 'GET',
        })

        const deliveryOrderUrl = deliveryOrderRes?.data?.data?.url

        const res = await apiClient({
          url: deliveryOrderUrl,
          method: 'GET',
          requireLogin: false,
          responseType: 'blob',
          useAuthorization: false,
        })

        sendLogHitEndpoint(
          events.getDeliveryOrder.event,
          dependencyContextMapper(
            events.getDeliveryOrder.dependency,
            requestMapper(deliveryOrderRes.config),
            responseMapper(deliveryOrderRes),
          ),
        )

        return res.data
      } catch (err) {
        const message = err?.response?.data?.message ?? err?.message
        actions.error(message)

        Toast(message, {
          type: 'error',
          toastId: 'error-download-delivery-order',
          theme: 'colored',
          position: 'top-center',
        })

        sendLogHitEndpoint(
          events.getDeliveryOrder.event,
          dependencyContextMapper(
            events.getDeliveryOrder.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )

        return null
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  getOrderInvoice: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setIsLoading(true)
      actions.resetError()

      const { apiClient } = injections
      const orderInvoiceRes = await apiClient({
        url: `/marketplace/v1/orders/${payload}/invoices`,
        method: 'GET',
      })

      const orderInvoiceUrl = orderInvoiceRes?.data?.data?.url

      const res = await apiClient({
        url: orderInvoiceUrl,
        method: 'GET',
        requireLogin: false,
        responseType: 'blob',
        useAuthorization: false,
      })

      sendLogHitEndpoint(
        events.getOrderInvoice.event,
        dependencyContextMapper(
          events.getOrderInvoice.dependency,
          requestMapper(orderInvoiceRes.config),
          responseMapper(orderInvoiceRes),
        ),
      )

      return res.data
    } catch (err) {
      const message = err?.response?.data?.message ?? err?.message
      actions.error(message)

      Toast(message, {
        type: 'error',
        theme: 'colored',
        position: 'top-center',
        toastId: 'error-download-order-invoice',
      })

      sendLogHitEndpoint(
        events.getOrderInvoice.event,
        dependencyContextMapper(
          events.getOrderInvoice.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )

      return null
    } finally {
      actions.setIsLoading(false)
    }
  }),
  downloadShippingLabel: thunk(
    async (actions, payload, { injections }) => {
      try {
        actions.setIsLoading(true)
        actions.resetError()

        const { apiClient } = injections
        const shippingLabelRes = await apiClient({
          url: `/marketplace/v1/orders/${payload}/shipping-labels`,
          method: 'GET',
        })

        const shippingLabelUrl = shippingLabelRes?.data?.data?.url

        const res = await apiClient({
          url: shippingLabelUrl,
          method: 'GET',
          requireLogin: false,
          responseType: 'blob',
          useAuthorization: false,
        })

        sendLogHitEndpoint(
          events.downloadShippingLabel.event,
          dependencyContextMapper(
            events.downloadShippingLabel.dependency,
            requestMapper(shippingLabelRes.config),
            responseMapper(shippingLabelRes),
          ),
        )
        return res.data
      } catch (err) {
        const message = err?.response?.data?.message ?? err?.message
        actions.error(message)

        Toast(message, {
          type: 'error',
          theme: 'colored',
          position: 'top-center',
          toastId: 'error-download-shipping-label',
        })
        sendLogHitEndpoint(
          events.downloadShippingLabel.event,
          dependencyContextMapper(
            events.downloadShippingLabel.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )

        return null
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default DownloadOrderModel
