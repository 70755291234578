import type { PropertiesHyphen } from 'csstype'

type TStringNumber = string | number

interface SetValueParams {
  value: TStringNumber
  defaultValue: TStringNumber
  unit?: string
}

const setValue = ({
  value,
  defaultValue,
  unit = '',
}: SetValueParams) => {
  switch (typeof value) {
    case 'number':
      return `${value}${unit}`
    case 'string':
      return value
    default:
      return defaultValue
  }
}

const setCSS = (
  attribute: keyof PropertiesHyphen,
  value: SetValueParams,
): string => {
  return `${attribute}: ${setValue(value)};`
}

export default setCSS
