import { WEB_DESKTOP_RALALI_URL } from 'common/constants'
import apiConfig from 'config/api'
import { action, thunk } from 'easy-peasy'
import afterLogout from 'lib/auth/after-logout'
import redirect from 'lib/common/redirect'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import eventTracking from 'lib/event-tracking'
import GA4 from 'lib/google-analytics-4'
import rudderstack from 'lib/rudderstack/tracker'
import posthog from 'posthog-js'
import { eventLogout } from 'services/Auth/event'
import ILogoutModel from 'services/Auth/stores/models/LogoutModel/interface'
import store from 'stores/index'

const { baseAccessUrl } = apiConfig

const LogoutModel: ILogoutModel = {
  isLoading: false,
  isError: false,
  setLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  postLogout: thunk(async (actions, payload, { injections }) => {
    try {
      const tracker = eventTracking(rudderstack())
      actions.setLoading(true)
      const { apiClient } = injections
      const response = await apiClient({
        apiHost: baseAccessUrl,
        url: '/sso/v1/blacklist',
        method: 'POST',
      })
      if (response.status === 200) {
        const ga = GA4()
        ga.trackEvent({
          eventName: 'logout',
          eventCategory: 'User Session',
          params: {
            user_id: store.getState().profile.profileData.id,
            login_type: store.getState().login.loginType.value,
            sso_id: store.getState().profile.profileData.sso_id,
          },
        })

        sendLogHitEndpoint(
          eventLogout.logout.event,
          dependencyContextMapper(
            eventLogout.logout.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )

        store.getActions().login.setLoginType(null)
        posthog.reset()
        tracker.identify('', {})
        afterLogout(() =>
          redirect(`${WEB_DESKTOP_RALALI_URL}/logout`),
        )
      }
    } catch (error) {
      sendLogHitEndpoint(
        eventLogout.logout.event,
        dependencyContextMapper(
          eventLogout.logout.dependency,
          requestMapper(error.config),
          errorMapper(error),
        ),
      )

      actions.error()
    }
  }),
  error: action((state) => {
    state.isLoading = false
    state.isError = true
  }),
}

export default LogoutModel
