import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import ArrayofObjectToCamelCase from 'lib/transform/array-to-camel-case'
import sellerEvent from 'services/Seller/events'
import IStoreBrandsModel from 'services/Seller/models/StoreBrandsModel/interface'

const StoreBrandModel: IStoreBrandsModel = {
  isError: false,
  isLoading: false,
  data: [],
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  error: action((state) => {
    state.isError = true
  }),
  reset: action((state) => {
    state.isLoading = false
    state.isError = false
    state.data = []
  }),
  getStoreBrands: thunk(async (actions, storeId, { injections }) => {
    try {
      actions.reset()
      actions.setIsLoading(true)

      const { apiClient } = injections
      const response = await apiClient({
        url: `/marketplace/v1/stores/${storeId}/brands`,
      })

      actions.setData(
        ArrayofObjectToCamelCase(response?.data?.data?.items ?? []),
      )

      sendLogHitEndpoint(
        sellerEvent.getStoreBrands.event,
        dependencyContextMapper(
          sellerEvent.getStoreBrands.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
    } catch (err) {
      actions.error()
      Toast(err?.response?.data?.message ?? err?.message, {
        type: 'error',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        pauseOnHover: true,
        toastId: 'get-store-brands-error',
      })

      sendLogHitEndpoint(
        sellerEvent.getStoreBrands.event,
        dependencyContextMapper(
          sellerEvent.getStoreBrands.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    } finally {
      actions.setIsLoading(false)
    }
  }),
}

export default StoreBrandModel
