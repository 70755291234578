import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import validationResponseHelper from 'lib/validation-response-handler'
import { eventAddressForm } from 'services/User/event'
import IAddressFormModel from 'services/User/stores/models/AddressFormModel/interface'

const AddressFormModel: IAddressFormModel = {
  isLoadingPostAddress: false,
  isLoadingDistrict: false,
  isLoadingPostalCode: false,
  isErrorPostalCode: false,
  isErrorDistrict: false,
  resetForm: false,
  errMessage: '',
  district: [],
  postalCode: [],
  setLoadingPostAddress: action((state, payload) => {
    state.isLoadingPostAddress = payload
  }),
  setLoadingDistrict: action((state, payload) => {
    state.isLoadingDistrict = payload
  }),
  setLoadingPostalCode: action((state, payload) => {
    state.isLoadingPostalCode = payload
  }),
  setResetForm: action((state, payload) => {
    state.resetForm = payload
  }),
  setDistrict: action((state, payload) => {
    if (payload.responseData) {
      const data = payload.responseData.map((item) => {
        return {
          value: item.district_id,
          label: item.keterangan,
        }
      })
      if (payload.findData) {
        if (
          data.find((item) => item.value === payload.findData.value)
        ) {
          state.district = data
        } else {
          state.district = [...data, payload.findData]
        }
      } else {
        state.district = data
      }
    } else {
      state.district = null
    }
  }),
  setPostalCode: action((state, payload) => {
    state.postalCode = payload
  }),
  getDistrict: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoadingDistrict(true)
      actions.setErrorDistrict(false)
      const { apiClient } = injections
      const response = await apiClient({
        url: '/core/api/mobile/v1/location/address',
        method: 'GET',
        params: {
          like: payload.like,
          limit: 30,
        },
      })
      sendLogHitEndpoint(
        eventAddressForm.getDistrictAddress.event,
        dependencyContextMapper(
          eventAddressForm.getDistrictAddress.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      if (response.data.result) {
        if (payload.findData !== undefined) {
          actions.setDistrict({
            responseData: response.data.result,
            findData: payload.findData,
          })
        } else {
          actions.setDistrict({
            responseData: response.data.result,
            findData: null,
          })
        }
      }
      actions.setLoadingDistrict(false)
    } catch (err) {
      actions.setErrorDistrict(true)
      actions.error(err.response.status)
      sendLogHitEndpoint(
        eventAddressForm.getDistrictAddress.event,
        dependencyContextMapper(
          eventAddressForm.getDistrictAddress.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    }
  }),
  getPostalCode: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoadingPostalCode(true)
      actions.setErrorPostalCode(false)
      const { apiClient } = injections
      const response = await apiClient({
        url: 'core/api/mobile/v1/location/subdistricts',
        method: 'GET',
        params: {
          district: payload,
          type: 'distinct',
        },
      })
      sendLogHitEndpoint(
        eventAddressForm.getPostalCode.event,
        dependencyContextMapper(
          eventAddressForm.getPostalCode.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      if (response.data.result) {
        actions.setPostalCode(response.data.result)
      }
      actions.setLoadingPostalCode(false)
    } catch (err) {
      actions.setErrorPostalCode(true)
      actions.error(err.message)
      sendLogHitEndpoint(
        eventAddressForm.getPostalCode.event,
        dependencyContextMapper(
          eventAddressForm.getPostalCode.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
    }
  }),
  postAddress: thunk(async (actions, payload, { injections }) => {
    const eventAddAddress =
      payload.addressType === 'shipping'
        ? eventAddressForm.submitShippingAddress
        : eventAddressForm.submitBillingAddress
    try {
      actions.setLoadingPostAddress(true)
      actions.setResetForm(false)
      const { apiClient } = injections
      const response = await apiClient({
        url: 'marketplace/v1/customer/addresses',
        method: 'POST',
        data: {
          address_name: payload.addressName,
          address_type: payload.addressType,
          customer_name: payload.receipantName,
          phone: payload.phoneNumber,
          address: payload.detailAddress,
          district_id: parseInt(payload.countryAddress, 10),
          city_id: parseInt(payload.postalCode.city_id, 10),
          province_id: parseInt(payload.postalCode.province_id, 10),
          postal_code: payload.postalCode.postal_code,
          latitude: payload.pinPointAddress.lat?.toString(),
          longitude: payload.pinPointAddress.lng?.toString(),
          is_primary: payload.defaultAddress,
          pin_point_address:
            payload.pinPointAddress?.formattedAddress,
        },
      })
      actions.setLoadingPostAddress(false)
      if (response?.status === 200) {
        sendLogHitEndpoint(
          eventAddAddress.event,
          dependencyContextMapper(
            eventAddAddress.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        actions.setResetForm(true)
        return response
      }
      return null
    } catch (err) {
      actions.error(err.message)
      sendLogHitEndpoint(
        eventAddAddress.event,
        dependencyContextMapper(
          eventAddAddress.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      const message = validationResponseHelper(
        err,
        'top-center',
        true,
        {
          toastClientSideError: false,
        },
      )
      Toast(message, {
        type: 'error',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        pauseOnHover: true,
        toastId: 'error-post-address',
      })
      return null
    }
  }),
  putAddress: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoadingPostAddress(true)
      actions.setResetForm(false)
      const { apiClient } = injections
      const response = await apiClient({
        url: `marketplace/v1/customer/addresses/${payload.id}`,
        method: 'PUT',
        data: {
          address_name: payload.address_name,
          address_type: payload.address_type,
          customer_name: payload.customer_name,
          phone: payload.phone,
          address: payload.address,
          district_id: payload.district_id,
          city_id: payload.city_id,
          province_id: payload.province_id,
          postal_code: payload.postal_code,
          latitude: payload?.latitude,
          longitude: payload?.longitude,
          is_primary: payload.is_primary,
          pin_point_address: payload?.pin_point_address,
        },
      })
      actions.setLoadingPostAddress(false)
      if (response?.status === 200) {
        sendLogHitEndpoint(
          eventAddressForm.putAddress.event,
          dependencyContextMapper(
            eventAddressForm.putAddress.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        actions.setResetForm(true)
        return true
      }
      return null
    } catch (err) {
      actions.setLoadingPostAddress(false)
      sendLogHitEndpoint(
        eventAddressForm.putAddress.event,
        dependencyContextMapper(
          eventAddressForm.putAddress.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      const message = validationResponseHelper(
        err,
        'top-center',
        true,
        {
          toastClientSideError: false,
        },
      )
      Toast(message, {
        type: 'error',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        pauseOnHover: true,
        toastId: 'error-put-address',
      })
      return null
    }
  }),
  deleteAddress: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setLoadingPostAddress(true)
      actions.setResetForm(false)
      const { apiClient } = injections
      const response = await apiClient({
        url: `marketplace/v1/customer/addresses/${payload.id}`,
        method: 'DELETE',
      })

      actions.setLoadingPostAddress(false)
      if (response?.status === 200) {
        sendLogHitEndpoint(
          eventAddressForm.deleteAddress.event,
          dependencyContextMapper(
            eventAddressForm.deleteAddress.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )

        Toast(response.data.message, {
          type: 'success',
          theme: 'colored',
          closeButton: true,
          hideProgressBar: false,
          position: 'top-center',
          pauseOnHover: true,
          toastId: 'success-delete-address',
        })

        actions.setResetForm(true)
        return response.data
      }
      return null
    } catch (err) {
      actions.setLoadingPostAddress(false)
      sendLogHitEndpoint(
        eventAddressForm.deleteAddress.event,
        dependencyContextMapper(
          eventAddressForm.deleteAddress.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      const message = validationResponseHelper(
        err,
        'top-center',
        true,
        {
          toastClientSideError: false,
        },
      )
      Toast(message, {
        toastId: message,
        type: 'error',
        theme: 'colored',
        closeButton: true,
        hideProgressBar: false,
        position: 'top-center',
        pauseOnHover: true,
      })
      return null
    }
  }),
  setErrorPostalCode: action((state, payload) => {
    state.isErrorPostalCode = payload
  }),
  setErrorDistrict: action((state, payload) => {
    state.isErrorDistrict = payload
  }),
  error: action((state, payload) => {
    state.isLoadingDistrict = false
    state.isLoadingPostalCode = false
    state.isLoadingPostAddress = false
    state.errMessage = payload
  }),
}

export default AddressFormModel
