import CrossIcon from 'assets/svg/alert/cross.svg'
import ErrorIcon from 'assets/svg/alert/error.svg'
import InfoIcon from 'assets/svg/alert/info.svg'
import SuccessIcon from 'assets/svg/alert/success.svg'
import theme from 'common/theme'
import styled from 'styled-components'

interface IAlertProps {
  type?: string
  margin?: string | number
  minWidth?: string
  onClose?: any
  children: any
  title?: string
}

const setType = (type) => {
  switch (type) {
    case 'error':
      return `
        background: ${theme.alert.error.background};
      `
    case 'info':
      return `
        background: ${theme.alert.info.background};
      `
    case 'success':
      return `
        background: ${theme.alert.success.background};
      `
    default:
      return `
        background: ${theme.alert.default.background};
      `
  }
}

const StyledAlert = styled.div`
  ${(props) => setType(props.type)}
  margin: ${(props) => props.margin};
  color: ${theme.colors.white};
  padding: ${theme.alert.padding};
  display: flex;
  align-items: center;
  border-radius: ${theme.radius.base};
  font-size: ${theme.alert.fontSize};
  min-width: ${(props) => props.minWidth};
`
const WrapperIcon = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  margin-right: 5px;
  display: flex;
  min-width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`

const Content = styled.div`
  padding: 4px 0;
  margin-right: 15px;
  margin-left: 10px;
  word-break: break-all;
`

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 6px;
`

const WrapperCloseIcon = styled.div`
  margin-left: auto;
  cursor: pointer;
`

const renderIcon = (type): JSX.Element => {
  switch (type) {
    case 'info':
      return <InfoIcon />
    case 'success':
      return <SuccessIcon />
    default:
      return <ErrorIcon />
  }
}

function Alert({
  type = '',
  margin = 0,
  onClose = null,
  minWidth = '300px',
  children,
  title,
  ...otherProps
}: IAlertProps): JSX.Element {
  const alertType = ['error', 'info', 'success']
  return (
    <StyledAlert
      type={type}
      margin={margin}
      minWidth={minWidth}
      data-testid="alert-component"
      {...otherProps}
    >
      {alertType.indexOf(type) > -1 && (
        <WrapperIcon>{renderIcon(type)}</WrapperIcon>
      )}
      <Content>
        {title && <Title>{title}</Title>}
        {children}
      </Content>
      {onClose && (
        <WrapperCloseIcon
          data-testid="alert-close-icon"
          onClick={() => onClose()}
        >
          <CrossIcon />
        </WrapperCloseIcon>
      )}
    </StyledAlert>
  )
}

export default Alert
