export default {
  LANGUAGE: 'Language',
  WHY_RALALI: 'Why Ralali',
  BUSINESS_SOLUTIONS: 'Business Solutions',
  MEMBERSHIP: 'Membership',
  CONTACT_US: 'Contact Us',
  VISIT_MARKETPLACE: 'Visit Marketplace',
  SEND: 'Send',
  FULL_NAME: 'Full Name',
  EMAIL: 'Email',
  PHONE_NUMBER: 'Phone Number',
  COMPANY_NAME: 'Company Name',
  NEEDS: 'Needs',
  YOUR_MESSAGE: 'Your Message',
  YOUR_FULL_NAME: 'Your Full Name',
  YOUR_EMAIL: 'Your Email',
  YOUR_PHONE_NUMBER: 'Your Phone Number',
  YOUR_COMPANY_NAME: 'Your Company Name',
  CHOOSE_YOUR_NEEDS: 'Choose Your Needs',
  WRITE_YOUR_MESSAGE: 'Write Your Message',
  FIELD_IS_REQUIRED: '{{field}} is Required',
  INVALID_FIELD_FORMAT: 'Invalid {{field}} format',
  RALALI: 'Ralali',
  RALALI_TENDER: 'Ralali Tender',
  RALALI_PLUS: 'Ralali Plus',
  RALALI_DIRECT: 'Ralali Direct',
  RALALI_PAVILION: 'Ralali Pavilion',
  RALALI_AGENT: 'Ralali Agent',
  RALALI_FOOD_VENTURE: 'Ralali Food Venture',
  RALALI_FOOD: 'Ralali Food',
  FIND_US: 'Find Us',
  ABOUT_US: 'About Us',
  RALALI_COM: 'Ralali.com',
  SERVICES: 'Services',
  CAREERS: 'Careers',
  NEWS: 'News',
  EVENT: 'Event',
  ALL_BRANDS: 'All Brands',
  BUYER: 'Buyer',
  HOW_TO_SHOP: 'How To Shop',
  VIEW_PROMOS: 'View Promos',
  PAYMENT_METHODS: 'Payment Methods',
  POPULAR_SEARCH: 'Popular Search',
  LATEST_PRODUCTS: 'Latest Products',
  TAGS: 'Tags',
  HOW_TO_SELL: 'How To Sell',
  SELLING_AT_RALALI: 'Selling at Ralali.com',
  SELLER_CENTER: 'Seller Center',
  HELP: 'Help',
  HELLO_RALALI: 'Hello Ralali',
  FAQ: 'FAQ',
  SECURITY_N_PRIVACY: 'Security and Privacy',
  FINANCIAL_PARTNERS: 'Financial Partners',
  SHIPPING_METHODS: 'Shipping Methods',
  SELLER: 'Seller',
  ENDS_ON: 'Ends on',
  VALUE: 'Value',
  FOOTER_DESCRIPTION:
    'Ralali is the largest online B2B platform that provides convenience in the process of buying and selling transactions through technology and features that help sellers and buyers conduct their business more easily, securely, and transparently.',
  TITLE_EXTRA_MENU: 'View All Solutions',
  DESCRIPTION_EXTRA_MENU:
    'Ralali Business Solutions provide various conveniences for your business to grow and achieve maximum profits.',
  LOGIN_REGISTER: 'Login / Register',
  NEXT: 'Next',
  ERROR_OCCURS: 'Error Occurs',
  MARKETPLACE: 'Marketplace',
  EVENT_AND_NEWS: 'Events & News',
  ALL_CATEGORIES: 'All Categories',
  OTHERS: 'Others',
  SEE_MORE_SOLUTIONS: 'See More Solutions',
}
