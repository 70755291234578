import toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import validationResponseHelper from 'lib/validation-response-handler'
import eventLogistic from 'services/Logistic/events'
import ILogisticModel from 'services/Logistic/models/LogisticModel/interface'

const LogisticModel: ILogisticModel = {
  isLoading: false,
  isError: false,
  shippingCostEstimationData: null,
  locationList: [],
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setIsError: action((state, payload) => {
    state.isError = payload
  }),
  setLocationList: action((state, payload) => {
    if (payload?.data?.items) {
      const data = payload.data.items.map((item) => {
        return {
          value: item.id,
          label: item.display_short_text,
        }
      })
      state.locationList = data
    } else {
      state.locationList = []
    }
  }),
  // eslint-disable-next-line consistent-return
  getLocationList: thunk(
    async (
      actions,
      { administrativeType, keywordShortText },
      { injections },
    ) => {
      try {
        actions.setIsLoading(true)
        actions.setIsError(false)
        const { apiClient } = injections
        const response = await apiClient({
          url: `/logistic/v1/locations?keyword_short_text=${keywordShortText}&administrative_type=${administrativeType}`,
          method: 'GET',
        })
        if (response?.status === 200) {
          actions.setLocationList(response?.data)
        }

        sendLogHitEndpoint(
          {
            ...eventLogistic.getLocationList.event,
          },
          dependencyContextMapper(
            eventLogistic.getLocationList.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )

        return response?.data?.data?.items
      } catch (error) {
        sendLogHitEndpoint(
          {
            ...eventLogistic.getLocationList.event,
          },
          dependencyContextMapper(
            eventLogistic.getLocationList.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
        actions.error()
        return undefined
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  setShippingCostEstimationData: action((state, payload) => {
    state.shippingCostEstimationData = {
      ...state?.shippingCostEstimationData,
      ...payload,
    }
  }),
  resetShippingCostEstimationData: action((state) => {
    state.shippingCostEstimationData = null
  }),
  getShippingCostEstimation: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: `/marketplace/${payload.apiVersion}/logistic/shipping-cost-estimation?address_id=${payload.addressId}&vendor_id=${payload?.vendorId}`,
          method: 'GET',
        })
        if (response?.status === 200) {
          actions.setShippingCostEstimationData(response?.data?.data)
        }
        sendLogHitEndpoint(
          {
            ...eventLogistic.getShippingCostEstimation.event,
          },
          dependencyContextMapper(
            eventLogistic.getShippingCostEstimation.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        return
      } catch (error) {
        const message = validationResponseHelper(
          error,
          'top-center',
          true,
          {
            toastClientSideError: false,
          },
        )

        toast(message, {
          type: 'error',
          theme: 'colored',
          hideProgressBar: false,
          autoClose: 5000,
          pauseOnHover: true,
          position: 'top-center',
          toastId: 'error-get-shipping-cost-est',
        })
        sendLogHitEndpoint(
          {
            ...eventLogistic.getShippingCostEstimation.event,
          },
          dependencyContextMapper(
            eventLogistic.getShippingCostEstimation.dependency,
            requestMapper(error.config),
            errorMapper(error),
          ),
        )
        actions.error()
        throw error
      }
    },
  ),
  error: action((state) => {
    state.isError = true
  }),
}

export default LogisticModel
