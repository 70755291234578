export default {
  validateSlug: {
    event: { name: 'Store', state: 'ValidateSlug' },
    dependency: {
      name: 'API Gateway',
      event: 'ValidateSlug',
      service: 'rll-gw-api',
    },
  },
  createStore: {
    event: { name: 'Store', state: 'CreateStore' },
    dependency: {
      name: 'API Gateway',
      event: 'CreateStore',
      service: 'rll-gw-api',
    },
  },
  getStoreList: {
    event: { name: 'Store', state: 'GetStoreList' },
    dependency: {
      name: 'API Gateway',
      event: 'GetStoreList',
      service: 'rll-gw-api',
    },
  },
  getStore: {
    event: { name: 'Store', state: 'GetStore' },
    dependency: {
      name: 'API Gateway',
      event: 'GetStore',
      service: 'rll-gw-api',
    },
  },
  getStoreLocation: {
    event: { name: 'Store', state: 'GetStoreLocation' },
    dependency: {
      name: 'API Gateway',
      event: 'GetStoreLocation',
      service: 'rll-gw-api',
    },
  },
  updateStore: {
    event: { name: 'Store', state: 'UpdateStore' },
    dependency: {
      name: 'API Gateway',
      event: 'UpdateStore',
      service: 'rll-gw-api',
    },
  },
  uploadFile: {
    event: { name: 'Store', state: 'UploadFile' },
    dependency: {
      name: 'API Gateway',
      event: 'UploadFile',
      service: 'rll-gw-api',
    },
  },
  getShippingMethod: {
    event: { name: 'Store', state: 'GetShippingMethod' },
    dependency: {
      name: 'API Gateway',
      event: 'GetShippingMethod',
      service: 'rll-gw-api',
    },
  },
  updateShippingMethod: {
    event: { name: 'Store', state: 'UpdateShippingMethod' },
    dependency: {
      name: 'API Gateway',
      event: 'UpdateShippingMethod',
      service: 'rll-gw-api',
    },
  },
  updateStoreLocation: {
    event: { name: 'Store', state: 'UpdateStoreLocation' },
    dependency: {
      name: 'API Gateway',
      event: 'UpdateStoreLocation',
      service: 'rll-gw-api',
    },
  },
  getUserActionState: {
    event: { name: 'Store', state: 'GetUserActionState' },
    dependency: {
      name: 'API Gateway',
      event: 'GetUserActionState',
      service: 'rll-gw-api',
    },
  },
  updateUserActionState: {
    event: { name: 'Store', state: 'UpdateUserActionState' },
    dependency: {
      name: 'API Gateway',
      event: 'UpdateUserActionState',
      service: 'rll-gw-api',
    },
  },
  getStoreStatistics: {
    event: { name: 'Store', state: 'GetStoreStatistics' },
    dependency: {
      name: 'API Gateway',
      event: 'GetStoreStatistics',
      service: 'rll-gw-api',
    },
  },
  getStoreSummaries: {
    event: { name: 'Store', state: 'GetStoreSummaries' },
    dependency: {
      name: 'API Gateway',
      event: 'GetStoreSummaries',
      service: 'rll-gw-api',
    },
  },
  getDeliveryOrder: {
    event: { name: 'Store', state: 'GetDeliveryOrder' },
    dependency: {
      name: 'API Gateway',
      event: 'GetDeliveryOrder',
      service: 'rll-gw-api',
    },
  },
  getOrderInvoice: {
    event: { name: 'Store', state: 'GetOrderInvoice' },
    dependency: {
      name: 'API Gateway',
      event: 'GetOrderInvoice',
      service: 'rll-gw-api',
    },
  },
  getOrderDetail: {
    event: { name: 'Store', state: 'GetOrderDetail' },
    dependency: {
      name: 'API Gateway',
      event: 'GetOrderDetail',
      service: 'rll-gw-api',
    },
  },
  getTimeSlotPickup: {
    event: { name: 'Store', state: 'GetTimeSlotPickup' },
    dependency: {
      name: 'API Gateway',
      event: 'GetTimeSlotPickup',
      service: 'rll-gw-api',
    },
  },
  postRequestPickup: {
    event: { name: 'Store', state: 'PostRequestPickup' },
    dependency: {
      name: 'API Gateway',
      event: 'PostRequestPickup',
      service: 'rll-gw-api',
    },
  },
  updateOrderStatus: {
    event: { name: 'Store', state: 'UpdateOrderStatus' },
    dependency: {
      name: 'API Gateway',
      event: 'UpdateOrderStatus',
      service: 'rll-gw-api',
    },
  },
  uploadFileOrders: {
    event: { name: 'Store', state: 'UploadFileOrders' },
    dependency: {
      name: 'API Gateway',
      event: 'UploadFileOrders',
      service: 'rll-gw-api',
    },
  },
  downloadShippingLabel: {
    event: { name: 'Store', state: 'DownloadShippingLabel' },
    dependency: {
      name: 'API Gateway',
      event: 'DownloadShippingLabel',
      service: 'rll-gw-api',
    },
  },
  getStoreBrands: {
    event: { name: 'Store', state: 'GetStoreBrands' },
    dependency: {
      name: 'API Gateway',
      event: 'GetStoreBrands',
      service: 'rll-gw-api',
    },
  },
  getStoreCategories: {
    event: { name: 'Store', state: 'GetStoreCategories' },
    dependency: {
      name: 'API Gateway',
      event: 'GetStoreCategories',
      service: 'rll-gw-api',
    },
  },
  getOperationalStore: {
    event: { name: 'Store', state: 'GetOperationalStore' },
    dependency: {
      name: 'API Gateway',
      event: 'GetOperationalStore',
      service: 'rll-gw-api',
    },
  },
  PatchOperationalStore: {
    event: { name: 'Store', state: 'updateOperationalStore' },
    dependency: {
      name: 'API Gateway',
      event: 'updateOperationalStore',
      service: 'rll-gw-api',
    },
  },
  getSummary: {
    event: { name: 'Store', state: 'GetSummary' },
    dependency: {
      name: 'API Gateway',
      event: 'GetSummary',
      service: 'rll-gw-api',
    },
  },
  getSummaryProduct: {
    event: { name: 'Store', state: 'GetSummaryProduct' },
    dependency: {
      name: 'API Gateway',
      event: 'GetSummaryProduct',
      service: 'rll-gw-api',
    },
  },
  getOrderReport: {
    event: { name: 'Store', state: 'GetOrderReport' },
    dependency: {
      name: 'API Gateway',
      event: 'GetOrderReport',
      service: 'rll-gw-api',
    },
  },
  getTopProduct: {
    event: { name: 'Store', state: 'GetTopProduct' },
    dependency: {
      name: 'API Gateway',
      event: 'GetTopProduct',
      service: 'rll-gw-api',
    },
  },
  getTopBuyer: {
    event: { name: 'Store', state: 'GetTopBuyer' },
    dependency: {
      name: 'API Gateway',
      event: 'GetTopBuyer',
      service: 'rll-gw-api',
    },
  },
  getTopCategories: {
    event: { name: 'Store', state: 'GetTopCategories' },
    dependency: {
      name: 'API Gateway',
      event: 'GetTopCategories',
      service: 'rll-gw-api',
    },
  },
  getExportReport: {
    event: { name: 'Store', state: 'GetExportReport' },
    dependency: {
      name: 'API Gateway',
      event: 'GetExportReport',
      service: 'rll-gw-api',
    },
  },
  getExportProductReport: {
    event: { name: 'Store', state: 'GetExportProductReport' },
    dependency: {
      name: 'API Gateway',
      event: 'GetExportProductReport',
      service: 'rll-gw-api',
    },
  },
  getTopLocation: {
    event: { name: 'Store', state: 'GetTopLocation' },
    dependency: {
      name: 'API Gateway',
      event: 'GetTopLocation',
      service: 'rll-gw-api',
    },
  },
  getExportBuyerReport: {
    event: { name: 'Store', state: 'GetExportBuyerReport' },
    dependency: {
      name: 'API Gateway',
      event: 'GetExportBuyerReport',
      service: 'rll-gw-api',
    },
  },
  getBuyerReport: {
    event: { name: 'Store', state: 'GetBuyerReport' },
    dependency: {
      name: 'API Gateway',
      event: 'GetBuyerReport',
      service: 'rll-gw-api',
    },
  },
}
