import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import onboardingEvent from 'services/Seller/events'
import IUserStoreModel from 'services/Seller/models/UserStoreModel/interface'

const UserStoreModel: IUserStoreModel = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setErrorMessage: action((state, payload) => {
    state.errorMessage = payload
  }),
  error: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  postCreateStore: thunk(async (actions, payload, { injections }) => {
    try {
      actions.setIsLoading(true)
      actions.setErrorMessage('')
      const { apiClient } = injections
      const response = await apiClient({
        url: '/store/v1/stores',
        method: 'POST',
        data: payload,
      })
      sendLogHitEndpoint(
        onboardingEvent.createStore.event,
        dependencyContextMapper(
          onboardingEvent.createStore.dependency,
          requestMapper(response.config),
          responseMapper(response),
        ),
      )
      return response.data.data
    } catch (err) {
      sendLogHitEndpoint(
        onboardingEvent.createStore.event,
        dependencyContextMapper(
          onboardingEvent.createStore.dependency,
          requestMapper(err.config),
          errorMapper(err),
        ),
      )
      Toast(err?.response?.data?.message, {
        type: 'error',
        theme: 'colored',
        hideProgressBar: false,
        autoClose: 5000,
        pauseOnHover: true,
        toastId: 'err-post-store-toast',
      })
      actions.error(err?.response?.data?.message)
      return undefined
    } finally {
      actions.setIsLoading(false)
    }
  }),
}

export default UserStoreModel
