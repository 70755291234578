import { action, thunk } from 'easy-peasy'
import ISearchPopularModel from 'services/Product/stores/models/SearchPopularModel/interface'

const SearchPopularModel: ISearchPopularModel = {
  isSearchPopularLoading: true,
  isError: false,
  searchPopular: [],
  setSearchPopular: action((state, payload) => {
    state.isSearchPopularLoading = false
    state.searchPopular = payload
  }),
  setLoading: action((state, payload) => {
    state.isSearchPopularLoading = payload
  }),
  getSearchPopular: thunk(
    async (actions, payload, { injections }) => {
      try {
        const { apiClient } = injections
        const response = await apiClient({
          url: '/core/api/v2/search/quick-search',
          method: 'GET',
        })
        if (response?.data?.result?.data.length) {
          actions.setSearchPopular(response.data.result.data)
        }
      } catch (err) {
        actions.error()
      }
    },
  ),
  error: action((state) => {
    state.isSearchPopularLoading = false
    state.isError = true
  }),
}

export default SearchPopularModel
