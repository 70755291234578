import Toast from 'common/components/Toast'
import { action, thunk } from 'easy-peasy'
import {
  dependencyContextMapper,
  errorMapper,
  requestMapper,
  responseMapper,
  sendLogHitEndpoint,
} from 'lib/datadog/log'
import sellerEvent from 'services/Seller/events'
import IStoreLocationModel from 'services/Seller/models/StoreLocationModel/interface'
import refetchProgress from 'services/Seller/widgets/CompletionBanner/utils'

export const MOCK_STORE_LOCATIONS: any[] = [
  {
    id: '1658035764006703105',
    type: 'store',
    address:
      '6, Jl. Lkr. Luar Barat No.88, RT.6/RW.1, Kembangan Utara, Kec. Kembangan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11610, Indonesia',
    location_id: 12604,
    location_display_text:
      'Kembangan Utara, Jakarta Barat, DKI Jakarta',
    postal_code: '',
    latitude: -6.1767545,
    longitude: 106.72895,
    contacts: {},
  },
  {
    id: '1645255635853905921',
    type: 'warehouse',
    address:
      '6, Jl. Lkr. Luar Barat No.88, RT.6/RW.1, Kembangan Utara, Kec. Kembangan, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta 11610, Indonesia',
    location_id: 12604,
    location_display_text:
      'Kembangan Utara, Jakarta Barat, DKI Jakarta',
    postal_code: '',
    latitude: -6.1767545,
    longitude: 106.72895,
    contacts: {
      name: 'Zaky',
      telephone_number: '+628136956311',
      handphone_number: '+622121356789',
    },
    is_same_with_store: 'no',
  },
]

const StoreLocationModel: IStoreLocationModel = {
  data: [],
  isError: false,
  isLoading: false,
  errorMessage: '',
  resetError: action((state) => {
    state.isError = false
    state.errorMessage = ''
  }),
  setData: action((state, payload) => {
    state.data = payload
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload
  }),
  setError: action((state, payload) => {
    state.isError = true
    state.errorMessage = payload
  }),
  getStoreLocation: thunk(
    async (actions, storeId, { injections }) => {
      try {
        actions.resetError()
        actions.setIsLoading(true)

        const { apiClient } = injections
        const response = await apiClient({
          url: `/store/v1/stores/${storeId}/locations`,
          method: 'GET',
        })

        sendLogHitEndpoint(
          sellerEvent.getStoreLocation.event,
          dependencyContextMapper(
            sellerEvent.getStoreLocation.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )
        if (response.status === 200) {
          actions.setData(response.data.data)
        }
      } catch (err) {
        sendLogHitEndpoint(
          sellerEvent.getStoreLocation.event,
          dependencyContextMapper(
            sellerEvent.getStoreLocation.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setError(err?.response?.data?.message)
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
  updateStoreLocation: thunk(
    // eslint-disable-next-line consistent-return
    async (actions, { storeId, locations }, { injections }) => {
      try {
        actions.resetError()
        actions.setIsLoading(true)

        const { apiClient } = injections
        const response = await apiClient({
          url: `/store/v1/stores/${storeId}/locations`,
          method: 'PUT',
          data: {
            locations,
          },
        })

        sendLogHitEndpoint(
          sellerEvent.updateStoreLocation.event,
          dependencyContextMapper(
            sellerEvent.updateStoreLocation.dependency,
            requestMapper(response.config),
            responseMapper(response),
          ),
        )

        if (response.status === 200) {
          Toast(`Berhasil memperbarui lokasi`, {
            toastId: `location-submit-toast-success`,
            type: 'success',
            theme: 'colored',
            position: 'top-center',
          })
          actions.getStoreLocation(storeId)
          refetchProgress('store-locations')
        }
      } catch (err) {
        Toast(`Gagal memperbarui lokasi`, {
          toastId: `location-submit-toast-failed`,
          type: 'error',
          theme: 'colored',
          position: 'top-center',
        })
        sendLogHitEndpoint(
          sellerEvent.updateStoreLocation.event,
          dependencyContextMapper(
            sellerEvent.updateStoreLocation.dependency,
            requestMapper(err.config),
            errorMapper(err),
          ),
        )
        actions.setError(err?.response?.data?.message)
      } finally {
        actions.setIsLoading(false)
      }
    },
  ),
}

export default StoreLocationModel
