export default {
  getPopularSearch: {
    event: { name: 'GetPopularSearch', state: 'APICall' },
    dependency: {
      name: 'API Gateway',
      event: 'GetPopularSearch',
      service: 'rll-gw-api',
    },
  },
}
