import Toast from 'common/components/Toast'
import randomizeNumber from 'lib/randomize-number'
import { ToastPosition } from 'react-toastify'

interface IHelper {
  response: any
}
interface IOptions {
  keyName?: string
  toastClientSideError?: boolean
}

const validationResponseHelper = (
  error: IHelper,
  position: ToastPosition = 'top-center',
  closeButton = true,
  options: IOptions = { keyName: '', toastClientSideError: true },
): string => {
  let message = ''

  function objectFilter(object, filter) {
    return { [filter]: object[filter] }
  }

  if (error?.response?.data?.errors) {
    if (options.keyName) {
      const errorMessage = objectFilter(
        error?.response?.data?.errors,
        options.keyName,
      )

      if (Object.values(errorMessage)[0] !== undefined) {
        message = Object.values(errorMessage)?.[0]?.[0]

        return message
      }
      message = Object.values(error?.response?.data?.errors)?.[0]?.[0]

      return message
    }
    message = Object.values(error?.response?.data?.errors)?.[0]?.[0]

    return message
  }
  if (error?.response?.data?.message) {
    message = error.response.data.message
    if (
      error?.response?.data?.name &&
      error.response.data.name === 'client_side_error' &&
      options.toastClientSideError
    ) {
      Toast(error.response.data.message, {
        type: 'error',
        theme: 'colored',
        closeButton,
        hideProgressBar: false,
        position,
        autoClose: 5000,
        pauseOnHover: true,
        toastId: `client-side-error-${randomizeNumber().toString(
          36,
        )}`,
      })
      return ''
    }
  }

  return message
}

export default validationResponseHelper
